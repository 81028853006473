/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import Layout from "../component/Layout";
import DatePicker from "react-datepicker";
import {
  ArrowUp,
  CalendarDays,
  ChartColumn,
  ChevronDown,
  Lightbulb,
  Smile,
  ThumbsDown,
  ThumbsUp,
  TrendingUp,
} from "lucide-react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import KeyInsightAnalysisPieChart from "../component/KeyInsightAnalysisPieChart";
import {
  useGetAiReviewAnalysis,
  usePostAiReviewAnalysis,
} from "../../../query/useReview";
import { toast } from "sonner";
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../../components/ui/table";
import { useGetGmbInfo } from "../../../query/useAuthentication";
import GmbIntegrationModal from "./components/GmbIntegrationModal";

const DateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className="relative w-full" onClick={onClick} ref={ref}>
    <Input
      type="text"
      value={value}
      readOnly
      className="w-full focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0"
      placeholder={placeholder}
    />
    <ChevronDown className="text-[#ebebeb] absolute right-5 top-1/2 -translate-y-1/2 size-4" />
  </div>
));

const KeyInsights = () => {
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setMonth(date.getMonth() - 2);
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [analysis, setAnalysis] = useState();
  const [isGmbIntegrate, setIsGmbItegrate] = useState(true);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const [isGetAi, setIsGetAi] = useState(true);
  const [tab, setTab] = useState("improvements");

  const { data: gmbInfo } = useGetGmbInfo({
    onSuccess: (res) => {
      setIsGmbItegrate(res.GMB_INTEGRATION);
    },
  });

  useGetAiReviewAnalysis({
    onSuccess: (res) => {
      if (res?.data) {
        setAnalysis(res?.data);
      } else {
        setAnalysis(res);
      }
    },
    enabled: isGetAi,
  });

  const { mutate: aiReviewAnalysis, isLoading: aiReviewAnalysisLoading } =
    usePostAiReviewAnalysis();

  const handleAiReviewAnalysis = () => {
    setIsGetAi(false);
    const formData = new FormData();
    formData.append("from_time", startDate?.toISOString());
    formData.append("to_time", endDate?.toISOString());
    aiReviewAnalysis(formData, {
      onSuccess: (res) => {
        setAnalysis(res);
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const positiveKeywords = useMemo(() => {
    return analysis?.ai_summary?.["Frequent Keywords"]?.["Positive"] || [];
  }, [analysis]);

  const negativeKeywords = useMemo(() => {
    return analysis?.ai_summary?.["Frequent Keywords"]?.["Negative"] || [];
  }, [analysis]);

  const mixedKeywords = useMemo(() => {
    let mixed = [...positiveKeywords, ...negativeKeywords];

    for (let i = mixed.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [mixed[i], mixed[j]] = [mixed[j], mixed[i]];
    }

    return mixed;
  }, [analysis]);

  useEffect(() => {
    if (!isGmbIntegrate) {
      setIsOpenLinkModal(true);
    } else {
      setIsOpenLinkModal(false);
    }
  }, [isGmbIntegrate]);

  return (
    <Layout title={"Key Insights"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px] font-pro">
        {gmbInfo?.GMB_INTEGRATION && (
          <>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5  w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm">
              <div className="w-full space-y-3">
                <h1 className="font-pro font-semibold text-[#333] dark:text-white">
                  Select Period
                </h1>

                <div className="flex items-center gap-5 w-full">
                  <div className="space-y-1 w-full relative">
                    <p className="text-[15px]">From</p>
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      customInput={<DateInput placeholder={"From"} />}
                      maxDate={new Date()}
                      wrapperClassName="w-full"
                      placeholderText="From"
                    />
                  </div>
                  <div className="space-y-1 w-full relative">
                    <p className="text-[15px]">To</p>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      customInput={<DateInput placeholder={"To"} />}
                      maxDate={new Date()}
                      wrapperClassName="w-full"
                      placeholderText="To"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full flex flex-col justify-between gap-3">
                <h1 className="font-pro font-semibold text-[#333] dark:text-white">
                  Start AI Analysis
                </h1>
                <div className="flex items-center gap-2">
                  <Button
                    onClick={handleAiReviewAnalysis}
                    className="px-5"
                    disabled={
                      aiReviewAnalysisLoading ||
                      startDate?.getTime() > endDate?.getTime()
                    }
                  >
                    Analyze
                  </Button>
                  <p className="text-[#4f4f4f] dark:text-whit">
                    (This task might take a while.){" "}
                  </p>
                </div>
              </div>
            </div>
            {aiReviewAnalysisLoading ? (
              <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
                <img
                  src="/illustrations/analysing.svg"
                  alt="Questions"
                  className="w-[100px] h-[100px] mx-auto mt-10"
                />
                <h1 className="mt-[20px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                  Analysing
                </h1>
                <p className="mt-[10px] text-[#888888] dark:text-white text-center font-pro">
                  Please be patient. This might take a while.
                </p>
              </div>
            ) : (
              <>
                {analysis ? (
                  <>
                    {analysis?.error ? (
                      <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
                        <img
                          src="/illustrations/no-analysing.svg"
                          alt="Questions"
                          className="w-[100px] h-[100px] mx-auto mt-10"
                        />
                        <h1 className="mt-[20px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                          {analysis?.error}
                        </h1>
                        <p className="mt-[10px] text-[#888888] dark:text-white text-center font-pro">
                          Please try after some time.
                        </p>
                      </div>
                    ) : (
                      <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg px-6 py-3.5 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            {/* <Smile className="text-[#ff9737]" />  */}
                            Sentiment Analysis
                          </h1>

                          <div className="px-6 pb-6 mt-5">
                            <KeyInsightAnalysisPieChart
                              data={analysis?.sentiment}
                            />
                          </div>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg px-6 py-3.5 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            {/* <ArrowUp className="text-[#4f46ba]" />  */}
                            Top Keywords
                          </h1>
                          {/* <div className="flex flex-wrap gap-2 mt-5 px-6 pb-6">
                            {analysis?.ai_summary?.["Frequent Keywords"]?.[
                              "Positive"
                            ]?.map((positive) => (
                              <div
                                key={positive}
                                className="bg-[#4f75fa]/10 text-[#4f75fa] rounded-[7px] px-2 py-1.5 text-xs font-medium"
                              >
                                {positive}
                              </div>
                            ))}
                            {analysis?.ai_summary?.["Frequent Keywords"]?.[
                              "Negative"
                            ]?.map((negative) => (
                              <div
                                key={negative}
                                className="bg-[#F2E8FC] text-[#615e83] rounded-[7px] px-2 py-1.5 text-xs font-medium"
                              >
                                {negative}
                              </div>
                            ))}
                          </div> */}
                          <div className="flex flex-wrap gap-2 mt-5 px-6 pb-6">
                            {mixedKeywords?.map((item, i) => (
                              <div
                                key={item}
                                className={`rounded-[7px] px-2 py-1.5 text-xs font-medium ${
                                  positiveKeywords.includes(item)
                                    ? "bg-[#4f75fa]/10 text-[#4f75fa]"
                                    : "bg-[#f9c33c]/10 text-[#ff953a]"
                                }`}
                              >
                                {item}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm hidden lg:flex flex-col overflow-hidden lg:col-span-2">
                          <div className="flex items-center justify-between bg-[#fafafa] dark:bg-darkBg border-t rounded-t-[12px] border-t-[#ebebeb]">
                            <button
                              className={`w-full h-full font-pro font-semibold dark:bg-darkBg py-3.5  border border-t-0 border-[#EBEBEB] text-[#333] dark:text-white flex items-center justify-center gap-2 text-[15px] ${tab === "improvements" ? "rounded-tr-[12px] bg-white" : "bg-[#fafafa] border-l-0 text-[#8c8c8c]"}`}
                              onClick={() => {
                                setTab("improvements");
                              }}
                            >
                              <TrendingUp
                                className={`${
                                  tab === "improvements"
                                    ? "text-[#2a49b8]"
                                    : "text-[#bfbfbf]"
                                } size-[18px]`}
                              />{" "}
                              Improvements
                            </button>
                            <button
                              className={`w-full h-full font-pro font-semibold dark:bg-darkBg py-3.5  border border-t-0 border-[#EBEBEB] text-[#333] dark:text-white flex items-center justify-center gap-2 text-[15px] ${tab === "likes" ? "rounded-tr-[12px] bg-white" : "bg-[#fafafa] border-l-0 text-[#8c8c8c]"}`}
                              onClick={() => {
                                setTab("likes");
                              }}
                            >
                              <ThumbsUp
                                className={`${
                                  tab === "likes"
                                    ? "text-[#34a853]"
                                    : "text-[#bfbfbf]"
                                } size-[18px]`}
                              />{" "}
                              Likes
                            </button>
                            <button
                              className={`w-full h-full font-pro font-semibold dark:bg-darkBg py-3.5  border border-t-0 border-[#EBEBEB] text-[#333] dark:text-white flex items-center justify-center gap-2 text-[15px] ${tab === "dislikes" ? "rounded-tr-[12px] bg-white" : "bg-[#fafafa] border-l-0 text-[#8c8c8c]"}`}
                              onClick={() => {
                                setTab("dislikes");
                              }}
                            >
                              <ThumbsDown
                                className={`${
                                  tab === "dislikes"
                                    ? "text-[#ff6464]"
                                    : "text-[#bfbfbf]"
                                } size-[18px]`}
                              />{" "}
                              Dislikes
                            </button>
                            <button
                              className={`w-full h-full font-pro font-semibold dark:bg-darkBg py-3.5 border border-t-0 border-[#EBEBEB] text-[#333] dark:text-white flex items-center justify-center gap-2 text-[15px] ${tab === "feature" ? "rounded-tr-[12px] bg-white" : "bg-[#fafafa] border-l-0 text-[#8c8c8c]"}`}
                              onClick={() => {
                                setTab("feature");
                              }}
                            >
                              <Lightbulb
                                className={`${
                                  tab === "feature"
                                    ? "text-[#ffaf19]"
                                    : "text-[#bfbfbf]"
                                } size-[18px]`}
                              />{" "}
                              Feature Requests
                            </button>
                            <button
                              className={`w-full h-full font-pro font-semibold dark:bg-darkBg py-3.5 border border-t-0 border-[#EBEBEB] text-[#333] dark:text-white flex items-center justify-center gap-2 text-[15px] ${tab === "competitor" ? "rounded-tr-[12px] bg-white" : "bg-[#fafafa] border-l-0 text-[#8c8c8c]"}`}
                              onClick={() => {
                                setTab("competitor");
                              }}
                            >
                              <ChartColumn
                                className={`${
                                  tab === "competitor"
                                    ? "text-[#7459d9]"
                                    : "text-[#bfbfbf]"
                                } size-[18px]`}
                              />{" "}
                              Competitor Comparison
                            </button>
                          </div>
                          <div className="h-[40vh] overflow-y-auto scrollbar-thin">
                            {tab === "improvements" && (
                              <ul className="mt-5 divide-y list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                                {Array.isArray(
                                  analysis?.ai_summary?.["Improvements"]
                                ) ? (
                                  analysis?.ai_summary?.["Improvements"]?.map(
                                    (item, i) => (
                                      <li key={i} className="py-2">
                                        {item}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <pre className="py-2 font-pro">
                                    {analysis?.ai_summary?.["Improvements"]}
                                  </pre>
                                )}
                              </ul>
                            )}
                            {tab === "likes" && (
                              <ul className="mt-5 divide-y list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                                {Array.isArray(
                                  analysis?.ai_summary?.["Likes"]
                                ) ? (
                                  analysis?.ai_summary?.["Likes"]?.map(
                                    (item, i) => (
                                      <li key={i} className="py-2 ">
                                        {item}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <pre className="py-2 font-pro">
                                    {analysis?.ai_summary?.["Likes"]}
                                  </pre>
                                )}
                              </ul>
                            )}
                            {tab === "dislikes" && (
                              <ul className="mt-5 divide-y list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                                {Array.isArray(
                                  analysis?.ai_summary?.["Dislikes"]
                                ) ? (
                                  analysis?.ai_summary?.["Dislikes"]?.map(
                                    (item, i) => (
                                      <li key={i} className="py-2">
                                        {item}
                                      </li>
                                    )
                                  )
                                ) : (
                                  <pre className="py-2 font-pro">
                                    {analysis?.ai_summary?.["Dislikes"]}
                                  </pre>
                                )}
                              </ul>
                            )}
                            {tab === "feature" && (
                              <ul className="mt-5 divide-y list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                                {Array.isArray(
                                  analysis?.ai_summary?.["Feature Requests"]
                                ) ? (
                                  analysis?.ai_summary?.[
                                    "Feature Requests"
                                  ]?.map((item, i) => (
                                    <li key={i} className="py-2 ">
                                      {item}
                                    </li>
                                  ))
                                ) : (
                                  <pre className="py-2 font-pro">
                                    {analysis?.ai_summary?.["Feature Requests"]}
                                  </pre>
                                )}
                              </ul>
                            )}
                            {tab === "competitor" && (
                              <ul className="mt-5 divide-y list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                                {Array.isArray(
                                  analysis?.ai_summary?.[
                                    "Competitor Comparison"
                                  ]
                                ) ? (
                                  analysis?.ai_summary?.[
                                    "Competitor Comparison"
                                  ]?.map((item, i) => (
                                    <li key={i} className="py-2">
                                      {item}
                                    </li>
                                  ))
                                ) : (
                                  <pre className="py-2 font-pro">
                                    {
                                      analysis?.ai_summary?.[
                                        "Competitor Comparison"
                                      ]
                                    }
                                  </pre>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:col-span-2 lg:hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg p-6 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            <TrendingUp className="text-[#2a49b8]" />{" "}
                            Improvements
                          </h1>
                          <ul className="mt-5 divide-y list-disc list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                            {Array.isArray(
                              analysis?.ai_summary?.["Improvements"]
                            ) ? (
                              analysis?.ai_summary?.["Improvements"]?.map(
                                (item, i) => (
                                  <li key={i} className="py-2">
                                    {item}
                                  </li>
                                )
                              )
                            ) : (
                              <pre className="py-2 font-pro">
                                {analysis?.ai_summary?.["Improvements"]}
                              </pre>
                            )}
                          </ul>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg p-6 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            <ThumbsUp className="text-[#34a853]" /> Likes
                          </h1>
                          <ul className="mt-5 divide-y list-disc list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                            {Array.isArray(analysis?.ai_summary?.["Likes"]) ? (
                              analysis?.ai_summary?.["Likes"]?.map(
                                (item, i) => (
                                  <li key={i} className="py-2 ">
                                    {item}
                                  </li>
                                )
                              )
                            ) : (
                              <pre className="py-2 font-pro">
                                {analysis?.ai_summary?.["Likes"]}
                              </pre>
                            )}
                          </ul>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg p-6 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            <ThumbsDown className="text-[#ff6464]" /> Dislikes
                          </h1>
                          <ul className="mt-5 divide-y list-disc list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                            {Array.isArray(
                              analysis?.ai_summary?.["Dislikes"]
                            ) ? (
                              analysis?.ai_summary?.["Dislikes"]?.map(
                                (item, i) => (
                                  <li key={i} className="py-2">
                                    {item}
                                  </li>
                                )
                              )
                            ) : (
                              <pre className="py-2 font-pro">
                                {analysis?.ai_summary?.["Dislikes"]}
                              </pre>
                            )}
                          </ul>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg p-6 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            <Lightbulb className="text-[#ffaf19]" /> Feature
                            Requests
                          </h1>
                          <ul className="mt-5 divide-y list-disc list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                            {Array.isArray(
                              analysis?.ai_summary?.["Feature Requests"]
                            ) ? (
                              analysis?.ai_summary?.["Feature Requests"]?.map(
                                (item, i) => (
                                  <li key={i} className="py-2 ">
                                    {item}
                                  </li>
                                )
                              )
                            ) : (
                              <pre className="py-2 font-pro">
                                {analysis?.ai_summary?.["Feature Requests"]}
                              </pre>
                            )}
                          </ul>
                        </div>
                        <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:hidden">
                          <h1 className="font-pro font-semibold bg-[#fafafa] dark:bg-darkBg p-6 border-b border-b-[#EBEBEB] text-[#333] dark:text-white flex items-center gap-2">
                            <ChartColumn className="text-[#7459d9]" />{" "}
                            Competitor Comparison
                          </h1>
                          <ul className="mt-5 divide-y list-disc list-outside pl-10 pr-6 pb-6 text-[#424242] dark:text-white">
                            {Array.isArray(
                              analysis?.ai_summary?.["Competitor Comparison"]
                            ) ? (
                              analysis?.ai_summary?.[
                                "Competitor Comparison"
                              ]?.map((item, i) => (
                                <li key={i} className="py-2">
                                  {item}
                                </li>
                              ))
                            ) : (
                              <pre className="py-2 font-pro">
                                {
                                  analysis?.ai_summary?.[
                                    "Competitor Comparison"
                                  ]
                                }
                              </pre>
                            )}
                          </ul>
                        </div>
                        {/* <div className="rounded-xl overflow-hidden lg:col-span-2">
                          <Table>
                            <TableBody className="divide-y">
                              {Object?.entries(analysis?.ai_summary)
                                ?.filter(
                                  (item) => item[0] !== "Frequent Keywords"
                                )
                                ?.map((item, i) => (
                                  <TableRow key={i} className="text-[15px]">
                                    <TableCell className="text-lg font-semibold font-pro">
                                      {item[0]}
                                    </TableCell>

                                    <TableCell>{item[1]}</TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </div> */}
                      </div>
                    )}
                  </>
                ) : null}
              </>
            )}
          </>
        )}
      </div>
      <GmbIntegrationModal
        openModal={isOpenLinkModal}
        setOpenModal={setIsOpenLinkModal}
      />
    </Layout>
  );
};

export default KeyInsights;
