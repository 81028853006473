import Apimanager from "./axios";
// import { useParams } from "react-router";
export const getReviewQuestions = async ({ entityId, staffId }) => {
  const res = await Apimanager.get(
    `/entity/questions/review/${entityId}/${staffId ? `?staff=${staffId}` : ""}`
  );
  return res.data;
};

export const postReviewQuestions = async ({ data, isDetailed }) => {
  const res = await Apimanager.post(
    `/entity/generate_reviews${isDetailed ? "?is_detailed=true" : ""}`,
    data
  );
  return res.data;
};

export const postGetReviewDetails = async (data) => {
  const res = await Apimanager.post(`/entity/get_review_details`, data);
  return res.data;
};

export const postGetReviewDetailsQr = async (data) => {
  const res = await Apimanager.post(`/entity/get_review_details_qr`, data, {
    responseType: "blob",
  });
  return res.data;
};

export const postGetRedeemDetails = async (data) => {
  const res = await Apimanager.post(`/entity/get_redeem_details`, data);
  return res.data;
};

export const postRedeemReview = async (data) => {
  const res = await Apimanager.post(`/entity/redeem_review`, data);
  return res.data;
};

export const postSaveNegativeFeedbacks = async ({ data }) => {
  const res = await Apimanager.post(`/entity/save_negative_feedbacks`, data);
  return res.data;
};

export const postSaveNegativeFeedbacksByVoice = async (data) => {
  const res = await Apimanager.post(`/entity/save_negative_feedbacks`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const postReviewQuestionsByVoice = async (data) => {
  const res = await Apimanager.post(`/entity/generate_reviews`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getReviewUrl = async (entityId) => {
  const res = await Apimanager.get(`/entity/review_url/${entityId}`);
  return res.data;
};

export const reviewCopy = async (data) => {
  const res = await Apimanager.post(`/entity/review_copied`, data);
  return res.data;
};

export const getEntityReviews = async (params) => {
  const res = await Apimanager.get(`/entity/get_entity_reviews`, {
    params: params,
  });

  return res.data;
};

export const getCanEntityGenerateReviews = async (entityId) => {
  const res = await Apimanager.get(
    `/entity/can_entity_generate_reviews/${entityId}`
  );
  return res.data;
};

export const getEntityReviewPermssions = async ({ entityId, employeeId }) => {
  const res = await Apimanager.get(`/entity/${entityId}/review_permissions`, {
    params: {
      employee: employeeId,
    },
  });
  return res.data;
};

export const getReviewPlatforms = async (entityId, employeeId) => {
  const res = await Apimanager.get(`/entity/${entityId}/review_platforms`, {
    params: {
      employee: employeeId,
    },
  });
  return res.data;
};

export const getSeoKeywords = async (entityId) => {
  const res = await Apimanager.get(`/entity/${entityId}/get_entity_keywords`);
  return res.data;
};

export const getDownloadReviews = async (parmas) => {
  const res = await Apimanager.get(`/entity/download_entity_reviews`, {
    params: parmas,
  });

  return res.data;
};

export const getPositivePrompt = async () => {
  const res = await Apimanager.get(`/prompts/conditions`);
  return res.data;
};

export const postPositivePrompt = async (data) => {
  const res = await Apimanager.post(`/prompts/conditions`, data);
  return res.data;
};

export const putPositivePrompt = async (id, data) => {
  const res = await Apimanager.put(`/prompts/${id}/conditions`, data);
  return res.data;
};

export const putAiReplySettings = async (data) => {
  const res = await Apimanager.put(`/prompts/ai_reply_setting`, data);
  return res.data;
};

export const deletePositivePrompt = async (id) => {
  const res = await Apimanager.delete(`/prompts/${id}/conditions`);
  return res.data;
};

export const postConditionToggle = async (id) => {
  const res = await Apimanager.post(`/prompts/${id}/conditions/toggle`);
  return res.data;
};

export const getReviewsAnalyticsPosted = async (params) => {
  const res = await Apimanager.get(`/entity/get_review_analytics_posted`, {
    params: params,
  });

  return res.data;
};

export const getReviewsAnalyticsNonPosted = async (params) => {
  const res = await Apimanager.get(`/entity/get_review_analytics_non_posted`, {
    params: params,
  });

  return res.data;
};

export const getReviewsAnalyticsNegative = async (params) => {
  const res = await Apimanager.get(`/entity/get_negative_feedbacks`, {
    params: params,
  });

  return res.data;
};

export const getDownloadReviewsPosted = async (params) => {
  const res = await Apimanager.get(`/entity/download_analytics_stats_posted`, {
    params: params,
  });

  return res.data;
};

export const getDownloadReviewsNegative = async (params) => {
  const res = await Apimanager.get(
    `/entity/download_analytics_stats_negative_feedback`,
    {
      params: params,
    }
  );

  return res.data;
};

export const getDownloadReviewsNonPosted = async (params) => {
  const res = await Apimanager.get(
    `/entity/download_analytics_stats_non_posted`,
    {
      params: params,
    }
  );

  return res.data;
};

export const postAiReviewsAnalysis = async (data) => {
  const res = await Apimanager.post(`/ai/review_analysis`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getAiReviewAnalysis = async () => {
  const res = await Apimanager.get(`/ai/review_analysis`);
  return res.data;
};

export const getGmbPosts = async (params) => {
  const res = await Apimanager.get(`/ai/get_gmb_posts`, {
    params: params,
  });

  return res.data;
};

export const getSchedulePosts = async (params) => {
  const res = await Apimanager.get(`/ai/get_schedule_posts`, {
    params: params,
  });

  return res.data;
};

export const postUploadMedia = async (data) => {
  const res = await Apimanager.post(`/ai/upload_media_blob`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const postStandardGenerate = async (data) => {
  const res = await Apimanager.post(`/ai/standard_post_generate`, data);
  return res.data;
};

export const postStandardAdd = async (data) => {
  const res = await Apimanager.post(`/ai/standard_post_add`, data);
  return res.data;
};

export const postStandardSchedule = async (data) => {
  const res = await Apimanager.post(`/ai/standard_post_schedule`, data);
  return res.data;
};

export const postOfferGenerate = async (data) => {
  const res = await Apimanager.post(`/ai/offer_post_generate`, data);
  return res.data;
};

export const postOfferAdd = async (data) => {
  const res = await Apimanager.post(`/ai/offer_post_add`, data);
  return res.data;
};

export const postOfferSchedule = async (data) => {
  const res = await Apimanager.post(`/ai/offer_post_schedule`, data);
  return res.data;
};

export const postEventGenerate = async (data) => {
  const res = await Apimanager.post(`/ai/event_post_generate`, data);
  return res.data;
};

export const postEventAdd = async (data) => {
  const res = await Apimanager.post(`/ai/event_post_add`, data);
  return res.data;
};

export const postEventSchedule = async (data) => {
  const res = await Apimanager.post(`/ai/event_post_schedule`, data);
  return res.data;
};

export const putEditSchedulePost = async (id, data) => {
  const res = await Apimanager.put(`ai/${id}/edit_delete_schedule_post`, data);
  return res.data;
};

export const deleteSchedulePost = async (id) => {
  const res = await Apimanager.delete(`ai/${id}/edit_delete_schedule_post`);
  return res.data;
};

export const getPrimaryKeywords = async () => {
  const res = await Apimanager.get(`/ai/get_add_delete_primary_keywords`);
  return res.data;
};

export const postAddPrimaryKeywords = async (data) => {
  const res = await Apimanager.post(
    `/ai/get_add_delete_primary_keywords`,
    data
  );
  return res.data;
};

export const deletePrimaryKeywords = async (data) => {
  const res = await Apimanager.delete(`/ai/get_add_delete_primary_keywords`, {
    data: data,
  });
  return res.data;
};

export const getSeoSettingCount = async () => {
  const res = await Apimanager.get(`/ai/get_update_seo_setting_count`);
  return res.data;
};

export const putUpdateSeoSettingCount = async (data) => {
  const res = await Apimanager.put(`/ai/get_update_seo_setting_count`, data);
  return res.data;
};

export const getKeywords = async () => {
  const res = await Apimanager.get(`/ai/get_keywords`);
  return res.data;
};

export const postAddAiKeywords = async () => {
  const res = await Apimanager.post(`/ai/add_seo_keyword_ai`);
  return res.data;
};

export const deleteKeywords = async (id) => {
  const res = await Apimanager.delete(`/ai/${id}/delete_keyword`);
  return res.data;
};

export const toggleKeyword = async (id) => {
  const res = await Apimanager.post(`/ai/${id}/toggle_keyword`);
  return res.data;
};

export const getTargetLocations = async () => {
  const res = await Apimanager.get(`/ai/get_add_target_locations`);
  return res.data;
};

export const postAddTargetLocations = async (data) => {
  const res = await Apimanager.post(`/ai/get_add_target_locations`, data);
  return res.data;
};

export const postSearchTargetLocation = async (data) => {
  const res = await Apimanager.post(`/ai/search_target_location`, data);
  return res.data;
};

export const deleteTargetLocation = async (id) => {
  const res = await Apimanager.delete(`/ai/${id}/delete_target_location`);
  return res.data;
};

export const getActiveKeywords = async (params) => {
  const res = await Apimanager.get(`/ai/get_active_keywords`, {
    params: params,
  });

  return res.data;
};

export const getInactiveKeywords = async (params) => {
  const res = await Apimanager.get(`/ai/get_inactive_keywords`, {
    params: params,
  });

  return res.data;
};
