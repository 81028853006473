import { StickyScroll } from "../ui/sticky-scroll-reveal";
import section1 from "/section1.png";
import section2 from "/section2.png";

const content = [
  {
    title: "Reviews in a Tap",
    description:
      "Switch From Emails And Texts To MagicReview's AI Review Cards, Featuring NFC And QR Technology. Customers Can Easily Leave Reviews With A Tap Or Scan On Their Phones, Enabling You To Gather Reviews Under 30 Seconds And Lower Marketing Costs With Word-Of-Mouth Promotion.",
    content: (
      <div className="bg-[#4F75FA]  rounded-[30px] w-full text-center">
        <img src={section1} className="mx-auto pt-10 max-w-[380px] w-full" />
      </div>
    ),
  },
  {
    title: "Rank 1st on Search Engine Result Page",
    description:
      "Boost your online presence with MagicReview and top search engine results. Our AI-generated, SEO-optimized reviews push your business to the top of search pages, attracting new customers to discover your business",
    content: (
      <div className="bg-newPrimary  rounded-[30px] ">
        <img src={section2} className="mx-auto pt-10 max-w-[306px] w-full" />
      </div>
    ),
  },
];

const NewResultComponent = () => {
  return (
    <div className="w-full bg-white font-poppins space-y-10">
      <StickyScroll content={content} />
    </div>
  );
};

export default NewResultComponent;
