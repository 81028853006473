/* eslint-disable react/prop-types */

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  Select,
  SelectTrigger,
  SelectItem,
  SelectContent,
  SelectValue,
} from "../../../../components/ui/select";
import { useEffect, useRef, useState } from "react";
import {
  useGetEmployeeIntegrations,
  usePostAddEmployee,
  usePutUpdateDefaultMagicLink,
  usePutUpdateEmployee,
} from "../../../../query/useEmployees";
import { toast } from "sonner";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  useDeleteTag,
  useGetQuestionSets,
  useGetTags,
  usePostAddTag,
} from "../../../../query/useEntity";
import {
  Asterisk,
  ChevronDown,
  Eye,
  EyeOff,
  Info,
  PlusSquare,
  Trash2,
  X,
} from "lucide-react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Switch } from "../../../../components/ui/switch";
import { LogoLoading } from "../../../../components/loading/loading";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import { Rating } from "react-simple-star-rating";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "../../../../components/ui/tooltip";

const EditDefaultStaffModal = ({
  openModal,
  setOpenModal,
  refetch,

  data,
  handleAction,
  // tagsFromApi,
  // refetchTags,
}) => {
  const tagBoxRef = useRef(null);
  const redirectBoxRef = useRef(null);
  const questionSetRef = useRef(null);
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [tags, setTags] = useState([]);
  const [questionSet, setQuestionSet] = useState(null);
  const [isShowName, setIsShowName] = useState(true);
  const [isVoiceReview, setIsVoiceReview] = useState(true);
  const [inputTag, setInputTag] = useState("");
  const [isOpenTagBox, setIsOpenTagBox] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState();
  const [redirects, setRedirects] = useState([]);
  const [isOpenRedirectBox, setIsOpenRedirectBox] = useState(false);
  const [inputRedirect, setInputRedirect] = useState("");
  const [isOpenQuestionSetBox, setIsOpenQuestionSetBox] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);
  const [starRating, setStarRating] = useState(0);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [subheading, setSubheading] = useState({
    name: "subheading",
    text: "",
    show: true,
  });
  const [isDirectToReview, setIsDirectToReview] = useState(false);
  const [isOnlyVoiceReview, setIsOnlyVoiceReview] = useState(false);
  const [offerPage, setOfferPage] = useState(false);
  const [isReqInfo, setIsReqInfo] = useState(false);

  const customerInfos = [
    {
      name: "Email",
      field_name: "email",
      must: false,
      type: "email",
    },
    {
      name: "Phone",
      field_name: "phone",
      must: false,
      type: "phone",
    },
    {
      name: "WhatsApp",
      field_name: "whatsapp",
      must: false,
      type: "phone",
    },
  ];

  const handleAddCustomerInfo = (item) => {
    setCustomerInfo((prev) =>
      prev.some((prevItem) => prevItem?.name === item?.name)
        ? prev
        : [...prev, item]
    );
  };

  const { data: questionSets } = useGetQuestionSets();

  const {
    mutate: updateDefaultMagicLink,
    isLoading: updateDefaultMagicLinkLoading,
  } = usePutUpdateDefaultMagicLink();

  const handleUpdateDefaultMagicLink = () => {
    const data = {
      set: questionSet,
      voice_review: isVoiceReview,
      negative_feedback: isNegativeFeedback,
      star_rating: starRating,
      direct_to_review: isDirectToReview,
      personal_info:
        customerInfo?.length > 0 ? [...customerInfo, subheading] : [subheading],
      only_voice_review: isOnlyVoiceReview,
      offer: offerPage,
      request_info: isReqInfo,
    };

    updateDefaultMagicLink(data, {
      onSuccess: (res) => {
        toast.success(res.message);
        setOpenModal(false);
        refetch();
      },

      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else if (err.response.data.tag) {
          toast.error(err.response.data.tag?.[0]);
        } else if (err.response.data.label) {
          toast.error(err.response.data.label?.[0]);
        } else if (err.respopnse.data.redirect?.[0]) {
          toast.error(err.response.data.redirect?.[0]);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  useEffect(() => {
    if (data) {
      setQuestionSet(data?.data);
      setIsVoiceReview(data?.voice_review);
      setIsNegativeFeedback(data?.negative_feedback);
      setStarRating(data?.star_rating);
      setIsDirectToReview(data?.direct_to_review);
      setIsOnlyVoiceReview(data?.only_voice_review);
      setOfferPage(data?.offer);
      setCustomerInfo(
        data?.personal_info?.filter((item) => item.name !== "subheading")
      );
      setIsReqInfo(data?.request_info);
      const subheadingFromApi = data?.personal_info?.find(
        (item) => item.name === "subheading"
      );
      if (subheadingFromApi) {
        setSubheading(subheadingFromApi);
      }
    }
  }, [data]);

  useOnClickOutside({
    ref: questionSetRef,
    setIsOpen: setIsOpenQuestionSetBox,
  });

  if (updateDefaultMagicLinkLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(false);
      }}
    >
      <DialogContent className="lg:max-w-[888px]">
        <DialogHeader>
          <DialogTitle>Magiclink Settings</DialogTitle>
        </DialogHeader>

        <div className="px-6 grid grid-cols-1 lg:grid-cols-2 gap-5 max-h-[70svh] overflow-y-auto scrollbar-thin">
          <div className="space-y-5 ">
            <div className="border rounded-[10px] p-5  space-y-5">
              <div className="flex justify-between items-start gap-5">
                <div className="space-y-1">
                  <p className="font-pro dark:text-white text-[15px]">
                    Voice Review
                  </p>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Allow reviewers to record their voice, and review is
                    generated based on their input.
                  </p>
                </div>

                <Switch
                  checked={isVoiceReview}
                  onCheckedChange={() => setIsVoiceReview((prev) => !prev)}
                ></Switch>
              </div>
              {isVoiceReview && (
                <div className="flex justify-between items-start gap-5">
                  <div className="space-y-1">
                    <p className="font-pro dark:text-white text-[15px]">
                      Questions / Keywords
                    </p>
                    <p className="font-pro dark:text-white text-[#808080] text-xs">
                      Show predefined questions or keywords to help reviewers
                      generate their review.
                    </p>
                  </div>
                  <Switch
                    checked={!isOnlyVoiceReview}
                    onCheckedChange={() =>
                      setIsOnlyVoiceReview((prev) => !prev)
                    }
                  ></Switch>
                </div>
              )}
              <div className="flex justify-between items-start gap-5">
                <div className="space-y-1">
                  <p className="font-pro dark:text-white text-[15px]">
                    Direct to Review
                  </p>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Automatically redirect reviewers to the platform after
                    generating a review, skipping the selection step.
                  </p>
                </div>
                <Switch
                  checked={isDirectToReview}
                  onCheckedChange={() => setIsDirectToReview((prev) => !prev)}
                ></Switch>
              </div>
            </div>
            <div className="border rounded-[10px] p-5 space-y-5">
              <div className="flex justify-between items-center gap-3">
                <div className="space-y-1">
                  <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                    Reward Page
                  </p>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Offer a redeemable incentive for future visits.
                  </p>
                </div>

                <Switch
                  checked={offerPage}
                  onCheckedChange={() => setOfferPage((prev) => !prev)}
                ></Switch>
              </div>
            </div>
            <div className="border rounded-[10px] p-5  space-y-5">
              <div className="space-y-1">
                <p className="font-pro dark:text-white text-[15px]">
                  Question Set
                </p>
                <p className="font-pro dark:text-white text-[#808080] text-xs">
                  Select a predefined set of questions to be displayed on the
                  review page.
                </p>
              </div>
              <Select
                value={questionSet}
                onValueChange={(e) => setQuestionSet(e)}
              >
                <SelectTrigger className="bg-[#f8f9f8] border-none placeholder:text-[#bfbfbf] h-[38px] dark:bg-darkLightBg">
                  <SelectValue placeholder="e.g Set 1" />
                </SelectTrigger>
                <SelectContent className="dark:bg-darkLightBg">
                  {questionSets?.map((item) => (
                    <SelectItem value={item?.id} key={item?.id}>
                      {item?.name === "default" ? "Default" : item?.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {/* <div className="select-question-set">
              <p className="font-pro font-medium dark:text-white mb-3 h-6">
                Question set
              </p>
              <Select
                value={questionSet}
                onValueChange={(e) => setQuestionSet(e)}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {questionSets?.map((item) => (
                    <SelectItem value={item?.id} key={item?.id}>
                      {item?.name === "default" ? "Default" : item?.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div> */}
          </div>
          <div className="space-y-5">
            <div className="border rounded-[10px] p-5  space-y-5">
              <div className="flex items-start justify-between">
                <div className="space-y-1">
                  <div className="flex items-center gap-3">
                    <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                      Negative Feedback
                    </p>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Info className="size-5" />
                        </TooltipTrigger>
                        <TooltipContent>
                          Users will be redirected to the Thank You page if they
                          select the specified star rating or lower. They
                          won&apos;t be redirected to the review page.
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Prevents reviews below a selected rating from being posted
                    on the redirected platform.
                  </p>
                </div>

                <Switch
                  checked={isNegativeFeedback}
                  onCheckedChange={() => setIsNegativeFeedback((prev) => !prev)}
                ></Switch>
              </div>

              <div className="w-full font-pro dark:bg-darkLightBg dark:!border-[#454545] dark:text-white  mt-3  h-14 bg-[#f8f9f8]  placeholder:text-[#bfbfbf]  rounded-md flex justify-between items-center px-3">
                <p className="text-sm text-[#9d9d9d] font-medium">
                  Select star rating
                </p>
                <Rating
                  initialValue={starRating}
                  onClick={(e) => setStarRating(e)}
                  SVGstyle={{ display: "inline" }}
                  SVGclassName="w-6"
                  iconsCount={4}
                />
              </div>
            </div>
            <div className="border rounded-[10px] p-5  space-y-5">
              <div className="flex justify-between">
                <div className="flex gap-3">
                  <div className="space-y-1">
                    <p className="font-pro dark:text-white text-[15px]">
                      Request Customer Information
                    </p>
                    <p className="font-pro dark:text-white text-[#808080] text-xs">
                      Collect customer details for redirected reviews.
                    </p>
                  </div>

                  {isReqInfo && (
                    <DropdownMenu modal={false}>
                      <DropdownMenuTrigger asChild>
                        <PlusSquare className="size-5 text-newPrimary cursor-pointer" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent className={"z-[1010]"}>
                        {customerInfos?.map((item, i) => (
                          <DropdownMenuItem
                            key={i}
                            onClick={() => handleAddCustomerInfo(item)}
                            className="capitalize"
                          >
                            {item.name}
                          </DropdownMenuItem>
                        ))}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
                <Switch
                  checked={isReqInfo}
                  onCheckedChange={() => setIsReqInfo((prev) => !prev)}
                ></Switch>
              </div>
              <div className="w-full font-pro   dark:!border-[#454545] dark:text-white  min-h-10  space-y-3">
                <div className="flex w-full gap-3 items-center">
                  <p className="text-xs  font-medium flex-shrink-0">
                    Sub Heading
                  </p>

                  <Input
                    value={subheading?.text}
                    onChange={(e) => {
                      setSubheading((prev) => {
                        const current = { ...prev };

                        current.text = e.target.value;

                        return current;
                      });
                    }}
                    className={
                      " placeholder:text-[#9d9d9d] dark:bg-darkLightBg"
                    }
                    placeholder="e.g. Enter your details to get rewards"
                    disabled={!subheading.show}
                  />
                  {subheading?.show ? (
                    <Eye
                      className="size-4 flex-shrink-0"
                      onClick={() => {
                        setSubheading((prev) => {
                          const current = { ...prev };

                          current.show = !current.show;

                          return current;
                        });
                      }}
                    />
                  ) : (
                    <EyeOff
                      className="size-4 flex-shrink-0"
                      onClick={() => {
                        setSubheading((prev) => {
                          const current = { ...prev };

                          current.show = !current.show;

                          return current;
                        });
                      }}
                    />
                  )}
                </div>
                {customerInfo?.map((item, i) => (
                  <div
                    className="grid grid-cols-2 w-full gap-3 items-center"
                    key={i}
                  >
                    <div className="relative">
                      <Input
                        value={item?.name}
                        readOnly
                        className={
                          "h-8 bg-newPrimary/10 text-newPrimary focus-visible:ring-0 focus-visible:ring-offset-0 pr-8 dark:bg-darkLightBg"
                        }
                      />
                      <div
                        className={`flex items-center justify-center ${item?.must ? "bg-newPrimary" : "bg-[#ddd]"} rounded-full size-4 absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer`}
                        onClick={() => {
                          setCustomerInfo((prev) => {
                            const current = [...prev];
                            current[i].must = !current[i]?.must;
                            return current;
                          });
                        }}
                      >
                        <div className="rounded-full size-1.5 bg-white"></div>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="font-medium text-[#9d9d9d] text-xs w-[60px]">
                        {" "}
                        {item?.must ? "Must have" : "Optional"}
                      </p>
                      <Trash2
                        onClick={() =>
                          setCustomerInfo((prev) =>
                            prev.filter((_, index) => index !== i)
                          )
                        }
                        className="size-4 text-red-500 cursor-pointer"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex justify-end items-end gap-x-3 lg:col-span-2">
            <Button
              className="w-[120px]"
              variant="secondary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-[120px]"
              onClick={handleUpdateDefaultMagicLink}
              //   disabled={redirects?.length <= 0}
            >
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditDefaultStaffModal;
