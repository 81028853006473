/* eslint-disable react/prop-types */
import React from "react";
import { motion } from "framer-motion";

const CommonHeading = ({ title, description }) => {
  return (
    <div className="font-poppins">
      <motion.h1
        className="text-center font-semibold text-[24px] md:text-[44px] text-[#2d2d2d] lg:leading-[55px] lg:tracking-tighter md:text-wrap md:whitespace-pre"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        viewport={{ once: true }}
        transition={{ duration: 0.4, ease: "easeInOut" }}
      >
        {title}
      </motion.h1>
      <motion.h3
        className="text-center text-base text-[#2d2d2d] capitalize text-wrap whitespace-pre mt-7"
        initial={{
          opacity: 0,
          y: 50,
        }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4, delay: 0.2, ease: "easeInOut" }}
      >
        {description}
      </motion.h3>
    </div>
  );
};

export default CommonHeading;
