/* eslint-disable react/prop-types */
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Checkbox } from "../../../components/ui/checkbox";

const PinModal = ({
  openModal,
  setOpenModal,
  pin,
  setPin,
  handleAction,
  internal,
  setInternal,
}) => {
  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm PIN</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <Input
            type="number"
            value={pin}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 4) {
                setPin(value);
              }
            }}
            placeholder="Confirm PIN"
          />
          <div className="flex items-center gap-2 mt-5">
            <Checkbox
              checked={internal}
              onCheckedChange={(bool) => setInternal(bool)}
              id="internal"
            />
            <label htmlFor="internal"> Redeem Internally</label>
          </div>
          <div className="flex items-center justify-end mt-5 gap-3">
            <Button
              variant="secondary"
              onClick={() => setOpenModal(!openModal)}
            >
              Cancel
            </Button>
            <Button onClick={handleAction}>Confirm</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PinModal;
