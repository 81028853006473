import { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { useGetGmbInfo } from "../../../query/useAuthentication";
import GmbIntegrationModal from "./components/GmbIntegrationModal";
import {
  useDeleteSchedulePost,
  useGetGmbPosts,
  useGetSchedulePosts,
} from "../../../query/useReview";
import { useInView } from "react-intersection-observer";
import {
  dayAndTimeFormatter,
  dayFormatter,
  dayFormatterLong,
  timeLeft,
} from "../../../utils/formatter";
import LoaderIcon from "react-loader-icon";
import GmbPostModal from "./components/GmbPostModal";
import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { Button } from "../../../components/ui/button";
import AddPostModal from "./components/AddPostModal";
import SchedulePostModal from "./components/SchedulePostModal";
import AddSchedulePostModal from "./components/AddSchedulePostModal";
import { Pencil, PlusSquare, Timer, Trash2 } from "lucide-react";
import { DeleteModal } from "../component/modal";
import { toast } from "sonner";
import UpdateScheduleUpdateModal from "./components/UpdateScheduleUpdateModal";
import UpdateScheduleOfferModal from "./components/UpdateScheduleOfferModal";
import UpdateScheduleEventModal from "./components/UpdateScheduleEventModal";
import CountdownTimer from "./components/CountdownTimer";
const AiPost = () => {
  const { darkMode } = useDarkMode();
  const { inView, ref } = useInView();
  const { inView: scheduleInView, ref: scheduleRef } = useInView();
  const [isGmbIntegrate, setIsGmbItegrate] = useState(true);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const [openGmbPostModal, setOpenGmbPostModal] = useState(null);
  const [openSchedulePostModal, setOpenSchedulePostModal] = useState(null);
  const [isOpenAddPostModal, setIsOpenAddPostModal] = useState(false);
  const [isOpenAddSchedulePostModal, setIsOpenAddSchedulePostModal] =
    useState(false);
  const [tab, setTab] = useState("google");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [toDeleteId, setToDeleteId] = useState(0);
  const [isOpenUpdateScheduleUpdateModal, setIsOpenUpdateScheduleUpdateModal] =
    useState();
  const [isOpenUpdateScheduleOfferModal, setIsOpenUpdateScheduleOfferModal] =
    useState();
  const [isOpenUpdateScheduleEventModal, setIsOpenUpdateScheduleEventModal] =
    useState();

  const { data: gmbInfo } = useGetGmbInfo({
    onSuccess: (res) => {
      setIsGmbItegrate(res.GMB_INTEGRATION);
    },
  });

  const {
    data: gmbPosts,
    hasNextPage,
    fetchNextPage,
    isLoading: gmbPostsLoading,
  } = useGetGmbPosts({
    enabled: tab === "google",
  });

  const flattenedGmbReviews = gmbPosts?.pages?.flatMap(
    (page) => page?.localPosts
  );

  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);

  const {
    data: schedulePosts,
    hasNextPage: hasScheduleNextPage,
    fetchNextPage: fetchScheduleNextPage,
    isLoading: schedulePostsLoading,
    refetch: refetchSchedulePosts,
  } = useGetSchedulePosts({
    enabled: tab === "schedule",
  });

  const { mutate: deleteSchedulePost, isLoading: deleteSchedulePostLoading } =
    useDeleteSchedulePost();

  const handleDeleteSchedulePost = () => {
    deleteSchedulePost(toDeleteId, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetchSchedulePosts();
        setIsOpenDeleteModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const flattenedScheduleReviews = schedulePosts?.pages?.flatMap(
    (page) => page
  );

  useEffect(() => {
    if (hasScheduleNextPage && scheduleInView) {
      fetchScheduleNextPage();
    }
  }, [scheduleInView]);

  useEffect(() => {
    if (!isGmbIntegrate) {
      setIsOpenLinkModal(true);
    } else {
      setIsOpenLinkModal(false);
    }
  }, [isGmbIntegrate]);

  return (
    <Layout title={"AI Post"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px] font-pro">
        {gmbInfo?.GMB_INTEGRATION && (
          <div className="space-y-5">
            <div className="font-medium text-sm  gap-3 flex items-center">
              <button
                className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkBg ${tab === "google" && "border-newPrimary text-newPrimary"}`}
                onClick={() => setTab("google")}
              >
                Google Posts
              </button>
              <button
                className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkBg ${tab === "schedule" && " border-newPrimary text-newPrimary"}`}
                onClick={() => setTab("schedule")}
              >
                Schedule Post
              </button>
              {tab === "google" ? (
                <Button
                  onClick={() => setIsOpenAddPostModal(true)}
                  className="gap-2"
                >
                  <PlusSquare className="size-5" /> Add
                </Button>
              ) : (
                <Button
                  onClick={() => setIsOpenAddSchedulePostModal(true)}
                  className="gap-2"
                >
                  <Timer className="size-5" /> Schedule
                </Button>
              )}
            </div>
            {tab === "google" && (
              <>
                {gmbPostsLoading ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-5">
                    {new Array(3).fill("").map((_, i) => (
                      <div
                        className="flex flex-col rounded-[12px] p-9 bg-[#FFF] dark:bg-darkBg gap-y-5"
                        key={i}
                      >
                        <Skeleton
                          height={150}
                          baseColor={darkMode && "#262626"}
                        />
                        <Skeleton
                          height={20}
                          baseColor={darkMode && "#262626"}
                        />
                      </div>
                    ))}
                  </div>
                ) : flattenedGmbReviews?.length > 0 &&
                  flattenedGmbReviews?.[0] !== undefined ? (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
                      {flattenedGmbReviews?.map((item) => (
                        <>
                          <div
                            key={item?.name}
                            className="w-full h-full overflow-hidden cursor-pointer  rounded-b-lg"
                            onClick={() => {
                              setOpenGmbPostModal(item?.name);
                            }}
                          >
                            <div className="capitalize bg-newPrimary/15 text-newPrimary font-poppins font-medium text-sm inline-block px-5 py-1 rounded-t-lg">
                              {item?.topicType?.toLowerCase()}
                            </div>
                            <div className="w-full h-64 overflow-hidden bg-white dark:bg-darkBg rounded-tr-lg">
                              {item?.media?.[0]?.googleUrl ? (
                                <img
                                  className="w-full h-full object-cover hover:scale-105 transition-all duration-500 ease-in-out"
                                  src={item?.media?.[0]?.googleUrl}
                                  alt={item?.media?.[0]?.name}
                                  referrerPolicy="no-referrer"
                                />
                              ) : (
                                <div className="w-full h-full flex justify-center items-center">
                                  <img
                                    src="/no-picture.svg"
                                    alt=""
                                    className="w-16 h-16"
                                  />
                                </div>
                              )}
                            </div>
                            <div className="p-5 space-y-1 bg-white dark:bg-darkBg">
                              <p className="text-[13px] text-[#525252] dark:text-white">
                                {item?.updateTime &&
                                  dayFormatter(item?.updateTime)}
                              </p>
                              <h3 className=" text-sm  line-clamp-2">
                                {item?.summary}
                              </h3>
                            </div>
                          </div>
                          {openGmbPostModal === item?.name && (
                            <GmbPostModal
                              openModal={openGmbPostModal === item?.name}
                              setOpenModal={setOpenGmbPostModal}
                              data={item}
                            />
                          )}
                        </>
                      ))}
                    </div>
                    {hasNextPage && (
                      <div ref={ref} className="text-center mt-10">
                        <LoaderIcon type="cylon" color="#7190FF" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full min-h-[300px] dark:bg-darkLightBg rounded-lg font-pro">
                    <img
                      src="/illustrations/dashboardreviews.svg"
                      alt="Questions"
                      className="w-[100px] h-[100px] mx-auto mt-10"
                    />
                    <h1 className="mt-[10px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                      Generate AI Post
                    </h1>
                    <p className="mt-1 text-[#888888] dark:text-white text-center font-pro">
                      No AI Post Yet!
                    </p>
                  </div>
                )}
              </>
            )}
            {tab === "schedule" && (
              <>
                {schedulePostsLoading ? (
                  <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-5">
                    {new Array(3).fill("").map((_, i) => (
                      <div
                        className="flex flex-col rounded-[12px] p-9 bg-[#FFF] dark:bg-darkBg gap-y-5"
                        key={i}
                      >
                        <Skeleton
                          height={150}
                          baseColor={darkMode && "#262626"}
                        />
                        <Skeleton
                          height={20}
                          baseColor={darkMode && "#262626"}
                        />
                      </div>
                    ))}
                  </div>
                ) : flattenedScheduleReviews?.length > 0 ? (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-5">
                      {flattenedScheduleReviews?.map((item) => (
                        <>
                          <div
                            key={item?.id}
                            className="w-full h-full overflow-hidden cursor-pointer  rounded-b-lg"
                          >
                            <div className="capitalize border border-b-0 border-newPrimary/15 bg-newPrimary/15 text-newPrimary font-poppins font-medium text-sm w-full pl-5 rounded-t-lg flex justify-between overflow-hidden">
                              <p className="pr-5 py-1">
                                {item?.type?.toLowerCase()}
                              </p>

                              <p className="bg-[#f0f5ff] dark:bg-darkBg w-full text-right pr-5 py-1 text-[#2a49b8]">
                                {item?.schedule_time && (
                                  <CountdownTimer
                                    scheduleTime={item?.schedule_time}
                                  />
                                )}
                              </p>
                            </div>

                            <div className="w-full h-64 overflow-hidden bg-white dark:bg-darkBg  relative">
                              {item?.post_data?.media?.[0]?.sourceUrl ? (
                                <img
                                  className="w-full h-full object-cover hover:scale-105 transition-all duration-500 ease-in-out"
                                  src={item?.post_data?.media?.[0]?.sourceUrl}
                                  // alt={item?.post_data?.media?.[0]?.name}
                                  referrerPolicy="no-referrer"
                                  onClick={() => {
                                    setOpenSchedulePostModal(item?.id);
                                  }}
                                />
                              ) : (
                                <div
                                  className="w-full h-full flex justify-center items-center"
                                  onClick={() => {
                                    setOpenSchedulePostModal(item?.id);
                                  }}
                                >
                                  <img
                                    src="/no-picture.svg"
                                    alt=""
                                    className="w-16 h-16"
                                  />
                                </div>
                              )}
                              <div className="flex items-center gap-3 absolute top-3 right-3 z-10">
                                <Button size="icon" className="w-8 h-8">
                                  <Pencil
                                    className="size-5"
                                    onClick={() => {
                                      if (item?.type === "Offer") {
                                        setIsOpenUpdateScheduleOfferModal(
                                          item?.id
                                        );
                                      } else if (item?.type === "Event") {
                                        setIsOpenUpdateScheduleEventModal(
                                          item?.id
                                        );
                                      } else {
                                        setIsOpenUpdateScheduleUpdateModal(
                                          item?.id
                                        );
                                      }
                                    }}
                                  />
                                </Button>
                                <Button
                                  size="icon"
                                  variant="destructive"
                                  className="w-8 h-8"
                                  onClick={() => {
                                    setIsOpenDeleteModal(true);
                                    setToDeleteId(item?.id);
                                  }}
                                >
                                  <Trash2 className="size-5" />
                                </Button>
                              </div>
                            </div>
                            <div
                              className="p-5 space-y-1 bg-white dark:bg-darkBg"
                              onClick={() => {
                                setOpenSchedulePostModal(item?.id);
                              }}
                            >
                              <div className="flex items-center justify-between text-[13px] text-[#525252] dark:text-white">
                                <p className="">
                                  {item?.schedule_time &&
                                    "Schedule At - " +
                                      dayAndTimeFormatter(item?.schedule_time)}
                                </p>
                                {/* <p>{item?.type}</p> */}
                              </div>
                              <h3 className=" text-sm  line-clamp-2">
                                {item?.post_data?.summary}
                              </h3>
                            </div>
                          </div>
                          {openSchedulePostModal === item?.id && (
                            <SchedulePostModal
                              openModal={openSchedulePostModal === item?.id}
                              setOpenModal={setOpenSchedulePostModal}
                              data={item}
                            />
                          )}
                          {isOpenUpdateScheduleUpdateModal === item?.id && (
                            <UpdateScheduleUpdateModal
                              openModal={
                                isOpenUpdateScheduleUpdateModal === item?.id
                              }
                              setOpenModal={setIsOpenUpdateScheduleUpdateModal}
                              data={item}
                            />
                          )}
                          {isOpenUpdateScheduleOfferModal === item?.id && (
                            <UpdateScheduleOfferModal
                              openModal={
                                isOpenUpdateScheduleOfferModal === item?.id
                              }
                              setOpenModal={setIsOpenUpdateScheduleOfferModal}
                              data={item}
                            />
                          )}
                          {isOpenUpdateScheduleEventModal === item?.id && (
                            <UpdateScheduleEventModal
                              openModal={
                                isOpenUpdateScheduleEventModal === item?.id
                              }
                              setOpenModal={setIsOpenUpdateScheduleEventModal}
                              data={item}
                            />
                          )}
                        </>
                      ))}
                    </div>
                    {hasScheduleNextPage && (
                      <div ref={ref} className="text-center mt-10">
                        <LoaderIcon type="cylon" color="#7190FF" />
                      </div>
                    )}
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full min-h-[300px] dark:bg-darkLightBg rounded-lg font-pro">
                    <img
                      src="/illustrations/dashboardreviews.svg"
                      alt="Questions"
                      className="w-[100px] h-[100px] mx-auto mt-10"
                    />
                    <h1 className="mt-[10px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                      Schedule AI Post
                    </h1>
                    <p className="mt-1 text-[#888888] dark:text-white text-center font-pro">
                      No AI Post to Schedule!
                    </p>
                  </div>
                )}
              </>
            )}
          </div>
        )}
        <GmbIntegrationModal
          openModal={isOpenLinkModal}
          setOpenModal={setIsOpenLinkModal}
        />
        <AddPostModal
          openModal={isOpenAddPostModal}
          setOpenModal={setIsOpenAddPostModal}
        />
        <AddSchedulePostModal
          openModal={isOpenAddSchedulePostModal}
          setOpenModal={setIsOpenAddSchedulePostModal}
        />
        <DeleteModal
          openModal={isOpenDeleteModal}
          setOpenModal={setIsOpenDeleteModal}
          title={"Are you sure you want to delete this post?"}
          handleDelete={handleDeleteSchedulePost}
          isLoading={deleteSchedulePostLoading}
        />
      </div>
    </Layout>
  );
};

export default AiPost;
