/* eslint-disable react/prop-types */
import { CircleAlert, Link, Mail, User } from "lucide-react";

import { useEffect, useRef, useState } from "react";
import { Input, InputWithEditButton } from "../../dashboard/component/Input";
import { Button } from "../../../components/ui/button";
import {
  usePutSubuserAccountUpdate,
  usePutUpdateSubUserProfile,
} from "../../../query/useMaster";
import { LogoLoading } from "../../../components/loading/loading";
import { toast } from "sonner";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import blobToFile from "../../../utils/blobToFile";
import ImageCropper from "../../dashboard/routes/components/ImageCropper";
import IconEdit from "../../../assets/icons/IconEdit";
import { PhoneInput } from "../../../components/ui/phone-input";
import { isValidPhoneNumber } from "react-phone-number-input";

const Account = ({ profile, entity, first_name, last_name, mail, id }) => {
  const inputRef = useRef(null);
  const [userProfile, setUserProfile] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [croppedImg, setCroppedImg] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [aboutBusiness, setAboutBusiness] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const queryClient = useQueryClient();

  const {
    mutate: subuserAccountUpdate,
    isLoading: subuserAccountUpdateLoading,
  } = usePutSubuserAccountUpdate();

  const { mutate: updateUserProfile, isLoading: updateProfilePictureLoading } =
    usePutUpdateSubUserProfile();

  const handleUpdateUser = () => {
    const data = {
      business_name: companyName,
      company_link: websiteLink,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      about_business: aboutBusiness,
      business_location: businessLocation,
    };
    subuserAccountUpdate(
      { id: id, data: data },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries("getSubUsersInfinity");
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else if (err?.response?.data?.phone_number) {
            setPhoneError(err?.response?.data?.phone_number[0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleChangeProfileClick = () => {
    inputRef.current.click();
    setIsEdit(true);
  };

  const handleChangeProfile = (e) => {
    setProfilePicture(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setProfilePictureUrl(objectUrl);
    inputRef.current.value = "";
  };

  const handleUpdateUserProfile = async () => {
    const croppedImgFile = await blobToFile(
      croppedImg,
      profilePicture?.name
    ).then((file) => file);

    if (croppedImgFile) {
      const formData = new FormData();
      formData.append("profile_picture", croppedImgFile);

      updateUserProfile(
        {
          id: id,
          data: formData,
        },
        {
          onSuccess: (res) => {
            setOpenModal(false);
            setCroppedImg(null);
            toast.success("Profile picture has been updated!");
            queryClient.invalidateQueries("getSubUsersInfinity");
            setUserProfile(croppedImg);
          },
          onError: (err) => {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
            setCroppedImg(null);
          },
        }
      );
    }
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError("");
    // Validate the phone number
    // if (!isValidPhoneNumber(value)) {
    //   setPhoneError("Invalid phone number");
    // } else {
    //   setPhoneError("");
    // }
  };

  useEffect(() => {
    setUserProfile(profile?.profile_picture);
    setFirstName(first_name);
    setLastName(last_name);
    setEmail(mail);
    setCompanyName(entity?.name);
    setWebsiteLink(entity?.company_link);
    setAboutBusiness(profile?.about_business);
    setBusinessLocation(entity?.location);
    setPhone(profile?.phone_number);
  }, []);

  useEffect(() => {
    if (profilePictureUrl && isEdit) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [profilePictureUrl, isEdit]);

  if (subuserAccountUpdateLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <div className="py-5">
        <p className="font-pro font-medium text-xl">Manage profile settings</p>
      </div>
      <hr />

      <div
        className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10 px-1
          "
      >
        <div className="w-full lg:col-span-2">
          <label className="text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
            Profile photo
          </label>
          <div className="flex items-center gap-x-3">
            <div
              className="relative w-16 h-16 cursor-pointer"
              onClick={handleChangeProfileClick}
            >
              <input
                type="file"
                accept="image/*"
                ref={inputRef}
                hidden
                onChange={handleChangeProfile}
              />
              <img
                className="h-14 w-14 shrink-0 rounded-full mr-[21px] object-cover"
                src={userProfile || "/new-logo.svg"}
                alt=""
              />
              <div className="w-6 h-6 bg-black/80 rounded-full absolute bottom-2 right-1 flex justify-center items-center">
                <IconEdit className=" w-4 h-4 text-white" />
              </div>
            </div>

            <button
              className="font-pro text-[#a7a5a5]"
              onClick={handleChangeProfileClick}
            >
              Upload photo
            </button>
          </div>
        </div>
        <Input
          label="First Name"
          placeholder="e.g. John"
          value={firstName}
          setValue={setFirstName}
        />
        <Input
          label="Last Name"
          placeholder="e.g. Doe"
          value={lastName}
          setValue={setLastName}
        />

        <Input
          label="Company Name"
          placeholder="e.g. xyz"
          value={companyName}
          setValue={setCompanyName}
        />
        <InputWithEditButton
          id={id}
          label="Email Address"
          placeholder="e.g. xyz@example.com"
          value={email}
          setValue={setEmail}
          disabled={true}
          readOnly
        />

        <div className="w-full">
          <label className=" text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
            {"Phone Number"}
          </label>
          <PhoneInput
            className=""
            value={phone}
            onChange={handlePhoneChange}
            defaultCountry="GB"
          />
          {/* {phone?.length > 5 && phoneError && (
            <p className="font-pro text-xs text-red-500 mt-2 flex gap-1">
              <CircleAlert className="size-4" /> {phoneError}
            </p>
          )} */}
        </div>

        <Input
          label="Website Link"
          placeholder="e.g. https://www.company.in"
          value={websiteLink}
          setValue={setWebsiteLink}
        />

        <Input
          label="Business Location"
          placeholder="e.g. Dubai"
          value={businessLocation}
          setValue={setBusinessLocation}
        />
        <div className="w-full">
          <label className="text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
            {"About Business"}
          </label>
          <textarea
            className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] pr-10 scrollbar-none"
            placeholder={"Write about your business"}
            value={aboutBusiness || ""}
            onChange={(e) => setAboutBusiness(e.target.value)}
            disabled={aboutBusiness?.length >= 1000}
            rows={5}
          />
          <p className="text-right -mt-5 text-gray-500 font-pro text-xs">
            {aboutBusiness?.length || 0} / 1000 characters
          </p>
        </div>
      </div>
      <div className="w-full flex justify-end mt-5">
        <Button onClick={handleUpdateUser}>Save</Button>
      </div>
      <ImageCropper
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleUpdateUserProfile={handleUpdateUserProfile}
        profilePictureUrl={profilePictureUrl}
        isLoading={updateProfilePictureLoading}
        croppedImage={croppedImg}
        setCroppedImage={setCroppedImg}
      />
    </>
  );
};

export default Account;
