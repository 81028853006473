/* eslint-disable react/prop-types */
import LoaderIcon from "react-loader-icon";

const Loading = ({ height }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: height || "80vh",
      }}
    >
      <LoaderIcon type="cylon" color="#7190FF" />
    </div>
  );
};

// const Loading = () => {
//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         height: "100vh",
//       }}
//     >
//       <img loading="lazy"src="/easyreview.gif" className=" hidden md:block w-[45%]" />
//       <div className="md:hidden flex justify-center items-center gap-2">
//         <motion.img
//           src="/logo-only.svg"
//           alt=""
//           className="w-[75px]"
//           initial={{
//             scale: 0,
//             rotateZ: -30,
//           }}
//           animate={{
//             scale: 1,
//             rotateZ: 0,
//           }}
//           transition={{
//             repeat: Infinity,
//             repeatDelay: 0.8,
//             duration: 0.7,
//           }}
//         />
//         {/* <div>
//           <p className="font-opensans text-[48px] leading-[75.5px] -tracking-[2.88px] font-bold">
//             Easy<span className="font-normal">review</span>
//           </p>
//           <p className="font-opensans text-"></p>
//         </div> */}
//       </div>
//     </div>
//   );
// };

export default Loading;

export const ReviewLoading = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <LoaderIcon type="cylon" color="#7190FF" />
    </div>
  );
};

export const DashboardLoading = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.5)", // Adjust the color and opacity as needed
          backdropFilter: "blur(5px)", // Adjust the blur radius as needed
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 10000,
        }}
      >
        <LoaderIcon type="cylon" color="#7190FF" />
      </div>
    </div>
  );
};

export function SvgSpinners3DotsMove(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <circle cx="4" cy="12" r="0" fill="currentColor">
        <animate
          fill="freeze"
          attributeName="r"
          begin="0;svgSpinners3DotsMove1.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="0;3"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove7.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="4;12"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove5.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="12;20"
        ></animate>
        <animate
          id="svgSpinners3DotsMove0"
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove3.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="3;0"
        ></animate>
        <animate
          id="svgSpinners3DotsMove1"
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove0.end"
          dur="0.001s"
          values="20;4"
        ></animate>
      </circle>
      <circle cx="4" cy="12" r="3" fill="currentColor">
        <animate
          fill="freeze"
          attributeName="cx"
          begin="0;svgSpinners3DotsMove1.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="4;12"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove7.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="12;20"
        ></animate>
        <animate
          id="svgSpinners3DotsMove2"
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove5.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="3;0"
        ></animate>
        <animate
          id="svgSpinners3DotsMove3"
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove2.end"
          dur="0.001s"
          values="20;4"
        ></animate>
        <animate
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove3.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="0;3"
        ></animate>
      </circle>
      <circle cx="12" cy="12" r="3" fill="currentColor">
        <animate
          fill="freeze"
          attributeName="cx"
          begin="0;svgSpinners3DotsMove1.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="12;20"
        ></animate>
        <animate
          id="svgSpinners3DotsMove4"
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove7.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="3;0"
        ></animate>
        <animate
          id="svgSpinners3DotsMove5"
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove4.end"
          dur="0.001s"
          values="20;4"
        ></animate>
        <animate
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove5.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="0;3"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove3.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="4;12"
        ></animate>
      </circle>
      <circle cx="20" cy="12" r="3" fill="currentColor">
        <animate
          id="svgSpinners3DotsMove6"
          fill="freeze"
          attributeName="r"
          begin="0;svgSpinners3DotsMove1.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="3;0"
        ></animate>
        <animate
          id="svgSpinners3DotsMove7"
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove6.end"
          dur="0.001s"
          values="20;4"
        ></animate>
        <animate
          fill="freeze"
          attributeName="r"
          begin="svgSpinners3DotsMove7.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="0;3"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove5.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="4;12"
        ></animate>
        <animate
          fill="freeze"
          attributeName="cx"
          begin="svgSpinners3DotsMove3.end"
          calcMode="spline"
          dur="0.5s"
          keySplines=".36,.6,.31,1"
          values="12;20"
        ></animate>
      </circle>
    </svg>
  );
}

export const LoadingButton = () => {
  return (
    <div className="flex items-center justify-center">
      <div className="w-5 h-5 border-t-2 border-b-2 border-white group-hover:border-secondary rounded-full animate-spin"></div>
    </div>
  );
};

export const ProgressBarLoading = ({ className }) => {
  return (
    <div className={className}>
      <div className="relative w-full h-full overflow-hidden">
        <div className="w-full h-full absolute left-0 bg-gradient-to-r from-transparent to-[#4f75fa] animate-barloader"></div>
      </div>
    </div>
  );
};

export const LogoLoading = ({ className }) => {
  return (
    <div
      className={`inset-0 fixed z-[10000]  w-full min-h-screen flex flex-col justify-center items-center gap-5 ${className} bg-white dark:bg-darkBg`}
    >
      <img
        loading="eager"
        src="/mr-logo.gif"
        alt="Loading Logo"
        className="w-20 h-20"
      />
    </div>
  );
};
