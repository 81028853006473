/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

const UpdateSeoSettingCountModal = ({
  openModal,
  setOpenModal,
  handleAction,
  settingCount,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    setCount(settingCount);
  }, [settingCount]);
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Update Seo Setting Count</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5 font-pro">
          <div className="space-y-3">
            <label htmlFor="add">Seo Setting Count</label>
            <Input
              id="add"
              placeholder="Seo Setting Count"
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
            />
          </div>
          <div className="flex justify-end gap-3">
            <Button onClick={() => setOpenModal(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleAction(count)}>Update</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateSeoSettingCountModal;
