/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import { usePostChangeEmailSubuser } from "../../../query/useMaster";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import {
  useUpdateUserEmail,
  useVerifyEmailUpdate,
} from "../../../query/useAuthentication";
import OTPInput from "react-otp-input";

const EditEmailModal = ({ isOpenModal, setIsOpenModal, email, setEmail }) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);
  const [updateEmail, setUpdateEmail] = useState();
  const [verifyId, setVerifyId] = useState();
  const [otpCode, setOtpCode] = useState();

  const { mutate: changeEmail, isLoading: changeEmailLoading } =
    useUpdateUserEmail();

  const { mutate: verifyEmail, isLoading: verifyEmailLoading } =
    useVerifyEmailUpdate();

  const handleChangeEmail = () => {
    changeEmail(
      { new_email: updateEmail },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setVerifyId(res.verification_id);
          setStep(2);
        },
        onError: (err) => {
          toast.error(err.response.data.message);
        },
      }
    );
  };

  const handleVerifyEmail = () => {
    verifyEmail(
      {
        id: verifyId,
        data: { code: otpCode },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries({ queryKey: ["user"] });
          setIsOpenModal(false);
          setStep(1);
        },
        onError: (err) => {
          toast.error(err.response.data.message);
        },
      }
    );
  };

  useEffect(() => {
    setUpdateEmail(email);
  }, [email]);

  //   if (changeEmailLoading) {
  //     return <LogoLoading />;
  //   }

  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal(0)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Update Email</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          {step === 1 ? (
            <>
              <div className="mb-3">Email</div>
              <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                <Input
                  value={updateEmail}
                  onChange={(e) => setUpdateEmail(e.target.value)}
                  autoComplete="off"
                  placeholder="Email"
                />
              </div>
            </>
          ) : (
            <>
              <h1 className="text-lg leading-8 md:-tracking-[0.5px] font-semibold  ">
                Verify your email address
              </h1>
              <p className=" text-sm mt-1">
                Please confirm your email address with the code we sent to{" "}
                <span className="font-medium">{updateEmail}</span>.
              </p>
              <div className="w-full my-5 ">
                <OTPInput
                  value={otpCode}
                  onChange={setOtpCode}
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    columnGap: "10px",
                  }}
                  inputStyle={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "8px",
                  }}
                />
              </div>
            </>
          )}
          <div className="flex justify-end gap-3 mt-5">
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpenModal(false);
                setStep(1);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={step === 1 ? handleChangeEmail : handleVerifyEmail}
            >
              {changeEmailLoading || verifyEmailLoading ? (
                <LoadingButton />
              ) : (
                <>{step === 1 ? "Continue" : "Confirm"}</>
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditEmailModal;
