/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import Campaign from "../campaign";
import CampaignComponent from "./CampaignComponent";
import { Button } from "../../../../components/ui/button";
import { usePostAddCampaign } from "../../../../query/useCampaign";
import { toast } from "sonner";
import { LogoLoading } from "../../../../components/loading/loading";
import { useQueryClient } from "@tanstack/react-query";

const AddCampaignModal = ({ openModal, setOpenModal }) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("Email");
  const [questionSet, setQuestionSet] = useState(null);
  const [magicLink, setMagicLink] = useState("");
  const [csv, setCsv] = useState(null);
  const [isOpenPopover, setIsOpenPopover] = useState(false);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = usePostAddCampaign();

  const handleClick = () => {
    mutate(
      {
        name: name,
        type: type,
        // question_set: questionSet,
        magiclink: magicLink,
        csv: csv,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          }
          queryClient.invalidateQueries({
            queryKey: ["getCampaignApi"],
          });
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
        setIsOpenPopover(false);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Campaign</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <CampaignComponent
            name={name}
            setName={setName}
            type={type}
            setType={setType}
            questionSet={questionSet}
            setQuestionSet={setQuestionSet}
            csv={csv}
            setCsv={setCsv}
            magicLink={magicLink}
            setMagicLink={setMagicLink}
            openPopover={isOpenPopover}
            setOpenPopover={setIsOpenPopover}
          />
          <div className="flex justify-center items-center gap-x-3 mt-7">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button className="w-full" onClick={handleClick}>
              Add
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddCampaignModal;
