import React from "react";
import ProductHero from "../../components/products/ProductHero";
import MainNav from "../../components/mainNavbar/MainNav";
import ProductKeyFeatures from "../../components/products/ProductKeyFeatures";
import ProductBenefits from "../../components/products/ProductBenefits";
import { IconWrapper } from "./ResponderAgent";
import { ChartColumnBig, MapPinned, SearchCheck } from "lucide-react";
import PricingComponent from "../pricing/PricingComponent";
import Footer from "../../components/footer/footer";

const SeoAgentProduct = () => {
  return (
    <div className="bg-white">
      <MainNav />
      <div className="mt-[1.2rem] lg:mt-[3.5rem] 2xl:mt-[7.5rem] relative text-black">
        <ProductHero
          title={"Rank Higher, Get Found, Grow Faster"}
          content={
            "MagicReview’s SEO Agent optimizes your online visibility by analyzing reviews, generating high-impact keywords, and aligning content with your business offerings. With AI-driven keyword extraction, location-based targeting it helps you rank higher on search engines and attract more customers—effortlessly boosting your local SEO."
          }
          badge={"SEO Agent"}
          imgUrl={"/products/seo-hero.png"}
        />
        <div className="bg-gradient-to-b from-[#f1f4ff] via-30% via-white to-white container">
          <ProductKeyFeatures
            contents={[
              {
                title: "AI-Powered Keyword Optimization",
                description:
                  "Extracts customer-driven keywords from reviews to improve search rankings and attract organic traffic.",
                icon: (
                  <IconWrapper color={"#4F75FA"}>
                    <SearchCheck className="text-[#4F75FA] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Competitor Analysis & Benchmarking",
                description:
                  "Identify top-performing keywords in your industry and optimize your strategy to stay ahead of competitors.",
                icon: (
                  <IconWrapper color={"#34A853"}>
                    <ChartColumnBig className="text-[#34A853] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Local SEO Enhancement",
                description:
                  "Boost visibility in local searches by generating location-specific keywords that align with customer searches.",
                icon: (
                  <IconWrapper color={"#FF9737"}>
                    <MapPinned className="text-[#FF9737] size-10" />
                  </IconWrapper>
                ),
              },
            ]}
          />
          <ProductBenefits
            title={"How SEO Agent\nStrengthens Your Reputation"}
            description={
              "Analyze trends, track sentiment, and improve your\n customer experience with AI-powered review analysis."
            }
            contents={[
              {
                title: "Attract More Customers by Matching Search Intent",
                description:
                  "AI-generated reviews naturally include service-related keywords, increasing your visibility in relevant searches.",
                img: (
                  <img
                    src="/products/seo1.png"
                    className="lg:w-[80%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
              {
                title: "Higher Google Rankings with AI-Optimized Reviews",
                description:
                  "Frequent, keyword-rich reviews help your business rank higher on search engines and attract more customers.",
                img: (
                  <img
                    src="/products/seo2.png"
                    className="lg:w-[80%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
              {
                title: "Seamless SEO Integration with Business Offerings",
                description:
                  "AI tailors reviews to your services and locations, ensuring they align with your business strategy and unique offerings.",
                img: (
                  <img
                    src="/products/seo3.png"
                    className="lg:w-[80%] lg:mx-auto"
                  />
                ),
              },
              {
                title:
                  "Better Engagement & Trust Through Authentic Review Optimization",
                description:
                  "Well-structured, engaging AI-generated reviews improve readability, credibility, and search engine rankings.",
                img: (
                  <img
                    src="/products/seo4.png"
                    className="lg:w-[80%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
            ]}
          />
        </div>
        <PricingComponent />
      </div>
      <Footer />
    </div>
  );
};

export default SeoAgentProduct;
