import Apimanager from "./axios";

// const user =
//   localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
// const entityId = user?.entity?.uuid;

export const getEntityExists = async () => {
  const res = await Apimanager.get("/entity/check_if_entity_exists");
  return res.data;
};

export const postCreateNewEntity = async (data) => {
  const res = await Apimanager.post("/entity/create_new_entity", data, {
    headers: {
      "Content-Type": "form-data",
    },
  });
  return res.data;
};

export const getDefaultEntityQuestions = async (params) => {
  const res = await Apimanager.get(`/entity/questions`, {
    params: {
      filter: params?.filter,
      sort: params?.sort,
    },
  });
  return res.data;
};

export const getDefaultEntityQuestionsForSets = async (params) => {
  const res = await Apimanager.get(`/entity/${params?.set}/questions`, {
    params: {
      filter: params?.filter,
      sort: params?.sort,
    },
  });
  return res.data;
};

export const getPreviewQuestionsForSets = async (params) => {
  const res = await Apimanager.get(`/entity/${params?.set}/preview`);
  return res.data;
};

export const getSetIdsForQuesitons = async (id) => {
  const res = await Apimanager.get(`/entity/${id}/get_set_id`);
  return res.data;
};

// export const postUpdateEntityQuestions = async (data) => {
//   const res = await Apimanager.post("/entity/update_entity_questions", data);
//   return res.data;
// };

export const postAddEntityQuestion = async (data) => {
  const res = await Apimanager.post(`/entity/questions`, data);
  return res.data;
};

export const putUpdateEntityQuestion = async ({ id, data }) => {
  const res = await Apimanager.put(`/entity/questions/${id}`, data);
  return res.data;
};

export const deleteEntityQuestion = async (id) => {
  const res = await Apimanager.delete(`/entity/questions/${id}`);
  return res.data;
};

export const postToggleQuestion = async (id) => {
  const res = await Apimanager.post(`/entity/questions/${id}/toggle`);
  return res.data;
};

export const postUpdateEntityQuestionsV2 = async (data) => {
  const res = await Apimanager.post(`/entity/update_entity_questions`, data);
  return res.data;
};

export const deleteEntityQuestionsV2 = async (data) => {
  const res = await Apimanager.delete(
    `/entity/delete_entity_questions/${data}`,
    {
      data: data,
    }
  );
  return res.data;
};

export const getUniqueEntityUrl = async () => {
  const res = await Apimanager.get("/entity/get_magic_link");
  return res.data;
};

export const getTotalReviewsGeneratedCount = async () => {
  const res = await Apimanager.get("/entity/total_reveiws_generated_count/");
  return res.data;
};

export const getReviewCount = async ({ startDate, endDate }) => {
  const res = await Apimanager.get("/entity/get_review_count/", {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });

  return res.data;
};

export const getTotalReviewsPosted = async () => {
  const res = await Apimanager.get("/entity/get_total_reviews_posted/");
  return res.data;
};

export const getAiReviewPosted = async () => {
  const res = await Apimanager.get("/entity/ai_review_posted/");
  return res.data;
};

export const getLatestReviews = async () => {
  const res = await Apimanager.get("/entity/get_latest_reviews/");
  return res.data;
};

export const getAiReviewPostedAggregation = async ({ aggregation }) => {
  const res = await Apimanager.get(
    "/entity/get_ai_review_posted_aggregation/",
    {
      params: {
        aggreagtion: aggregation,
      },
    }
  );

  return res.data;
};

export const getEntityInformation = async (id) => {
  const res = await Apimanager.get(`/entity/get_entity_information/${id}/`);
  return res.data;
};

export const getEntitySuggestions = async (placename) => {
  const res = await Apimanager.get(`/entity/suggestions`, {
    params: {
      placeName: placename,
    },
  });

  return res.data;
};

export const getStats = async () => {
  const res = await Apimanager.get(`/entity/stats`);
  return res.data;
};

export const getStatsGraph = async (sort) => {
  const res = await Apimanager.get(`/entity/stats/graph`, {
    params: {
      sort: sort,
    },
  });
  return res.data;
};

export const getQuestionCategories = async () => {
  const res = await Apimanager.get(`/entity/questions/categories`);
  return res.data;
};

export const postAddQuestionCategories = async (data) => {
  const res = await Apimanager.post(`/entity/questions/categories`, data);
  return res.data;
};

export const putEditQuestionCategories = async (data) => {
  const res = await Apimanager.put(
    `/entity/questions/categories/${data.category_slug}`,
    {
      category: data.category,
    }
  );
  return res.data;
};

export const deleteQuestionCategories = async (data) => {
  const res = await Apimanager.delete(`/entity/questions/categories/${data}`);
  return res.data;
};

export const getQuestionKeyword = async () => {
  const res = await Apimanager.get(`/entity/questions/keywords`);
  return res.data;
};

export const postAddQuestionKeyword = async (data) => {
  const res = await Apimanager.post(`/entity/questions/keywords`, data);
  return res.data;
};

export const putEditQuestionKeyword = async (data) => {
  const res = await Apimanager.put(
    `/entity/questions/keywords/${data.keyword_slug}`,
    {
      keyword: data.keyword,
    }
  );
  return res.data;
};

export const deleteQuestionKeyword = async (data) => {
  const res = await Apimanager.delete(`/entity/questions/keywords/${data}`);
  return res.data;
};

export const getEntityInfo = async () => {
  const res = await Apimanager.get(`/entity`);
  return res.data;
};

export const updateEntityInfo = async (data) => {
  const res = await Apimanager.put(`/entity/`, data);
  return res.data;
};

export const getTags = async () => {
  const res = await Apimanager.get(`/entity/tags`);
  return res.data;
};

export const getTagsExist = async (data) => {
  const res = await Apimanager.get(`/entity/tags/${data}/exists`);
  return res.data;
};

export const postAddTag = async (data) => {
  const res = await Apimanager.post(`/entity/tags`, data);
  return res.data;
};

export const deleteTag = async (data) => {
  const res = await Apimanager.delete(`/entity/tags/${data.id}`, {
    data: {
      name: data.name,
      entity: data.entity,
    },
  });
  return res.data;
};

export const getQuestionSetsApi = async () => {
  const res = await Apimanager.get(`/entity/sets`);
  return res.data;
};

export const postAddQuestionSetApi = async (data) => {
  const res = await Apimanager.post(`/entity/sets`, data);
  return res.data;
};

export const putUpdateQuestionSetApi = async (data) => {
  const res = await Apimanager.put(`/entity/sets/${data?.id}`, {
    name: data?.name,
  });
  return res.data;
};

export const deleteQuestionSetApi = async (id) => {
  const res = await Apimanager.delete(`/entity/sets/${id}`);
  return res.data;
};

export const getSearchQuestions = async (params) => {
  const res = await Apimanager.get(`/entity/search_questions`, {
    params: params,
  });

  return res.data;
};

export const getEntityTheme = async () => {
  const res = await Apimanager.get(`/entity/entity_theme`);

  return res.data;
};

export const putUpdateEntityTheme = async (data) => {
  const res = await Apimanager.put(`/entity/entity_theme`, data);

  return res.data;
};

export const deleteEntityTheme = async () => {
  const res = await Apimanager.delete(`/entity/entity_theme`);

  return res.data;
};

export const getEntityPin = async () => {
  const res = await Apimanager.get(`/entity/entity_pin`);
  return res.data;
};

export const postEntityPin = async (data) => {
  const res = await Apimanager.post(`/entity/entity_pin`, data);
  return res.data;
};

export const putResetEntityPin = async (data) => {
  const res = await Apimanager.put(`/entity/entity_pin_reset`, data);
  return res.data;
};

export const getCustomDomain = async () => {
  const res = await Apimanager.get(`/entity/custom_domain`);
  return res.data;
};

export const postAddCustomDomain = async (data) => {
  const res = await Apimanager.post(`/entity/custom_domain`, data);
  return res.data;
};

export const putEditCustomDomain = async (data) => {
  const res = await Apimanager.put(`/entity/custom_domain`, data);
  return res.data;
};

export const deleteCustomDomain = async () => {
  const res = await Apimanager.delete(`/entity/custom_domain`);
  return res.data;
};

export const postToggleCategory = async (id) => {
  const res = await Apimanager.post(
    `/entity/questions/category/${id}/togglecategory`
  );
  return res.data;
};

export const postToggleCategoryMandatory = async (id) => {
  const res = await Apimanager.post(
    `/entity/questions/category/${id}/category_mandatory_toggle`
  );
  return res.data;
};

export const getToggleDirectReview = async () => {
  const res = await Apimanager.get(`/entity/get_toggle_direct_review`);
  return res.data;
};

export const postToggleDirectReview = async () => {
  const res = await Apimanager.post(`/entity/get_toggle_direct_review`);
  return res.data;
};

export const getToggleVoiceReview = async (entityId) => {
  const res = await Apimanager.get(`/entity/${entityId}/get_set_voice_review`);

  return res.data;
};

export const postToggleVoiceReview = async (entityId) => {
  const res = await Apimanager.post(`/entity/${entityId}/get_set_voice_review`);

  return res.data;
};

export const postSetCategoryOrder = async (data) => {
  const res = await Apimanager.post(
    `/entity/${data?.set}/set_category_order`,
    data?.order
  );
  return res.data;
};

export const getAudioUrl = async (data) => {
  const res = await Apimanager.get(`/entity/${data?.id}/get_audio_url`);
  return res.data;
};

export const postGenerateAiQuestions = async (data) => {
  const res = await Apimanager.post(
    `/entity/generate_ai_question_suggestions`,
    data
  );
  return res.data;
};

export const postGenerateAiQuestionsByVoice = async (data) => {
  const res = await Apimanager.post(
    `/entity/generate_ai_question_suggestions`,
    data,
    {
      headers: {
        "Content-Type": "multipart-formdata",
      },
    }
  );
  return res.data;
};

export const postAddAiQuestions = async ({ set, data }) => {
  const res = await Apimanager.post(
    `/entity/add_ai_questions${set ? "?set=" + set : ""}`,
    data
  );
  return res.data;
};

export const getSupportedLanguageLocales = async () => {
  const res = await Apimanager.get(`/entity/voice_candidate_languages`);
  return res.data;
};

export const putSupportedLanguageLocales = async (data) => {
  const res = await Apimanager.put(`/entity/voice_candidate_languages`, data);
  return res.data;
};

export const postRequestReview = async (data) => {
  const res = await Apimanager.post(`/entity/request_review`, data);
  return res.data;
};

export const getStorageUsage = async () => {
  const res = await Apimanager.get(`/entity/get_storage_usage`);
  return res.data;
};

export const postUploadKnowledgeBase = async (data) => {
  const res = await Apimanager.post(`/entity/get_upload_knowledge_base`, data, {
    headers: {
      "Content-Type": "multipart/formdata",
    },
  });

  return res.data;
};

export const getUploadKnowledgeBase = async () => {
  const res = await Apimanager.get(`/entity/get_upload_knowledge_base`);

  return res.data;
};

export const deleteKnowledgeBase = async (id) => {
  const res = await Apimanager.delete(`/entity/${id}/delete_upload`);

  return res.data;
};

export const postUploadKnowledgeUrl = async (data) => {
  const res = await Apimanager.post(`/entity/url_upload`, data);

  return res.data;
};

export const getUploadKnowledgeUrl = async () => {
  const res = await Apimanager.get(`/entity/url_upload`);

  return res.data;
};

export const deleteKnowledgeUrl = async (id) => {
  const res = await Apimanager.delete(`/entity/${id}/delete_url`);

  return res.data;
};
