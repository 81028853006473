/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { AudioLines, ChevronDown, SquarePlus, Text } from "lucide-react";
import { toast } from "sonner";
import { usePostPositivePrompt } from "../../../../query/useReview";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { cn } from "../../../../utils/cn";
import { Slider } from "../../../../components/ui/slider";

const AddConditionModal = ({ openModal, setOpenModal, refetchPositive }) => {
  const [title, setTitle] = useState("");
  const [condition, setCondition] = useState("");
  const [tone, setTone] = useState("Formal");
  const [length, setLength] = useState([50]);

  const { mutate: addPositivePrompt, isLoading: addPositivePromptLoading } =
    usePostPositivePrompt();

  const handleAddPositivePrompt = () => {
    addPositivePrompt(
      {
        title: title,
        condition: condition,
        tone: tone,
        response_length: length?.[0],
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchPositive();
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Add Condition</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5">
          <div className="flex items-center gap-5">
            <Select value={tone} onValueChange={(e) => setTone(e)}>
              <SelectTrigger className="w-[180px] rounded-[8px] h-9 flex-shrink-0">
                <AudioLines />
                <SelectValue placeholder="Select Tone" />
              </SelectTrigger>
              <SelectContent>
                {[
                  "Formal",
                  "Informal",
                  "Polite",
                  "Friendly",
                  "Enthusiastic",
                  "Neutral",
                  "Casual",
                  "Professional",
                  "Optimistic",
                  "Reassuring",
                ].map((item) => (
                  <SelectItem key={item} value={item}>
                    {item}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Popover modal={true}>
              <PopoverTrigger
                className={cn(
                  "flex h-9  items-center justify-between rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0  disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1  dark:bg-darkBg w-[180px] flex-shrink-0"
                )}
              >
                <Text />
                Length - {length}
                <ChevronDown className="h-4 w-4 opacity-50" />
              </PopoverTrigger>
              <PopoverContent className="z-[1010]">
                <Slider
                  value={[length]}
                  min={50}
                  max={250}
                  step={50}
                  onValueChange={(e) => setLength(e)}
                />
                <div className="w-full flex justify-between mt-2 font-pro">
                  {[50, 100, 150, 200, 250].map((item) => (
                    <span
                      className="text-[10px] cursor-pointer"
                      key={item}
                      onClick={() => {
                        setLength([item]);
                      }}
                    >
                      {item}
                    </span>
                  ))}
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className="w-full space-y-3">
            <label htmlFor="title" className="font-medium text-sm">
              Title
            </label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="title"
            />
          </div>
          <div className="w-full space-y-3">
            <label htmlFor="condition" className="font-medium text-sm">
              Condition
            </label>
            <Textarea
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              id="condition"
            />
          </div>

          <div className="flex justify-end items-center gap-3">
            <Button onClick={handleAddPositivePrompt}>Save</Button>
            <Button variant="secondary" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddConditionModal;
