/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { dayFormatter, timeFormatter } from "../../../../utils/formatter";
import { Check, Link, Mic, Play, PlayCircle, Square } from "lucide-react";
import Booking from "../../../../assets/reviewservices/booking.svg";
import Google from "../../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../../assets/reviewservices/yelp.svg";
import { useGetAudioUrl } from "../../../../query/useEntity";
import { toast } from "sonner";

const DashboardReviewCard = ({ review }) => {
  const audioRef = useRef(null);
  const [url, setUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleGoToMagiclink = (link) => {
    window.open(link);
  };

  const { mutate: audioUrl, isLoading: audioUrlLoading } = useGetAudioUrl();

  const handleGetAudioUrl = (id) => {
    audioUrl(
      {
        id: id,
      },
      {
        onSuccess: (res) => {
          if (res.url) {
            setUrl(res.url);
            const audio = audioRef.current;
            audio.src = res.url;
            audio.play();
            setIsPlaying(!isPlaying);
          }
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    const audio = audioRef?.current;

    const handleMetadataLoaded = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio?.addEventListener("timeupdate", handleTimeUpdate);
    audio?.addEventListener("durationchange", handleMetadataLoaded);

    return () => {
      audio?.removeEventListener("timeupdate", handleTimeUpdate);
      audio?.removeEventListener("durationchange", handleMetadataLoaded);
    };
  }, [url]);

  useEffect(() => {
    if (Math.round(duration) === Math.round(currentTime)) {
      setIsPlaying(false);
    }
  }, [currentTime, duration]);
  return (
    <>
      {review ? (
        <div
          className="flex flex-col h-full gap-y-2 bg-white dark:bg-darkBg"
          key={review?.uuid}
        >
          <div className="flex flex-col gap-[35px]">
            <div className="flex items-center justify-between">
              <div className="flex gap-4 items-center">
                {/* <img
                  src={review?.profile_photo_url || "/new-logo.svg"}
                  alt=""
                  className="w-[50px] h-[50px]"
                  referrerPolicy="no-referrer"
                /> */}
                {/* <div>
                  <h1 className="font-pro text-base font-medium mb-1">
                    {review?.author_name || "MagicReview"}
                  </h1>
                  <p className="text-[#777] text-[13px] font-pro ">
                    {review?.time && dayFormatter(review?.time)}
                  </p>
                </div> */}
                <div>
                  <h1 className="font-pro text-sm  mb-1">
                    {/* {review?.author_name || "MagicReview"} */}
                    {review?.time && dayFormatter(review?.time)}
                  </h1>
                  <p className=" text-[10px] font-pro ">
                    {/* {review?.time && dayFormatter(review?.time)} */}
                    {review?.time && timeFormatter(review?.time)}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <audio ref={audioRef}></audio>
                {review?.voice_review && (
                  <>
                    {isPlaying ? (
                      <button
                        className="p-2 rounded-full bg-newPrimary text-white"
                        onClick={() => {
                          setIsPlaying(false);
                          audioRef.current.pause();
                          audioRef.current.value = "";
                          setUrl("");
                        }}
                      >
                        <Square className="fill-white" />
                      </button>
                    ) : (
                      <button
                        className="p-2 rounded-full bg-newPrimary text-white"
                        onClick={() =>
                          handleGetAudioUrl(review?.original_voice)
                        }
                        disabled={audioUrlLoading}
                      >
                        {audioUrlLoading ? (
                          <div className="flex items-center justify-center">
                            <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                          </div>
                        ) : (
                          <Play className="fill-white" />
                        )}
                      </button>
                    )}
                  </>
                )}
                {review?.is_posted_ai_review && (
                  // <div className="p-2 rounded-full bg-[#F0F5FF] text-[#4F75FA]">
                  //   <Check />
                  // </div>
                  <div className="px-2 py-1 rounded-md bg-[#fff5e3] text-[#ffaf19] text-sm font-pro">
                    Redirected
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* <div className="flex gap-[3px]">
            <img src="/yellowstar.svg" alt="" className="w-[28px] h-[28px]" />
            <img src="/yellowstar.svg" alt="" className="w-[28px] h-[28px]" />
            <img src="/yellowstar.svg" alt="" className="w-[28px] h-[28px]" />
            <img src="/yellowstar.svg" alt="" className="w-[28px] h-[28px]" />
            <img src="/yellowstar.svg" alt="" className="w-[28px] h-[28px]" />
          </div> */}
          <p className="text-[#777] dark:text-white text-sm font-pro">
            {review?.text}
          </p>

          <div className="flex items-center gap-x-2 mt-2">
            {review?.source === "G" && (
              <div className="p-2 rounded-full bg-[#F6F6FF]">
                <img src={Google} className="w-6 h-6" />
              </div>
            )}
            {review?.source === "T" && (
              <div className="p-2 rounded-full bg-[#F6F6FF]">
                <img src={TrustPilot} className="w-6 h-6" />
              </div>
            )}
            {review?.source === "Y" && (
              <div className="p-2 rounded-full bg-[#F6F6FF]">
                <img src={Yelp} className="w-6 h-6" />
              </div>
            )}
            {review?.source === "B" && (
              <div className="p-2 rounded-full bg-[#F6F6FF]">
                <img src={Booking} className="w-6 h-6" />
              </div>
            )}
            {review?.source === "TA" && (
              <div className="p-2 rounded-full bg-[#F6F6FF]">
                <img src={TripAdvisor} className="w-6 h-6" />
              </div>
            )}
            {/* {review?.source === "AI" && (
                        <div className="p-2 rounded-full bg-secondary text-white">
                          <Bot />
                        </div>
                      )} */}
          </div>

          <div className="flex items-center gap-x-2 mt-1">
            {review?.staff_member_name && (
              <div className="p-2 rounded-md text-[#615E83] bg-[#F6F6FF] dark:text-secondary dark:bg-darkLightBg text-sm font-medium">
                {review?.staff_member_name}
              </div>
            )}
            {review?.staff_member_code && (
              <div className="p-2 rounded-md text-[#615E83] bg-[#F6F6FF] dark:text-secondary dark:bg-darkLightBg text-sm font-medium">
                {review?.staff_member_code}
              </div>
            )}
            <div
              className="rounded-md text-white p-2 bg-newPrimary cursor-pointer"
              onClick={() => handleGoToMagiclink(review?.magic_link)}
            >
              <Link className="w-5 h-5" />
            </div>
          </div>
        </div>
      ) : (
        <p>No selected review</p>
      )}
    </>
  );
};

export default DashboardReviewCard;
