import { Card, CardContent, CardHeader } from "../../../components/ui/card";
import Layout from "../../dashboard/component/Layout";
import { useState } from "react";
import {
  useGetDomain,
  usePostAddSubuserInvite,
  usePostCreateSubUserInternal,
} from "../../../query/useMaster";
import { LogoLoading } from "../../../components/loading/loading";
import { toast } from "sonner";
import ConfirmUserModal from "./ConfirmUserModal";
import { Asterisk, Copy } from "lucide-react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Switch } from "../../../components/ui/switch";
import {
  Select,
  SelectContent,
  SelectValue,
  SelectTrigger,
  SelectItem,
} from "../../../components/ui/select";
import { emailRegex } from "../../contact/contact";
import { useGetIndustryTypes } from "../../../query/useAuthentication";

const NewUser = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("hotel");
  const [domain, setDomain] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isCompleteProfile, setIsCompleteProfile] = useState(false);
  const [isCreditBasedAdd, setIsCreditBasedAdd] = useState(true);
  const [isCreditBasedInvite, setIsCreditBasedInvite] = useState(true);

  useGetDomain({
    onSuccess: (res) => {
      setDomain(res.domain);
    },
  });

  const { data: industryTypes } = useGetIndustryTypes();

  const { mutate: createSubUser, isLoading: createSubUserLoading } =
    usePostCreateSubUserInternal();

  const { mutate: addSubuserInvite, isLoading: addSubuserInviteLoading } =
    usePostAddSubuserInvite();

  const handleCreateSubUser = () => {
    let data;
    if (isCompleteProfile) {
      data = {
        username: userName,
        password: password,
        is_entity_toggle: isCompleteProfile,
        first_name: firstName,
        last_name: lastName,
        company_name: companyName,
        industry: industry,
        credit_payment: isCreditBasedAdd,
      };
      if (!firstName) {
        delete data["first_name"];
      }
      if (!lastName) {
        delete data["last_name"];
      }
      if (!companyName) {
        delete data["company_name"];
      }
      if (!industry) {
        delete data["industry"];
      }
    } else {
      data = {
        username: userName,
        password: password,
        credit_payment: isCreditBasedAdd,
      };
    }

    createSubUser(data, {
      onSuccess: (res) => {
        toast.success(res.message);
        setUserName("");
        setPassword("");
        setFirstName("");
        setLastName("");
        setCompanyName("");
        setIndustry("hotel");
        setIsOpenConfirmModal(false);
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
        setIsOpenConfirmModal(false);
      },
    });
  };

  const handleInvite = () => {
    if (!emailRegex.test(inviteEmail)) {
      toast.error("Invalid email");
      return;
    }
    addSubuserInvite(
      {
        email: inviteEmail,
        credit_payment: isCreditBasedInvite,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setInviteEmail("");
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleOpenModal = () => {
    if (!userName) {
      toast.error("Username is required");
      return;
    }
    if (!password) {
      toast.error("Password is required");
      return;
    }
    setIsOpenConfirmModal(true);
  };

  const handleCopy = (value) => {
    if (value) {
      navigator.clipboard.writeText(value);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No text to copy!");
    }
  };

  if (createSubUserLoading || addSubuserInviteLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Users"}>
      <div className="flex flex-col w-full dark:bg-darkLightBg rounded-[16px] py-5 min-h-[calc(100vh-200px)] 3xl:min-h-[calc(100vh-150px)] px-5 lg:px-[30.2px] mt-[20px]">
        <div className="w-full lg:w-9/12 space-y-7">
          <div>
            <p className="font-pro font-semibold text-base text-[#252323] dark:text-white mb-3">
              Create New User
            </p>

            <Card>
              <CardContent>
                <div className="space-y-5 font-pro pt-5">
                  <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                    <div className="w-[200px] flex-shrink-0 flex items-center gap-1">
                      Username <Asterisk className="text-red-500 size-4" />
                    </div>
                    <Input
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      autoComplete="off"
                      //   error={"Username is required"}
                    />
                    @
                    <Input value={domain} disabled />
                    <button
                      className="bg-lightpurple dark:bg-darkLightBg size-8 flex justify-center items-center rounded-md flex-shrink-0"
                      onClick={() => {
                        handleCopy(userName + "@" + domain);
                      }}
                    >
                      <Copy className="size-4 text-newPrimary" />
                    </button>
                  </div>
                  <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                    <div className="w-[200px] flex-shrink-0 flex items-center gap-2">
                      Password <Asterisk className="text-red-500 size-4" />
                    </div>

                    <Input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="bg-lightpurple dark:bg-darkLightBg size-8 flex justify-center items-center rounded-md flex-shrink-0"
                      onClick={() => {
                        handleCopy(password);
                      }}
                    >
                      <Copy className="size-4 text-newPrimary" />
                    </button>
                  </div>
                  <div className="flex items-center gap-5 lg:gap-3 flex-wrap lg:flex-nowrap justify-between">
                    <div className="flex items-center gap-3">
                      <div className="w-[200px] flex-shrink-0 ">
                        Add Complete Profile
                      </div>
                      <Switch
                        checked={isCompleteProfile}
                        onCheckedChange={() =>
                          setIsCompleteProfile((prev) => !prev)
                        }
                      ></Switch>
                    </div>
                    <div className="flex items-center gap-5 lg:gap-3 lg:flex-nowrap flex-wrap">
                      <div className="w-[150px] flex-shrink-0 ">
                        Subscription Type
                      </div>
                      <div
                        className={`flex items-center bg-white dark:bg-darkBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border`}
                      >
                        <button
                          className={` ${isCreditBasedAdd === true ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                          onClick={() => setIsCreditBasedAdd(true)}
                        >
                          Credit Based
                        </button>
                        <button
                          className={`${isCreditBasedAdd === false ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                          onClick={(prev) => setIsCreditBasedAdd(false)}
                        >
                          Subscription Based
                        </button>
                      </div>
                    </div>
                  </div>

                  {isCompleteProfile && (
                    <>
                      <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                        <div className="w-[200px] flex-shrink-0">
                          First Name
                        </div>

                        <Input
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                        <div className="w-[200px] flex-shrink-0">Last Name</div>

                        <Input
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </div>
                      <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                        <div className="w-[200px] flex-shrink-0">
                          Company Name
                        </div>

                        <Input
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                        />
                      </div>
                      <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                        <div className="w-[200px] flex-shrink-0">Industry</div>

                        <Select
                          onValueChange={(value) => setIndustry(value)}
                          value={industry}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="Select an industry" />
                          </SelectTrigger>
                          <SelectContent>
                            {industryTypes?.map((item) => (
                              <SelectItem
                                key={item?.id}
                                value={item?.industry_identifier}
                              >
                                {item?.display_text}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </>
                  )}
                  <div className="flex items-center gap-3">
                    <div className="w-[200px] hidden lg:block"></div>
                    <Button
                      // className="w-full md:w-[120px] bg-newPrimary text-white flex items-center justify-center rounded-lg p-2 gap-x-3 text-sm font-pro font-medium"
                      onClick={handleOpenModal}
                    >
                      Create
                    </Button>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div>
            <p className="font-pro font-semibold text-base text-[#252323] dark:text-white mb-3">
              Link an account
            </p>

            <Card>
              <CardContent>
                <div className="space-y-5 font-pro pt-5">
                  <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
                    <div className="w-[200px] flex-shrink-0 ">
                      Email Address
                    </div>
                    <Input
                      value={inviteEmail}
                      onChange={(e) => setInviteEmail(e.target.value)}
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleInvite();
                        }
                      }}
                    />

                    <Button onClick={handleInvite}>Invite</Button>
                  </div>
                  <div className="flex items-center gap-3 lg:flex-nowrap flex-wrap">
                    <div className="w-[200px] flex-shrink-0 ">
                      Subscription Type
                    </div>
                    <div
                      className={`flex items-center bg-white dark:bg-darkBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border`}
                    >
                      <button
                        className={` ${isCreditBasedInvite === true ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                        onClick={() => setIsCreditBasedInvite(true)}
                      >
                        Credit Based
                      </button>
                      <button
                        className={`${isCreditBasedInvite === false ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                        onClick={() => setIsCreditBasedInvite(false)}
                      >
                        Subscription Based
                      </button>
                    </div>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      <ConfirmUserModal
        isOpenModal={isOpenConfirmModal}
        setIsOpenModal={setIsOpenConfirmModal}
        handleAction={handleCreateSubUser}
      />
    </Layout>
  );
};

export default NewUser;
