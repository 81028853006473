/* eslint-disable react/prop-types */
import React from "react";
import { motion } from "framer-motion";

const ProductBenefits = ({ contents, title, description }) => {
  return (
    <div className="lg:py-20 py-10 font-poppins">
      <div className="space-y-6">
        <motion.p
          className=" bg-newPrimary h-10 w-fit mx-auto text-white text-[17px] rounded-full flex items-center justify-center px-5"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          viewport={{
            once: true,
          }}
        >
          Benefits
        </motion.p>
        <motion.p
          className="font-semibold lg:text-[38px] lg:leading-[48px] lg:tracking-tighter text-center whitespace-pre text-wrap"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          {title}
        </motion.p>
        <motion.p
          className="text-sm lg:text-[17px] text-[#2d2d2d] text-center leading-[26px] whitespace-pre text-wrap"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.4,
          }}
          viewport={{
            once: true,
          }}
        >
          {description}
        </motion.p>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 lg:mt-20 mt-10 lg:px-20">
        {contents.map((content, i) => (
          <motion.div
            key={i}
            className={`w-full h-full bg-[#fcfcfc] border border-[#ebebeb] rounded-[16px] p-5 lg:p-12 space-y-5 flex-grow hover:drop-shadow-lg transition-all duration-200`}
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: i * 0.2,
            }}
            viewport={{
              once: true,
            }}
          >
            <p className="font-semibold lg:text-[22px] lg:leading-[30px] text-[#1d1d1f] text-wrap whitespace-pre">
              {content.title}
            </p>
            <p>{content.description}</p>
            <div>{content.img}</div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProductBenefits;
