/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";
import {
  useGetDomain,
  usePostChangeEmailSubuser,
} from "../../../query/useMaster";
import { LogoLoading } from "../../../components/loading/loading";
import { toast } from "sonner";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { useQueryClient } from "@tanstack/react-query";

const EditEmailModal = ({
  isOpenModal,
  setIsOpenModal,
  id,
  email,
  setEmail,
}) => {
  const [userName, setUserName] = useState("");
  const [domain, setDomain] = useState("");
  const [inputValue, setInputValue] = useState("");
  const queryClient = useQueryClient();
  useGetDomain({
    onSuccess: (res) => {
      setDomain(res.domain);
    },
  });
  const { mutate: changeEmail, isLoading: changeEmailLoading } =
    usePostChangeEmailSubuser();

  const handleChangeEmail = () => {
    changeEmail(
      { pk: id, email: userName + "@" + inputValue },
      {
        onSuccess: (res) => {
          setEmail(userName + "@" + inputValue);
          toast.success(res.message);
          queryClient.invalidateQueries("getSubUsersInfinity");

          setIsOpenModal(false);
        },
        onError: (err) => {
          toast.error(err.response.data.message);
        },
      }
    );
  };

  useEffect(() => {
    if (email) {
      const [user, domainName] = email.split("@");
      setUserName(user);
      setInputValue(domainName);
    }
  }, [email]);

  if (changeEmailLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal(0)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit User Email</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <div className="mb-3">Email</div>
          <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
            <Input
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
              autoComplete="off"
              placeholder="Username"
              //   error={"Username is required"}
            />
            @
            {/* <Input value={domain} onChange={(e) => setDomain(e.target.value)} /> */}
            <Popover modal={true}>
              <PopoverTrigger asChild>
                <Input
                  type="text"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Type or select a domain"
                  className="cursor-pointer w-full"
                />
              </PopoverTrigger>
              <PopoverContent className="z-[1010] p-0">
                <Button
                  variant="ghost"
                  className="w-full text-center"
                  onClick={() => setInputValue(domain)}
                >
                  {domain}
                </Button>
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex justify-end gap-3 mt-5">
            <Button variant="secondary" onClick={() => setIsOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleChangeEmail}>Confirm</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditEmailModal;
