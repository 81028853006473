import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  useDeleteCustomDomain,
  useGetCustomDomain,
  usePostAddCustomDomain,
  usePutEditCustomDomain,
} from "../../../../query/useEntity";
import { Input } from "../../component/Input";
import {
  Check,
  CircleArrowLeft,
  CircleArrowRight,
  Info,
  Loader2,
  Trash2,
} from "lucide-react";
import { Button } from "../../../../components/ui/button";
import Loading, { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { Switch } from "../../../../components/ui/switch";
import { DeleteModal } from "../../component/modal";
import ViewConfigurationModal from "./ViewConfigurationModal";
import AddDomainModal from "./AddDomainModal";
import { AnimatePresence } from "framer-motion";

const CustomDomain = () => {
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenConfigModal, setIsOpenConfigModal] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [tab, setTab] = useState("");
  const [step, setStep] = useState(0);

  const {
    data: customDomain,
    refetch: refetchDomain,
    isLoading,
    isFetching,
  } = useGetCustomDomain();

  const { mutate: editDomain, isLoading: editDomainLoading } =
    usePutEditCustomDomain();

  const { mutate: deleteDomain, isLoading: deleteDomainLoading } =
    useDeleteCustomDomain();

  const toggle = () => {
    editDomain(
      {
        active: !customDomain?.data?.active,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchDomain();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteDomain = () => {
    deleteDomain(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchDomain();
          setIsOpenDeleteModal(false);
          setTab("learn");
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (customDomain?.data) {
      setStep(0);
      setTab("domain");
    }
  }, [customDomain]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          {step === 0 && (
            <Card className="border-[#E7E4E4] dark:border-[#454545] font-pro transition-all duration-200">
              <CardHeader className="pb-3">
                <CardTitle className="font-medium text-base text-[#252323] dark:text-white">
                  Domain
                </CardTitle>
                <CardDescription className="font-normal text-[#4a4a4a] dark:text-white text-sm">
                  Use your own domain to customize MagicLinks for a branded,
                  seamless experience.
                </CardDescription>
              </CardHeader>

              <CardContent>
                <hr className="mb-3" />
                <div className="flex justify-end items-center gap-4">
                  <Button variant="secondary" onClick={() => setStep(1)}>
                    Learn More
                  </Button>
                  {customDomain?.data && (
                    <Button
                      variant="outline"
                      onClick={() => setIsOpenConfigModal(true)}
                    >
                      View Configuration
                    </Button>
                  )}
                  {tab !== "domain" && (
                    <>
                      {customDomain?.data ? (
                        <Button
                          onClick={() => setTab("domain")}
                          className="bg-newPrimary/70 hover:bg-newPrimary/50"
                        >
                          Add Domain
                        </Button>
                      ) : (
                        <Button onClick={() => setIsOpenAddModal(true)}>
                          Add Domain
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </CardContent>
            </Card>
          )}

          {step === 1 && (
            <Card className="border-[#E7E4E4] dark:border-[#454545] font-pro transition-all duration-200">
              <CardHeader className="pb-3">
                <CardTitle className="font-medium text-base text-[#252323] dark:text-white">
                  Use Your Own Domain for a Branded Experience
                </CardTitle>
                <CardDescription className="font-normal text-[#4a4a4a] dark:text-white text-sm">
                  Enhance your brand’s credibility by using your own domain for
                  review collection.
                </CardDescription>
              </CardHeader>

              <CardContent>
                <hr className="mb-3" />
                <div className="grid lg:grid-cols-2 gap-3">
                  <div className="space-y-1">
                    <div className="flex items-center gap-2 text-sm">
                      <CircleArrowRight className="size-4 text-[#ffaf19]" />
                      Instead of sharing links like this
                    </div>
                    <Input
                      disabled={true}
                      readOnly={true}
                      className="border-none bg-[#f8f9f8] text-sm text-[#888] w-full rounded-lg"
                      value={"magicreview.ai/business/......."}
                    />
                  </div>
                  <div className="space-y-1">
                    <div className="flex items-center gap-2 text-sm">
                      <CircleArrowRight className="size-4 text-[#34a853]" />
                      You can use this
                    </div>
                    <Input
                      disabled={true}
                      readOnly={true}
                      className="border-none bg-[#f8f9f8] text-sm text-newPrimary w-full rounded-lg"
                      value={"yourdomain.com/business/......."}
                    />
                  </div>
                </div>
                <hr className="mb-3" />
                <ul className="mb-3">
                  {[
                    "Strengthen brand identity",
                    "Improve customer trust",
                    "Seamlessly integrate with your website",
                  ].map((item) => (
                    <li
                      key={item}
                      className="flex items-center gap-2 text-sm text-[#4a4a4a]"
                    >
                      <Check className="size-4 text-newPrimary" />
                      <p>{item}</p>
                    </li>
                  ))}
                </ul>
                <p className="text-sm text-[#4a4a4a]">
                  Simply add your domain or subdomain, follow the setup
                  instructions, and start using your branded magic link!
                </p>
              </CardContent>
              <CardFooter className="justify-end gap-4">
                <Button variant="secondary" onClick={() => setStep(0)}>
                  Show Less
                </Button>
                {customDomain?.data ? (
                  <Button
                    variant="outline"
                    onClick={() => setIsOpenConfigModal(true)}
                  >
                    View Configuration
                  </Button>
                ) : (
                  <Button onClick={() => setIsOpenAddModal(true)}>
                    Add Domain
                  </Button>
                )}
              </CardFooter>
            </Card>
          )}
          {tab === "domain" && (
            <>
              <div className="hidden lg:flex"></div>
              <Card className="border-[#E7E4E4] dark:border-[#454545] font-pro">
                <CardHeader className="pb-3 flex flex-row justify-between items-center">
                  <CardTitle className="font-medium text-base text-[#252323] dark:text-white">
                    Your Domain
                  </CardTitle>
                  <div className="flex items-center gap-2">
                    {editDomainLoading && (
                      <Loader2 className="animate-spin size-5 text-newPrimary" />
                    )}
                    <Switch
                      disabled={editDomainLoading}
                      checked={customDomain?.data?.active}
                      onCheckedChange={toggle}
                    ></Switch>

                    <Button
                      variant="secondary"
                      className="bg-red-100 text-red-500"
                      onClick={() => setIsOpenDeleteModal(true)}
                      size="icon"
                    >
                      <Trash2 className="size-4" />
                    </Button>
                  </div>
                </CardHeader>

                <CardContent>
                  <div className="w-full">
                    <label className="text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {"Domain Type"}
                    </label>
                    <div className="relative mb-[20px]">
                      <input
                        className="border-none bg-[#f8f9f8] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
                        type="text"
                        disabled={true}
                        readOnly={true}
                        value={customDomain?.data?.type}
                      />
                    </div>
                  </div>

                  <div className="w-full">
                    <label className="text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {customDomain?.data?.type === "domain"
                        ? "Domain name"
                        : "Subdomain name"}
                    </label>
                    <div className="relative mb-[20px]">
                      <input
                        className="border-none bg-[#f8f9f8] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
                        type="text"
                        disabled={true}
                        readOnly={true}
                        value={customDomain?.data?.actual_domain}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </>
          )}
        </div>
      )}

      <AddDomainModal
        openModal={isOpenAddModal}
        setOpenModal={setIsOpenAddModal}
        refetchDomain={refetchDomain}
      />

      <ViewConfigurationModal
        openModal={isOpenConfigModal}
        setOpenModal={setIsOpenConfigModal}
        domainType={customDomain?.data?.type}
        domainName={customDomain?.data?.actual_domain}
      />
      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={"Are you sure you want to delete this domain?"}
        handleDelete={handleDeleteDomain}
        isLoading={deleteDomainLoading}
      />
    </>
  );
};

export default CustomDomain;
