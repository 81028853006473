/* eslint-disable react/prop-types */

const MainLogo = ({ className, color }) => {
  return (
    <div className={`flex items-center gap-2 ${className}`}>
      <img src="/new-logo.svg" alt="" className="w-[40px]" />
      <p
        className={`text-[20px] -tracking-[3%] leading-[30px] ${
          color === "white" ? "text-white" : "text-[#434343]"
        } font-avertaBold text-center -mt-0.5`}
      >
        magicreview
      </p>
    </div>
  );
};

export default MainLogo;
