import { useSearchParams } from "react-router-dom";
import { useGetEntityReviewPermissions } from "../../query/useReview";
import { useState } from "react";
import { Rating } from "react-simple-star-rating";
import MainLogo from "../../components/mainlogo";

const ThankYou = () => {
  const [params] = useSearchParams();
  const id = params.get("reviewId");
  const employeeId = params.get("employeeId");
  const star = params.get("star");
  const [entityName, setEntityName] = useState("");
  const [entityLogo, setEntityLogo] = useState("");
  const [bgColor, setBgColor] = useState("");

  const { isLoading } = useGetEntityReviewPermissions(
    {
      entityId: id,
      employeeId: employeeId,
    },
    {
      onSuccess: (res) => {
        setEntityName(res?.entity_name);
        setEntityLogo(res?.logo);
        setBgColor(res?.bg_color);
      },
      enabled: Boolean(id),
    }
  );
  return (
    <div className={`w-full flex flex-col h-screen `}>
      {/* <MainNav /> */}
      {isLoading ? (
        <div className="w-full h-dvh flex flex-col justify-center items-center gap-5">
          <img
            loading="lazy"
            src="/mr-logo.gif"
            alt="Loading Logo"
            className="w-20 h-20"
          />
        </div>
      ) : (
        <div className="h-dvh flex flex-col justify-center items-center gap-9 dark:bg-white font-heebo">
          {entityLogo && (
            <div className="flex flex-col justify-center items-center gap-3.5 ">
              <img
                src={entityLogo}
                alt=""
                className="rounded-full size-20 object-cover"
              />
              <p className="font-bold text-[24px] ">{entityName}</p>
            </div>
          )}
          <div
            className={`w-[90%] md:w-[60%] rounded-3xl relative font-heebo p-8 border border-[#7190ff]/15 bg-white drop-shadow`}
          >
            <img
              src="/thank-you.png"
              alt=""
              className="max-w-[252px] mx-auto"
            />
            <h1 className="text-[34px] font-semibold mt-8 mb-5 md:text-center text-left leading-[38px]">
              Thank you for <br className="md:hidden" /> your feedback!
            </h1>
            <p className=" mt-5  lg:text-center text-[15px] text-[#4d4d4d]">
              Your input helps us continuously enhance your experience and serve
              you better
            </p>

            {/* <div className="mt-10 flex items-center justify-center">
              <Rating
                initialValue={Number(star)}
                SVGstyle={{
                  display: "inline",
                  height: "30px",
                  width: "30px",
                }}
              />
            </div> */}
          </div>
        </div>
      )}

      <div className="md:flex items-center justify-center gap-5 hidden py-5 lg:py-10 ">
        <div className="h-[1px] w-full bg-[#ebebeb]"></div>
        <div className="flex items-center justify-between flex-shrink-0 gap-2">
          <p className="font-poppins text-[#4d4d4d] text-xl flex-shrink-0">
            Powered by
          </p>
          <MainLogo className={"flex-shrink-0"} />
        </div>
        <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
      </div>
      <div className="md:hidden py-10">
        <div className="flex items-center justify-center gap-5 ">
          {/* <div className="h-[1px] w-full bg-[#ebebeb]"></div> */}
          <p className="font-poppins  text-sm flex-shrink-0">Powered by</p>
          {/* <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div> */}
        </div>

        <div className="flex items-center justify-center gap-5 mt-3">
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
          <div className="flex items-center justify-center gap-x-2 flex-shrink-0">
            <img
              src={"/new-logo.svg"}
              alt="MagicReview Logo"
              className="w-[30px] flex-shrink-0"
            />
            <h1 className="font-avertaBold font-bold text-[18px] text-[#4d4d4d] -tracking-[1%] -mt-1">
              magicreview
            </h1>
          </div>
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default ThankYou;
