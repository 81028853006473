/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

const AddPrimaryKeywordModal = ({ openModal, setOpenModal, handleAction }) => {
  const [primaryKeyword, setPrimaryKeyword] = useState("");
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Add Primary Keyword</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5 font-pro">
          <div className="space-y-3">
            <label htmlFor="add">Primary Keyword</label>
            <Input
              id="add"
              placeholder="Add Primary Keyword"
              value={primaryKeyword}
              onChange={(e) => setPrimaryKeyword(e.target.value)}
            />
          </div>
          <div className="flex justify-end gap-3">
            <Button onClick={() => setOpenModal(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleAction(primaryKeyword)}>Add</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddPrimaryKeywordModal;
