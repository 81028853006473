/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import {
  usePostReviewQuestionsByVoice,
  usePostSaveNegativeFeedbacks,
  usePostSaveNegativeFeedbacksByVoice,
} from "../../../query/useReview";
import AudioPlayer from "./AudioPlayer";

import { Check, Pause, Play, RotateCcw, Square } from "lucide-react";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { Rating } from "react-simple-star-rating";
import { Input } from "../../../components/ui/input";
import { PhoneInput } from "../../../components/ui/phone-input";
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "../../../components/ui/drawer";
import { Checkbox } from "../../../components/ui/checkbox";

const VoiceReview = ({
  entityId,
  setStep,
  keywords,
  employeeId,
  mutate,
  trackId,
  bgColor,
  isNegativeFeedback,
  starRating,
  personalInfo,
  formValues,
  formErrors,
  handleFieldPhoneChange,
  checkFormStatus,
  isShowFormErrors,
  handleFieldChange,
  handleThankYou,
  isOfferPage,
}) => {
  const navigate = useNavigate();

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const recorderControls = useVoiceVisualizer();

  const {
    recordedBlob,
    error,
    startRecording,
    stopRecording,
    togglePauseResume,
    clearCanvas,
    formattedDuration,
    formattedRecordedAudioCurrentTime,
    formattedRecordingTime,
    isRecordingInProgress,
    isAvailableRecordedAudio,
    isPausedRecording,
    isPausedRecordedAudio,
    recordingTime,
    duration,
  } = recorderControls;
  const [audioFile, setAudioFile] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const [givenStars, setGivenStars] = useState(0);
  const [isOptIn, setIsOptIn] = useState(true);

  const { mutate: saveNegative, isLoading: saveNegativeLoading } =
    usePostSaveNegativeFeedbacksByVoice();

  const isButtonDisabled = personalInfo?.some(
    (field) => field.must && !formValues[field.field_name].trim()
  );

  const handleVoiceSubmit = () => {
    if (checkFormStatus()) return;

    const formData = new FormData();
    formData.append("audio", audioFile);
    formData.append("business_uuid", entityId);
    formData.append("tr_id", trackId);
    formData.append("save", isOptIn);
    if (formValues) {
      Object.entries(formValues)?.map((item) =>
        formData.append(item[0], item[1])
      );
    }

    if (givenStars > starRating || !isNegativeFeedback) {
      sessionStorage.setItem("isClicked", "false");

      mutate(formData, {
        onSuccess: (res) => {
          localStorage.setItem("reviews", JSON.stringify(res));
          localStorage.setItem("reviewId", entityId);

          navigate(`/review?employee=${employeeId}&voicereview=true`);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data["business_uuid"]) {
            toast.error(err.response.data["business_uuid"]);
          }
        },
      });
    } else {
      // formData.append("employee_code", employeeId);
      saveNegative(formData, {
        onSuccess: (data) => {
          handleThankYou(givenStars);
        },
        onError: (err) => {
          if (err.response.data.error) {
            toast.error(err.response.data.error.message);
          } else if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      });
    }
  };

  useEffect(() => {
    if (!recordedBlob) {
      setAudioFile();
      setAudioUrl();
      return;
    }
    setAudioFile(recordedBlob);

    const audioUrl = URL.createObjectURL(recordedBlob);
    setAudioUrl(audioUrl);

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [recordedBlob, error]);

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (recordingTime > 60000) {
      stopRecording();
    }
  }, [recordingTime]);

  return (
    <div>
      <Drawer>
        <DrawerTrigger asChild>
          <button
            className="w-full rounded-md text-center py-3 font-heebo text-sm"
            onClick={() => {
              startRecording();
            }}
            style={{
              backgroundColor: `color-mix(in srgb, ${bgColor} 5%, white)`,
              color: bgColor,
            }}
          >
            Start Recording
          </button>
        </DrawerTrigger>
        <DrawerContent>
          <div className="max-h-[90vh] overflow-y-auto scrollbar-thin">
            <div className="space-y-3 mb-5">
              <p className="font-heebo font-medium lg:text-2xl">
                Things to Talk About:
              </p>
              <div className="flex items-center gap-3 flex-wrap">
                {keywords
                  ?.filter((item) => item !== null)
                  .map((item) => (
                    <div
                      key={item}
                      className="px-2 py-1.5 border rounded-md bg-[#f7f7f7] text-[#888] text-[13px] font-heebo"
                    >
                      {item}
                    </div>
                  ))}
              </div>
            </div>
            <div className="w-full">
              <div className="flex items-center justify-between mb-3">
                <p className="text-center font-heebo font-medium">
                  {isRecordingInProgress && "Recording..."}
                  {audioFile && "Recorded Audio"}
                </p>
                <div className=" font-heebo text-[#808080] flex items-center gap-x-2">
                  {(isRecordingInProgress || audioFile) && (
                    <div
                      className={`flex-shrink-0 size-2 rounded-full bg-red-500 ${isRecordingInProgress && "animate-pulse"}`}
                    ></div>
                  )}
                  {isRecordingInProgress && <p>{formattedRecordingTime}</p>}
                  {audioFile && audioFile && <p>{formattedDuration}</p>}
                </div>
              </div>
              {isRecordingInProgress ||
              audioFile ||
              audioUrl ||
              isAvailableRecordedAudio ? (
                <div className="bg-[#fafafa] border border-[#ebebeb] rounded-[11px] p-3">
                  {(isRecordingInProgress || audioFile) && (
                    <VoiceVisualizer
                      controls={recorderControls}
                      isControlPanelShown={false}
                      mainBarColor="#5B7FFA"
                      secondaryBarColor="#7190FF"
                      barWidth={5}
                      gap={3}
                      height={70}
                    />
                  )}
                  {audioUrl && isAvailableRecordedAudio && (
                    <AudioPlayer src={audioUrl} duration={duration} />
                  )}
                </div>
              ) : null}

              {audioFile && (
                <div className="my-3 flex items-center gap-2">
                  <Checkbox
                    id="optin"
                    checked={isOptIn}
                    onCheckedChange={(bool) => setIsOptIn(bool)}
                  />
                  <label
                    htmlFor="optin"
                    className="text-xs text-[#808080] font-heebo cursor-pointer"
                  >
                    I consent to recording my voice
                  </label>
                </div>
              )}
            </div>
            <div className="flex items-center justify-between gap-3 mt-3 font-heebo text-sm">
              {isRecordingInProgress ? (
                <>
                  <button
                    className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-12"
                    onClick={togglePauseResume}
                  >
                    {isPausedRecording ? (
                      <Play className="size-4" />
                    ) : (
                      <Pause className="size-4" />
                    )}
                    {isPausedRecording ? "Resume" : "Pause"}
                  </button>
                  <button
                    className="w-full bg-[#ff6464]/20 text-[#ff6464] border border-[#ff6464] rounded-md flex items-center justify-center gap-x-2 h-12"
                    onClick={stopRecording}
                  >
                    <Square className="size-4" />
                    Stop
                  </button>
                </>
              ) : (
                <div className="w-full">
                  {personalInfo?.length > 0 && (
                    <div className="flex flex-col items-center justify-center">
                      {personalInfo.some(
                        (field) => field?.name === "subheading" && field?.show
                      ) && (
                        <p className="mb-4 max-w-lg w-full">
                          {
                            personalInfo.find(
                              (field) => field?.name === "subheading"
                            )?.text
                          }
                        </p>
                      )}
                      {personalInfo.map((field) => (
                        <>
                          {field?.name === "subheading" ? null : (
                            <div
                              key={field.field_name}
                              className="mb-4 max-w-lg w-full"
                            >
                              {field.type === "phone" ? (
                                <PhoneInput
                                  className=""
                                  value={formValues[field.field_name]}
                                  onChange={(value) =>
                                    handleFieldPhoneChange(
                                      field.field_name,
                                      value
                                    )
                                  }
                                  defaultCountry="GB"
                                  placeholder={`${field.name} ${
                                    field.must ? "*" : ""
                                  }`}
                                />
                              ) : (
                                <Input
                                  type={field.type}
                                  id={field.field_name}
                                  name={field.field_name}
                                  value={formValues[field.field_name]}
                                  onChange={handleFieldChange}
                                  className="border p-2 rounded w-full dark:bg-white dark:border-[#ebebeb] h-12"
                                  required={field.must}
                                  placeholder={`${field.name} ${
                                    field.must ? "*" : ""
                                  }`}
                                />
                              )}
                              {isShowFormErrors &&
                                formErrors[field.field_name] &&
                                formValues[field.field_name] !== "" && (
                                  <span className="text-sm text-red-500">
                                    * {formErrors[field.field_name]}
                                  </span>
                                )}
                            </div>
                          )}
                        </>
                      ))}
                    </div>
                  )}
                  {isNegativeFeedback && (
                    <div className="flex justify-center items-center flex-col lg:flex-row w-full mb-5 gap-3 bg-[#fafafa] p-3 lg:p-5 max-w-lg mx-auto border border-newPrimary rounded-md">
                      <p>Select star rating</p>
                      <Rating
                        onClick={(e) => setGivenStars(e)}
                        SVGstyle={{ display: "inline" }}
                        SVGclassName="w-8 lg:w-9"
                      />
                    </div>
                  )}
                  <div className="flex justify-between items-center gap-3">
                    <button
                      className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-12"
                      onClick={() => {
                        // clearCanvas();
                        setAudioFile();
                        setAudioUrl();
                        startRecording();
                      }}
                    >
                      <RotateCcw className="size-4" />
                      Record Again
                    </button>
                    {audioFile && (
                      <button
                        className="bg-[#00cc00]/20 border border-[#00cc00] text-[#00cc00] rounded-md flex items-center justify-center gap-x-2 h-12 w-full"
                        onClick={handleVoiceSubmit}
                        disabled={
                          (isNegativeFeedback && givenStars <= 0) ||
                          isButtonDisabled
                        }
                      >
                        <Check className="size-4" />
                        Generate
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

export default VoiceReview;
