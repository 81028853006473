import { useEffect, useState } from "react";
import { Input } from "../../../../components/ui/input";
import { AudioLines, ChevronDown, Pencil, Text, Trash2 } from "lucide-react";
import { Textarea } from "../../../../components/ui/textarea";
import { Switch } from "../../../../components/ui/switch";
import EditConditionModal from "./EditConditionModal";
import { toast } from "sonner";
import {
  useDeletePositivePrompt,
  usePostConditionToggle,
} from "../../../../query/useReview";
import { useQueryClient } from "@tanstack/react-query";
import { DeleteModal } from "../../component/modal";
import { LogoLoading } from "../../../../components/loading/loading";

/* eslint-disable react/prop-types */
const ConditionCard = ({ condition }) => {
  const queryClient = useQueryClient();
  const [isEditable, setIsEditable] = useState(false);
  const [isDelete, setIsDelete] = useState(false);

  const {
    mutate: deletePositivePrompt,
    isLoading: deletePositivePromptLoading,
  } = useDeletePositivePrompt();

  const { mutate: toggle, isLoading: toggleLoading } = usePostConditionToggle();

  const handleDeletePositivePrompt = () => {
    deletePositivePrompt(condition?.id, {
      onSuccess: (res) => {
        toast.success("Condition has been deleted");
        setIsDelete(false);
        queryClient.invalidateQueries(["getPositivePrompt"]);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleToggle = () => {
    toggle(condition?.id, {
      onSuccess: (res) => {
        toast.success(res?.message);
        queryClient.invalidateQueries(["getPositivePrompt"]);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  if (deletePositivePromptLoading || toggleLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <div className="dark:bg-darkBg duration-500 bg-white dark:border-none rounded-lg shadow-md space-y-5 cursor-pointer p-5 ">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-5 lg:flex-nowrap flex-wrap">
            <Input value={condition?.title} readOnly className="lg:w-[250px]" />
            <div className="flex h-10  items-center gap-5 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0  disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1  dark:bg-darkBg w-[180px] flex-shrink-0">
              <AudioLines />
              {condition?.tone}
            </div>
            <div className="flex h-10  items-center gap-5 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0  disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1  dark:bg-darkBg w-[180px] flex-shrink-0">
              <Text />
              Length - {condition?.response_length}
            </div>
          </div>

          <div className="flex items-center gap-2">
            <div
              className="p-2 bg-[#f0f5ff] dark:bg-darkLightBg rounded-[8px] cursor-pointer  text-[#2a49b8]"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              <Pencil className="w-4 h-4 " />
            </div>
            <div
              className="p-2 bg-[#ff6464]/15 rounded-[8px] cursor-pointer  text-[#ff6464]"
              onClick={() => {
                setIsDelete(true);
              }}
            >
              <Trash2 className="w-4 h-4 " />
            </div>
            <Switch
              checked={condition?.is_active}
              onCheckedChange={handleToggle}
              className="data-[state=checked]:bg-newPrimary"
            />
          </div>
        </div>
        <Textarea value={condition?.condition} readOnly />
      </div>
      <EditConditionModal
        openModal={isEditable}
        setOpenModal={setIsEditable}
        condition={condition}
      />
      <DeleteModal
        openModal={isDelete}
        setOpenModal={setIsDelete}
        title={"Are you sure you want to delete the condition?"}
        handleDelete={handleDeletePositivePrompt}
      />
    </>
  );
};

export default ConditionCard;
