/* eslint-disable react/prop-types */
import { useState, useRef, useEffect, forwardRef } from "react";
import { Dialog, Popover, Menu } from "@headlessui/react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";
import MainLogo from "../mainlogo";
import RightArrow from "../../assets/icons/RightArrow";
import { useAuth } from "../../context/authContext";
import { motion } from "framer-motion";
import Intercom from "@intercom/messenger-js-sdk";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../ui/navigation-menu";
import {
  ChartNoAxesCombined,
  ChevronRight,
  Library,
  Mail,
  MessageSquare,
  MessageSquareMore,
  Phone,
  SearchCheck,
  SquarePen,
  UserRound,
} from "lucide-react";
import { cn } from "../../utils/cn";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";

const products = [
  {
    title: "Responder Agent",
    href: "/products/responder-agent",
    description: "AI-powered replies tailored to your brand’s voice.",
    icon: <MessageSquareMore className="size-5" />,
  },
  {
    title: "Key Insights",
    href: "/products/key-insights",
    description: "AI-powered review analysis for smarter business decisions.",
    icon: <ChartNoAxesCombined className="size-5" />,
  },
  {
    title: "SEO Agent",
    href: "/products/seo-agent",
    description:
      "Optimize reviews with AI-driven keywords for better visibility.",
    icon: <SearchCheck className="size-5" />,
  },
  {
    title: "AI Post",
    href: "/products/ai-post",
    description: "Auto-generate and schedule Google My Business posts.",
    icon: <SquarePen className="size-5" />,
  },
];

const company = [
  {
    title: "About Us",
    href: "/about-us",
    description: "Unveiling the narrative behind MagicReview",
    icon: <UserRound className="size-5" />,
  },
  {
    title: "Blog",
    href: "/blog",
    description: "Insights, Innovation, and Inspirations unleashed",
    icon: <Library className="size-5" />,
  },
  {
    title: "Contact Us",
    href: "/contact-us",
    description: "Talk to our Business Growth Specialist",
    icon: <Mail className="size-5" />,
  },
];

const ListItem = forwardRef(
  ({ className, title, children, icon, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <a
            ref={ref}
            className={cn(
              "select-none flex gap-2 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent dark:hover:bg-[hsl(210_40%_96.1%)] hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground font-poppins",
              className
            )}
            {...props}
          >
            {icon && <div className="text-newPrimary">{icon}</div>}
            <div className="space-y-2">
              <div className="text-sm font-semibold tracking-[1%] text-[#1d1d1f]">
                {title}
              </div>
              {children && (
                <p className="line-clamp-2 text-[13px] leading-5 dark:text-[#1d1d1f]">
                  {children}
                </p>
              )}
            </div>
          </a>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";

const MainNav = () => {
  Intercom({
    app_id: "ncew78xq",
  });
  const location = useLocation();
  const { token } = useAuth();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const isHowItworks = location.pathname.includes("how-it-works");
  const isReview = location.pathname.includes("payment");
  const companyDropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const isPricing = location.pathname.includes("pricing");
  const isContact = location.pathname.includes("contact");
  const isAbout = location.pathname.includes("about");
  const isBlog = location.pathname.includes("blog");
  const { state } = location;
  const referrer = state?.prev;
  const profileId = state?.profileId;

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the Platform dropdown if clicked outside
      if (
        companyDropdownRef.current &&
        !companyDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <motion.header
      className="bg-white w-full h-16 shadow-[0_3px_15px_0_rgba(0,0,0,.1)] flex items-center sticky top-0 inset-x-0 z-50"
      initial={{ opacity: 0, translateY: -50 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 0.5, ease: "easeIn" }}
    >
      {mobileMenuOpen && (
        <div
          className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40 lg:hidden"
          onClick={() => setMobileMenuOpen(false)} // Close menu on overlay click
        ></div>
      )}
      <nav className="bg-white w-full container" aria-label="Global">
        <div className="w-full flex items-center justify-between">
          <motion.div
            className="flex lg:flex-1"
            whileTap={{
              scale: 0.95,
            }}
          >
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/"}
              aria-label="MagicReview"
              className=""
            >
              <MainLogo />
            </Link>
          </motion.div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.25 6.72363H3.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 12.7236H9.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M21.25 18.7236H7.25"
                  stroke="#434343"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <NavigationMenu className="hidden lg:flex">
            <NavigationMenuList>
              <NavigationMenuItem>
                <NavigationMenuTrigger
                  className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary`}
                >
                  Products
                </NavigationMenuTrigger>
                <NavigationMenuContent className="lg:-left-[57%]">
                  <ul className="grid w-[400px] gap-x-3 md:w-[600px] md:grid-cols-3 lg:w-[1085px] font-poppins pt-4">
                    <p className="md:col-span-2 font-medium text-sm pb-4 pl-12 text-[#4d4d4d]">
                      AI Agent Suite
                    </p>
                    <p className="font-medium text-sm pb-4 pl-12 text-[#4d4d4d]">
                      Schedule a demo
                    </p>
                    <hr className="w-full md:col-span-3" />
                    <div className="md:col-span-2 grid grid-cols-2 gap-2 py-10 pl-12">
                      {products.map((component) => (
                        <ListItem
                          key={component.title}
                          title={component.title}
                          href={component.href}
                          icon={component.icon}
                        >
                          {component.description}
                        </ListItem>
                      ))}
                    </div>
                    <div className="bg-[#f8f9f8] p-12 pr-14 flex flex-col justify-between gap-3 dark:text-[#4d4d4d]">
                      <p className="font-semibold leading-6 tracking-[1%]">
                        Get a personalised demo of MagicReview
                      </p>
                      <p className="text-xs leading-4">
                        Hop on to a meeting and learn how you can transform your
                        onboarding and service business with magicreview.
                      </p>
                      <Link
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={"/schedule-a-call"}
                        className="bg-newPrimary text-white py-2 px-5 rounded-lg flex items-center justify-center  gap-2 font-medium border border-newPrimary hover:text-newPrimary hover:bg-white transition-all duration-300 active:scale-95 text-sm"
                        aria-label="Sign Up"
                      >
                        Schedule a demo <ChevronRight />
                      </Link>
                    </div>
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuLink
                  href="/how-it-works"
                  className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary ${isHowItworks && "text-newPrimary"} ${navigationMenuTriggerStyle()}`}
                >
                  How it Works
                </NavigationMenuLink>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuLink
                  href="/pricing"
                  className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary ${isPricing && "text-newPrimary"} ${navigationMenuTriggerStyle()}`}
                >
                  Pricing
                </NavigationMenuLink>
              </NavigationMenuItem>
              <NavigationMenuItem>
                <NavigationMenuTrigger
                  className={`text-sm font-semibold leading-6 text-[#101828] font-montserrat hover:text-newPrimary`}
                >
                  Company
                </NavigationMenuTrigger>
                <NavigationMenuContent className="-left-[70%]">
                  <ul className="grid w-[400px] gap-x-3 md:w-[600px] md:grid-cols-2 font-poppins p-4">
                    {company.map((item) => (
                      <ListItem
                        key={item.title}
                        href={item.href}
                        title={item.title}
                        icon={item.icon}
                      >
                        {item.description}
                      </ListItem>
                    ))}
                  </ul>
                </NavigationMenuContent>
              </NavigationMenuItem>
            </NavigationMenuList>
          </NavigationMenu>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end gap-2 font-poppins">
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={
                referrer === "register-entity"
                  ? `/register-entity?profileId=${profileId}`
                  : token
                    ? "/dashboard"
                    : "/sign-in"
              }
              className="flex items-center font-medium gap-2 px-8 py-2 text-newPrimary border border-newPrimary bg-white dark:bg-white rounded-lg hover:text-newPrimary transition-all duration-300 hover:bg-white cursor-pointer text-sm active:scale-95 h-[38px]"
              aria-label="Sign In"
            >
              Login
            </Link>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              to={"/schedule-a-call"}
              className="bg-newPrimary text-white py-2 px-5 rounded-lg flex items-center  gap-2 font-medium border border-newPrimary hover:text-newPrimary hover:bg-white transition-all duration-300 active:scale-95 text-sm  h-[38px]"
              aria-label="Sign Up"
            >
              Schedule a demo
            </Link>
          </div>
        </div>
      </nav>

      <div
        className={`fixed top-0 right-0 z-[1000] w-full max-w-[550px] h-screen transition-transform py-7  overflow-y-auto bg-white overflow-x-hidden lg:hidden 
    ${mobileMenuOpen ? "-translate-x-0 ease-out" : "translate-x-full ease-in"}
  `}
      >
        <div className="flex justify-between items-center mx-8">
          <MainLogo />

          <button
            type="button"
            className="rounded-md p-2.5 text-gray-700 mr-3"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="mt-4 flow-root mx-10">
          <div className="-my-6 divide-y divide-gray-500/10">
            <div className="space-y-2 py-6">
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/"
                  href="#"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="How it works"
                >
                  Home
                </Link>
              </h2>
              <Accordion type="single" collapsible className="w-full">
                <AccordionItem
                  value="item-1"
                  className=" text-[#101828]  font-montserrat border-b-0"
                >
                  <AccordionTrigger className="hover:no-underline hover:text-[#4F75FA] font-semibold text-base  leading-7 py-2">
                    Products
                  </AccordionTrigger>
                  <AccordionContent className="pb-0">
                    {products?.map((item) => (
                      <Link
                        key={item.title}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={item.href}
                        className={`flex text-sm items-center montserrat text-[#070707] hover:text-newPrimary border border-white px-3 py-2 rounded-md hover:bg-[#1000C50A] gap-3 active:scale-95 transition-all duration-300 font-medium`}
                      >
                        <div className="text-newPrimary">{item.icon}</div>
                        {item.title}
                      </Link>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/how-it-works"
                  href="#"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/how-it-works"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="How it works"
                >
                  How It works
                </Link>
              </h2>
              <h2>
                <Link
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                  to="/pricing"
                  className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-[#101828] hover:text-[#4F75FA] hover:bg-[#F1F4FF] font-montserrat
                    ${
                      location.pathname === "/pricing"
                        ? "text-[#4F75FA] bg-[#F1F4FF]"
                        : ""
                    }
                  `}
                  aria-label="Pricing"
                >
                  Pricing
                </Link>
              </h2>

              <Accordion type="single" collapsible className="w-full">
                <AccordionItem
                  value="item-1"
                  className=" text-[#101828]  font-montserrat border-b-0"
                >
                  <AccordionTrigger className="hover:no-underline hover:text-[#4F75FA] font-semibold text-base  leading-7 py-2">
                    Company
                  </AccordionTrigger>
                  <AccordionContent className="pb-0">
                    {company?.map((item) => (
                      <Link
                        key={item.title}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        to={item.href}
                        className={`flex text-sm items-center font-montserrat text-[#070707] font-medium hover:text-newPrimary border border-white px-3 py-2 rounded-md hover:bg-[#1000C50A] gap-3 active:scale-95 transition-all duration-300`}
                      >
                        <div className=" text-newPrimary"> {item.icon}</div>

                        {item.title}
                      </Link>
                    ))}
                  </AccordionContent>
                </AccordionItem>
              </Accordion>
            </div>
            <Link
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              className="block w-full"
              to="/sign-in"
              aria-label="Sign Up"
            >
              <button className="bg-newPrimary text-white py-3 px-5 rounded-[10px] flex items-center justify-center w-full font-montserrat text-center font-medium">
                Login
              </button>
            </Link>
          </div>
        </div>
      </div>
    </motion.header>
  );
};

export default MainNav;
