/* eslint-disable no-undef */
import { ChevronDown, Pencil, X } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { toast } from "sonner";
import IconEdit from "../../../assets/icons/IconEdit";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import { PhoneInput } from "../../../components/ui/phone-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { supportedLanguageLocales } from "../../../data/data";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useGetIndustryTypes,
  useUpdateUser,
  useUpdateUserProfile,
  useUser,
} from "../../../query/useAuthentication";
import {
  useDeleteEntityTheme,
  useGetEntityPin,
  useGetEntityTheme,
  useGetSupportedLanguageLocales,
  usePostEntityPin,
  usePutEditSupportedLanguageLocales,
  usePutResetEntityPin,
  usePutUpdateEntityTheme,
} from "../../../query/useEntity";
import blobToFile from "../../../utils/blobToFile";
import { Input } from "../component/Input";
import Layout from "../component/Layout";
import ImageCropper from "./components/ImageCropper";
import EditEmailModal from "../component/EditEmailModal";
import { Button } from "../../../components/ui/button";
import CustomDomain from "./components/CustomDomain";
import PinModal from "./components/PinModal";

const Account = () => {
  const inputRef = useRef(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [websiteLink, setWebsiteLink] = useState("");
  const [industry, setIndustry] = useState("");
  const [aboutBusiness, setAboutBusiness] = useState("");
  const [businessLocation, setBusinessLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [userProfile, setUserProfile] = useState("");
  const [accountType, setAccountType] = useState("");
  const [profilePicture, setProfilePicture] = useState();
  const [profilePictureUrl, setProfilePictureUrl] = useState("");
  const [croppedImg, setCroppedImg] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isActiveStaffManagement, setIsActiveStaffManagement] = useState(false);
  const [entityId, setEntityId] = useState();
  const [bgColor, setBgColor] = useState("");
  const [btnColor, setBtnColor] = useState("");
  const dropdownRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguageLocales, setSelectedLanguageLocales] = useState([]);
  const [isOpenEmail, setIsOpenEmail] = useState(false);
  const [tab, setTab] = useState("profile");
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [isOpenPinModal, setIsOpenPinModal] = useState(false);

  const { data: industryTypes } = useGetIndustryTypes();

  const { refetch, isFetching, isLoading } = useUser({
    onSuccess: (res) => {
      // setIsLoading(false);
      setFirstName(res.first_name);
      setLastName(res.last_name);
      setEmail(res.email);
      setEntityId(res.entity.id);
      setCompanyName(res.entity.name);
      setWebsiteLink(res.entity.company_link);
      setIsActiveStaffManagement(
        res.profile.user_settings_staff_management_enabled
      );
      setUserProfile(res.profile.profile_picture);
      setAboutBusiness(res.profile.about_business);
      setBusinessLocation(res.entity.location);
      setIndustry(res.entity.industry);
      setPhone(res.profile.phone_number);
      setAccountType(res.profile.account_type);
      localStorage.setItem("user", JSON.stringify(res));
    },
  });

  const { refetch: refetchEntityTheme } = useGetEntityTheme({
    onSuccess: (res) => {
      setBgColor(res?.data?.bg_color);
      setBtnColor(res?.data?.button_color);
    },
  });
  const { mutate: updateEntityTheme, isLoading: updateEntityThemeLoading } =
    usePutUpdateEntityTheme();
  const { mutate: deleteEntityTheme, isLoading: deleteEntityThemeLoading } =
    useDeleteEntityTheme();

  const { mutate: updateUser, isLoading: updateUserLoading } = useUpdateUser();
  const { mutate: updateUserProfile, isLoading: updateProfilePictureLoading } =
    useUpdateUserProfile();

  const { refetch: refetchLanguageLocales } = useGetSupportedLanguageLocales({
    onSuccess: (res) => {
      setSelectedLanguageLocales(res.candidate_languages);
    },
  });

  const { mutate: editLanguageLocales, isLoading: editLanguageLocalesLoading } =
    usePutEditSupportedLanguageLocales();

  const { data: isPin, refetch: refetchPin } = useGetEntityPin();
  const { mutate: updatePin, isLoading: updatePinLoading } = usePostEntityPin();
  const { mutate: resetPin, isLoading: resetPinLoading } =
    usePutResetEntityPin();

  const handleUpdatePin = () => {
    if (pin !== confirmPin) {
      toast.error("PIN does not match");
      return;
    }
    updatePin(
      {
        pin: pin,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setPin("");
          setConfirmPin("");
          refetchPin();
        },
        onError: (err) => {
          if (err.response.data?.message) {
            toast.error(err.response.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleResetPin = () => {
    if (pin !== confirmPin) {
      toast.error("PIN does not match");
      return;
    }
    resetPin(
      {
        pin: pin,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setPin("");
          setConfirmPin("");
          refetchPin();
        },
        onError: (err) => {
          if (err.response.data?.message) {
            toast.error(err.response.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateUser = () => {
    if (aboutBusiness?.length > 1000) {
      toast.error("About business is exceeded 1000 characters");
      return;
    }
    updateUser(
      {
        business_name: companyName || null,
        company_link: websiteLink || null,
        google_review_link: null,
        phone_number: phone,
        first_name: firstName || null,
        last_name: lastName || null,
        about_business: aboutBusiness || null,
        business_location: businessLocation || null,
        industry: industry,
      },
      {
        onSuccess: (res) => {
          toast.success("User details have been updated!");
          refetch();
        },
        onError: (err) => {
          if (err.response.data?.phone_number) {
            setPhoneError(err.response.data?.phone_number[0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError("");
  };

  const handleChangeProfileClick = () => {
    inputRef.current.click();
    setIsEdit(true);
  };

  const handleChangeProfile = (e) => {
    setProfilePicture(e.target.files[0]);
    const objectUrl = URL.createObjectURL(e.target.files[0]);
    setProfilePictureUrl(objectUrl);
    inputRef.current.value = "";
  };

  const handleUpdateUserProfile = async () => {
    const croppedImgFile = await blobToFile(
      croppedImg,
      profilePicture?.name
    ).then((file) => file);
    updateUserProfile(
      {
        profile_picture: croppedImgFile,
      },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          setCroppedImg(null);
          toast.success("Profile picture has been updated!");
          refetch();
        },
        onError: (err) => {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
          setCroppedImg(null);
        },
      }
    );
  };

  const handleUpdateEntityTheme = () => {
    updateEntityTheme(
      {
        bg_color: bgColor,
        button_color: btnColor,
      },
      {
        onSuccess: (res) => {
          toast.success("Updated entity theme successfully");
          refetchEntityTheme();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteEntityTheme = () => {
    deleteEntityTheme(
      {},
      {
        onSuccess: (res) => {
          toast.success("Reseted entity theme successfully");
          refetchEntityTheme();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleSelectedLanguageToggle = (item) => {
    if (selectedLanguageLocales?.includes(item)) {
      setSelectedLanguageLocales((prev) => prev.filter((e) => e !== item));
    } else {
      if (selectedLanguageLocales?.length < 4) {
        setSelectedLanguageLocales((prev) => [...prev, item]);
      }
    }
  };

  const handleUpdateLanguageLocales = () => {
    editLanguageLocales(
      {
        candidate_languages: selectedLanguageLocales,
      },
      {
        onSuccess: (res) => {
          toast.success("Updated language locales successfully");
          refetchLanguageLocales();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const localeToLang = new Intl.DisplayNames(["en"], {
    type: "language",
  });

  useEffect(() => {
    if (profilePictureUrl && isEdit) {
      setOpenModal(true);
    } else {
      setOpenModal(false);
    }
  }, [profilePictureUrl, isEdit]);

  useOnClickOutside({ ref: dropdownRef, setIsOpen: setIsOpen });

  if (
    updateEntityThemeLoading ||
    updateProfilePictureLoading ||
    updateUserLoading ||
    deleteEntityThemeLoading ||
    editLanguageLocalesLoading ||
    updatePinLoading ||
    resetPinLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Layout title="Account details">
      <div className="my-5 w-full dark:bg-darkLightBg rounded-[16px] pt-5 lg:pt-8 pb-[250px] lg:pb-[100px] px-5 lg:px-[30.2px]">
        <div className="font-pro font-medium text-sm  gap-3 flex items-center mb-10 overflow-x-auto flex-1">
          {[
            "profile",
            "review theme",
            "language",
            "redeem pin",
            "custom domain",
          ].map((item) => (
            <button
              key={item}
              className={`capitalize border rounded-lg py-2 px-3 bg-white dark:bg-darkBg flex-shrink-0 ${tab === item && "border-newPrimary text-newPrimary"}`}
              onClick={() => setTab(item)}
            >
              {item}
            </button>
          ))}
        </div>

        {isLoading || isFetching ? (
          <Loading />
        ) : (
          <>
            {tab === "profile" && (
              <>
                <div className="flex justify-between items-center flex-wrap gap-[20px]">
                  <p className="font-pro font-medium text-lg">
                    Manage profile settings
                  </p>
                </div>
                <hr className="mt-6" />
                <div
                  className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
                >
                  <div className="w-full lg:col-span-2">
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      Profile photo
                    </label>
                    <div className="flex items-center gap-x-3">
                      <div
                        className="relative w-16 h-16 cursor-pointer"
                        onClick={handleChangeProfileClick}
                      >
                        <input
                          type="file"
                          accept="image/*"
                          ref={inputRef}
                          hidden
                          onChange={handleChangeProfile}
                        />
                        <img
                          className="h-14 w-14 shrink-0 rounded-full mr-[21px] object-cover"
                          src={userProfile || "/new-logo.svg"}
                          alt=""
                        />
                        <div className="w-6 h-6 bg-black/80 rounded-full absolute bottom-2 right-1 flex justify-center items-center">
                          <IconEdit className=" w-4 h-4 text-white" />
                        </div>
                      </div>

                      <button
                        className="font-pro text-[#a7a5a5] text-sm font-medium"
                        onClick={handleChangeProfileClick}
                      >
                        Upload photo
                      </button>
                    </div>
                  </div>
                  <Input
                    label="First name"
                    placeholder="e.g. John"
                    value={firstName}
                    setValue={setFirstName}
                  />
                  <Input
                    label="Last name"
                    placeholder="e.g. Doe"
                    value={lastName}
                    setValue={setLastName}
                  />

                  <Input
                    label="Company name"
                    placeholder="e.g. xyz"
                    value={companyName}
                    setValue={setCompanyName}
                  />

                  <div className="w-full">
                    <label className="text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {"Email / Email address"}
                    </label>
                    <div className="relative mb-[20px]">
                      <input
                        className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
                        type="text"
                        placeholder={"e.g. xyz@example.com"}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled={true}
                      />

                      {accountType === "email" && (
                        <Pencil
                          className="size-4 absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer"
                          onClick={() => setIsOpenEmail(true)}
                        />
                      )}
                    </div>
                  </div>

                  <div>
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {"Industry"}
                    </label>
                    <Select
                      value={industry}
                      onValueChange={(item) => setIndustry(item)}
                    >
                      <SelectTrigger className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1">
                        <SelectValue placeholder="Select industry type" />
                      </SelectTrigger>
                      <SelectContent>
                        {industryTypes
                          ?.filter(
                            (item) => item?.industry_identifier !== "other"
                          )
                          ?.map((item) => (
                            <SelectItem
                              key={item?.id}
                              value={item?.industry_identifier}
                            >
                              {item?.display_text}
                            </SelectItem>
                          ))}
                        {industryTypes
                          ?.filter(
                            (item) => item?.industry_identifier === "other"
                          )
                          ?.map((item) => (
                            <SelectItem
                              key={item?.id}
                              value={item?.industry_identifier}
                            >
                              {item?.display_text}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="w-full">
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {"Phone number"}
                    </label>
                    <PhoneInput
                      className=""
                      value={phone}
                      onChange={handlePhoneChange}
                      defaultCountry="GB"
                    />
                  </div>
                  <Input
                    label="Website link"
                    placeholder="e.g. https://www.company.in"
                    value={websiteLink}
                    setValue={setWebsiteLink}
                  />

                  <Input
                    label="Business location"
                    placeholder="e.g. Dubai"
                    value={businessLocation}
                    setValue={setBusinessLocation}
                  />
                  <div className="w-full">
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      {"About business"}
                    </label>
                    <textarea
                      className="border-[1px] border-[#E7E4E4] dark:border-[#454545] rounded-[8px] pt-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full pr-10 scrollbar-none dark:bg-darkBg"
                      type="text"
                      placeholder={"Write about your business"}
                      value={aboutBusiness}
                      onChange={(e) => setAboutBusiness(e.target.value)}
                      // disabled={aboutBusiness?.length >= 1000}
                      rows={5}
                    />
                    <p className="text-right -mt-5 text-gray-500 font-pro text-xs">
                      {aboutBusiness?.length || 0} / 1000 characters
                    </p>
                  </div>
                </div>
                <div className="w-full flex justify-end mt-5">
                  <Button onClick={handleUpdateUser}>Save</Button>
                </div>{" "}
              </>
            )}
            {tab === "review theme" && (
              <>
                <div className="flex justify-between items-center flex-wrap gap-[20px]">
                  <p className="font-pro font-medium text-lg">
                    Select theme/ Change review
                  </p>
                </div>
                <hr className="my-6" />
                <div
                  className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
                >
                  <div className="w-full">
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      Background color
                    </label>
                    <input
                      className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
                      type="color"
                      // placeholder={placeholder}
                      value={bgColor}
                      onChange={(e) => setBgColor(e.target.value)}
                    />
                  </div>
                  <div className="w-full">
                    <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                      Button color
                    </label>
                    <input
                      className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
                      type="color"
                      // placeholder={placeholder}
                      value={btnColor}
                      onChange={(e) => setBtnColor(e.target.value)}
                    />
                  </div>
                </div>
                <div className="w-full flex justify-end items-center gap-5 flex-wrap mt-5">
                  <Button
                    onClick={handleDeleteEntityTheme}
                    disabled={deleteEntityThemeLoading}
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleUpdateEntityTheme}
                    disabled={updateEntityThemeLoading}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {tab === "language" && (
              <>
                <div className="flex justify-between items-center flex-wrap gap-[20px] mt-5">
                  <p className="font-pro font-medium text-lg">
                    Identify suitable language
                  </p>
                </div>
                <hr className="my-6" />
                <div
                  className="lg:w-1/2 w-full relative h-12 font-pro mt-"
                  ref={dropdownRef}
                >
                  <div
                    className="px-5 py-3 h-full rounded-[7px] text-sm font-medium focus:outline-none focus:border-transparent flex items-center justify-between w-full bg-white dark:bg-darkLightBg  cursor-pointer border border-[#ebebeb] dark:border-[#454545] dark:text-white"
                    onClick={() => setIsOpen((prev) => !prev)}
                  >
                    <p className="">Select language locales</p>
                    <ChevronDown
                      className={`w-5 h-5 ${isOpen && "rotate-180"} transition-all duration-200`}
                    />
                  </div>
                  {isOpen && (
                    <div className="w-full p-5 rounded-[7px] absolute translate-y-3 left-0 bg-white dark:bg-darkLightBg dark:border-[#454545] border space-y-5 z-[10] text-left lg:h-[200px] overflow-y-auto scrollbar-thin shadow-md">
                      {supportedLanguageLocales?.map((item) => (
                        <div
                          key={item}
                          className="flex items-center justify-between gap-x-3 group dark:text-white"
                        >
                          <div className="flex items-center gap-x-3">
                            <input
                              id={item}
                              type="checkbox"
                              checked={selectedLanguageLocales?.includes(item)}
                              onClick={() => handleSelectedLanguageToggle(item)}
                              readOnly
                              className="appearance-none outline-none ring-0 checked:bg-secondary rounded-sm"
                              // disabled={selectedLanguageLocales?.length >= 4}
                            />
                            <label
                              htmlFor={item}
                              className={`w-full cursor-pointer  ${selectedLanguageLocales?.length >= 4 ? "text-gray-400 cursor-not-allowed" : "group-hover:text-secondary"} `}
                            >
                              {localeToLang.of(item)}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex items-center gap-3 flex-wrap mt-3">
                    {selectedLanguageLocales?.map((item) => (
                      <div
                        className=" border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2 text-sm font-medium "
                        key={item}
                      >
                        <p> {localeToLang.of(item)}</p>
                        <X
                          className="size-4 cursor-pointer"
                          onClick={() => handleSelectedLanguageToggle(item)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="w-full flex justify-end items-center gap-5 flex-wrap mt-5">
                  <Button onClick={handleUpdateLanguageLocales}>Save</Button>
                </div>
              </>
            )}
            {tab === "redeem pin" && (
              <>
                <div className="space-y-3 font-pro">
                  <p className=" font-medium text-lg">
                    {isPin?.PIN ? "Reset Redeem PIN" : "Setup 4 digit PIN"}
                  </p>
                  <p className="text-sm text-[#4a4a4a]">
                    {isPin?.PIN
                      ? "Your PIN has been set up. You can reset your PIN."
                      : "Set up 4 digit PIN to redeem rewards in offer page"}
                  </p>
                </div>
                <hr className="my-6" />
                {/* <div className="grid grid-cols-1 gap-y-5 md:gap-x-5 lg:gap-x-10 lg:w-1/4">
                  <input
                    className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
                    type="number"
                    placeholder={"Enter pin"}
                    value={pin}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 4) {
                        setPin(value);
                      }
                    }}
                  />
                  <input
                    className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
                    type="number"
                    placeholder={"Re-enter pin"}
                    value={confirmPin}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 4) {
                        setConfirmPin(value);
                      }
                    }}
                  />
                </div> */}

                <div className="w-full flex justify-start items-center gap-5 flex-wrap mt-5">
                  <Button onClick={() => setIsOpenPinModal(true)}>
                    {isPin?.PIN ? "Reset" : "Setup"}
                  </Button>
                </div>
              </>
            )}
            {tab === "custom domain" && <CustomDomain />}
          </>
        )}
      </div>

      <EditEmailModal
        isOpenModal={isOpenEmail}
        setIsOpenModal={setIsOpenEmail}
        email={email}
        setEmail={setEmail}
      />
      <ImageCropper
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleUpdateUserProfile={handleUpdateUserProfile}
        profilePictureUrl={profilePictureUrl}
        isLoading={updateProfilePictureLoading}
        croppedImage={croppedImg}
        setCroppedImage={setCroppedImg}
      />
      <PinModal
        openModal={isOpenPinModal}
        setOpenModal={setIsOpenPinModal}
        isEdit={isPin?.PIN}
        pinFromApi={isPin?.value}
      />
    </Layout>
  );
};

export default Account;
