import {
  getAllCardsApi,
  createCardApi,
  updateCardApi,
  deleteCardApi,
  toggleCardApi,
} from "../api/card";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetAllCards = (config) => {
  return useQuery({
    queryKey: ["getAllCards"],
    queryFn: () => getAllCardsApi(),
    ...config,
    refetchOnWindowFocus: false,
  });
};

export const useCreateCard = () => {
  return useMutation({
    mutationKey: ["createCard"],
    mutationFn: (data) => createCardApi(data),
  });
};

export const useUpdateCard = () => {
  return useMutation({
    mutationKey: ["updateCard"],
    mutationFn: (data) => updateCardApi(data),
  });
};

export const useDeleteCard = () => {
  return useMutation({
    mutationKey: ["deleteCard"],
    mutationFn: (id) => deleteCardApi(id),
  });
};

export const useToggleCard = () => {
  return useMutation({
    mutationKey: ["toggleCard"],
    mutationFn: (id) => toggleCardApi(id),
  });
};
