import {
  startTransition,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from "react";
import { toast } from "sonner";
import { DashboardLoading } from "../../components/loading/loading";
import {
  useGetCanEntityGenerateReviews,
  useGetEntityReviewPermissions,
  useGetReviewPlatforms,
  useGetReviewQuestions,
  usePostReviewQuestions,
  usePostReviewQuestionsByVoice,
  usePostSaveNegativeFeedbacks,
} from "../../query/useReview";
import { useNavigate, useParams } from "react-router";
import {
  ArrowLeft,
  ArrowRight,
  Asterisk,
  Check,
  ChevronDown,
  Lock,
  Mic,
  Search,
  SquarePen,
} from "lucide-react";
import { Tooltip } from "flowbite-react";
import { useSearchParams } from "react-router-dom";
import {
  useGetEmployeeName,
  usePostEmployeeName,
} from "../../query/useEmployees";
import VoiceReview from "./component/VoiceReview";
import MainLogo from "../../components/mainlogo";
import { Helmet } from "react-helmet";
import { Rating } from "react-simple-star-rating";
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Input } from "../../components/ui/input";
import { PhoneInput } from "../../components/ui/phone-input";
import { emailRegex } from "../contact/contact";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isLightColor } from "../../utils/getIsLightColor";

const MobileGenerate = () => {
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const { id } = useParams();
  const [parmas] = useSearchParams();
  const employeeId = parmas.get("employee");
  const trackId = parmas.get("tr_id");

  const paginationRef = useRef(null);

  const { mutate, isLoading: postReviewLoading } = usePostReviewQuestions();

  const { mutate: saveNegative, isLoading: saveNegativeLoading } =
    usePostSaveNegativeFeedbacks();

  const { mutate: voiceMutate, isLoading: voiceReviewLoading } =
    usePostReviewQuestionsByVoice();
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState({});
  const [isCanGenerateReviews, setIsCanGenerateReviews] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [isStaffManagementEnabled, setIsStaffManagementEnabled] =
    useState(false);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [openDropdownId, setOpenDropdownId] = useState();
  const [searchDropdown, setSearchDropdown] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffTags, setStaffTags] = useState([]);
  const [staffLabel, setStaffLabel] = useState("");
  const [staffId, setStaffId] = useState(null);
  const [staffShow, setStaffShow] = useState(false);
  const [entityName, setEntityName] = useState("");
  const [entityLogo, setEntityLogo] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [isHoverContinue, setIsHoverContinue] = useState(false);
  const [isHoverPost, setIsHoverPost] = useState(false);
  const [isHoverGenerate, setIsHoverGenerate] = useState(false);
  const [isHoverRecording, setIsHoverRecording] = useState(false);
  const [isDirectReview, setIsDirectReview] = useState(false);
  const [isVoiceReview, setIsVoiceReview] = useState(false);
  const [isOnlyVoiceReview, setIsOnlyVoiceReview] = useState(false);
  const [isOpenDropdownKeyword, setIsOpenDropdownKeyword] = useState(false);
  const [openDropdownKeyword, setOpenDropdownKeyword] = useState();
  const [searchDropdownKeyword, setSearchDropdownKeyword] = useState({});
  const [questions, setQuestions] = useState([]);
  const [categoryOrder, setCategoryOrder] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const [starRating, setStarRating] = useState(0);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);
  const [givenStars, setGivenStars] = useState(0);
  const [personalInfo, setPersonalInfo] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [isShowFormErrors, setIsShowFormErrors] = useState(false);
  const [isOfferPage, setIsOfferPage] = useState(false);

  const { data: reviewPlatforms } = useGetReviewPlatforms(
    {
      entityId: id,
      employeeId: employeeId,
    },
    {
      enabled: Boolean(id),
    }
  );

  const {
    isLoading: isReviewPermemissionLoading,
    isSuccess: isReviewPermissionSuccess,
  } = useGetEntityReviewPermissions(
    {
      entityId: id,
      employeeId: employeeId,
    },
    {
      onSuccess: (res) => {
        setIsCanGenerateReviews(res.can_generate_reviews);
        setTooltipMessage(res.tooltip_message);
        setIsStaffManagementEnabled(res.staff_management_enabled);
        setStaffName(res?.staff_details?.employee_name);
        setStaffTags(res?.staff_details?.tags);
        setStaffLabel(res?.staff_details?.label);
        setStaffId(res?.staff_details?.id);
        setStaffShow(res?.staff_details?.show);
        setEntityName(res?.entity_name);
        setEntityLogo(res?.logo);
        setBgColor(res?.bg_color);
        setButtonColor(res?.button_color);
        setIsDirectReview(res?.direct_review);
        setIsVoiceReview(res?.voice_review);
        setIsOnlyVoiceReview(res?.only_voice_review);
        setStarRating(res?.star_rating);
        setIsNegativeFeedback(res?.negative_feedback);
        setPersonalInfo(res?.personal_info);
        setIsOfferPage(res?.offer);
        setFormValues(
          res?.personal_info
            ?.filter((item) => item?.name !== "subheading")
            .reduce((acc, field) => ({ ...acc, [field.field_name]: "" }), {})
        );
      },
      onError: (err) => {
        setIsCanGenerateReviews(false);
        setIsStaffManagementEnabled(false);
      },
      enabled: Boolean(id),
    }
  );

  const { isLoading: isReviewLoading } = useGetReviewQuestions(
    {
      entityId: id,
      staffId: staffId,
    },
    {
      onSuccess: (res) => {
        setQuestions(res?.questions);
        setCategoryOrder(res?.category_order);
      },
      enabled:
        isReviewPermissionSuccess && (employeeId ? Boolean(staffId) : true),
    }
  );

  const handleAnswerClick = (questionIndex, answer, isMultipleResponses) => {
    if (!isMultipleResponses) {
      setSelectedAnswers((prev) => {
        let current = { ...prev };

        if (current[questionIndex]) {
          let options = [...current[questionIndex]];

          if (options.includes(answer)) {
            // If the answer is already in the array, remove it
            options = [];
          } else {
            // If the answer is not in the array, add it
            options[0] = answer;
          }

          current[questionIndex] = options;
        } else {
          // If there are no answers for the question yet, create a new array with the answer
          current[questionIndex] = [answer];
        }

        return current;
      });
    } else {
      setSelectedAnswers((prev) => {
        let current = { ...prev };

        if (current[questionIndex]) {
          let options = [...current[questionIndex]];

          if (options.includes(answer)) {
            // If the answer is already in the array, remove it
            options = options.filter((item) => item !== answer);
          } else {
            // If the answer is not in the array, add it
            options.push(answer);
          }

          current[questionIndex] = options;
        } else {
          // If there are no answers for the question yet, create a new array with the answer
          current[questionIndex] = [answer];
        }

        return current;
      });
    }
  };

  const handleNavigateThankyou = (stars) => {
    navigate(
      `/thank-you?reviewId=${id}&employeeId=${employeeId}&star=${stars || givenStars}`
    );
  };

  const checkFormValues = () => {
    const hasErrors = formValues
      ? Object.entries(formValues).some(
          ([key, value]) =>
            formErrors[key] && formErrors[key] !== "" && value !== ""
        )
      : false;

    setIsShowFormErrors(hasErrors);

    return hasErrors;
  };

  const handleGenerate = () => {
    if (checkFormValues()) return;

    const response = questions
      ?.filter(
        (item) =>
          selectedCategory?.includes(item.category) || item?.category === null
      )
      ?.filter((item) =>
        item?.category === null && item?.keyword === null
          ? item
          : selectedKeywords[item.category].includes(item.keyword)
      )
      ?.map((question) => {
        const selectedAnswer = question?.has_multiple_responses
          ? selectedAnswers[question.id]
          : selectedAnswers[question.id]?.[0];
        const selectedKeyword = selectedKeywords[question.category]?.filter(
          (item) => item === question.keyword
        );
        return {
          question_id: question.id,
          question: question.question,
          category: question.category,
          keywords: selectedKeyword,
          response_options: question.response_options,
          selected_response: selectedAnswer,
          is_mandatory: question.is_mandatory,
        };
      });

    const isNotAnsweredMandatory = response?.some(
      (item) => item?.is_mandatory && !item?.selected_response
    );

    if (isNotAnsweredMandatory) {
      toast.error("All mandatory questions must be answered");
      return;
    }

    const modifiedResponse = response
      ?.filter((item) => (item.question ? item.selected_response : item))
      ?.map((question) => {
        return {
          question_id: question.question_id,
          question: question.question,
          category: question.category,
          keywords: question.keywords,
          response_options: question.response_options,
          selected_response: question.selected_response,
        };
      });

    if (givenStars > starRating || !isNegativeFeedback) {
      sessionStorage.setItem("isClicked", "false");

      localStorage.setItem("response", JSON.stringify(modifiedResponse));
      staffName && localStorage.setItem("staffName", staffName);

      mutate(
        {
          data: {
            business_uuid: id,
            response: modifiedResponse,
            employee_code: staffName && employeeId ? employeeId : null,
            staff_name: staffName || null,
            tr_id: trackId === "null" ? null : trackId,
            ...formValues,
          },
          isDetailed: false,
        },
        {
          onSuccess: (data) => {
            toast.success("Review generated successfully");
            localStorage.setItem("reviews", JSON.stringify(data));
            localStorage.setItem("reviewId", id);
            // if (isOfferPage) {

            //   navigate(`/offer?id=${id}&employeeId=${employeeId}`);
            //   window.open(`/review?employee=${employeeId}`, "_blank");
            // } else {
            navigate(`/review?employee=${employeeId}`);
            // }
          },
          onError: (err) => {
            if (err.response.data.error) {
              toast.error(err.response.data.error.message);
            } else if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      saveNegative(
        {
          business_uuid: id,
          response: modifiedResponse,
          employee_code: staffName && employeeId ? employeeId : null,
          staff_name: staffName || null,
          tr_id: trackId === "null" ? null : trackId,
          ...formValues,
        },
        {
          onSuccess: (data) => {
            handleNavigateThankyou();
          },
          onError: (err) => {
            if (err.response.data.error) {
              toast.error(err.response.data.error.message);
            } else if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  const categories = useMemo(
    () => categoryOrder?.map((item) => item.category),
    [categoryOrder]
  );

  const keywords = useMemo(
    () => [...new Set(questions?.map((item) => item.keyword))],
    [questions]
  );

  const categoriesWithDropDown = useMemo(
    () =>
      categoryOrder
        ?.map((item) => {
          return {
            category: item.category,
            isDropdown: item?.is_dropdown,
            isMandatory: item?.is_mandatory,
          };
        })
        ?.filter(
          (item, i, arr) =>
            arr.findIndex((a) => a.category === item.category) === i
        )
        ?.concat({
          category: null,
          isDropdown: false,
          isMandatory: false,
        }),
    [categoryOrder]
  );

  const handleSelectCategory = (item) => {
    if (selectedCategory.includes(item)) {
      setSelectedCategory((prev) =>
        prev.filter((previousItem) => previousItem !== item)
      );
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        delete current[item];
        return current;
      });
    } else {
      setSelectedCategory((prev) => {
        let current = [...prev];
        current.push(item);
        return current;
      });
    }
  };

  const handleKeywordClick = (category, keyword) => {
    if (selectedKeywords[category]?.includes(keyword)) {
      let notIncludedKeywords = selectedKeywords[category].filter(
        (item) => item !== keyword
      );
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        let currentKeywords = [...current[category]];
        currentKeywords = notIncludedKeywords;
        current[category] = currentKeywords;
        return current;
      });
    } else {
      setSelectedKeywords((prev) => {
        let current = { ...prev };
        let currentKeywords = [...current[category]];
        currentKeywords.push(keyword);
        current[category] = currentKeywords;
        return current;
      });
    }
  };

  useEffect(() => {
    setSelectedCategory(
      categoriesWithDropDown
        ?.filter((item) => item?.isMandatory)
        ?.map((item) => item?.category)
        ?.concat(null)
    );
  }, [categoriesWithDropDown]);

  useEffect(() => {
    if (selectedCategory.length > 0) {
      selectedCategory.forEach((category) => {
        if (!selectedKeywords[category]) {
          setSelectedKeywords((prev) => {
            let current = { ...prev };
            current[category] = [];
            return current;
          });
        }
      });
    }
  }, [selectedCategory]);

  const handleGenerateDirectReview = () => {
    if (checkFormValues()) return;

    const mandatoryCategories = categoriesWithDropDown?.filter(
      (item) => item?.isMandatory
    );

    const selectedAllMandatoryCategory = mandatoryCategories?.filter(
      (item) =>
        selectedCategory?.includes(item?.category) &&
        (questions
          ?.filter((q) => q?.category === item?.category)
          ?.map((item) => item?.keyword)
          ?.filter((item) => item)?.length > 0
          ? selectedKeywords[item?.category]?.length > 0
          : true)
    );
    if (mandatoryCategories?.length !== selectedAllMandatoryCategory?.length) {
      toast.error("Select at least one keyword for mandatory categories");
      return;
    }
    const response = selectedCategory?.map((category) => {
      return {
        category: category,
        keywords: selectedKeywords[category],
      };
    });

    if (givenStars > starRating || !isNegativeFeedback) {
      sessionStorage.setItem("isClicked", "false");

      localStorage.setItem("response", JSON.stringify(response));
      staffName && localStorage.setItem("staffName", staffName);

      mutate(
        {
          data: {
            business_uuid: id,
            response: response,
            employee_code: staffName && employeeId ? employeeId : null,
            staff_name: staffName || null,
            tr_id: trackId === "null" ? null : trackId,
            ...formValues,
          },
          isDetailed: false,
        },
        {
          onSuccess: (data) => {
            toast.success("Review generated successfully");
            localStorage.setItem("reviews", JSON.stringify(data));
            localStorage.setItem("reviewId", id);

            navigate(`/review?employee=${employeeId}`);
          },
          onError: (err) => {
            if (err.response.data.error) {
              toast.error(err.response.data.error.message);
            } else if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      saveNegative(
        {
          business_uuid: id,
          response: response,
          employee_code: staffName && employeeId ? employeeId : null,
          staff_name: staffName || null,
          tr_id: trackId === "null" ? null : trackId,
          ...formValues,
        },
        {
          onSuccess: (data) => {
            handleNavigateThankyou();
          },
          onError: (err) => {
            if (err.response.data.error) {
              toast.error(err.response.data.error.message);
            } else if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  const handleNext = () => {
    const mandatoryCategories = categoriesWithDropDown?.filter(
      (item) => item?.isMandatory
    );

    const selectedAllMandatoryCategory = mandatoryCategories?.filter(
      (item) =>
        selectedCategory?.includes(item?.category) &&
        (questions
          ?.filter((q) => q?.category === item?.category)
          ?.map((item) => item?.keyword)
          ?.filter((item) => item)?.length > 0
          ? selectedKeywords[item?.category]?.length > 0
          : true)
    );
    if (mandatoryCategories?.length !== selectedAllMandatoryCategory?.length) {
      toast.error("Select at least one keyword for mandatory categories");
      return;
    }
    window.scrollTo(0, 0);
    setStep(2);
    window.history.pushState("#step2", "Step 2", document.location + "#step2");
    Object.keys(selectedKeywords).forEach((key) => {
      if (selectedKeywords[key].length === 0) {
        // const keyword = questions?.find(
        //   (item) => item.category === key
        // ).keyword;
        setSelectedKeywords((prev) => {
          let current = { ...prev };
          current[key] = [null];
          return current;
        });
      }
    });
  };

  const sortedQuestions = useMemo(() => {
    return questions?.sort((a, b) => {
      // If 'a.category' is null and 'b.category' is not, put 'a' after 'b'
      if (a.category === null && b.category !== null) {
        return 1;
      }
      // If 'b.category' is null and 'a.category' is not, put 'b' after 'a'
      if (a.category !== null && b.category === null) {
        return -1;
      }
      // If both 'a.category' and 'b.category' are null or both are non-null, keep their order

      return categories?.indexOf(a.category) - categories?.indexOf(b.category);
    });
  }, [questions, categories]);

  let isSwiper = false;

  const swiperRef = useRef(null);
  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleFieldChange = (e) => {
    setIsShowFormErrors(false);

    const { name, value } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (name === "email" && !emailRegex.test(value)) {
      setFormErrors((prev) => ({ ...prev, [name]: "Invalid Email" }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleFieldPhoneChange = (name, value) => {
    setIsShowFormErrors(false);

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (!isValidPhoneNumber(value)) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "Invalid Phone Number",
      }));
    } else {
      setFormErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  // Check if any required field is empty
  const isButtonDisabled = personalInfo?.some(
    (field) => field.must && !formValues[field.field_name].trim()
  );

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function () {
        setStep(1);
      },
      false
    );

    return () => {
      window.removeEventListener(
        "popstate",
        function () {
          setStep(1);
        },
        false
      );
    };
  }, []);

  useEffect(() => {
    if (categoryOrder?.length === 0) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [categoryOrder]);

  if (isReviewLoading && isReviewPermemissionLoading) {
    return <DashboardLoading />;
  }

  return (
    <>
      <div
        className="min-h-screen p-5 lg:p-20 text-black
        "
        style={{
          backgroundColor: bgColor || "#4F75FA",
        }}
      >
        <Helmet>
          <meta
            property="og:title"
            content="MagicReview | AI-Powered Effortless Review Collection"
          />
          <meta
            property="og:description"
            content="Effortlessly collect, manage, and showcase reviews for your business with MagicReview, an AI-powered platform designed to enhance your brand's online reputation."
          />
          <meta
            property="og:image"
            content="https://magicreviewstorage.blob.core.windows.net/assets/MR-logo.png"
          />
          <meta
            property="og:image:secure"
            content="https://magicreviewstorage.blob.core.windows.net/assets/MR-logo.png"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content={`${window.location.href}`} />
          <meta property="og:type" content="website" />

          {/* Add other OG tags as needed */}
        </Helmet>

        <div className=" min-h-[90vh] font-heebo  space-y-5 relative">
          {step === 1 && (
            <div className="bg-white rounded-xl space-y-5 pt-5">
              {postReviewLoading ||
              voiceReviewLoading ||
              saveNegativeLoading ? (
                <div className="w-full h-[70vh] flex flex-col justify-center items-center gap-5">
                  <img
                    loading="lazy"
                    src="/mr-logo.gif"
                    alt="Loading Logo"
                    className="w-20 h-20"
                  />
                  <p className="animate-pulse text-white">
                    Hold on while we get your reviews ...
                  </p>
                </div>
              ) : (
                <>
                  {entityLogo ? (
                    <div className="flex flex-col justify-center items-center my-5">
                      <img
                        src={entityLogo}
                        className="size-20 rounded-full"
                        loading="eager"
                      />
                      <p
                        className={`text-[22px] lg:text-[28px] leading-[30px] text-[#434343] font-poppins font-semibold text-center mt-3 hidden`}
                      >
                        {entityName || ""}
                      </p>
                    </div>
                  ) : null}
                  {isStaffManagementEnabled && staffName && staffShow && (
                    <div className="space-y-3 px-5">
                      <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px] break-words">
                        {staffLabel || "Representative"}
                      </p>
                      <input
                        type="text"
                        placeholder="Enter Staff Name"
                        className="w-full flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] font-heebo h-[50px]"
                        style={{
                          backgroundColor: buttonColor + "50",
                          border: `${buttonColor} 1px solid `,
                        }}
                        value={staffName}
                        onChange={(e) => setStaffName(e.target.value)}
                        disabled={employeeId}
                      />
                    </div>
                  )}
                  {isVoiceReview && (
                    <div className={`px-5 ${isOnlyVoiceReview && "pb-5"}`}>
                      <div
                        className="w-full p-5 rounded-xl space-y-5"
                        style={{
                          backgroundColor: buttonColor,
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <p className="font-heebo  tracking-[-1%] leading-[20px] text-white">
                            Speak your thoughts, <br className="md:hidden" />{" "}
                            and we&apos;ll craft them{" "}
                            <br className="md:hidden" /> into a detailed review
                            for you
                          </p>

                          <div
                            className="w-10 h-10 text-white flex items-center justify-center rounded-full overflow-hidden flex-shrink-0"
                            style={{
                              backgroundColor: `color-mix(in srgb, ${buttonColor} 80%, white)`,
                              // backgroundColor: buttonColor,
                            }}
                          >
                            <Mic />
                          </div>
                        </div>

                        <VoiceReview
                          entityId={id}
                          setStep={setStep}
                          keywords={keywords}
                          employeeId={employeeId}
                          bgColor={buttonColor}
                          mutate={voiceMutate}
                          trackId={trackId}
                          starRating={starRating}
                          isNegativeFeedback={isNegativeFeedback}
                          personalInfo={personalInfo}
                          formValues={formValues}
                          handleFieldChange={handleFieldChange}
                          handleThankYou={handleNavigateThankyou}
                          formErrors={formErrors}
                          handleFieldPhoneChange={handleFieldPhoneChange}
                          checkFormStatus={checkFormValues}
                          isShowFormErrors={isShowFormErrors}
                          isOfferPage={isOfferPage}
                        />
                      </div>
                      {!isOnlyVoiceReview && (
                        <div className="flex items-center justify-center gap-5 mt-5">
                          <div className="h-[1px] w-full bg-[#ebebeb]"></div>
                          <p className="font-heebo font-medium text-[#a6a6a6] flex-shrink-0 ">
                            OR
                          </p>
                          <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
                        </div>
                      )}
                    </div>
                  )}
                  {!(isVoiceReview && isOnlyVoiceReview) && (
                    <div className="w-full space-y-5 bg-white rounded-xl p-5">
                      <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                        What would you like <br className="lg:hidden" /> to
                        review on?
                      </p>
                      <div className="w-full space-y-5 ">
                        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-5">
                          {categoriesWithDropDown
                            // ?.filter((item) => item?.category !== null)
                            ?.map((category, i) => (
                              <div key={i} className="flex flex-col space-y-5">
                                {category?.category && (
                                  <button
                                    // key={i}
                                    onClick={() =>
                                      handleSelectCategory(category?.category)
                                    }
                                    className={`flex items-center justify-between px-4 py-4 border rounded-lg text-[#4A4A4A] w-full font-heebo h-[50px]`}
                                    style={{
                                      border: `${
                                        selectedCategory?.includes(
                                          category?.category
                                        )
                                          ? buttonColor
                                          : "#bbb"
                                      } 1px solid`,
                                      backgroundColor:
                                        selectedCategory?.includes(
                                          category?.category
                                        )
                                          ? buttonColor + "50"
                                          : "white",
                                    }}
                                  >
                                    <div className="flex items-center">
                                      <span
                                        className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2`}
                                        style={{
                                          border: `${
                                            selectedCategory?.includes(
                                              category?.category
                                            )
                                              ? buttonColor
                                              : "#bbb"
                                          } 1px solid`,
                                          backgroundColor:
                                            selectedCategory?.includes(
                                              category?.category
                                            )
                                              ? buttonColor
                                              : "white",
                                        }}
                                      >
                                        {selectedCategory.includes(
                                          category?.category
                                        ) ? (
                                          <svg
                                            width="12"
                                            height="10"
                                            viewBox="0 0 12 10"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                              stroke="white"
                                              strokeWidth="1.5"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                      {category?.category}
                                    </div>
                                    {category?.isMandatory && (
                                      <Asterisk className="text-red-500" />
                                    )}
                                  </button>
                                )}

                                {selectedCategory.length > 0 &&
                                  selectedCategory.includes(
                                    category?.category
                                  ) && (
                                    <>
                                      {category?.isDropdown ? (
                                        <div className="w-full relative mt-3">
                                          <button
                                            className="w-full h-14 bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo text-lg"
                                            style={{
                                              border: `${buttonColor} 1px solid`,
                                            }}
                                            onClick={() => {
                                              if (
                                                openDropdownKeyword !==
                                                category?.category
                                              ) {
                                                setIsOpenDropdownKeyword(true);
                                                setOpenDropdownKeyword(
                                                  category?.category
                                                );
                                              } else {
                                                setIsOpenDropdownKeyword(
                                                  (prev) => !prev
                                                );
                                                setOpenDropdownKeyword(
                                                  category?.category
                                                );
                                              }
                                            }}
                                          >
                                            <p>
                                              {selectedKeywords[
                                                category?.category
                                              ]?.length
                                                ? selectedKeywords[
                                                    category?.category
                                                  ]?.join(", ")
                                                : "Select keyword"}
                                            </p>

                                            <ChevronDown
                                              className={`w-5 h-5 ${isOpenDropdownKeyword && openDropdownKeyword === category?.category && "rotate-180"} transition-all duration-200`}
                                            />
                                          </button>
                                          {isOpenDropdownKeyword &&
                                            openDropdownKeyword ===
                                              category?.category && (
                                              <>
                                                <div
                                                  className="w-full p-5 rounded-lg absolute top-16 left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow-xl bg-white"
                                                  style={{
                                                    border: `${buttonColor} 1px solid`,
                                                  }}
                                                >
                                                  <div className="relative w-full">
                                                    <input
                                                      type="text"
                                                      value={
                                                        searchDropdownKeyword?.[
                                                          category?.category
                                                        ]
                                                      }
                                                      onChange={(e) =>
                                                        setSearchDropdownKeyword(
                                                          {
                                                            [category?.category]:
                                                              e.target.value,
                                                          }
                                                        )
                                                      }
                                                      className="w-full border font-pro border-[#e7e4e4] rounded-lg"
                                                      placeholder="Search"
                                                    />
                                                    <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2" />
                                                  </div>

                                                  {[
                                                    ...new Set(
                                                      questions
                                                        ?.filter(
                                                          (item) =>
                                                            item.category ===
                                                            category?.category
                                                        )
                                                        ?.filter(
                                                          (item) =>
                                                            item.keyword !==
                                                            null
                                                        )
                                                        .map(
                                                          (item) => item.keyword
                                                        )
                                                    ),
                                                  ]
                                                    ?.filter(
                                                      (item) =>
                                                        item
                                                          ?.toLowerCase()
                                                          ?.indexOf(
                                                            (
                                                              searchDropdownKeyword?.[
                                                                category
                                                                  ?.category
                                                              ] || ""
                                                            )?.toLowerCase()
                                                          ) > -1
                                                    )
                                                    ?.map((keyword, i) => (
                                                      <p
                                                        key={keyword}
                                                        onClick={() => {
                                                          handleKeywordClick(
                                                            category?.category,
                                                            keyword
                                                          );
                                                          setIsOpenDropdownKeyword(
                                                            false
                                                          );
                                                        }}
                                                        className={`cursor-pointer hover:bg-gray-100 px-2 flex items-center justify-between rounded py-1`}
                                                        style={{
                                                          color:
                                                            selectedKeywords[
                                                              category?.category
                                                            ]?.includes(
                                                              keyword
                                                            ) && buttonColor,
                                                        }}
                                                      >
                                                        {keyword}
                                                        {selectedKeywords[
                                                          category?.category
                                                        ]?.includes(
                                                          keyword
                                                        ) && (
                                                          <Check className="w-4 h-4" />
                                                        )}
                                                      </p>
                                                    ))}
                                                </div>
                                              </>
                                            )}
                                        </div>
                                      ) : (
                                        <div className="flex flex-wrap gap-2">
                                          {[
                                            ...new Set(
                                              questions
                                                ?.filter(
                                                  (item) =>
                                                    item.category ===
                                                    category?.category
                                                )
                                                ?.filter(
                                                  (item) =>
                                                    item.keyword !== null
                                                )
                                                .map((item) => item.keyword)
                                            ),
                                          ]?.map((keyword, i) => (
                                            <button
                                              onClick={() =>
                                                handleKeywordClick(
                                                  category?.category,
                                                  keyword
                                                )
                                              }
                                              key={i}
                                              className={`text-center px-3.5 py-2 border  rounded-[8px] ${
                                                selectedKeywords[
                                                  category?.category
                                                ]?.includes(keyword)
                                                  ? "border-[#ba9a46] bg-[#fffaea] text-[#ba9a46]"
                                                  : "border-[#4a4a4a]"
                                              }`}
                                            >
                                              {keyword}
                                            </button>
                                          ))}
                                        </div>
                                      )}
                                    </>
                                  )}
                              </div>
                            ))}
                        </div>

                        <div
                          className={` w-full  py-5 rounded-md ${isVisible ? "opacity-100" : "opacity-0"}`}
                        >
                          <div className="w-full flex lg:flex-row-reverse flex-col gap-5   ">
                            {!isDirectReview ? (
                              <button
                                className={`w-full py-2.5 lg:py-4 rounded-lg  text-center lg:text-[20px] active:scale-95 transition-all duration-200`}
                                style={{
                                  border: `${buttonColor} 1px solid`,
                                  backgroundColor: isHoverContinue
                                    ? "white"
                                    : buttonColor,
                                  color: isHoverContinue
                                    ? buttonColor
                                    : "white",
                                }}
                                onMouseEnter={() => {
                                  startTransition(() => {
                                    setIsHoverContinue(true);
                                  });
                                }}
                                onMouseLeave={() => {
                                  startTransition(() => {
                                    setIsHoverContinue(false);
                                  });
                                }}
                                onClick={handleNext}
                              >
                                Continue
                              </button>
                            ) : (
                              <>
                                {postReviewLoading ? null : !isCanGenerateReviews ? (
                                  <Tooltip
                                    content={
                                      tooltipMessage ||
                                      "You cannot perform this action"
                                    }
                                    theme={{
                                      target: "w-full",
                                    }}
                                  >
                                    <button
                                      className="w-full py-2.5 lg:py-4 rounded-lg text-white text-center lg:text-[20px] disabled:cursor-not-allowed flex justify-center items-center gap-x-2"
                                      disabled={true}
                                      style={{
                                        border: `${buttonColor} 1px solid`,
                                        backgroundColor: isHoverGenerate
                                          ? "white"
                                          : buttonColor,
                                        color: isHoverGenerate
                                          ? buttonColor
                                          : "white",
                                      }}
                                      onMouseEnter={() => {
                                        startTransition(() => {
                                          setIsHoverGenerate(true);
                                        });
                                      }}
                                      onMouseLeave={() => {
                                        startTransition(() => {
                                          setIsHoverGenerate(false);
                                        });
                                      }}
                                    >
                                      <Lock className="" />
                                      {"Generate Review"}
                                    </button>
                                  </Tooltip>
                                ) : (
                                  <div className="w-full">
                                    {personalInfo?.length > 0 && (
                                      <div className="flex flex-col items-center justify-center">
                                        {personalInfo.some(
                                          (field) =>
                                            field?.name === "subheading" &&
                                            field?.show
                                        ) && (
                                          <p className="mb-4 max-w-lg w-full">
                                            {
                                              personalInfo.find(
                                                (field) =>
                                                  field?.name === "subheading"
                                              )?.text
                                            }
                                          </p>
                                        )}
                                        {personalInfo.map((field) => (
                                          <>
                                            {field?.name ===
                                            "subheading" ? null : (
                                              <div
                                                key={field.field_name}
                                                className="mb-4 max-w-lg w-full"
                                              >
                                                {/* <label
                                          htmlFor={field.field_name}
                                          className="block font-medium mb-2"
                                        >
                                          {field.name}{" "}
                                          {field.must && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                        </label> */}
                                                {field.type === "phone" ? (
                                                  <PhoneInput
                                                    className=""
                                                    value={
                                                      formValues[
                                                        field.field_name
                                                      ]
                                                    }
                                                    onChange={(value) =>
                                                      handleFieldPhoneChange(
                                                        field.field_name,
                                                        value
                                                      )
                                                    }
                                                    defaultCountry="GB"
                                                    placeholder={`${field.name} ${
                                                      field.must ? "*" : ""
                                                    }`}
                                                  />
                                                ) : (
                                                  <Input
                                                    type={field.type}
                                                    id={field.field_name}
                                                    name={field.field_name}
                                                    value={
                                                      formValues[
                                                        field.field_name
                                                      ]
                                                    }
                                                    onChange={handleFieldChange}
                                                    className="border p-2 rounded w-full dark:bg-white dark:border-[#ebebeb] h-12"
                                                    required={field.must}
                                                    placeholder={`${field.name} ${
                                                      field.must ? "*" : ""
                                                    }`}
                                                  />
                                                )}
                                                {isShowFormErrors &&
                                                  formErrors[
                                                    field.field_name
                                                  ] &&
                                                  formValues[
                                                    field.field_name
                                                  ] !== "" && (
                                                    <span className="text-sm text-red-500">
                                                      *{" "}
                                                      {
                                                        formErrors[
                                                          field.field_name
                                                        ]
                                                      }
                                                    </span>
                                                  )}
                                              </div>
                                            )}
                                          </>
                                        ))}
                                      </div>
                                    )}
                                    {isNegativeFeedback && (
                                      <div className="flex justify-center items-center flex-col lg:flex-row w-full mb-5 gap-3 bg-[#fafafa] p-3 lg:p-5 max-w-lg mx-auto border border-newPrimary rounded-md">
                                        <p>Select star rating</p>
                                        <Rating
                                          initialValue={givenStars}
                                          onClick={(e) => setGivenStars(e)}
                                          SVGstyle={{ display: "inline" }}
                                          SVGclassName="w-8 lg:w-9"
                                        />
                                      </div>
                                    )}

                                    <button
                                      onClick={handleGenerateDirectReview}
                                      className={`w-full py-2.5 lg:py-4 rounded-lg  text-center lg:text-[20px] active:scale-95 disabled:cursor-not-allowed flex justify-center items-center gap-x-2 transition-all duration-200`}
                                      style={{
                                        border: `${buttonColor} 1px solid`,
                                        backgroundColor: isHoverGenerate
                                          ? "white"
                                          : buttonColor,
                                        color: isHoverGenerate
                                          ? buttonColor
                                          : "white",
                                      }}
                                      onMouseEnter={() => {
                                        startTransition(() => {
                                          setIsHoverGenerate(true);
                                        });
                                      }}
                                      onMouseLeave={() => {
                                        startTransition(() => {
                                          setIsHoverGenerate(false);
                                        });
                                      }}
                                      disabled={
                                        isNegativeFeedback && givenStars <= 0
                                      }
                                    >
                                      {"Generate Review"}
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                            <>
                              {!isNegativeFeedback &&
                                reviewPlatforms?.platform?.google && (
                                  <button
                                    onClick={() =>
                                      isNegativeFeedback &&
                                      givenStars <= starRating
                                        ? navigate("/thank-you")
                                        : (window.location.href =
                                            reviewPlatforms?.platform?.google)
                                    }
                                    className="w-full py-2.5 lg:py-4 rounded-lg text-center lg:text-[20px] active:scale-95 transition-all duration-200 disabled:hidden bg-[#f9c33c]/15 text-[#ff9737] flex items-center justify-center gap-3 self-end"
                                    disabled={
                                      isNegativeFeedback && givenStars <= 0
                                    }
                                  >
                                    <SquarePen className="size-5" />
                                    Post your own review
                                  </button>
                                )}
                            </>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {step === 2 && (
            <>
              {postReviewLoading ||
              voiceReviewLoading ||
              saveNegativeLoading ? (
                <div className="w-full h-[70vh] flex flex-col justify-center items-center gap-5">
                  <img
                    loading="lazy"
                    src="/mr-logo.gif"
                    alt="Loading Logo"
                    className="w-20 h-20"
                  />
                  <p className="animate-pulse text-white">
                    Hold on while we get your reviews ...
                  </p>
                </div>
              ) : (
                <div className="bg-white rounded-xl pt-5">
                  {/* <div className="flex items-center gap-x-2 flex-shrink-0">
                    <img
                      src={"/new-logo.svg"}
                      alt="MagicReview Logo"
                      className="w-[30px] flex-shrink-0"
                    />
                    <h1 className="font-avertaBold font-bold text-[18px] text-white -tracking-[1%]">
                      magicreview
                    </h1>
                  </div> */}
                  {entityLogo ? (
                    <div className="flex flex-col justify-center items-center my-5">
                      <img
                        src={entityLogo}
                        className="size-20 rounded-full"
                        loading="eager"
                      />
                      <p
                        className={`text-[22px] lg:text-[28px] leading-[30px] text-[#434343] font-poppins font-semibold text-center mt-3 hidden`}
                      >
                        {entityName || ""}
                      </p>
                    </div>
                  ) : null}

                  {isStaffManagementEnabled &&
                    staffName &&
                    staffShow &&
                    categoryOrder?.length === 0 && (
                      <div className="space-y-5 p-5">
                        <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px] break-words">
                          {staffLabel || "Representation"}
                        </p>
                        <input
                          type="text"
                          placeholder="Enter Staff Name"
                          className="w-full flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] font-heebo"
                          style={{
                            backgroundColor: buttonColor + "50",
                            border: `${buttonColor} 1px solid `,
                          }}
                          value={staffName}
                          onChange={(e) => setStaffName(e.target.value)}
                          disabled={employeeId}
                        />
                      </div>
                    )}

                  {categoryOrder?.length === 0 && isVoiceReview && (
                    <div className={`px-5 ${isOnlyVoiceReview && "pb-5"}`}>
                      <div
                        className="w-full p-5 rounded-xl space-y-5"
                        style={{
                          backgroundColor: buttonColor,
                        }}
                      >
                        <div className="flex justify-between items-center">
                          <p className="font-heebo  tracking-[-1%] leading-[20px] text-white">
                            Speak your thoughts, <br className="md:hidden" />{" "}
                            and we&apos;ll craft them{" "}
                            <br className="md:hidden" /> into a detailed review
                            for you
                          </p>

                          <div
                            className="w-10 h-10 text-white flex items-center justify-center rounded-full overflow-hidden flex-shrink-0"
                            style={{
                              backgroundColor: `color-mix(in srgb, ${buttonColor} 80%, white)`,
                              // backgroundColor: buttonColor,
                            }}
                          >
                            <Mic />
                          </div>
                        </div>

                        <VoiceReview
                          entityId={id}
                          setStep={setStep}
                          keywords={keywords}
                          employeeId={employeeId}
                          bgColor={buttonColor}
                          mutate={voiceMutate}
                          trackId={trackId}
                          starRating={starRating}
                          isNegativeFeedback={isNegativeFeedback}
                          personalInfo={personalInfo}
                          formValues={formValues}
                          handleFieldChange={handleFieldChange}
                          handleThankYou={handleNavigateThankyou}
                          formErrors={formErrors}
                          handleFieldPhoneChange={handleFieldPhoneChange}
                          checkFormStatus={checkFormValues}
                          isShowFormErrors={isShowFormErrors}
                          isOfferPage={isOfferPage}
                        />
                      </div>
                      {!isOnlyVoiceReview && (
                        <div className="flex items-center justify-center gap-5 mt-5">
                          <div className="h-[1px] w-full bg-[#ebebeb]"></div>
                          <p className="font-heebo font-medium text-[#a6a6a6] flex-shrink-0 ">
                            OR
                          </p>
                          <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
                        </div>
                      )}
                    </div>
                  )}

                  {isSwiper && (
                    <div
                      ref={paginationRef}
                      className="custom-pagination"
                      id="customPagination"
                    />
                  )}

                  {!(isVoiceReview && isOnlyVoiceReview) && (
                    <div className="flex flex-col gap-2 bg-white rounded-xl p-5">
                      <>
                        {isSwiper ? (
                          <>
                            <Swiper
                              className="max-w-full"
                              modules={[Navigation, Pagination]}
                              slidesPerView={1}
                              spaceBetween={5}
                              navigation={{
                                nextEl: ".next",
                                prevEl: ".prev",
                              }}
                              pagination={{
                                clickable: true,
                                el: "#customPagination",
                              }}
                              ref={swiperRef}
                              onSwiper={(swiper) => {
                                // Update the swiper pagination dynamically
                                swiper.params.pagination.el =
                                  paginationRef.current;
                                swiper.pagination.init();
                                swiper.pagination.update();
                              }}
                            >
                              {sortedQuestions
                                ?.filter(
                                  (item) =>
                                    selectedCategory?.includes(item.category) ||
                                    item?.category === null
                                )
                                ?.filter((item) =>
                                  item?.category === null &&
                                  item?.keyword === null
                                    ? item
                                    : selectedKeywords[item.category]?.includes(
                                        item.keyword
                                      )
                                )
                                ?.map((item, questionIndex) => {
                                  return item?.show_as === "DROPDOWN" ? (
                                    <SwiperSlide key={questionIndex}>
                                      <div
                                        key={questionIndex}
                                        className="min-h-72 h-full"
                                      >
                                        <div className="flex items-center gap-x-2">
                                          <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                            {item.question}
                                          </p>
                                          {item?.is_mandatory && (
                                            <Asterisk className="text-red-500" />
                                          )}
                                        </div>
                                        <div className="w-full relative mt-5">
                                          <button
                                            className="w-full bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo h-[50px] border border-[#bbb]"
                                            onClick={() => {
                                              if (openDropdownId !== item?.id) {
                                                setIsOpenDropdown(true);
                                                setOpenDropdownId(item?.id);
                                              } else {
                                                setIsOpenDropdown(
                                                  (prev) => !prev
                                                );
                                                setOpenDropdownId(item?.id);
                                              }
                                            }}
                                          >
                                            <p>
                                              {selectedAnswers[item?.id]?.length
                                                ? item?.has_multiple_responses
                                                  ? selectedAnswers[
                                                      item?.id
                                                    ]?.join(", ")
                                                  : selectedAnswers[
                                                      item?.id
                                                    ]?.[0]
                                                : "Select response"}
                                            </p>
                                            <ChevronDown
                                              className={`w-5 h-5 ${isOpenDropdown && openDropdownId === item?.id && "rotate-180"} transition-all duration-200`}
                                            />
                                          </button>
                                          {isOpenDropdown &&
                                            openDropdownId === item?.id && (
                                              <>
                                                <div className="w-full p-5 rounded-lg absolute top-14 border-[#bbb] left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow bg-white border">
                                                  <div className="relative w-full">
                                                    <input
                                                      type="text"
                                                      value={searchDropdown}
                                                      onChange={(e) =>
                                                        setSearchDropdown(
                                                          e.target.value
                                                        )
                                                      }
                                                      className="w-full border font-pro border-[#e7e4e4] rounded-lg"
                                                      placeholder="Search"
                                                    />
                                                    <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2" />
                                                  </div>

                                                  {item?.response_options
                                                    ?.filter(
                                                      (item) =>
                                                        item
                                                          ?.toLowerCase()
                                                          ?.indexOf(
                                                            searchDropdown?.toLowerCase()
                                                          ) > -1
                                                    )
                                                    ?.map((answer, i) => (
                                                      <p
                                                        key={answer}
                                                        onClick={() => {
                                                          handleAnswerClick(
                                                            // item.category + item.keyword,
                                                            item.id,
                                                            answer,
                                                            item?.has_multiple_responses
                                                          );
                                                          setIsOpenDropdown(
                                                            false
                                                          );
                                                        }}
                                                        className={`cursor-pointer hover:bg-gray-100 px-2 flex items-center justify-between rounded py-1`}
                                                        style={{
                                                          color:
                                                            selectedAnswers[
                                                              item.id
                                                            ]?.includes(
                                                              answer
                                                            ) && buttonColor,
                                                        }}
                                                      >
                                                        {answer}
                                                        {selectedAnswers[
                                                          item.id
                                                        ]?.includes(answer) && (
                                                          <Check className="w-4 h-4" />
                                                        )}
                                                      </p>
                                                    ))}
                                                </div>
                                              </>
                                            )}
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  ) : (
                                    <SwiperSlide key={questionIndex}>
                                      <div
                                        className="flex flex-col"
                                        key={questionIndex}
                                      >
                                        <div className="flex items-center gap-x-2">
                                          <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                            {item.question}
                                          </p>
                                          {item?.is_mandatory && (
                                            <Asterisk className="text-red-500" />
                                          )}
                                        </div>
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
                                          {item.response_options.map(
                                            (answer, i) => (
                                              <button
                                                key={answer}
                                                onClick={() =>
                                                  handleAnswerClick(
                                                    // item.category + item.keyword,
                                                    item.id,
                                                    answer,
                                                    item?.has_multiple_responses
                                                  )
                                                }
                                                className={`flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] h-[50px]`}
                                                style={{
                                                  backgroundColor:
                                                    selectedAnswers[
                                                      item.id
                                                    ]?.includes(answer)
                                                      ? buttonColor + 50
                                                      : "white",
                                                  border: `${
                                                    selectedAnswers[
                                                      item.id
                                                    ]?.includes(answer)
                                                      ? buttonColor
                                                      : "#bbb"
                                                  } 1px solid`,
                                                }}
                                              >
                                                <span
                                                  className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2`}
                                                  style={{
                                                    backgroundColor:
                                                      selectedAnswers[
                                                        item.id
                                                      ]?.includes(answer)
                                                        ? buttonColor
                                                        : "white",
                                                    border: `${
                                                      selectedAnswers[
                                                        item.id
                                                      ]?.includes(answer)
                                                        ? buttonColor
                                                        : "#bbb"
                                                    } 1px solid`,
                                                  }}
                                                >
                                                  {selectedAnswers[
                                                    item.id
                                                  ]?.includes(answer) ? (
                                                    <svg
                                                      width="12"
                                                      height="10"
                                                      viewBox="0 0 12 10"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path
                                                        d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      />
                                                    </svg>
                                                  ) : (
                                                    ""
                                                  )}
                                                </span>
                                                {answer}
                                              </button>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  );
                                })}
                            </Swiper>
                            {sortedQuestions
                              ?.filter(
                                (item) =>
                                  selectedCategory?.includes(item.category) ||
                                  item?.category === null
                              )
                              ?.filter((item) =>
                                item?.category === null &&
                                item?.keyword === null
                                  ? item
                                  : selectedKeywords[item.category]?.includes(
                                      item.keyword
                                    )
                              )?.length > 0 ? (
                              <div className="flex justify-between lg:mt-20">
                                <div
                                  className="prev cursor-pointer border border-[#E4DDDD] dark:border-none dark:bg-darkLightBg w-fit p-2 rounded-[12px]"
                                  onClick={handlePrevSlide}
                                >
                                  <ArrowLeft className="text-black/45 dark:text-[#888]" />
                                </div>
                                <div
                                  className="next cursor-pointer border border-[#E4DDDD] dark:border-none dark:bg-darkLightBg w-fit p-2 rounded-[12px]"
                                  onClick={handleNextSlide}
                                >
                                  <ArrowRight className="text-black/45 dark:text-[#888]" />
                                </div>
                              </div>
                            ) : null}
                          </>
                        ) : (
                          sortedQuestions
                            ?.filter(
                              (item) =>
                                selectedCategory?.includes(item.category) ||
                                item?.category === null
                            )
                            ?.filter((item) =>
                              // item?.category === null
                              //   ? item
                              //   : selectedKeywords[item.category]?.includes(
                              //       item.keyword
                              //     )
                              item?.category === null && item?.keyword === null
                                ? item
                                : selectedKeywords[item.category]?.includes(
                                    item.keyword
                                  )
                            )
                            ?.map((item, questionIndex) => {
                              return item?.show_as === "DROPDOWN" ? (
                                <div key={questionIndex} className="mb-5">
                                  <div className="flex items-center gap-x-2">
                                    <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                      {item.question}
                                    </p>
                                    {item?.is_mandatory && (
                                      <Asterisk className="text-red-500" />
                                    )}
                                  </div>
                                  <div className="w-full relative mt-5">
                                    <button
                                      className="w-full bg-white rounded-lg flex justify-between items-center px-5 text-[#4A4A4A] font-heebo h-[50px] border border-[#bbb]"
                                      onClick={() => {
                                        if (openDropdownId !== item?.id) {
                                          setIsOpenDropdown(true);
                                          setOpenDropdownId(item?.id);
                                        } else {
                                          setIsOpenDropdown((prev) => !prev);
                                          setOpenDropdownId(item?.id);
                                        }
                                      }}
                                    >
                                      <p>
                                        {selectedAnswers[item?.id]?.length
                                          ? item?.has_multiple_responses
                                            ? selectedAnswers[item?.id]?.join(
                                                ", "
                                              )
                                            : selectedAnswers[item?.id]?.[0]
                                          : "Select response"}
                                      </p>
                                      <ChevronDown
                                        className={`w-5 h-5 ${isOpenDropdown && openDropdownId === item?.id && "rotate-180"} transition-all duration-200`}
                                      />
                                    </button>
                                    {isOpenDropdown &&
                                      openDropdownId === item?.id && (
                                        <>
                                          <div
                                            className="w-full p-5 rounded-lg absolute top-16 left-0 space-y-3 z-50 text-left max-h-[200px] overflow-y-auto scrollbar-thin shadow bg-white border border-[#bbb]"
                                            // style={{
                                            //   border: `${buttonColor} 1px solid`,
                                            // }}
                                          >
                                            <div className="relative w-full">
                                              <input
                                                type="text"
                                                value={searchDropdown}
                                                onChange={(e) =>
                                                  setSearchDropdown(
                                                    e.target.value
                                                  )
                                                }
                                                className="w-full border font-pro border-[#e7e4e4] rounded-lg"
                                                placeholder="Search"
                                              />
                                              <Search className="text-[#615E83] w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2" />
                                            </div>

                                            {item?.response_options
                                              ?.filter(
                                                (item) =>
                                                  item
                                                    ?.toLowerCase()
                                                    ?.indexOf(
                                                      searchDropdown?.toLowerCase()
                                                    ) > -1
                                              )
                                              ?.map((answer, i) => (
                                                <p
                                                  key={answer}
                                                  onClick={() => {
                                                    handleAnswerClick(
                                                      // item.category + item.keyword,
                                                      item.id,
                                                      answer,
                                                      item?.has_multiple_responses
                                                    );
                                                    setIsOpenDropdown(false);
                                                  }}
                                                  className={`cursor-pointer hover:bg-gray-100 px-2 flex items-center justify-between rounded py-1`}
                                                  style={{
                                                    color:
                                                      selectedAnswers[
                                                        item.id
                                                      ]?.includes(answer) &&
                                                      buttonColor,
                                                  }}
                                                >
                                                  {answer}
                                                  {selectedAnswers[
                                                    item.id
                                                  ]?.includes(answer) && (
                                                    <Check className="w-4 h-4" />
                                                  )}
                                                </p>
                                              ))}
                                          </div>
                                        </>
                                      )}
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className="flex flex-col mb-5"
                                  key={questionIndex}
                                >
                                  <div className="flex items-center gap-x-2">
                                    <p className="font-medium text-[28px] font-heebo -tracking-[1px] leading-[36px]">
                                      {item.question}
                                    </p>
                                    {item?.is_mandatory && (
                                      <Asterisk className="text-red-500" />
                                    )}
                                  </div>
                                  <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
                                    {item.response_options.map((answer, i) => (
                                      <button
                                        key={answer}
                                        onClick={() =>
                                          handleAnswerClick(
                                            // item.category + item.keyword,
                                            item.id,
                                            answer,
                                            item?.has_multiple_responses
                                          )
                                        }
                                        className={`flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] h-[50px]`}
                                        style={{
                                          backgroundColor: selectedAnswers[
                                            item.id
                                          ]?.includes(answer)
                                            ? buttonColor + 50
                                            : "white",
                                          border: `${
                                            selectedAnswers[item.id]?.includes(
                                              answer
                                            )
                                              ? buttonColor
                                              : "#bbb"
                                          } 1px solid`,
                                        }}
                                      >
                                        <span
                                          className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2 flex-shrink-0`}
                                          style={{
                                            backgroundColor: selectedAnswers[
                                              item.id
                                            ]?.includes(answer)
                                              ? buttonColor
                                              : "white",
                                            border: `${
                                              selectedAnswers[
                                                item.id
                                              ]?.includes(answer)
                                                ? buttonColor
                                                : "#bbb"
                                            } 1px solid`,
                                          }}
                                        >
                                          {selectedAnswers[item.id]?.includes(
                                            answer
                                          ) ? (
                                            <svg
                                              width="12"
                                              height="10"
                                              viewBox="0 0 12 10"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                                                stroke="white"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        {answer}
                                      </button>
                                    ))}
                                  </div>
                                  {/* <input
                      type="text"
                      value={userInputs[questionIndex] || ""}
                      onChange={(e) =>
                        handleUserInputChange(questionIndex, e.target.value)
                      }
                      className="mt-4 px-5 py-5 border rounded-[8px] placeholder:text-[#B1AAAA] font-montserrat"
                      placeholder="Enter your thoughts."
                    />  */}
                                </div>
                              );
                            })
                        )}
                      </>

                      {sortedQuestions?.length > 0 && (
                        <div className="w-full py-5 rounded-md">
                          {postReviewLoading ? null : !isCanGenerateReviews ? (
                            <Tooltip
                              content={
                                tooltipMessage ||
                                "You cannot perform this action"
                              }
                              theme={{
                                target: "w-full",
                              }}
                            >
                              <button
                                className="w-full py-4  rounded-lg text-white text-center lg:text-[20px] disabled:cursor-not-allowed flex justify-center items-center gap-x-2"
                                disabled={true}
                                style={{
                                  border: `${buttonColor} 1px solid`,
                                  backgroundColor: isHoverGenerate
                                    ? "white"
                                    : buttonColor,
                                  color: isHoverGenerate
                                    ? buttonColor
                                    : "white",
                                }}
                                onMouseEnter={() => {
                                  startTransition(() => {
                                    setIsHoverGenerate(true);
                                  });
                                }}
                                onMouseLeave={() => {
                                  startTransition(() => {
                                    setIsHoverGenerate(false);
                                  });
                                }}
                              >
                                <Lock className="" />
                                {"Generate Review"}
                              </button>
                            </Tooltip>
                          ) : (
                            <>
                              {personalInfo?.length > 0 && (
                                <div className="flex flex-col items-center justify-center">
                                  {personalInfo.some(
                                    (field) =>
                                      field?.name === "subheading" &&
                                      field?.show
                                  ) && (
                                    <p className="mb-4 max-w-lg w-full">
                                      {
                                        personalInfo.find(
                                          (field) =>
                                            field?.name === "subheading"
                                        )?.text
                                      }
                                    </p>
                                  )}
                                  {personalInfo.map((field) => (
                                    <>
                                      {field?.name === "subheading" ? null : (
                                        <div
                                          key={field.field_name}
                                          className="mb-4 max-w-lg w-full"
                                        >
                                          {/* <label
                                          htmlFor={field.field_name}
                                          className="block font-medium mb-2"
                                        >
                                          {field.name}{" "}
                                          {field.must && (
                                            <span className="text-red-500">
                                              *
                                            </span>
                                          )}
                                        </label> */}
                                          {field.type === "phone" ? (
                                            <PhoneInput
                                              className=""
                                              value={
                                                formValues[field.field_name]
                                              }
                                              onChange={(value) =>
                                                handleFieldPhoneChange(
                                                  field.field_name,
                                                  value
                                                )
                                              }
                                              defaultCountry="GB"
                                              placeholder={`${field.name} ${
                                                field.must ? "*" : ""
                                              }`}
                                            />
                                          ) : (
                                            <Input
                                              type={field.type}
                                              id={field.field_name}
                                              name={field.field_name}
                                              value={
                                                formValues[field.field_name]
                                              }
                                              onChange={handleFieldChange}
                                              className="border p-2 rounded w-full dark:bg-white dark:border-[#ebebeb] h-12"
                                              required={field.must}
                                              placeholder={`${field.name} ${
                                                field.must ? "*" : ""
                                              }`}
                                            />
                                          )}
                                          {isShowFormErrors &&
                                            formErrors[field.field_name] &&
                                            formValues[field.field_name] !==
                                              "" && (
                                              <span className="text-sm text-red-500">
                                                * {formErrors[field.field_name]}
                                              </span>
                                            )}
                                        </div>
                                      )}
                                    </>
                                  ))}
                                </div>
                              )}
                              {isNegativeFeedback && (
                                <div className="flex justify-center items-center flex-col lg:flex-row w-full mb-5 gap-3 bg-[#fafafa] p-3 lg:p-5 max-w-lg mx-auto border border-newPrimary rounded-md">
                                  <p>Select star rating</p>
                                  <Rating
                                    initialValue={givenStars}
                                    onClick={(e) => setGivenStars(e)}
                                    SVGstyle={{ display: "inline" }}
                                    SVGclassName="w-8 lg:w-9"
                                  />
                                </div>
                              )}
                              <button
                                onClick={handleGenerate}
                                className={`w-full py-2.5 lg:py-4 rounded-lg  text-center lg:text-[20px] active:scale-95 disabled:cursor-not-allowed flex justify-center items-center gap-x-2 transition-all duration-200`}
                                style={{
                                  border: `${buttonColor} 1px solid`,
                                  backgroundColor: isHoverGenerate
                                    ? "white"
                                    : buttonColor,
                                  color: isHoverGenerate
                                    ? buttonColor
                                    : "white",
                                }}
                                onMouseEnter={() => {
                                  startTransition(() => {
                                    setIsHoverGenerate(true);
                                  });
                                }}
                                onMouseLeave={() => {
                                  startTransition(() => {
                                    setIsHoverGenerate(false);
                                  });
                                }}
                                disabled={
                                  (isNegativeFeedback && givenStars <= 0) ||
                                  isButtonDisabled
                                }
                              >
                                {"Generate Review"}
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          <>
            <div className="md:flex items-center justify-center gap-5 hidden py-5 lg:py-10 ">
              <div className="h-[1px] w-full bg-[#ebebeb]"></div>
              <div className="flex items-center gap-5 justify-between flex-shrink-0">
                <p className="font-poppins text-[#b5c5fd] text-xl flex-shrink-0">
                  Powered by
                </p>
                <MainLogo className={"flex-shrink-0"} color={"black"} />
              </div>
              <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
            </div>
            <div className="md:hidden py-5 lg:py-10">
              <div className="flex items-center justify-center gap-5 ">
                {/* <div className="h-[1px] w-full bg-[#ebebeb]"></div> */}
                <p className="font-poppins text-[#b5c5fd] text-sm flex-shrink-0">
                  Powered by
                </p>
                {/* <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div> */}
              </div>

              <div className="flex items-center justify-center gap-5 ">
                <div className="h-[1px] w-full bg-[#6A8AFB]"></div>
                <div className="flex items-center justify-center gap-x-2 flex-shrink-0 mt-3">
                  <img
                    src={"/new-logo.svg"}
                    alt="MagicReview Logo"
                    className="w-[30px] flex-shrink-0"
                  />
                  <h1
                    className={`font-avertaBold font-bold text-[18px]  -tracking-[1%] text-[#434343]
        }`}
                  >
                    magicreview
                  </h1>
                </div>
                <div className="h-[1px] w-full bg-[#6A8AFB]"></div>
              </div>
            </div>
          </>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default MobileGenerate;
