import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate, useParams } from "react-router";
import {
  useResendVerifyEmail,
  useVerifyEmail,
} from "../../query/useAuthentication";
import { toast } from "sonner";
import { Link, useSearchParams } from "react-router-dom";
import { LogoLoading } from "../../components/loading/loading";
import { RefreshCcw, RotateCcw } from "lucide-react";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const email = state?.email;
  const [resendTime, setResendTime] = useState(0);

  const [params] = useSearchParams();
  const [otp, setOtp] = useState("");
  const isMobile = window.innerWidth <= 768;
  const { mutate: verifyEmail, isLoading: verifyEmailLoading } =
    useVerifyEmail();
  const { mutate: resendVerifyEmail, isLoading: resendVerifyEmailLoading } =
    useResendVerifyEmail();
  const [verificationId, setVerificationId] = useState("");
  const profileId = params.get("profileId");
  const [time, setTime] = useState(() => {
    const savedTime = sessionStorage.getItem("time");
    return savedTime !== null ? parseInt(savedTime, 10) : 30;
  });

  const handleVerifyEmail = () => {
    verifyEmail(
      { id: verificationId, code: otp },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          navigate(`/register-entity?profileId=${res.profile_uuid}`);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleResendVerifyEmail = () => {
    setResendTime((prev) => prev + 1);
    resendVerifyEmail(
      { id: profileId },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setVerificationId(res.verificiation_id);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    const id = sessionStorage.getItem("verificationId");
    setVerificationId(id);
  }, []);

  useEffect(() => {
    sessionStorage.setItem("time", time);
    if (time === 0) {
      return;
    }

    const intervalId = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [time]);

  if (verifyEmailLoading || resendVerifyEmailLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full min-h-screen container flex lg:items-center lg:justify-center  lg:bg-[#F7F7F7]">
      <div className="w-full lg:max-w-md flex flex-col md:justify-center md:items-center bg-white rounded-[20px] p-5 lg:p-10 font-poppins ">
        <div className="flex flex-col w-full  mb-5">
          <Link to="/sign-up" className="w-fit mx-auto">
            <img
              src="/new-logo-with-sidetext.svg"
              alt="logo"
              className="object-contain w-auto"
            />
          </Link>
          <h1 className="text-[22px] leading-8 md:-tracking-[0.5px] font-semibold text-[#4a4a4a] mt-[20px] ">
            Verify your email address
          </h1>
          <p className="text-[#4a4a4a] text-sm mt-1">
            To start using MagicReview, confirm your email <br /> address with
            the code we sent to <span className="font-medium">{email}</span>.
          </p>
        </div>

        <div className="w-full my-5">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
            containerStyle={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              columnGap: "5px",
            }}
            inputStyle={{
              width: isMobile ? "35px" : "50px",
              height: isMobile ? "35px" : "50px",
              borderRadius: "8px",
            }}
          />
        </div>
        <button
          type="submit"
          disabled={otp.length < 6}
          className="bg-newPrimary border border-newPrimary  text-white py-2.5 rounded-full mt-3 transition-all duration-300  font-montserrat text-base font-medium active:scale-95 w-full"
          onClick={handleVerifyEmail}
        >
          Verify Code
        </button>
        <div className="flex items-center gap-x-1 text-[14px] leading-7 mt-5 font-poppins">
          {time === 0 ? (
            <button
              className=" text-[#2a49b8] cursor-pointer flex items-center gap-1 text-sm font-medium"
              onClick={() => {
                setTime(30);
                handleResendVerifyEmail();
              }}
              disabled={resendTime >= 3}
            >
              <RefreshCcw className="size-5" />
              Resend link
            </button>
          ) : (
            <p className="text-[#2a49b8]  flex items-center gap-1 text-sm font-medium cursor-not-allowed">
              <RefreshCcw className="size-5 text-[#888]" />{" "}
              <span className="text-[#888]">Resend link in</span> {time}s
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
