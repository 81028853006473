const IconLock = (props) => {
  return (
    <svg
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.25">
        <path
          d="M6.50067 1.12109C6.82445 1.12109 7.14506 1.18487 7.44419 1.30877C7.74332 1.43267 8.01511 1.61428 8.24406 1.84323C8.473 2.07217 8.65461 2.34397 8.77851 2.6431C8.90242 2.94223 8.96619 3.26283 8.96619 3.58661V5.60385H4.03516V3.58661C4.03516 2.93272 4.29492 2.3056 4.75729 1.84323C5.21966 1.38085 5.84678 1.12109 6.50067 1.12109Z"
          stroke="black"
          strokeWidth="0.896552"
          strokeMiterlimit="10"
        />
        <path
          d="M2.68952 5.1543H10.3102C10.548 5.1543 10.776 5.24875 10.9442 5.41689C11.1123 5.58503 11.2068 5.81307 11.2068 6.05085V10.9819C11.2068 11.2197 11.1123 11.4477 10.9442 11.6158C10.776 11.784 10.548 11.8784 10.3102 11.8784H2.68952C2.45174 11.8784 2.2237 11.784 2.05556 11.6158C1.88743 11.4477 1.79297 11.2197 1.79297 10.9819V6.05085C1.79297 5.81307 1.88743 5.58503 2.05556 5.41689C2.2237 5.24875 2.45174 5.1543 2.68952 5.1543Z"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default IconLock;
