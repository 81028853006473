/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useRef, useState } from "react";
import {
  usePostAddAiQuestions,
  usePostGenerateAiQuestions,
  usePostGenerateAiQuestionsByVoice,
} from "../../../query/useEntity";
import { toast } from "sonner";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import QuestionCardForAiQuestions from "./QuestionCardForAiQuestions";
import { useQueryClient } from "@tanstack/react-query";
import {
  Sparkles,
  Info,
  MicrochipIcon,
  Mic,
  Play,
  Pause,
  Square,
  RotateCcw,
} from "lucide-react";
import { useSearchParams } from "react-router-dom";
import { useGetQuestionSets } from "../../../query/useEntity.js";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog.jsx";
import { Button } from "../../../components/ui/button.jsx";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import AudioPlayer from "../../generate/component/AudioPlayer.jsx";
import { Textarea } from "../../../components/ui/textarea.jsx";

const AIQuestions = ({ questionSet }) => {
  const [tab, setTab] = useState(1);
  const [isSupportWebSpeech, setIsSupportWebSpeech] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [recognition, setRecognition] = useState(null);
  const [aboutCompany, setAboutCompany] = useState("");
  const [catalog, setCatalog] = useState("");
  const [instructions, setInstructions] = useState("");
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState();
  const [audioFile, setAudioFile] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const recorderControls = useVoiceVisualizer();

  const {
    recordedBlob,
    error,
    startRecording,
    stopRecording,
    togglePauseResume,
    clearCanvas,
    formattedDuration,
    formattedRecordedAudioCurrentTime,
    formattedRecordingTime,
    isRecordingInProgress,
    isAvailableRecordedAudio,
    isPausedRecording,
    isPausedRecordedAudio,
    recordingTime,
    duration,
  } = recorderControls;

  const queryClient = useQueryClient();
  const { data: questionSets } = useGetQuestionSets();

  const { mutate: generateAiQuestions, isLoading: generateAiQuestionsLoading } =
    usePostGenerateAiQuestions();

  const {
    mutate: generateAiQuestionsByVoice,
    isLoading: generateAiQuestionsByVoiceLoading,
  } = usePostGenerateAiQuestionsByVoice();

  const { mutate: addAiQuestions, isLoading: addAiQuestionsLoading } =
    usePostAddAiQuestions();

  const handleGenerateAiQuestions = () => {
    generateAiQuestions(
      {
        // about: aboutCompany,
        catalog: catalog,
        misc_instructions: instructions || null,
      },
      {
        onSuccess: (res) => {
          toast.success("Generated AI assissted questions successfully");
          setGeneratedQuestions(res.suggestions);
          setOpenAddModal(true);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleGenerateAiQuestionsByVoice = () => {
    const formData = new FormData();
    formData.append("audio", audioFile);
    generateAiQuestionsByVoice(formData, {
      onSuccess: (res) => {
        toast.success("Generated AI assissted questions successfully");
        setGeneratedQuestions(res.suggestions);
        setOpenAddModal(true);
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddAiQuestions = () => {
    const modifiedQuestions = selectedQuestions?.map((item) => {
      return {
        question: item?.question,
        response_options: item?.possible_responses,
        category: item?.category,
        keyword: item?.keyword,
      };
    });
    addAiQuestions(
      {
        set: selectedQuestionSet,
        data: {
          ai_questions: modifiedQuestions,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenAddModal(false);
          queryClient.invalidateQueries({ queryKey: ["getDefaultQuestions"] });
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestionsForSets"],
          });
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  // Function to toggle individual selection
  const toggleSelection = (item) => {
    setSelectedQuestions((prev) => {
      const current = [...prev];
      if (current.some((e) => e?.question === item?.question)) {
        return current.filter((e) => e?.question !== item?.question);
      } else {
        return [...prev, item];
      }
    });
  };

  // Function to toggle "Select All"
  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedQuestions([]); // Deselect all
    } else {
      setSelectedQuestions(generatedQuestions); // Select all
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    setSelectedQuestionSet(questionSet);
  }, []);

  useEffect(() => {
    // Check for browser support
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (SpeechRecognition) {
      const recog = new SpeechRecognition();
      recog.continuous = false; // Keep listening until stopped
      recog.interimResults = true; // Get interim results for better UX

      recog.onresult = (event) => {
        let finalTranscript = "";
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const result = event.results[i];
          finalTranscript += result.isFinal ? result[0].transcript : "";
        }
        setCatalog(finalTranscript);
        setIsListening(false);
      };

      recog.onerror = (event) => {
        console.error("Speech recognition error", event);
      };

      setIsSupportWebSpeech(true);
      setRecognition(recog);
    } else {
      setIsSupportWebSpeech(false);
      console.error("Speech Recognition API not supported in this browser.");
    }
  }, []);

  useEffect(() => {
    if (!recordedBlob) {
      setAudioFile();
      setAudioUrl();
      return;
    }
    setAudioFile(recordedBlob);

    const audioUrl = URL.createObjectURL(recordedBlob);
    setAudioUrl(audioUrl);

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl);
      }
    };
  }, [recordedBlob, error]);

  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  useEffect(() => {
    if (recordingTime > 15000) {
      stopRecording();
    }
  }, [recordingTime]);

  // if (
  //   addAiQuestionsLoading ||
  //   generateAiQuestionsLoading ||
  //   generateAiQuestionsByVoiceLoading
  // ) {
  //   return <LogoLoading />;
  // }

  return (
    <>
      <div className="w-full border border-newPrimary rounded-[12px] overflow-hidden">
        <div className="flex gap-3 items-center px-5 py-2 bg-newPrimary text-white text-sm">
          <Sparkles className="size-[18px]" /> AI Question Generator
        </div>

        <div className="py-5 px-5 space-y-5">
          <div className="border border-newPrimary dark:bg-darkBg dark:border-none p-4 bg-[#7190FF]/15 rounded-[7px]">
            <div className="flex gap-2 mb-3">
              <Info size={20} color="#4f75fa" className=" shrink-0" />
              <h1 className="text-[15px] text-[#252323] dark:text-white font-semibold font-pro">
                For Best Results
              </h1>
            </div>

            <p className="text-[13px] text-[#4A4A4A] dark:text-white">
              Provide as much detail as possible. Mention your audience if
              relevant. Describe the context of your form (e.g if it&apos;s an
              event registration form, mention details like event date, name of
              the event.)
            </p>
          </div>

          <div className="flex w-full md:flex-row flex-col justify-between gap-5">
            <div className=" flex flex-col w-full font-pro">
              <h1 className="text-[#252323] dark:text-white  text-base mb-3">
                Describe the question you have in mind
              </h1>

              <div className="relative">
                <Textarea
                  className="border border-[#EBEBEB] px-3 py-3 rounded-lg focus:outline-none focus:ring-1 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkBg dark:border-none resize-none text-sm placeholder:text-sm"
                  value={catalog}
                  onChange={(e) => {
                    if (audioFile || audioUrl) {
                      setAudioFile();
                      setAudioUrl();
                    }
                    setCatalog(e.target.value);
                  }}
                  placeholder="e.g A form for my potential customers to request a demo of my product"
                  rows={4}
                />
              </div>

              <p className="text-center mt-3">OR</p>

              <div className="w-full">
                <div className="flex items-center justify-between mb-3">
                  <p className="text-center font-heebo font-medium">
                    {/* {!audioFile && !isRecordingInProgress && "Record Audio"} */}
                    {isRecordingInProgress && "Recording..."}
                    {audioFile && "Recorded Audio"}
                  </p>
                  <div className=" font-heebo text-[#808080] flex items-center gap-x-2">
                    {/* {!audioFile && !isRecordingInProgress && "00:00"} */}
                    {(isRecordingInProgress || audioFile) && (
                      <div
                        className={`flex-shrink-0 size-2 rounded-full bg-red-500 ${isRecordingInProgress && "animate-pulse"}`}
                      ></div>
                    )}
                    {isRecordingInProgress && <p>{formattedRecordingTime}</p>}
                    {audioFile && audioFile && <p>{formattedDuration}</p>}
                  </div>
                </div>

                {(isRecordingInProgress || audioFile) && (
                  <div className="max-w-sm mx-auto">
                    <VoiceVisualizer
                      controls={recorderControls}
                      isControlPanelShown={false}
                      mainBarColor="#5B7FFA"
                      secondaryBarColor="#7190FF"
                      barWidth={3}
                      gap={3}
                      height={50}
                    />
                  </div>
                )}
                {audioUrl && isAvailableRecordedAudio && (
                  <AudioPlayer src={audioUrl} duration={duration} />
                )}
              </div>
              <div className="flex items-center justify-between gap-3 mt-3 font-heebo text-sm">
                {isRecordingInProgress ? (
                  <>
                    <button
                      className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-10"
                      onClick={togglePauseResume}
                    >
                      {isPausedRecording ? (
                        <Play className="size-4" />
                      ) : (
                        <Pause className="size-4" />
                      )}
                      {isPausedRecording ? "Resume" : "Pause"}
                    </button>
                    <button
                      className="w-full bg-[#ff6464]/20 text-[#ff6464] border border-[#ff6464] rounded-md flex items-center justify-center gap-x-2 h-10"
                      onClick={() => {
                        stopRecording();
                      }}
                    >
                      <Square className="size-4" />
                      Stop
                    </button>
                  </>
                ) : (
                  <>
                    {audioFile ? (
                      <button
                        className="w-full bg-[#99afff]/20 text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-10"
                        onClick={() => {
                          // clearCanvas();
                          setAudioFile();
                          setAudioUrl();
                          startRecording();
                        }}
                      >
                        <RotateCcw className="size-4" />
                        Record Again
                      </button>
                    ) : (
                      <button
                        className="w-full bg-white  text-secondary border border-secondary rounded-md flex items-center justify-center gap-x-2 h-10"
                        onClick={() => {
                          setCatalog("");
                          startRecording();
                        }}
                      >
                        Record
                      </button>
                    )}
                  </>
                )}
              </div>
              <Button
                className=" mt-3"
                onClick={() => {
                  catalog
                    ? handleGenerateAiQuestions()
                    : handleGenerateAiQuestionsByVoice();
                }}
                disabled={
                  !(catalog || audioUrl) ||
                  generateAiQuestionsLoading ||
                  generateAiQuestionsByVoiceLoading
                }
              >
                {generateAiQuestionsLoading ||
                generateAiQuestionsByVoiceLoading ? (
                  <LoadingButton />
                ) : (
                  "Generate"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={openAddModal}
        onOpenChange={() => {
          setOpenAddModal(false);
        }}
      >
        <DialogContent
          className={`${generatedQuestions?.length > 0 ? "lg:max-w-4xl" : "lg:max-w-xl"}`}
        >
          {!generatedQuestions?.length > 0 && (
            <DialogHeader>
              <DialogTitle className="text-[20px] font-medium font-pro ">
                Generate custom questions with AI
              </DialogTitle>
            </DialogHeader>
          )}

          <div className="flex flex-col scrollbar-none font-pro px-6">
            {generatedQuestions?.length > 0 && (
              <div className="max-h-[90vh] space-y-3 w-full p-1 pt-6">
                <div className="flex sm:flex-row flex-col gap-2 items-center justify-between mb-6">
                  <div className="flet lg:flex-row flex-col lg:items-center lg:justify-start w-full">
                    <p className="text-lg text-[#252323] dark:text-white font-medium font-pro mb-3">
                      Question Set
                    </p>
                    <Select
                      value={selectedQuestionSet}
                      onValueChange={(item) => setSelectedQuestionSet(item)}
                    >
                      <SelectTrigger className="max-w-sm">
                        <SelectValue placeholder="Select question set" />
                      </SelectTrigger>
                      <SelectContent>
                        {questionSets?.map((item) => (
                          <SelectItem key={item?.id} value={item?.id}>
                            {item?.name === "default" ? "Default" : item?.name}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <button
                    className="px-4 py-2 bg-newPrimary text-white rounded-lg flex-shrink-0"
                    onClick={toggleSelectAll}
                  >
                    {selectAll ? "Deselect All" : "Select All"}
                  </button>
                </div>

                <div className="overflow-y-auto scrollbar-thin h-[50vh] space-y-3">
                  {generatedQuestions?.map((item, i) => (
                    <div
                      key={item?.question}
                      className="flex items-center gap-3 w-full cursor-pointer"
                      onClick={() => toggleSelection(item)}
                    >
                      <label
                        htmlFor={item?.question}
                        className="w-full cursor-pointer"
                      >
                        <QuestionCardForAiQuestions
                          item={item}
                          questionIndex={i}
                          selected={selectedQuestions.some(
                            (e) => e?.question === item?.question
                          )}
                        />
                      </label>
                    </div>
                  ))}
                </div>

                <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end pt-5">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      setOpenAddModal(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      handleAddAiQuestions();
                    }}
                  >
                    {addAiQuestionsLoading ? (
                      <LoadingButton />
                    ) : (
                      "Add to your questions"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AIQuestions;
