/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router";

const tabs = ["Instant Reviews", "Top SEO Ranking"];

export const StickyScroll = ({ content, contentClassName }) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const ref = useRef(null);
  const navigate = useNavigate();
  const { scrollYProgress } = useScroll({
    // uncomment line 22 and comment line 23 if you DONT want the overflow container and want to have it change on the entire page scroll
    // target: ref
    container: ref,
    offset: ["start start", "end start"],
  });
  const cardLength = content.length;

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const cardsBreakpoints = content.map((_, index) => index / cardLength);
    const closestBreakpointIndex = cardsBreakpoints.reduce(
      (acc, breakpoint, index) => {
        const distance = Math.abs(latest - breakpoint);
        if (distance < Math.abs(latest - cardsBreakpoints[acc])) {
          return index;
        }
        return acc;
      },
      0
    );
    setActiveCard(closestBreakpointIndex);
  });

  const backgroundColors = [
    "var(--slate-900)",
    "var(--black)",
    "var(--neutral-900)",
  ];
  const linearGradients = [
    "linear-gradient(to bottom right, var(--cyan-500), var(--emerald-500))",
    "linear-gradient(to bottom right, var(--pink-500), var(--indigo-500))",
    "linear-gradient(to bottom right, var(--orange-500), var(--yellow-500))",
  ];

  const [backgroundGradient, setBackgroundGradient] = useState(
    linearGradients[0]
  );

  useEffect(() => {
    setBackgroundGradient(linearGradients[activeCard % linearGradients.length]);
  }, [activeCard]);

  return (
    <>
      <div className="flex p-1.5 w-full max-w-lg mx-auto  bg-white border border-[#b2c3ff] rounded-full">
        {tabs.map((tab, i) => (
          <button
            key={i}
            onClick={() => {
              // const element = document.getElementById(`tab-${i}`);
              // element?.scrollIntoView({
              //   behavior: "smooth",
              //   inline: "end",
              //   block: "center",
              // });
              // setActiveCard(i);
            }}
            className={cn(
              "flex-1 py-2 px-4 rounded-full transition-colors duration-200 font-poppins text-base font-normal text-[#252323] lg:px-10",
              activeCard === i
                ? "bg-[#f1f4ff] shadow-sm"
                : "bg-white hover:bg-white/50"
            )}
          >
            {tab}
          </button>
        ))}
      </div>
      <motion.div
        animate={{
          backgroundColor:
            backgroundColors[activeCard % backgroundColors.length],
        }}
        className="lg:h-[30rem] h-[20rem] overflow-y-auto flex justify-center relative space-x-10 rounded-md scrollbar-none"
        ref={ref}
      >
        <div
          style={{ background: backgroundGradient }}
          className={cn(
            "hidden lg:block w-full rounded-md bg-white sticky top-10 overflow-hidden lg:w-1/2",
            contentClassName
          )}
        >
          {content[activeCard].content ?? null}
        </div>
        <div className="div relative flex items-start px-4 lg:w-[42%]">
          <div className="">
            {content.map((item, index) => (
              <div
                key={item.title + index}
                className="lg:my-[120px] my-10 space-y-5"
                id={`tab-${index}`}
              >
                <motion.h2
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 0.3,
                  }}
                  className="font-semibold text-[24px] md:text-[32px] leading-tight text-[#333333]"
                >
                  {item.title}
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 0.3,
                  }}
                  className="tracking-wide text-[14px] md:text-base text-[#2D2D2D]"
                >
                  {item.description}
                </motion.p>
                <motion.button
                  className="flex items-center font-medium text-sm h-11 gap-2 px-6 py-3 text-white bg-newPrimary rounded-lg border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white font-poppins"
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 0.3,
                  }}
                  onClick={() => navigate("/sign-up")}
                >
                  Get Started <ChevronRight className="size-5" />
                </motion.button>
              </div>
            ))}
            <div className="lg:h-10" />
          </div>
        </div>
      </motion.div>
    </>
  );
};
