import { Helmet } from "react-helmet";
import MainNav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";

const Schedule = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <Helmet>
        <title>Schedule A Call - Magicreview</title>
        <meta
          name="description"
          content="Improve feedback collection: Use magicreview to simplify, engage, and grow. Empower your business for digital excellence. Contact us today!"
        />
        <link
          rel="canonical"
          href="https://www.magicreview.ai/schedule-a-call"
        />
      </Helmet>

      <div className="relative bg-white h-auto">
        <MainNav />
        <div className="w-full container flex flex-row flex-wrap items-center justify-center mt-10 mb-10 md:mt-16 xl:mt-24 md:mb-32 gap-9 md:gap-0">
          <iframe
            id="myFrame"
            src="https://calendar.app.google/6pSsfiDfwuZykj2UA"
            frameBorder="0"
            className="w-full landscape:min-h-[240svh] portrait:min-h-svh md:landscape:min-h-[180svh] md:portrait:min-h-svh"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Schedule;
