import React from "react";
import ProductHero from "../../components/products/ProductHero";
import MainNav from "../../components/mainNavbar/MainNav";
import ProductKeyFeatures from "../../components/products/ProductKeyFeatures";
import ProductBenefits from "../../components/products/ProductBenefits";
import { IconWrapper } from "./ResponderAgent";
import { ChartColumn, ListTree, MapPin } from "lucide-react";
import PricingComponent from "../pricing/PricingComponent";
import Footer from "../../components/footer/footer";

const KeyInsightsProduct = () => {
  return (
    <div className="bg-white">
      <MainNav />
      <div className="mt-[1.2rem] lg:mt-[3.5rem] 2xl:mt-[7.5rem] relative text-black">
        <ProductHero
          title={"Turn Customer Feedback\ninto Winning Strategies"}
          content={
            "Understanding your customers has never been this simple. MagicReview’s Key Insights analyzes your reviews and provides a comprehensive sentiment score Gain actionable insights to refine your offerings, enhance customer satisfaction, and stay ahead of the competition."
          }
          badge={"Key Insights"}
          imgUrl={"/products/key-hero.png"}
        />
        <div className="bg-gradient-to-b from-[#f1f4ff] via-30% via-white to-white container">
          <ProductKeyFeatures
            contents={[
              {
                title: "AI-Driven Sentiment Analysis",
                description:
                  "Instantly gauge whether customer reviews are positive, negative, or neutral, helping you measure brand reputation in real time.",
                icon: (
                  <IconWrapper color={"#753EE9"}>
                    <ChartColumn className="text-[#753EE9] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Categorized Customer Insights",
                description:
                  "Identify trends in customer feedback, including product improvements, feature requests, and competitor comparisons.",
                icon: (
                  <IconWrapper color={"#FF6464"}>
                    <ListTree className="text-[#FF6464] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Multi-Location and Performance Benchmarking",
                description:
                  "Track sentiment and review trends across multiple business locations, ensuring consistent customer experience and service quality.",
                icon: (
                  <IconWrapper color={"#2A49B8"}>
                    <MapPin className="text-[#2A49B8] size-10" />
                  </IconWrapper>
                ),
              },
            ]}
          />
          <ProductBenefits
            title={"How Key Insights\n Strengthens Your Reputation"}
            description={
              "Monitor keyword performance, track competitors, and enhance visibility with AI-driven SEO insights."
            }
            contents={[
              {
                title: "Data-Driven Decision Making",
                description:
                  "AI extracts actionable insights from customer reviews, helping businesses spot trends and make data-driven improvements.",
                img: (
                  <img
                    src="/products/key1.png"
                    className="lg:w-[80%] lg:mr-0 lg:ml-auto"
                  />
                ),
              },
              {
                title: "Uncover Strengths & Address Weaknesses",
                description:
                  "Identify customer likes, dislikes, and feature requests to enhance services based on actual customer experiences.",
                img: (
                  <img
                    src="/products/key2.png"
                    className="lg:w-[80%] lg:mr-0 lg:ml-auto"
                  />
                ),
              },
              {
                title:
                  "Boost Ratings & Reputation by Proactively Addressing Issues",
                description:
                  "AI flags common complaints, enabling proactive issue resolution leading to higher ratings and improved customer satisfaction.",
                img: (
                  <img
                    src="/products/key3.png"
                    className="lg:w-[80%] lg:mx-auto"
                  />
                ),
              },
              {
                title: "Stay Ahead with Competitor Benchmarking",
                description:
                  "Compare sentiment with competitors to uncover trends, gaps, and refine your business strategy to gain a competitive edge.",
                img: (
                  <img
                    src="/products/key4.png"
                    className="lg:w-[80%] lg:mx-auto"
                  />
                ),
              },
            ]}
          />
        </div>
        <PricingComponent />
      </div>
      <Footer />
    </div>
  );
};

export default KeyInsightsProduct;
