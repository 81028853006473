/* eslint-disable react/prop-types */
import React from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { AudioLines, ChevronDown, Text } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import { cn } from "../../../../utils/cn";
import { Slider } from "../../../../components/ui/slider";
import { Input } from "../../../../components/ui/input";

const ToneAndLength = ({
  tone,
  setTone,
  length,
  setLength,
  input,
  setInput,
}) => {
  return (
    <div className="flex items-center gap-3 flex-wrap lg:flex-nowrap">
      {/* <Input
        className="h-9"
        placeholder="AI input"
        value={input}
        onChange={(e) => setInput(e.target.value)}
      /> */}
      <Select
        value={tone}
        onValueChange={(e) => {
          setTone(e);
        }}
      >
        <SelectTrigger className="w-[140px] rounded-[8px] h-8 flex-shrink-0 font-poppins font-medium text-xs">
          <AudioLines className="size-[18px]" />
          <SelectValue placeholder="Select Tone" />
        </SelectTrigger>
        <SelectContent>
          {[
            "Formal",
            "Informal",
            "Polite",
            "Friendly",
            "Enthusiastic",
            "Neutral",
            "Casual",
            "Professional",
            "Optimistic",
            "Reassuring",
          ].map((item) => (
            <SelectItem
              key={item}
              value={item}
              className="font-poppins font-medium text-xs"
            >
              {item}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Popover>
        <PopoverTrigger
          className={cn(
            "flex h-8  items-center justify-between rounded-md border border-input bg-background px-3 py-2 ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-0  disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1  dark:bg-darkBg w-[150px] flex-shrink-0 font-poppins font-medium text-xs"
          )}
        >
          <Text className="size-[18px]" />
          Length - {length}
          <ChevronDown className="h-4 w-4 opacity-50" />
        </PopoverTrigger>
        <PopoverContent>
          <Slider
            value={[length]}
            min={50}
            max={250}
            step={50}
            onValueChange={(e) => {
              setLength(e);
            }}
          />
          <div className="w-full flex justify-between mt-2 font-poppins">
            {[50, 100, 150, 200, 250].map((item) => (
              <span
                className="text-[10px] cursor-pointer"
                key={item}
                onClick={() => {
                  setLength([item]);
                }}
              >
                {item}
              </span>
            ))}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default ToneAndLength;
