import style from "./GetStarted.module.scss";
import Mainnav from "../../components/mainNavbar/MainNav";
// import { usePostUpdatePaymentStatus } from "../../query/usePayment";
import { useEffect } from "react";
import { useNavigate } from "react-router";
const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/dashboard");
    }, 5000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <div className={`w-full flex flex-col h-screen ${style.getstarted}`}>
      <Mainnav />
      <div className="h-[calc(100vh-200px)] md:h-[calc(100vh-100px)] flex justify-center items-center">
        <div
          className={`w-[90%] md:w-[60%] pb-9 m-auto flex flex-col justify-center items-center bg-[#FFF] rounded-3xl ${style.maincont}`}
          style={{
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          }}
        >
          <img
            loading="lazy"
            src="./phone.png"
            alt=""
            className={` max-w-[300px] w-full`}
          />
          <h1
            className="
      text-2xl md:text-4xl text-[#21243D] font-bold mt-3 text-center
      "
          >
            Thank you for your payment.
          </h1>
          {/* <p className=" text-[#21243D] text-sm md:text-xl mt-3 text-center ">
          We appreciate your business and look forward to serving you.
        </p> */}
          {/* <button className="mt-3 bg-[#4F46BA] text-white py-4 px-8 rounded-lg flex">
          Go to Dashboard
          <img loading="lazy"src="./arrow.svg" alt="" className="ml-3" />
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default Success;
