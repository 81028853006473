/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { CalendarDays } from "lucide-react";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useGetEmployeesStats } from "../../../../query/useEmployees";
import { toast } from "sonner";
import { LogoLoading } from "../../../../components/loading/loading";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
} from "../../../../components/ui/dialog";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Label } from "../../../../components/ui/label";
import { Button } from "../../../../components/ui/button";
import {
  useGetDownloadReviewsNegative,
  useGetDownloadReviewsNonPosted,
  useGetDownloadReviewsPosted,
} from "../../../../query/useReview";

const DateInput = forwardRef(({ value, onClick }, ref) => (
  <div className="relative w-full" onClick={onClick} ref={ref}>
    <input
      type="text"
      value={value}
      readOnly
      className="w-full border border-[#ebebeb] dark:border-[#454545] rounded px-5 py-3 dark:bg-darkLightBg"
      placeholder="Select"
    />
    <CalendarDays className="text-[#ebebeb] absolute right-5 top-1/2 -translate-y-1/2" />
  </div>
));

const DownloadStatsRedirectedModal = ({
  openModal,
  setOpenModal,
  employeeCode,
  tab,
}) => {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(new Date());

  const { mutate: downloadPosted, isLoading: downloadPostedLoading } =
    useGetDownloadReviewsPosted();

  const { mutate: downloadNonPosted, isLoading: downloadNonPostedLoading } =
    useGetDownloadReviewsNonPosted();

  const { mutate: downloadNegative, isLoading: downloadNegativeLoading } =
    useGetDownloadReviewsNegative();

  const handleClick = () => {
    if (tab === "redirected") {
      downloadPosted(
        {
          from_time: startDate ? startDate?.toISOString() : null,
          to_time: endDate ? endDate?.toISOString() : null,
          employee_code: employeeCode,
        },
        {
          onSuccess: (res) => {
            const blob = new Blob([res], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Stats ${startDate?.toLocaleDateString("en-GB") || ""} - ${endDate?.toLocaleDateString("en-GB") || ""}.csv`;
            link.click();
            toast.success("Stats downloaded successfully");
            setStartDate();
            setEndDate(new Date());
            setOpenModal(false);
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else if (tab === "non-redirected") {
      downloadNonPosted(
        {
          from_time: startDate ? startDate?.toISOString() : null,
          to_time: endDate ? endDate?.toISOString() : null,
          employee_code: employeeCode,
        },
        {
          onSuccess: (res) => {
            const blob = new Blob([res], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Stats ${startDate?.toLocaleDateString("en-GB") || ""} - ${endDate?.toLocaleDateString("en-GB") || ""}.csv`;
            link.click();
            toast.success("Stats downloaded successfully");
            setStartDate();
            setEndDate(new Date());
            setOpenModal(false);
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      downloadNegative(
        {
          from_time: startDate ? startDate?.toISOString() : null,
          to_time: endDate ? endDate?.toISOString() : null,
          employee_code: employeeCode,
        },
        {
          onSuccess: (res) => {
            const blob = new Blob([res], { type: "text/csv" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Stats ${startDate?.toLocaleDateString("en-GB") || ""} - ${endDate?.toLocaleDateString("en-GB") || ""}.csv`;
            link.click();
            toast.success("Stats downloaded successfully");
            setStartDate();
            setEndDate(new Date());
            setOpenModal(false);
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  if (
    downloadPostedLoading ||
    downloadNonPostedLoading ||
    downloadNegativeLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      // dismissible
      open={openModal}
      onOpenChange={() => {
        setOpenModal(false);
        setStartDate();
        setEndDate(new Date());
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Magiclinks stats</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <div className="px-2 font-pro space-y-5 dark:text-[#bfbfbf]">
            <div className="flex items-center gap-5">
              <div className="flex flex-col gap-3">
                <p>From</p>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  customInput={<DateInput />}
                  maxDate={new Date()}
                />
              </div>
              <div className="flex flex-col gap-3">
                <p>To</p>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  customInput={<DateInput />}
                  maxDate={new Date()}
                />
              </div>
            </div>
            <div className="flex justify-center items-center gap-x-3 mt-7">
              <Button
                // className="bg-white dark:bg-darkLightBg w-full py-3 rounded-[7px] text-newPrimary border border-newPrimary"
                variant="secondary"
                className="w-full"
                onClick={() => {
                  setOpenModal(false);
                  setStartDate();
                  setEndDate(new Date());
                }}
              >
                Cancel
              </Button>
              <Button
                // className="bg-newPrimary w-full py-3 rounded-[7px] text-white border border-newPrimary hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-200"
                className="w-full"
                onClick={handleClick}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadStatsRedirectedModal;
