/* eslint-disable react/prop-types */
import { FilePlus, X } from "lucide-react";
import React, { useState } from "react";
import { Button } from "../../../components/ui/button";

const ImageDragAndDrop = ({ image, setImage, className }) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (file && file.size < MAX_FILE_SIZE) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setError(null); // Clear any previous error
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller image.");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size < MAX_FILE_SIZE) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
      setError(null); // Clear any previous error
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller image.");
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full py-5 ">
      <div
        className={`w-80 h-80 border-4 relative ${
          dragActive ? "border-blue-500" : "border-gray-300"
        } border-dashed flex items-center justify-center rounded-lg ${className}`}
        onDragEnter={handleDrag}
        onDragOver={handleDrag}
        onDragLeave={handleDrag}
        onDrop={handleDrop}
      >
        {imagePreview || image ? (
          <img
            src={imagePreview ? imagePreview : image}
            alt="Uploaded"
            className="w-full h-full object-cover rounded-lg"
          />
        ) : (
          <label
            htmlFor="upload"
            className="flex flex-col items-center justify-center text-center cursor-pointer"
          >
            <FilePlus className="text-gray-400 mb-1" />
            <span className="text-gray-400">
              Drag and drop an image, or click to select
            </span>
            <input
              type="file"
              id="upload"
              className="hidden"
              accept="image/*"
              onChange={handleChange}
            />
          </label>
        )}

        {(imagePreview || image) && (
          <Button
            className="absolute top-2 right-2 w-6 h-6"
            shape="pill"
            size="icon"
            onClick={() => {
              setImage(null);
              setImagePreview(null);
            }}
          >
            <X className="w-4 h-4" />
          </Button>
        )}
      </div>
      {error && <p className="text-red-500 mt-2 text-xs">{error}</p>}
    </div>
  );
};

export default ImageDragAndDrop;
