/* eslint-disable react/prop-types */
import { AnimatePresence, motion } from "framer-motion";
import {
  ChartColumn,
  ChartNoAxesCombined,
  ChartPie,
  Lightbulb,
  Smile,
  ThumbsDown,
  ThumbsUp,
  TrendingUp,
} from "lucide-react";
import { useState } from "react";

const CardComponent = ({ title, description, children }) => {
  return (
    <div
      className="bg-[#f1f4ff] border border-[#ccd7ff] w-full
   h-full rounded-[18px] p-5 font-poppins z-20 relative"
    >
      <div className="space-y-2.5">
        <p className="font-semibold text-xl">{title}</p>
        <p className="text-sm text-[#2d2d2d] capitalize">{description}</p>
      </div>
      {children}
    </div>
  );
};

const ReputationComponent = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[29%_34%_34%] w-full gap-5 mt-16">
      <motion.div
        className="relative group p-1 h-full w-full"
        onMouseEnter={() => setHoveredIndex("responder")}
        onMouseLeave={() => setHoveredIndex(null)}
        initial={{
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.4,
          ease: "easeInOut",
        }}
        viewport={{
          once: true,
        }}
      >
        <CardComponent
          title={"Responder Agent"}
          description={
            "Auto-reply to Google reviews with customizable tone and response conditions."
          }
        >
          <div className="mt-3 flex flex-col justify-center items-center gap-2.5">
            <img
              src="/magicreview/home1.png"
              alt=""
              className="w-[80%] shadow-light rounded-lg overflow-hidden"
            />
            <img
              src="/magicreview/home2.png"
              alt=""
              className="w-[90%] shadow-light rounded-xl overflow-hidden"
            />
            <img
              src="/magicreview/home3.png"
              alt=""
              className="rounded-2xl shadow-light overflow-hidden"
            />
          </div>
        </CardComponent>
        <AnimatePresence>
          {hoveredIndex === "responder" && (
            <motion.span
              className="absolute inset-0 h-full w-full bg-newPrimary dark:bg-slate-800/[0.8] block rounded-[20px] z-0"
              layoutId="hoverBackground"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.15 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.15, delay: 0.2 },
              }}
            />
          )}
        </AnimatePresence>
      </motion.div>
      <div className="flex flex-col gap-5">
        <motion.div
          className="relative group p-1 h-full w-full"
          onMouseEnter={() => setHoveredIndex("seo")}
          onMouseLeave={() => setHoveredIndex(null)}
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          <CardComponent
            title={"SEO Agent"}
            description={
              "Boost visibility with AI-generated keywords and improve local SEO."
            }
          >
            <div className="mt-7 flex justify-end">
              <img
                src="/seo-agent.png"
                alt=""
                className="shadow-light rounded-xl overflow-hidden"
              />
            </div>
          </CardComponent>
          <AnimatePresence>
            {hoveredIndex === "seo" && (
              <motion.span
                className="absolute inset-0 h-full w-full bg-newPrimary dark:bg-slate-800/[0.8] block rounded-[20px] z-0"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div
          className="relative group p-1 h-full w-full"
          onMouseEnter={() => setHoveredIndex("key")}
          onMouseLeave={() => setHoveredIndex(null)}
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.4,
          }}
          viewport={{
            once: true,
          }}
        >
          <CardComponent
            title={"Key Insights"}
            description={
              "AI-driven analysis to sentiments and customer feedback for smarter decision-making."
            }
          >
            <div className="lg:mt-7 mt-5 flex justify-center gap-4 md:flex-nowrap flex-wrap">
              {/* <div className="flex items-center gap-2">
                <div className="w-[60px] h-[60px] bg-white border border-[#ebebeb] rounded-xl flex items-center justify-center">
                  <ChartNoAxesCombined className="size-7 text-[#753EE9]" />
                </div>
              </div> */}
              <div className="flex items-center gap-2">
                <div className="size-12 bg-white border border-[#ebebeb] rounded-[10px] flex items-center justify-center shadow-light  ">
                  <TrendingUp className=" text-[#2A49B8]" />
                </div>
              </div>
              <div className="flex items-center gap-2 mt-8">
                <div className="size-12 bg-newPrimary border border-[#ebebeb] rounded-[10px]  flex items-center justify-center shadow-light">
                  <Smile className=" text-white" />
                </div>
              </div>

              <div className="flex items-center gap-2">
                <div className="size-12 bg-white border border-[#ebebeb] rounded-[10px]  flex items-center justify-center shadow-light">
                  <Lightbulb className=" text-[#FFAF19]" />
                </div>
              </div>

              {/* <div className="flex items-center gap-2">
                <div className="w-[60px] h-[60px] bg-white border border-[#ebebeb] rounded-xl flex items-center justify-center">
                  <ChartPie className="size-7 text-[#D064FF]" />
                </div>
              </div> */}
              <div className="flex items-center gap-2 mt-8">
                <div className="size-12 bg-white border border-[#ebebeb] rounded-[10px]  flex items-center justify-center shadow-light">
                  <ThumbsUp className="text-[#34A853]" />
                </div>
              </div>
              <div className="flex items-center gap-2">
                <div className="size-12 bg-white border border-[#ebebeb] rounded-[10px]  flex items-center justify-center shadow-light">
                  <ThumbsDown className=" text-[#FF6464]" />
                </div>
              </div>
              <div className="flex items-center gap-2 mt-8">
                <div className="size-12 bg-white border border-[#ebebeb] rounded-[10px]  flex items-center justify-center shadow-light">
                  <ChartColumn className=" text-[#2A49B8]" />
                </div>
              </div>
            </div>
          </CardComponent>
          <AnimatePresence>
            {hoveredIndex === "key" && (
              <motion.span
                className="absolute inset-0 h-full w-full bg-newPrimary dark:bg-slate-800/[0.8] block rounded-[20px] z-0"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
      <div className="flex flex-col gap-5">
        <motion.div
          className="relative group p-1 h-full w-full"
          onMouseEnter={() => setHoveredIndex("percent")}
          onMouseLeave={() => setHoveredIndex(null)}
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.6,
          }}
          viewport={{
            once: true,
          }}
        >
          <div className="bg-newPrimary w-full h-full rounded-[18px] py-7 px-7 font-poppins z-20 relative flex flex-col justify-between gap-5">
            <p className="text-[41px] font-semibold leading-[50px] text-white/50">
              Boost <br /> Visibility
            </p>
            <p className="text-sm leading-[22px] text-white capitalize">
              Effortlessly manage reviews, enhance search rankings, and fuel
              business growth with AI.
            </p>
          </div>
          <AnimatePresence>
            {hoveredIndex === "percent" && (
              <motion.span
                className="absolute inset-0 h-full w-full bg-[#f1f4ff] dark:bg-slate-800/[0.8] block rounded-[20px] z-0"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
        </motion.div>
        <motion.div
          className="relative group p-1 h-full w-full"
          onMouseEnter={() => setHoveredIndex("ai")}
          onMouseLeave={() => setHoveredIndex(null)}
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.8,
          }}
          viewport={{
            once: true,
          }}
        >
          <CardComponent
            title={"AI Post"}
            description={
              "Effortlessly create and schedule AI-generated content to keep your business active and engaging online."
            }
          >
            <div className="mt-5 flex justify-center items-center gap-3">
              <img
                src="/ai-post-1.png"
                alt=""
                className="w-[45%] shadow-light"
              />
              <img src="/ai-post-2.png" alt="" className="w-[45%]" />
            </div>
          </CardComponent>
          <AnimatePresence>
            {hoveredIndex === "ai" && (
              <motion.span
                className="absolute inset-0 h-full w-full bg-newPrimary dark:bg-slate-800/[0.8] block rounded-[20px] z-0"
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>
        </motion.div>
      </div>
    </div>
  );
};

export default ReputationComponent;
