/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useState, useRef } from "react";
import {
  usePostAddQuestionSet,
  usePutUpdateQuestionSet,
} from "../../../query/useEntity";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

const AddOrEditQuestionSet = ({
  isOpenModal,
  setIsOpenmodal,
  data,
  setData,
  refetchQuestionSets,
}) => {
  const [name, setName] = useState("");
  const inputRef = useRef(null);
  const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
    usePostAddQuestionSet();
  const { mutate: updateQuestionSet, isLoading: updateQuestionSetLoading } =
    usePutUpdateQuestionSet();

  const handleClick = () => {
    if (data) {
      updateQuestionSet(
        {
          id: data?.id,
          name: name,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.message);
            setIsOpenmodal(false);
            refetchQuestionSets();
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      addQuestionSet(
        {
          name: name,
        },
        {
          onSuccess: (res) => {
            toast.success(res?.message);
            setIsOpenmodal(false);
            refetchQuestionSets();
          },
          onError: (err) => {
            if (err?.response?.data?.message) {
              toast.error(err?.response?.data?.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    if (isOpenModal) {
      if (data) {
        setName(data?.name);
      }
      inputRef.current?.focus();
    }
  }, [isOpenModal, data]);

  return (
    <Dialog
      open={isOpenModal}
      onOpenChange={() => {
        setIsOpenmodal(false);
        setData();
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Question Set</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <p className="font-pro font-medium text-xl dark:text-white">Name</p>
          <input
            type="text"
            className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-5 dark:bg-darkLightBg dark:border-none dark:text-white"
            placeholder="Name"
            autoFocus
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="flex justify-end items-center gap-x-3 mt-7">
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpenmodal(false);
                setData();
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleClick}>
              {addQuestionSetLoading || updateQuestionSetLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Save"
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrEditQuestionSet;
