/* eslint-disable react/prop-types */
import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";

const ConditionInfoModal = ({ isOpen, setIsOpen }) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Know more about Conditions</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <p>
            Conditions help you customize auto-replies based on review types.
            You can tailor replies for positive, neutral, or negative reviews by
            setting:
          </p>
          <ul className="list-disc list-inside">
            <li>
              <b>Tone:</b> Friendly, Professional, Empathetic, etc.
            </li>
            <li>
              <b>Length:</b> 50 to 250.
            </li>
            <li>
              <b>Custom Instructions:</b> Add specific guidelines for replies.
            </li>
          </ul>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConditionInfoModal;
