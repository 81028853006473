import React from "react";
import { InfiniteMovingCards } from "../ui/infinite-moving-cards";

export const HomeSliderData = [
  {
    name: "Musthafa",
    role: "Marketing Manager of Lallummas Restaurant",
    description:
      'As the Marketing Manager of Lallummas Restaurant, I believe Magic Review is an excellent tool for generating reviews that can enhance both our SEO and ratings. It addresses a common issue where restaurant reviews are often short and lack detail like "good food" or "great service". This system provides a solution by encouraging more content-rich feedback. The only challenge is guiding customers through the copy-paste process, but with our team\'s support, we can easily manage it. Appreciate the innovation behind this platform it\'s truly a problem solver!',
    image: "/lallummas.png",
  },
  {
    name: "Cecille",
    role: "Primestay",
    description:
      "MagicReview is a friendly tool, Easy use and access. Primestay team didn't encounter any issues using it. Would really recommend to any company especially for Hospitality Industry.",
    image: "/primestay.svg",
  },
  {
    name: "Prasun",
    role: "Store Manager of Mila Gold and Diamonds",
    description:
      "Being a shop manager at Mila Gold and Diamonds, I strongly believe MagicReview is excellent tool for generating reviews. On other hand it makes very easy to customers to put their reviews. MagicReview Team is doing great work.",
    image: "/mila-blue.png",
  },
];

const NewHomeSlider = () => {
  return (
    <div className="w-full flex justify-between items-center mx-auto mt-16">
      <InfiniteMovingCards speed="slow">
        {HomeSliderData.map((item, index) => (
          <li
            key={index}
            className="lg:w-[750px] md:w-[600px] w-[320px] max-w-full mx-auto bg-[#f1f4ff] rounded-[5.8px] sm:rounded-[20px] flex flex-col px-[15px] md:px-[75px] py-[22px] md:py-[52px] font-poppins relative flex-grow h-auto justify-between"
          >
            <div>
              {/* <img
                src="/leftquote.svg"
                alt="leftquote"
                className="w-[28px] md:w-[72px] mb-[10px] sm:mb-[28px]"
              /> */}
              <p className="text-sm text-black w-full font-poppins font-normal mb-[26px] capitalize lg:leading-[23px] lg:tracking-[1%]">
                {item.description}
              </p>
            </div>

            <div className="flex flex-col lg:flex-row gap-3 md:gap-7 lg:items-center">
              <img src={item.image} alt="happywoman" className="w-[100px]" />
              <div className="flex flex-col">
                <p className="text-black text-[12px]  md:text-lg font-semibold">
                  {item.name}
                </p>
                <p className="text-black text-[10px]  md:text-sm font-normal">
                  {item.role}
                </p>
              </div>
            </div>
          </li>
        ))}
      </InfiniteMovingCards>
    </div>
  );
};

export default NewHomeSlider;
