import Apimanager from "./axios";

export const getCampaignApi = async () => {
  const res = await Apimanager.get(`/campaign/get_create_campaign`);
  return res.data;
};

export const getViewCampaignApi = async ({ id }) => {
  const res = await Apimanager.get(`/campaign/${id}/view_campaign`);
  return res.data;
};

export const postAddCampaignApi = async (data) => {
  const res = await Apimanager.post(`/campaign/get_create_campaign`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const postRunCampaignApi = async (id) => {
  const res = await Apimanager.post(`/campaign/${id}/run_campaign`);
  return res.data;
};

export const putEditCampaignApi = async ({ id, data }) => {
  const res = await Apimanager.put(
    `/campaign/${id}/edit_delete_campaign`,
    data
  );
  return res.data;
};

export const deleteCampaignApi = async (id) => {
  const res = await Apimanager.delete(`/campaign/${id}/edit_delete_campaign`);
  return res.data;
};
