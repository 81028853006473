export const dayFormatter = (day) => {
  return new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
    new Date(day)
  );
};

export const dayAndTimeFormatter = (day) => {
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "long",
  }).format(new Date(day));
};

export const dayFormatterLong = (day) => {
  return new Intl.DateTimeFormat("en-US", {
    dateStyle: "medium",
    timeStyle: "long",
  }).format(new Date(day));
};

export const timeFormatter = (day) => {
  return new Intl.DateTimeFormat("en-US", {
    timeStyle: "long",
  }).format(new Date(day));
};

export const nextDay = (day) => {
  // Create a new Date object from the string
  const date = new Date(day);

  // Add one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
  date.setDate(date.getDate() + 1);

  // Convert back to an ISO string
  const newDateStr = date.toISOString();

  return new Intl.DateTimeFormat("en-US", { dateStyle: "medium" }).format(
    new Date(newDateStr)
  );
};

export const numberFormatter = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};

export const timeAgo = (timestamp) => {
  const now = new Date();
  const diff = now - new Date(timestamp);

  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;
  const week = 7 * day;
  const month = 30 * day;
  const year = 365 * day;

  if (diff < minute) {
    return "Just now";
  } else if (diff < hour) {
    const minutes = Math.floor(diff / minute);
    return `${minutes} minute${minutes !== 1 ? "s" : ""} ago`;
  } else if (diff < day) {
    const hours = Math.floor(diff / hour);
    return `${hours} hour${hours !== 1 ? "s" : ""} ago`;
  } else if (diff < week) {
    const days = Math.floor(diff / day);
    return `${days} day${days !== 1 ? "s" : ""} ago`;
  } else if (diff < month) {
    const weeks = Math.floor(diff / week);
    return `${weeks} week${weeks !== 1 ? "s" : ""} ago`;
  } else if (diff < year) {
    const months = Math.floor(diff / month);
    return `${months} month${months !== 1 ? "s" : ""} ago`;
  } else {
    const years = Math.floor(diff / year);
    return `${years} year${years !== 1 ? "s" : ""} ago`;
  }
};

export const getShortMonthName = (monthNumber) => {
  const shortMonthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Check if the month number is valid
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number";
  }

  return shortMonthNames[monthNumber - 1];
};

export const timeLeft = (scheduleTime) => {
  if (!scheduleTime) return "No schedule time available";

  const now = new Date();
  const scheduledDate = new Date(scheduleTime);

  const diffInMs = scheduledDate - now;

  if (diffInMs <= 0) return "Time has passed";

  const diffInSeconds = Math.floor(diffInMs / 1000);
  const days = Math.floor(diffInSeconds / (3600 * 24));
  const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((diffInSeconds % 3600) / 60);
  const seconds = diffInSeconds % 60;

  return `${days}d ${hours}h ${minutes}m ${seconds}s`;
};
