import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";

const ViewConfigurationModal = ({
  openModal,
  setOpenModal,
  domainType,
  domainName,
}) => {
  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Custom Domain</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-3 font-pro">
          <p className="text-center text-[#4A4A4A] text-sm">
            Please visit your DNS provider and add the following{" "}
            {domainType === "domain" ? "A" : "CNAME"} record:
          </p>
          <div className="grid grid-cols-3 gap-2 place-items-center py-2 bg-[#F8F9F8] rounded-lg border border-[#ebebeb] text-sm text-[#4A4A4A]">
            {["Type", "Name", "Value"].map((item) => (
              <p
                key={item}
                className="text-[#808080] uppercase
              "
              >
                {item}
              </p>
            ))}
            <span>{domainType === "domain" ? "A" : "CNAME"}</span>
            <span>
              {domainType === "domain" ? "@" : domainName?.split(".")?.[0]}
            </span>
            <span>
              {domainType === "domain" ? "76.76.21.21" : "cname.vercel-dns.com"}
            </span>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewConfigurationModal;
