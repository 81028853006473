/* eslint-disable react/prop-types */
import { DialogTitle } from "@radix-ui/react-dialog";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../../components/ui/dialog";
import { useState } from "react";
import PasswordInput from "../../../components/input/passwordinput";
import { usePostChangePasswordSubuser } from "../../../query/useMaster";
import { toast } from "sonner";
import { Button } from "../../../components/ui/button";
import { LoadingButton } from "../../../components/loading/loading";

const ChangePasswordModal = ({ openModal, setOpenModal, id }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { mutate: changePassword, isLoading: changePasswordLoading } =
    usePostChangePasswordSubuser();

  const handleChangePassword = () => {
    if (password !== confirmPassword) {
      toast.error("Password and Confirm Password must be the same");
      return;
    }
    changePassword(
      { pk: id, password: password },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenModal();
        },
        onError: (err) => {
          toast.error(err.response.data.message);
        },
      }
    );
  };

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal();
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Change Password</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5 font-pro">
          <PasswordInput
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <PasswordInput
            label="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="flex justify-end gap-3">
            <Button variant="secondary" onClick={() => setOpenModal()}>
              Cancel
            </Button>
            <Button
              onClick={handleChangePassword}
              disabled={changePasswordLoading}
            >
              {changePasswordLoading ? <LoadingButton /> : "Confirm"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePasswordModal;
