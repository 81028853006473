/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  Select,
  SelectTrigger,
  SelectItem,
  SelectContent,
  SelectValue,
} from "../../../../components/ui/select";
import { useEffect, useRef, useState } from "react";
import {
  useGetEmployeeIntegrations,
  usePostAddEmployee,
  usePutUpdateEmployee,
} from "../../../../query/useEmployees";
import { toast } from "sonner";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  useDeleteTag,
  useGetQuestionSets,
  useGetTags,
  usePostAddTag,
} from "../../../../query/useEntity";
import { Asterisk, ChevronDown, Trash2 } from "lucide-react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Switch } from "../../../../components/ui/switch";
import { LogoLoading } from "../../../../components/loading/loading";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import MagiclinkSettings from "./MagiclinkSettings";
import { useMagiclinkForm } from "../../../../hooks/useMagiclinkForm";

const EditStaffModal = ({
  openModal,
  setOpenModal,
  refetch,
  entityId,
  data: initialData,
  setData,
  // tagsFromApi,
  // refetchTags,
}) => {
  const tagBoxRef = useRef(null);
  const redirectBoxRef = useRef(null);
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [isOpenTagBox, setIsOpenTagBox] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState();
  const [isOpenRedirectBox, setIsOpenRedirectBox] = useState(false);
  const [inputRedirect, setInputRedirect] = useState("");

  const { mutate: updateEmployee, isLoading: updateEmployeeLoading } =
    usePutUpdateEmployee();
  const { data: tagsFromApi, refetch: refetchTags } = useGetTags();
  const { data: emplyeeIntegrations } = useGetEmployeeIntegrations();
  const { mutate: addTag } = usePostAddTag();
  const { mutate: deleteTag, isLoading: deleteLoading } = useDeleteTag();

  const { data: questionSets } = useGetQuestionSets();

  const isLoading = updateEmployeeLoading;

  const extractedTags = tagsFromApi?.filter((item) => item.tag === inputTag);

  const { formState, setters, handleSubmit } = useMagiclinkForm({
    entityId,
    onSubmit: (data, onSuccess) => {
      updateEmployee(
        {
          id: initialData?.id,
          data: data,
        },
        {
          onSuccess: (res) => {
            if (res.message) {
              toast.success(res.message);
            } else {
              toast.success("Link has been added successfully");
            }
            refetch();
            setOpenModal(false);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else if (err.response.data.tag) {
              toast.error(err.response.data.tag?.[0]);
            } else if (err.response.data.label) {
              toast.error(err.response.data.label?.[0]);
            } else if (err.respopnse.data.redirect?.[0]) {
              toast.error(err.response.data.redirect?.[0]);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    },
    initialData: initialData,
  });

  const handleAddTag = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
        addTag(
          {
            tag: inputTag,
            entity: entityId,
          },
          {
            onSuccess: (res) => {
              refetchTags();
            },
          }
        );

        setTags([...tags, inputTag.trim()]);
        setInputTag("");
      }
    }
  };

  const handleAddClickTag = () => {
    if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
      addTag(
        {
          tag: inputTag,
          entity: entityId,
        },
        {
          onSuccess: (res) => {
            refetchTags();
          },
        }
      );

      setTags([...tags, inputTag.trim()]);
      setInputTag("");
    }
  };

  const handleDeleteTag = (item) => {
    setDeleteTagId(item?.id);
    deleteTag(
      {
        name: item?.tag,
        entity: entityId,
        id: item?.id,
      },
      {
        onSuccess: (res) => {
          refetchTags();
          setTags((prev) => prev?.filter((tag) => tag !== item?.tag));
        },
      }
    );
  };

  useOnClickOutside({ ref: tagBoxRef, setIsOpen: setIsOpenTagBox });
  useOnClickOutside({ ref: redirectBoxRef, setIsOpen: setIsOpenRedirectBox });

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(false);
      }}
    >
      <DialogContent className="lg:max-w-[888px]">
        <DialogHeader>
          <DialogTitle>Magiclink Settings</DialogTitle>
        </DialogHeader>

        <MagiclinkSettings
          {...formState}
          {...setters}
          inputTag={inputTag}
          setInputTag={setInputTag}
          handleAddTag={handleAddTag}
          isOpenRedirectBox={isOpenRedirectBox}
          setIsOpenRedirectBox={setIsOpenRedirectBox}
          isOpenTagBox={isOpenTagBox}
          setIsOpenTagBox={setIsOpenTagBox}
          tagsFromApi={tagsFromApi}
          deleteLoading={deleteLoading}
          deleteTagId={deleteTagId}
          handleDeleteTag={handleDeleteTag}
          extractedTags={extractedTags}
          handleAddClickTag={handleAddClickTag}
          inputRedirect={inputRedirect}
          setInputRedirect={setInputRedirect}
          emplyeeIntegrations={emplyeeIntegrations}
          questionSets={questionSets}
          setOpenModal={setOpenModal}
          setData={setData}
          handleClick={handleSubmit}
          isLoading={isLoading}
          ref={{
            tagBoxRef: tagBoxRef,
            redirectBoxRef: redirectBoxRef,
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default EditStaffModal;
