/* eslint-disable react/prop-types */
import { motion } from "framer-motion";
import AiAssitance from "../../assets/home/ai-assitance.png";
import Cards from "../../assets/home/cards.png";
import Recording from "../../assets/home/record-audio.png";
import UnifiedInbox from "../../assets/home/unified-inbox.png";
import { cn } from "../../utils/cn";
import CommonHeading from "./CommonHeading";

const Card = ({ children, className, delay }) => {
  return (
    <motion.div
      className={cn(
        "w-full p-5 bg-white rounded-xl flex-grow group hover:shadow-xl shadow-input border border-[#ebebeb]",
        className
      )}
      initial={{
        opacity: 0,
        y: 50,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      transition={{
        duration: 0.4,
        ease: "easeInOut",
        delay: delay,
      }}
      viewport={{
        once: true,
      }}
    >
      {children}
    </motion.div>
  );
};

const CardHeading = ({ children }) => {
  return (
    <p className="font-poppins text-[22px] text-black font-semibold lg:leading-[26.8px] mb-2 group-hover:translate-x-2 transition duration-200">
      {children}
    </p>
  );
};

const CardBody = ({ children }) => {
  return (
    <p className="font-poppins text-sm text-black group-hover:translate-x-2 transition duration-200">
      {children}
    </p>
  );
};

const ClientComponent = () => {
  return (
    <div className="bg-[#FAFBFF] py-10 lg:py-20 text-black container">
      <CommonHeading
        title={"What We Offer"}
        description={"Features to boost your online presence and engagement."}
      />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-16">
        <div className="flex flex-col gap-5 justify-between">
          <Card key={0}>
            <div className="">
              <CardHeading>
                {
                  <p>
                    Simplified Review <br /> Process
                  </p>
                }
              </CardHeading>
              <CardBody>
                {
                  <p className="w-3/4">
                    Guided templates and voice review options, reducing effort
                    and increasing participation.
                  </p>
                }
              </CardBody>

              <img
                src={"/simplified-process.png"}
                className="w-full mt-5 lg:mt-10 group-hover:rotate-2 transition duration-200"
                loading="lazy"
              />
            </div>
          </Card>
          <Card delay={0.6} key={1}>
            <div>
              <img
                src={Cards}
                className="w-full group-hover:rotate-2 transition duration-200"
                loading="lazy"
              />
              <CardHeading>{"MagicCards"}</CardHeading>
              <CardBody>
                {"NFC & QR enabled cards with customisable Magiclink"}
              </CardBody>
            </div>
          </Card>
        </div>

        <div className="flex flex-col gap-5 justify-between">
          <Card delay={0.2} key={2}>
            <div>
              <img
                src={UnifiedInbox}
                className="w-[90%] mb-5 mx-auto group-hover:rotate-2 transition duration-200"
              />
              <CardHeading>
                {
                  <p>
                    Unified Inbox / <br className="2xl:hidden" /> MagicBox
                  </p>
                }
              </CardHeading>
              <CardBody>
                {"Inbox where you can manage all of your reviews in one place"}
              </CardBody>
            </div>
          </Card>
          <Card delay={0.8} key={3}>
            <div>
              <CardHeading>
                <p className="flex items-center gap-3">AI-Agent Assitance</p>
              </CardHeading>
              <img
                src={AiAssitance}
                className="w-full mt-5 group-hover:rotate-2 transition duration-200"
                loading="lazy"
              />
            </div>
          </Card>
        </div>

        <div className="flex flex-col gap-5 justify-between">
          <Card delay={0.4} key={4}>
            <div className="flex justify-between items-center">
              <div>
                <CardHeading>
                  {
                    <p>
                      Multilingual <br /> Voice to Review
                    </p>
                  }
                </CardHeading>
                <CardBody>
                  {"Turn customer voices into written reviews in any language."}
                </CardBody>
              </div>
              <img
                src={Recording}
                className="w-1/2 mb-1 group-hover:rotate-2 transition duration-200"
                loading="lazy"
              />
            </div>
          </Card>
          <Card delay={1} key={5}>
            <div>
              <div className="flex items-start justify-between gap-5 mb-7">
                <CardBody>
                  {
                    <p>
                      Track - Customise <br /> & Send anywhere
                    </p>
                  }
                </CardBody>
                <img
                  src={"/magic-link.png"}
                  className="w-1/2 group-hover:rotate-2 transition duration-200"
                  loading="lazy"
                />
              </div>

              <CardHeading>{"MagicLinks"}</CardHeading>
              <CardBody>
                {
                  "MagicLinks that allow you to request reviews anywhere your customers are."
                }
              </CardBody>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ClientComponent;
