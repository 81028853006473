/* eslint-disable no-unused-vars */
import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import {
  createResetPasswordSession,
  deleteCustomIntegration,
  deleteGmb,
  deleteGmbReviewReply,
  getAirbnbSettings,
  getAutoReplyToggle,
  getBookingSettings,
  getCustomIntegration,
  getFreeTrial,
  getGmbInfo,
  getGmbReviews,
  getIndustryTypes,
  getIntegrations,
  getIsSubUser,
  getMasterAccountAccess,
  getNotifications,
  getReferralsApi,
  getSettings,
  getSubUserBilling,
  getSubUserCharges,
  getSubUserCreditRequests,
  getSubUserMasterPlan,
  getTripSettings,
  getTrustPilotSettings,
  getUserApi,
  getYelpSettings,
  googleSignInApi,
  onboardedApi,
  postAirbnbToggle,
  postAutoReplyToggle,
  postBookingToggle,
  postCustomIntegration,
  postDecryptedToken,
  postEnableAirbnbSupport,
  postEnableBookingSupport,
  postEnableSupport,
  postEnableTripSupport,
  postEnableTrustPilotSupport,
  postEnableYelpSupport,
  postFreeTrial,
  postGenerateAiReply,
  postGmbAcc,
  postGmbAccConfirm,
  postResetPassword,
  postSubUserMasterPlan,
  postToggle,
  postToggleStaffManagement,
  postTripToggle,
  postTrustPilotToggle,
  postUpdateReferralCode,
  postYelpToggle,
  putCustomIntegration,
  putEditGmbReviewReply,
  putUpdateAirbnbSettings,
  putUpdateBookingSettings,
  putUpdateSettings,
  putUpdateTripSettings,
  putUpdateTrustPilotSettings,
  putUpdateYelpSettings,
  registerEntityApi,
  resendVerifyEmailApi,
  signInApi,
  signUpApi,
  updateUserApi,
  updateUserEmailApi,
  updateUserProfileApi,
  verfiyEmailUpdateApi,
  verifyCode,
  verifyEmailApi,
} from "../api/auth";

export const useSignIn = (config) => {
  return useMutation({
    mutationKey: ["signIn"],
    mutationFn: (data) => signInApi(data),
    ...config,
  });
};

export const useSignUp = (config) => {
  return useMutation({
    mutationKey: ["signUp"],
    mutationFn: (data) => signUpApi(data),
    ...config,
  });
};

export const useGoogleSignIn = () => {
  return useMutation({
    mutationKey: ["googleSingIn"],
    mutationFn: (data) => googleSignInApi(data),
  });
};

export const useVerifyEmail = () => {
  return useMutation({
    mutationKey: ["verifyEmail"],
    mutationFn: (data) => verifyEmailApi(data),
  });
};

export const useResendVerifyEmail = () => {
  return useMutation({
    mutationKey: ["resendVerifyEmail"],
    mutationFn: (data) => resendVerifyEmailApi(data),
  });
};

export const useRegiserEntity = () => {
  return useMutation({
    mutationKey: ["registerEntity"],
    mutationFn: (data) => registerEntityApi(data),
  });
};

export const useOnboarded = () => {
  return useMutation({
    mutationKey: ["onboarded"],
    mutationFn: () => onboardedApi(),
  });
};

export const useUser = (config) => {
  return useQuery({
    queryKey: ["user"],
    queryFn: () => getUserApi(),
    ...config,
    refetchOnWindowFocus: false,
  });
};

export const useUpdateUser = (config) => {
  return useMutation({
    mutationKey: ["updateUser"],
    mutationFn: (data) => updateUserApi(data),
    ...config,
  });
};

export const useUpdateUserEmail = () => {
  return useMutation({
    mutationKey: ["updateUserEmail"],
    mutationFn: (data) => updateUserEmailApi(data),
  });
};

export const useVerifyEmailUpdate = () => {
  return useMutation({
    mutationKey: ["verifyEmailUpdate"],
    mutationFn: ({ id, data }) => verfiyEmailUpdateApi(id, data),
  });
};

export const useUpdateUserProfile = (config) => {
  return useMutation({
    mutationKey: ["updateUserProfile"],
    mutationFn: (data) => updateUserProfileApi(data),
    ...config,
  });
};

export const useCreatResetPasswordSession = (config) => {
  return useMutation({
    mutationKey: ["sendCode"],
    mutationFn: (data) => createResetPasswordSession(data),
    ...config,
  });
};

export const useVerifyCode = (config) => {
  return useMutation({
    mutationKey: ["verifyCode"],
    mutationFn: (data) => verifyCode(data),
    ...config,
  });
};

export const usePostResetPassword = (config) => {
  return useMutation({
    mutationKey: ["resetPassword"],
    mutationFn: (data) => postResetPassword(data),
    ...config,
  });
};

export const useGetReferrals = (params, config) => {
  return useQuery({
    queryKey: ["getReferrals", params],
    queryFn: () => getReferralsApi(params),
    ...config,
  });
};

export const useGetIndustryTypes = (params, config) => {
  return useQuery({
    queryKey: ["getIndustryTypes", params],
    queryFn: () => getIndustryTypes(params),
    ...config,
  });
};

export const useGetIntegrations = (config) => {
  return useQuery({
    queryKey: ["getIntegrations"],
    queryFn: () => getIntegrations(),
    ...config,
  });
};

export const useGetCustomIntegrations = (config) => {
  return useQuery({
    queryKey: ["getCustomIntegration"],
    queryFn: () => getCustomIntegration(),
    ...config,
  });
};

export const usePostCustomIntegration = () => {
  return useMutation({
    mutationKey: ["postCustomIntegration"],
    mutationFn: (data) => postCustomIntegration(data),
  });
};

export const usePutCustomIntegration = () => {
  return useMutation({
    mutationKey: ["putCustomIntegration"],
    mutationFn: ({ id, data }) => putCustomIntegration({ id, data }),
  });
};

export const useDeleteCustomIntegration = () => {
  return useMutation({
    mutationKey: ["deleteCustomIntegration"],
    mutationFn: (id) => deleteCustomIntegration(id),
  });
};

export const useGetSettings = (config) => {
  return useQuery({
    queryKey: ["getSettings"],
    queryFn: () => getSettings(),
    ...config,
    refetchOnWindowFocus: false,
  });
};

export const usePutUpdateSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateSettings"],
    mutationFn: (data) => putUpdateSettings(data),
  });
};

export const usePostEnableSupport = () => {
  return useMutation({
    mutationKey: ["postEnableSupport"],
    mutationFn: () => postEnableSupport(),
  });
};

export const usePostToggle = () => {
  return useMutation({
    mutationKey: ["postToggle"],
    mutationFn: () => postToggle(),
  });
};

export const useGetYelpSettings = (config) => {
  return useQuery({
    queryKey: ["getYelpSettings"],
    queryFn: () => getYelpSettings(),
    ...config,
  });
};

export const usePutUpdateYelpSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateYelpSettings"],
    mutationFn: (data) => putUpdateYelpSettings(data),
  });
};

export const usePostEnableYelpSupport = () => {
  return useMutation({
    mutationKey: ["postEnableYelpSupport"],
    mutationFn: () => postEnableYelpSupport(),
  });
};

export const usePostYelpToggle = () => {
  return useMutation({
    mutationKey: ["postYelpToggle"],
    mutationFn: () => postYelpToggle(),
  });
};

export const useGetTrustPilotSettings = (config) => {
  return useQuery({
    queryKey: ["getTrustPilotSettings"],
    queryFn: () => getTrustPilotSettings(),
    ...config,
  });
};

export const usePutUpdateTrustPilotSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateTrustPilotSettings"],
    mutationFn: (data) => putUpdateTrustPilotSettings(data),
  });
};

export const usePostEnableTrustPilotSupport = () => {
  return useMutation({
    mutationKey: ["postEnableTrustPilotSupport"],
    mutationFn: () => postEnableTrustPilotSupport(),
  });
};

export const usePostTrustPilotToggle = () => {
  return useMutation({
    mutationKey: ["postTrustPilotToggle"],
    mutationFn: () => postTrustPilotToggle(),
  });
};

export const useGetBookingSettings = (config) => {
  return useQuery({
    queryKey: ["getBookingSettings"],
    queryFn: () => getBookingSettings(),
    ...config,
  });
};

export const usePutUpdateBookingSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateBookingSettings"],
    mutationFn: (data) => putUpdateBookingSettings(data),
  });
};

export const usePostEnableBookingSupport = () => {
  return useMutation({
    mutationKey: ["postEnableBookingSupport"],
    mutationFn: () => postEnableBookingSupport(),
  });
};

export const usePostBookingToggle = () => {
  return useMutation({
    mutationKey: ["postBookingToggle"],
    mutationFn: () => postBookingToggle(),
  });
};

export const useGetAirbnbSettings = (config) => {
  return useQuery({
    queryKey: ["getAirbnbSettings"],
    queryFn: () => getAirbnbSettings(),
    ...config,
  });
};

export const usePutUpdateAirbnbSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateAirbnbSettings"],
    mutationFn: (data) => putUpdateAirbnbSettings(data),
  });
};

export const usePostEnableAirbnbSupport = () => {
  return useMutation({
    mutationKey: ["postEnableAirbnbSupport"],
    mutationFn: () => postEnableAirbnbSupport(),
  });
};

export const usePostAirbnbToggle = () => {
  return useMutation({
    mutationKey: ["postAirbnbToggle"],
    mutationFn: () => postAirbnbToggle(),
  });
};

export const useGetTripSettings = (config) => {
  return useQuery({
    queryKey: ["getTripSettings"],
    queryFn: () => getTripSettings(),
    ...config,
  });
};

export const usePutUpdateTripSettings = () => {
  return useMutation({
    mutationKey: ["putUpdateTripSettings"],
    mutationFn: (data) => putUpdateTripSettings(data),
  });
};

export const usePostEnableTripSupport = () => {
  return useMutation({
    mutationKey: ["postEnableTripSupport"],
    mutationFn: () => postEnableTripSupport(),
  });
};

export const usePostTripToggle = () => {
  return useMutation({
    mutationKey: ["postTripToggle"],
    mutationFn: () => postTripToggle(),
  });
};

export const useGetNotifications = (config) => {
  return useInfiniteQuery({
    queryKey: ["getNotifications"],
    queryFn: () => getNotifications(),
    ...config,
  });
};

export const usePostUpdateReferralCode = () => {
  return useMutation({
    mutationKey: ["postUpdateReferralCode"],
    mutationFn: (data) => postUpdateReferralCode(data),
  });
};

export const usePostToggleStaffManagement = () => {
  return useMutation({
    mutationKey: ["postToggeStaffManagement"],
    mutationFn: () => postToggleStaffManagement(),
  });
};

export const useGetFreeTrial = () => {
  return useQuery({
    queryKey: ["getFreeTrial"],
    queryFn: () => getFreeTrial(),
  });
};

export const usePostFreeTrial = () => {
  return useMutation({
    mutationKey: ["getFreeTrial"],
    mutationFn: () => postFreeTrial(),
  });
};

export const useGetMasterAccountAccess = () => {
  return useMutation({
    mutationKey: ["getMasterAccountAccess"],
    mutationFn: () => getMasterAccountAccess(),
  });
};

export const useGetIsSubUser = () => {
  return useQuery({
    queryKey: ["getIsSubUser"],
    queryFn: () => getIsSubUser(),
  });
};

export const useGetSubUserBilling = () => {
  return useQuery({
    queryKey: ["getSubUserBilling"],
    queryFn: () => getSubUserBilling(),
  });
};

export const useGetSubUserCharges = () => {
  return useQuery({
    queryKey: ["getSubUserCharges"],
    queryFn: () => getSubUserCharges(),
  });
};

export const useGetSubUserMasterPlan = (params, config) => {
  return useQuery({
    queryKey: ["getSubUserMasterPlan", params],
    queryFn: () => getSubUserMasterPlan(params),
    ...config,
  });
};

export const usePostSubUserMasterPlan = () => {
  return useMutation({
    mutationKey: ["postSubUserMasterPlan"],
    mutationFn: (data) => postSubUserMasterPlan(data),
  });
};

export const useGetSubUserCreditRequests = () => {
  return useQuery({
    queryKey: ["getSubUserCreditRequests"],
    queryFn: () => getSubUserCreditRequests(),
  });
};

export const useGetGmbInfo = (config) => {
  return useQuery({
    queryKey: ["getGmbInfo"],
    queryFn: () => getGmbInfo(),
    ...config,
  });
};

export const useGetGmbReviews = () => {
  return useQuery({
    queryKey: ["getGmbReviews"],
    queryFn: () => getGmbReviews(),
  });
};

export const useGetGmbReviewsInfinity = ({ reply }, config) => {
  return useInfiniteQuery({
    queryKey: ["getGmbReviewsInfinity", reply],
    queryFn: ({ pageParam = 0 }) => {
      const dataForGetGmbReviews = {
        limit: 5,
        index: pageParam,
        reply: reply,
      };

      return getGmbReviews(dataForGetGmbReviews);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (reply) {
        if (
          allPages?.flatMap((page) => page?.results)?.length <
          lastPage?.total_reviews_to_reply
        ) {
          return allPages?.flatMap((page) => page?.results)?.length;
        }
      } else {
        if (
          allPages?.flatMap((page) => page?.results)?.length <
          lastPage?.total_results
        ) {
          return allPages?.flatMap((page) => page?.results)?.length;
        }
      }
    },
    keepPreviousData: true,
    ...config,
  });
};

export const usePutEditGmbReviewReply = () => {
  return useMutation({
    mutationKey: ["putEditGmbReviewReply"],
    mutationFn: (data) => putEditGmbReviewReply(data),
  });
};

export const useDeleteGmbReviewReply = () => {
  return useMutation({
    mutationKey: ["deleteGmbReviewReply"],
    mutationFn: (data) => deleteGmbReviewReply(data),
  });
};

export const useDeleteGmb = () => {
  return useMutation({
    mutationKey: ["deleteGmb"],
    mutationFn: () => deleteGmb(),
  });
};

export const usePostGmbAcc = () => {
  return useMutation({
    mutationKey: ["postGmbAcc"],
    mutationFn: (data) => postGmbAcc(data),
  });
};

export const usePostGmbAccConfirm = () => {
  return useMutation({
    mutationKey: ["postGmbAccConfirm"],
    mutationFn: (data) => postGmbAccConfirm(data),
  });
};

export const useGetAutoReplyToggle = (config) => {
  return useQuery({
    queryKey: ["getAutoReplyToggle"],
    queryFn: () => getAutoReplyToggle(),
    ...config,
  });
};

export const usePostAutoReplyToggle = () => {
  return useMutation({
    mutationKey: ["postAutoReplyToggle"],
    mutationFn: () => postAutoReplyToggle(),
  });
};

export const usePostGenerateAiReply = () => {
  return useMutation({
    mutationKey: ["postGenerateAiReply"],
    mutationFn: (data) => postGenerateAiReply(data),
  });
};

export const usePostDecryptedToken = () => {
  return useMutation({
    mutationKey: ["postDecryptedToken"],
    mutationFn: (data) => postDecryptedToken(data),
  });
};
