import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { usePostAddCustomDomain } from "../../../../query/useEntity";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../../../../components/ui/select";
import { Input } from "../../component/Input";
import { Button } from "../../../../components/ui/button";
import { LogoLoading } from "../../../../components/loading/loading";

const domainRegex = /^[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;
const subdomainRegex = /^(?:[a-zA-Z0-9-]+\.)+[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/;

const AddDomainModal = ({ openModal, setOpenModal, refetchDomain }) => {
  const [domainType, setDomainType] = useState("domain");
  const [domain, setDomain] = useState("");
  const [error, setError] = useState("");
  const [step, setStep] = useState(1);

  const { mutate: addDomain, isLoading: addDomainLoading } =
    usePostAddCustomDomain();

  const handleAddDomain = () => {
    if (domainType === "domain") {
      if (domainRegex.test(domain) && domain.split(".").length === 2) {
        setError("");
      } else {
        setError("Invalid domain name");
        return;
      }
    } else {
      if (subdomainRegex.test(domain) && domain.split(".").length > 2) {
        setError("");
      } else {
        setError("Invalid subdomain name");
        return;
      }
    }

    addDomain(
      {
        type: domainType,
        actual_domain: domain,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenModal(false);
          refetchDomain();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (domain.length === 0) {
      setError("");
    }
  }, [domain]);

  if (addDomainLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Domain Configuration</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          {step === 1 ? (
            <>
              <div>
                <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
                  {"Choose your domain type"}
                </label>
                <Select
                  value={domainType}
                  onValueChange={(item) => setDomainType(item)}
                >
                  <SelectTrigger className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1">
                    {domainType ? (
                      <p className="capitalize text-sm">{domainType}</p>
                    ) : (
                      "Select your domain"
                    )}
                  </SelectTrigger>
                  <SelectContent>
                    {["domain", "subdomain"].map((item) => (
                      <SelectItem
                        key={item}
                        value={item}
                        className="capitalize"
                      >
                        {item}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>

              <Input
                label={
                  domainType === "domain" ? "Domain name" : "Subdomain name"
                }
                placeholder={
                  domainType === "domain"
                    ? "e.g. example.com"
                    : "e.g. review.example.com"
                }
                value={domain}
                setValue={setDomain}
                error={error}
              />
            </>
          ) : (
            <div className="space-y-3 font-pro">
              <p className="text-center text-[#4A4A4A] text-sm">
                Please visit your DNS provider and add the following{" "}
                {domainType === "domain" ? "A" : "CNAME"} record:
              </p>
              <div className="grid grid-cols-3 gap-2 place-items-center py-2 bg-[#F8F9F8] rounded-lg border border-[#ebebeb] text-sm text-[#4A4A4A]">
                {["Type", "Name", "Value"].map((item) => (
                  <p
                    key={item}
                    className="text-[#808080] uppercase
              "
                  >
                    {item}
                  </p>
                ))}
                <span>{domainType === "domain" ? "A" : "CNAME"}</span>
                <span>
                  {domainType === "domain" ? "@" : domain?.split(".")?.[0]}
                </span>
                <span>
                  {domainType === "domain"
                    ? "76.76.21.21"
                    : "cname.vercel-dns.com"}
                </span>
              </div>
            </div>
          )}

          <div className="flex justify-end mt-3 gap-3">
            <Button
              variant="secondary"
              onClick={() => {
                step === 1 ? setOpenModal(false) : setStep(1);
              }}
            >
              {step === 1 ? "Cancel" : "Back"}
            </Button>
            <Button
              onClick={() => {
                step === 1 ? setStep(2) : handleAddDomain();
              }}
            >
              {step === 1 ? "Continue" : "Confirm"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddDomainModal;
