/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { Slider } from "../../../../components/ui/slider";
import { ChevronRight } from "lucide-react";
import TargetLocationModal from "./TargetLocationModal";

const SettingModal = ({
  openModal,
  setOpenModal,
  handleAction,
  settingCount,
}) => {
  const [count, setCount] = useState(0);
  const [isOpenTargetLocationModal, setIsOpenTargetLocationModal] =
    useState(false);

  useEffect(() => {
    setCount(settingCount);
  }, [settingCount]);
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5 font-pro">
          <div
            className="flex items-center justify-between group hover:cursor-pointer"
            onClick={() => setIsOpenTargetLocationModal(true)}
          >
            <div className="space-y-1 group-hover:underline">
              <p className="text-semibold">Location</p>
              <p className="text-sm text-[#525252] dark:text-white">
                Add target location for precise keyword generation.
              </p>
            </div>
            <ChevronRight className="text-[#525252] dark:text-white" />
          </div>
          <hr />
          <div className="">
            <label htmlFor="add">Keyword Count</label>
            <div className="flex items-center gap-5">
              <Slider
                value={[count]}
                min={1}
                max={100}
                onValueChange={(e) => {
                  setCount(e?.[0]);
                }}
              />
              <Input value={count} readOnly className="text-center w-14 h-10" />
            </div>
            <p className="text-sm text-[#525252] dark:text-white -mt-1">
              This specifies the number of keywords the AI will generate.
            </p>

            {/* <Input
              id="add"
              placeholder="Seo Setting Count"
              value={count}
              onChange={(e) => setCount(Number(e.target.value))}
            /> */}
          </div>
          <div className="flex justify-end gap-3">
            <Button onClick={() => setOpenModal(false)} variant="secondary">
              Cancel
            </Button>
            <Button onClick={() => handleAction(count)}>Update</Button>
          </div>
        </div>
        <TargetLocationModal
          openModal={isOpenTargetLocationModal}
          setOpenModal={setIsOpenTargetLocationModal}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SettingModal;
