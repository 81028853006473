/* eslint-disable react/prop-types */

import { Info, Pencil } from "lucide-react";
import { useState } from "react";
import EditEmailModal from "../../master/components/EditEmailModal";

export const Input = ({
  label,
  placeholder,
  value,
  setValue,
  disabled,
  error,
  ...props
}) => {
  return (
    <div className="w-full">
      <label className="text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
        {label}
      </label>
      <div className="relative mb-[20px]">
        <input
          className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={disabled}
          {...props}
        />
        {error && value && (
          <p className="text-red-500 mt-2 text-xs flex items-center gap-2">
            <Info className="size-4" /> {error}
          </p>
        )}
      </div>
    </div>
  );
};

export const InputWithEditButton = ({
  label,
  placeholder,
  value,
  setValue,
  disabled,
  id,
}) => {
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  return (
    <div className="w-full">
      <label className="text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
        {label}
      </label>
      <div className="relative mb-[20px]">
        <input
          className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base  w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={disabled}
        />

        <Pencil
          className="size-4 absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer"
          onClick={() => setIsOpenEdit(true)}
        />
      </div>
      <EditEmailModal
        isOpenModal={isOpenEdit}
        setIsOpenModal={setIsOpenEdit}
        id={id}
        email={value}
        setEmail={setValue}
      />
    </div>
  );
};

export const UploadInput = ({ label, placeholder }) => {
  return (
    <div className="w-full">
      <label className="text-[#000] text-xl font-pro mb-[10px] flex gap-1">
        {label}
      </label>
      <div className="w-full flex relative z-0">
        <img
          src="/upload.svg"
          alt=""
          className=" w-6 h-6 absolute right-4 top-4"
        />
        <input
          className="border-[1px] border-[#E7E4E4] rounded-[8px] py-[15px] px-[15px] text-[#A7A5A5] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full"
          type="text"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export const TextAreaInput = ({ label, placeholder }) => {
  return (
    <div className="w-full">
      <label className="text-[#000] text-xl font-pro font-medium mb-[10px] flex gap-1">
        {label}
      </label>
      <textarea
        className="border-[1px] border-[#E7E4E4] rounded-[8px] py-[15px] px-[15px] text-[#A7A5A5] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full resize-none pb-[30px]"
        rows={5}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
