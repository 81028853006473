import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { Toaster } from "sonner";
import { DarkModeProvider } from "./context/DarkModeProvider.jsx";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SentryReportButton from "./routes/sentry/SentryReportButton.jsx";

const googleClientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
// const googleClientId =
//   "477532192307-bbmdrr5nedbhf9fnqerktj7357ktn7ik.apps.googleusercontent.com";
const queryClient = new QueryClient();

Sentry.init({
  dsn: "https://96cc808d920f1548bf59a4c0c216b8bb@sentry.magicpitch.ai/9",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
    // Sentry.feedbackIntegration({}),
  ],

  // userfeedback
  // beforeSend(event, hint) {
  //   // Check if it is an exception, and if so, show the report dialog
  //   if (event.exception && event.event_id) {
  //     Sentry.showReportDialog({ eventId: event.event_id });
  //   }
  //   return event;
  // },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "https://magic-review-dev.vercel.app",
    "https://magicreview.ai",
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={googleClientId}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <DarkModeProvider>
            <App />
          </DarkModeProvider>
          {/* <SentryReportButton /> */}
          {/* <ChatBot /> */}
          <Toaster
            position="bottom-center"
            richColors
            toastOptions={{
              style: {
                padding: "15px 12px",
              },
            }}
          />
        </BrowserRouter>
      </QueryClientProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
