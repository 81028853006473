import React from "react";
import ProductHero from "../../components/products/ProductHero";
import MainNav from "../../components/mainNavbar/MainNav";
import ProductKeyFeatures from "../../components/products/ProductKeyFeatures";
import ProductBenefits from "../../components/products/ProductBenefits";
import { IconWrapper } from "./ResponderAgent";
import { CalendarClock, LayoutDashboard, PencilLine } from "lucide-react";
import Footer from "../../components/footer/footer";
import PricingComponent from "../pricing/PricingComponent";

const AiPostProduct = () => {
  return (
    <div className="bg-white">
      <MainNav />
      <div className="mt-[1.2rem] lg:mt-[3.5rem] 2xl:mt-[7.5rem] relative text-black">
        <ProductHero
          title={"Transform Reviews into Social Proof, Instantly"}
          content={
            "Keeping your Google My Business profile active has never been easier. MagicReview’s AI Post generates engaging content from your customer reviews. Simply add details, and let AI craft the perfect post. Schedule content in advance to maintain a consistent online presence and attract more customers."
          }
          badge={"AI Post"}
          imgUrl={"/products/ai-hero.png"}
        />
        <div className="bg-gradient-to-b from-[#f1f4ff] via-30% via-white to-white container">
          <ProductKeyFeatures
            contents={[
              {
                title: "AI-Generated, High-Quality Content",
                description:
                  "Transform customer feedback into professional, engaging Google My Business posts with just a few clicks.",
                icon: (
                  <IconWrapper color={"#753EE9"}>
                    <PencilLine className="text-[#753EE9] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Multiple Post Types for Maximum Impact",
                description:
                  "Choose from event announcements, special offers, or general updates to keep your audience informed and engaged.",
                icon: (
                  <IconWrapper color={"#FF6464"}>
                    <LayoutDashboard className="text-[#FF6464] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Seamless Scheduling & Automation",
                description:
                  "Plan and schedule posts in advance to ensure a steady flow of fresh content without manual effort.",
                icon: (
                  <IconWrapper color={"#2A49B8"}>
                    <CalendarClock className="text-[#2A49B8] size-10" />
                  </IconWrapper>
                ),
              },
            ]}
          />
          <ProductBenefits
            title={"How AI Post\nStrengthens Your Reputation"}
            description={
              "Generate and schedule AI-powered posts that enhance your\n online presence and drive customer engagement."
            }
            contents={[
              {
                title: "Maintain an Active & Professional Online Presence",
                description:
                  "Regular updates boost credibility. AI automates post creation, ensuring businesses stay active without extra effort.",
                img: (
                  <img
                    src="/products/ai1.png"
                    alt=""
                    className="lg:w-[60%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
              {
                title: "SEO-Optimized Posts for Higher Search Rankings",
                description:
                  "AI automatically integrates trending keywords into your posts, enhancing visibility and search rankings without manual SEO optimization.",
                img: (
                  <img
                    src="/products/ai2.png"
                    alt=""
                    className="lg:w-[80%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
              {
                title: "Engage Customers in Real-Time with Relevant Content",
                description:
                  "Timely updates drive higher engagement. AI creates event-based, promotional, and update posts, keeping customers informed and engaged. ",
                img: (
                  <img
                    src="/products/ai3.png"
                    alt=""
                    className="lg:w-[80%] lg:ml-auto lg:mr-0"
                  />
                ),
              },
              {
                title: "Save Time & Focus on Growth with AI Automation",
                description:
                  "No more content struggles—AI writes, schedules, and optimizes posts, letting businesses focus on service.",
                img: (
                  <img
                    src="/products/ai4.png"
                    alt=""
                    className="lg:w-[80%] lg:ml-auto lg:mr-0 lg:mt-10"
                  />
                ),
              },
            ]}
          />
        </div>
        <PricingComponent />
      </div>
      <Footer />
    </div>
  );
};

export default AiPostProduct;
