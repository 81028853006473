import { useQueryClient } from "@tanstack/react-query";
import {
  ChartColumn,
  ChevronLeftCircle,
  ChevronRightCircle,
  Download,
  Ellipsis,
  Info,
  Link,
  MailPlus,
  Pencil,
  QrCode,
  SquarePlus,
  Trash2,
  TriangleAlert,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Google from "../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import { Button } from "../../../components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Switch } from "../../../components/ui/switch";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import {
  useGetIntegrations,
  useOnboarded,
  usePostToggleStaffManagement,
  useUser,
} from "../../../query/useAuthentication";
import {
  useClickGetMagicQr,
  useDeleteEmployee,
  useGetActiveDefaultMagicLink,
  useGetDefaultQuestionSets,
  useGetEmployeesList,
  useGetEmployeesStats,
  useGetMagicLink,
  usePostAddDefaultQuestionSets,
  usePostDefaultMagicLink,
  usePostEmployeeEnable,
  usePostResetMagicLink,
} from "../../../query/useEmployees";
import { useGetQuestionSets } from "../../../query/useEntity";
import { MagiclinkIllustrations } from "../../onboarding/components/illustrations/MagiclinkIllustrations";
import CustomTooltipForJoyride from "../component/CustomTooltipForJoyride";
import IconButton from "../component/IconButton";
import Layout from "../component/Layout";
import { DeleteModal } from "../component/modal";
import QrContainer from "../component/QrContainer";
import AddStaffModal from "./components/AddStaffModal";
import DownloadStatsModal from "./components/DownloadStatsModal";
import EditDefaultStaffModal from "./components/EditDefaultStaffModal";
import EditStaffModal from "./components/EditStaffModal";
import LinkModal from "./components/LinkModal";
import RequestReviewModal from "./components/RequestReviewModal";
import ReviewsAnalyticsComponent from "./ReviewAnalyticsComponent";
const Staffs = () => {
  const scrollRef = useRef(null);
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const questionSetRef = useRef(null);
  const queryClient = useQueryClient();
  const { darkMode } = useDarkMode();
  const [index, setIndex] = useState(0);
  const [isOpenAddStaffModal, setIsOpenAddStaffModal] = useState(false);
  const [isOpenEditStaffModal, setIsOpenEditStaffModal] = useState();
  const [isOpenEditDefaultStaff, setIsOpenEditDefaultStaff] = useState(false);
  const [toUpdateData, setToUpdateData] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isActiveStaffManagement, setIsActiveStaffManagement] = useState(false);
  const [file, setFile] = useState();
  const [csvArray, setCsvArray] = useState([]);
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);
  const [employeeId, setEmployeeId] = useState();
  const [isOpenQuestionSetBox, setIsOpenQuestionSetBox] = useState(false);
  const [selectedDefaultQuestionSet, setSelectedDefaultQuestionSet] =
    useState();
  const [originalDefaultQuestionSet, setOriginalDefaultQuestionSet] =
    useState();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [seeMoreLabel, setSeeMoreLabel] = useState();
  const [seeMoreTags, setSeeMoreTags] = useState();
  const [loadingMagicLink, setLoadingMagicLink] = useState();
  const [isOpenQrContainer, setIsOpenQrContainer] = useState(false);
  const [isOpenRequestReview, setIsOpenRequestReview] = useState(null);
  const [qr, setQr] = useState();
  const [selectedSet, setSelectedSet] = useState(null);
  const [isOpenLink, setIsOpenLink] = useState(null);
  const { mutate: onboarded, isLoading: onboardedLoading } = useOnboarded();
  const { pathname } = useLocation();
  const [baseUrl, setBaseUrl] = useState("");
  const joyRideStepsForMagiclink = [
    {
      title: "Default Magiclink",
      content:
        "Create a default question set to access its Magiclink via the copy icon to the right of Magiclink button above",
      target: ".select-set",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Enable Magiclinks",
      content: "Turn on the toggle to add Magiclinks",
      target: ".enable-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Add Magiclinks",
      content: "Click to add Magiclinks for your team",
      target: ".add-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Reset",
      content: "Reset the Magiclink to the default question set",
      target: ".reset-magiclink",
      spotlightClicks: true,
    },
    {
      title: "Question sets",
      content:
        "View Magiclinks arranged according to the designated question sets",
      target: ".set",
      spotlightClicks: true,
      referrer: "Complete",
      referrerFunc: function () {
        onboarded(
          {},
          {
            onSuccess: (res) => {
              toast.success(res.message);
              setStep((prev) => {
                return { ...prev, run: false };
              });
            },
            onError: (err) => {
              if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message);
              } else {
                toast.error(
                  `Something went wrong, try again later ${err.response.status}`
                );
              }
            },
          }
        );
      },
    },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForMagiclink,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "verify-email" && pathname === "/dashboard/magiclinks") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const processCSV = (str, delim = ",") => {
    const headers = str.slice(0, str.indexOf("\n")).split(delim);
    const rows = str.slice(str.indexOf("\n") + 1).split("\n");

    const newArray = rows.map((row) => {
      const values = row.split(delim);
      const eachObject = headers.reduce((obj, header, i) => {
        obj[header] = values[i];
        return obj;
      }, {});
      return eachObject;
    });

    setCsvArray(newArray);
  };

  const userInfo =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const {
    data,
    isLoading: employeeListLoading,
    isFetching: employeeListFetching,
    refetch,
  } = useGetEmployeesList();

  useGetMagicLink({
    onSuccess: (res) => {
      setBaseUrl(res?.host);
    },
  });

  const { data: active, refetch: refetchActive } =
    useGetActiveDefaultMagicLink();

  const { mutate: postToggleStaffManagement, isLoading: isToggleLoading } =
    usePostToggleStaffManagement();

  const { mutate: deleteEmployee, isLoading: deleteEmployeeLoading } =
    useDeleteEmployee();

  const { mutate: toggleEmployeeEnable, isLoading: isToggleEmployeeLoading } =
    usePostEmployeeEnable();

  const { mutate: resetMagicLink, isLoading: isResetMagicLinkLoading } =
    usePostResetMagicLink();

  const { refetch: refetchUser } = useUser({
    onSuccess: (res) => {
      setIsActiveStaffManagement(
        res.profile.user_settings_staff_management_enabled
      );
    },
  });

  const {
    data: defaultQuestionSetFromApi,
    refetch: refetchDefaultQuestionSet,
  } = useGetDefaultQuestionSets({
    onSuccess: (res) => {
      setSelectedDefaultQuestionSet(res?.data);
      setOriginalDefaultQuestionSet(res?.data);
    },
  });

  const {
    mutate: addDefaultQuestionSets,
    isLoading: addDefaultQuestionSetsLoading,
  } = usePostAddDefaultQuestionSets();

  const { data: magicLink } = useGetMagicLink();
  const { mutate: defaultMagicLink, isLoading: defaultMagicLinkLoading } =
    usePostDefaultMagicLink();
  const { data: questionSets, isLoading: isQuestionSetsLoading } =
    useGetQuestionSets();

  const { mutate: getMagicQr, isLoading: getMagicQrLoading } =
    useClickGetMagicQr();

  const { refetch: refetchStats, isLoading } = useGetEmployeesStats({
    enabled: false,
  });

  const [isIntegrated, setIsIntegrated] = useState(true);

  const handleCopy = (employeeCode) => {
    if (userInfo?.entity?.magic_link) {
      navigator.clipboard.writeText(
        baseUrl +
          "/" +
          userInfo?.entity?.magic_link?.split("?")?.[0] +
          `?employee=${employeeCode}`
      );
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const handleGetQr = (employeeCode) => {
    getMagicQr(employeeCode, {
      onSuccess: (res) => {
        setQr(res);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
      },
    });
    setIsOpenQrContainer(true);
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const handleDeleteEmployee = () => {
    deleteEmployee(deleteId, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        } else {
          toast.success("Link has been deleted successfully");
        }
        refetch();
        setIsOpenDeleteModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      const reader = new FileReader();

      reader.onload = function (e) {
        const text = e.target.result;
        processCSV(text); // plugged in here
      };

      reader.readAsText(file);
      setIsOpenPreviewModal(true);
    }
    e.target.value = "";
  };

  const toggleEmployee = (employeeId) => {
    setEmployeeId(employeeId);
    toggleEmployeeEnable(employeeId, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetch();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleResetMagicLink = () => {
    resetMagicLink(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries("getMagicLink");
          queryClient.invalidateQueries("getMagicQr");
          refetchActive();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDefaultMagicLink = (id) => {
    setLoadingMagicLink(id);
    defaultMagicLink(id, {
      onSuccess: (res) => {
        toast.success(res.message);
        queryClient.invalidateQueries("getMagicLink");
        queryClient.invalidateQueries("getMagicQr");
        refetchActive();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddDefaultQuestionSet = (set) => {
    addDefaultQuestionSets(
      {
        set: set,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchDefaultQuestionSet();
          setIsOpenEditDefaultStaff(false);
          // setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const { data: defaultQuestionSet } = useGetDefaultQuestionSets();

  useGetIntegrations({
    onSuccess: (res) => {
      setIsIntegrated(res?.value);
    },
  });

  useOnClickOutside({
    ref: questionSetRef,
    setIsOpen: setIsOpenQuestionSetBox,
  });

  const defaultSet = questionSets?.find((item) => item?.is_default);
  useEffect(() => {
    setSelectedSet(defaultQuestionSetFromApi?.data);
  }, [defaultQuestionSetFromApi]);

  const scrollByAmount = (amount) => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: amount, behavior: "smooth" });
    }
  };

  const [isOverflowing, setIsOverflowing] = useState(true);

  const checkOverflow = () => {
    if (scrollRef.current) {
      setIsOverflowing(
        scrollRef.current.scrollWidth > scrollRef.current.clientWidth
      );
    }
  };

  const reviewPlatforms = (platform) => {
    switch (platform) {
      case "Google":
        return <img src={Google} />;
      case "Yelp":
        return <img src={Yelp} />;
      case "Trustpilot":
        return <img src={TrustPilot} />;
      case "Airbnb":
        return <img src={Airbnb} className="w-5" />;
      case "Booking":
        return <img src={Booking} />;
      case "Tripadvisor":
        return <img src={TripAdvisor} className="w-5" />;
    }
  };

  const handleCopyId = (id) => {
    if (id) {
      navigator.clipboard.writeText(id);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const [isOpenStas, setIsOpenStas] = useState(false);
  const [statsId, setStatsId] = useState(null);

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
  }, [questionSets]);

  if (
    defaultMagicLinkLoading ||
    isResetMagicLinkLoading ||
    isToggleLoading ||
    isToggleEmployeeLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Magic Links"}>
      {isOpenStas ? (
        <ReviewsAnalyticsComponent
          isOpenStats={isOpenStas}
          setIsOpenStats={setIsOpenStas}
          statsId={statsId}
        />
      ) : (
        <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-120px)] px-5 lg:px-[30.2px]">
          <DownloadStatsModal
            setOpenModal={setIsOpenModal}
            openModal={isOpenModal}
          />

          {employeeListLoading || isQuestionSetsLoading ? (
            <Loading height="50vh" />
          ) : (
            <>
              <div className="flex lg:flex-row flex-col lg:items-center justify-between gap-5">
                <div className="flex flex-row items-center justify-between gap-3 ">
                  {isOverflowing && (
                    <button onClick={() => scrollByAmount(-100)} className="">
                      <ChevronLeftCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
                    </button>
                  )}

                  {!isQuestionSetsLoading && questionSets?.length > 0 && (
                    <div
                      className="flex items-center w-full gap-3 bg-white dark:bg-darkBg px-5 h-9 rounded-[8px] overflow-x-auto scrollbar-none relative set font-pro text-[#525252] lg:max-w-xl"
                      ref={scrollRef}
                    >
                      {questionSets
                        ?.filter((item) => item?.is_default)
                        .map((item, i) => (
                          <React.Fragment key={item?.id}>
                            <button
                              className={`px-3 py-2 flex-shrink-0 text-[#525252] dark:text-darkGray ${selectedSet === item?.id ? "font-medium border-b-2 border-b-newPrimary text-newPrimary" : ""} text-[15px]`}
                              onClick={() => setSelectedSet(item?.id)}
                            >
                              {item?.name === "default"
                                ? "Default"
                                : item?.name}
                            </button>
                            {questionSets?.length !== i + 1 && (
                              <div className="border-l-2 h-7"></div>
                            )}
                          </React.Fragment>
                        ))}
                      {questionSets
                        ?.filter((item) => !item?.is_default)
                        .map((item, i) => (
                          <React.Fragment key={item?.id}>
                            <button
                              className={`px-3 py-2  flex-shrink-0 ${selectedSet === item?.id ? "font-medium border-b-2 border-b-newPrimary text-newPrimary" : ""}  text-[#525252] text-[15px]`}
                              onClick={() => setSelectedSet(item?.id)}
                            >
                              {item?.name}
                            </button>
                            {questionSets?.length !== i + 2 && (
                              <div className="border-l-2 h-7"></div>
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  )}

                  {isOverflowing && (
                    <button onClick={() => scrollByAmount(100)} className="">
                      <ChevronRightCircle className="fill-secondary stroke-white dark:stroke-darkLightBg size-7" />
                    </button>
                  )}
                </div>

                <div className="flex items-center gap-3">
                  <button
                    className=" flex items-center justify-center gap-x-3 px-3 py-2.5 rounded-[8px] border border-newPrimary bg-newPrimary text-white dark:hover:bg-darkLightBg hover:bg-white hover:text-newPrimary transition-all duration-200 w-[150px] font-pro add-magiclink h-9"
                    onClick={() => {
                      setIsOpenAddStaffModal(true);
                      setToUpdateData();
                    }}
                    disabled={!isActiveStaffManagement || !isIntegrated}
                  >
                    <SquarePlus />
                    <p className="text-sm font-normal">Add Magiclink</p>
                  </button>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Button
                          variant="outline"
                          className="size-9 bg-white"
                          size="icon"
                          onClick={() => setIsOpenModal(true)}
                        >
                          <Download />
                        </Button>
                      </TooltipTrigger>
                      <TooltipContent>
                        Download stats for Magiclink
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>

              <div className="overflow-x-auto mt-7 scrollbar-thin">
                {!employeeListLoading ? (
                  <div className="rounded-xl overflow-hidden">
                    <Table>
                      <TableHeader>
                        <TableRow className="text-[15px] text-[#808080]">
                          <TableHead className="text-[#808080] dark:text-white">
                            Name
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white">
                            Role
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white">
                            Tags
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white">
                            Redirects
                          </TableHead>

                          <TableHead className="text-[#808080] dark:text-white w-fit">
                            <div className="flex items-center gap-1">
                              Reviews{" "}
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Info className="text-[#888] size-4" />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    No. of reviews redirected
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white w-fit">
                            <div className="flex items-center gap-1">
                              Verified Reviews{" "}
                              <TooltipProvider>
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Info className="text-[#888] size-4" />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    No. of reviews verified on platforms
                                  </TooltipContent>
                                </Tooltip>
                              </TooltipProvider>
                            </div>
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white">
                            Link
                          </TableHead>
                          <TableHead className="text-[#808080] dark:text-white">
                            Activate
                          </TableHead>
                          {isActiveStaffManagement && (
                            <TableHead className="text-[#808080] dark:text-white"></TableHead>
                          )}
                        </TableRow>
                      </TableHeader>
                      <TableBody className="divide-y">
                        {employeeListLoading ? (
                          <TableRow>
                            {new Array(10).fill("").map((_, i) => (
                              <TableCell key={i}>
                                <Skeleton
                                  height={30}
                                  baseColor={darkMode && "#262626"}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ) : data?.filter(
                            (item) => item?.default_set === selectedSet
                          )?.length > 0 ? (
                          <>
                            {selectedSet ===
                              defaultQuestionSetFromApi?.data && (
                              <TableRow className="bg-[#fafafa] dark:bg-darkBg text-[15px]  relative z-30  ring-1 ring-[#ebebeb] ">
                                <TableCell className="relative">
                                  <p className="text-newPrimary">-</p>
                                </TableCell>
                                <TableCell className="text-newPrimary">
                                  <div className="max-w-[120px] w-full">-</div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center gap-1 max-w-[150px] w-full flex-wrap">
                                    <p className="text-newPrimary">-</p>
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center flex-wrap gap-3 w-fit">
                                    {defaultQuestionSetFromApi?.redirect
                                      ?.length <= 0 ? (
                                      <div
                                        className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                        title="Please select any redirect"
                                      >
                                        <TriangleAlert className="size-4" />{" "}
                                        Action Required
                                      </div>
                                    ) : (
                                      defaultQuestionSetFromApi?.redirect?.map(
                                        (e) => (
                                          <React.Fragment key={e}>
                                            {reviewPlatforms(e)}
                                          </React.Fragment>
                                        )
                                      )
                                    )}
                                  </div>
                                </TableCell>

                                <TableCell className="w-[50px]">
                                  <p className="text-center text-newPrimary">
                                    {defaultQuestionSetFromApi?.total_redirected_reviews ||
                                      0}
                                  </p>
                                </TableCell>

                                <TableCell className="w-[180px]">
                                  <p className="text-center text-newPrimary">
                                    {defaultQuestionSetFromApi?.verified_reviews ||
                                      0}
                                  </p>
                                </TableCell>

                                <TableCell>
                                  <div className="flex items-center">
                                    <IconButton>
                                      <Link
                                        className={`w-5 h-5 cursor-pointer text-newPrimary `}
                                        onClick={() => setIsOpenLink("default")}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <QrCode
                                        className="w-5 h-5 cursor-pointer text-newPrimary"
                                        onClick={() => {
                                          handleGetQr();
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell className="text-newPrimarys">
                                  -
                                </TableCell>

                                {isActiveStaffManagement && (
                                  <TableCell>
                                    <DropdownMenu>
                                      <DropdownMenuTrigger>
                                        <IconButton>
                                          <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                        </IconButton>
                                      </DropdownMenuTrigger>
                                      <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => handleResetMagicLink()}
                                        >
                                          <input
                                            type="radio"
                                            name="default"
                                            className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === defaultQuestionSetFromApi && "animate-pulse cursor-progress"} w-4 h-4`}
                                            checked={
                                              defaultQuestionSetFromApi?.data ===
                                              active?.employee_id
                                            }
                                          />
                                          Make a default
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => {
                                            setIsOpenStas(true);
                                            setStatsId(null);
                                          }}
                                        >
                                          <IconButton>
                                            <ChartColumn
                                              className={`w-4 h-4 cursor-pointer`}
                                            />
                                          </IconButton>
                                          Stats
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => {
                                            setIsOpenEditDefaultStaff(true);
                                          }}
                                        >
                                          <IconButton>
                                            <Pencil
                                              className={`w-4 h-4 cursor-pointer`}
                                            />
                                          </IconButton>
                                          Edit
                                        </DropdownMenuItem>
                                      </DropdownMenuContent>
                                    </DropdownMenu>
                                  </TableCell>
                                )}
                              </TableRow>
                            )}
                            <LinkModal
                              openModal={isOpenLink === "default"}
                              setOpenModal={setIsOpenLink}
                              link={
                                baseUrl + "/" + userInfo?.entity?.magic_link
                              }
                            />
                            {data
                              ?.filter((item) => item?.is_enable)
                              ?.filter(
                                (item) => item?.default_set === selectedSet
                              )
                              ?.map((item, i) => (
                                <>
                                  <TableRow
                                    className="bg-white dark:bg-darkBg text-[15px]"
                                    key={item?.id}
                                  >
                                    <TableCell>
                                      <div className="flex items-center gap-2">
                                        <p className="text-[#777] dark:text-white">
                                          {item?.name}
                                        </p>
                                        {item?.id === active?.employee_id && (
                                          <div
                                            key={item}
                                            className="bg-[#f0f5ff]  rounded-lg px-3 py-1 w-fit text-xs text-newPrimary"
                                          >
                                            <p>Default</p>
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="max-w-[120px] w-full">
                                        {item?.label?.length > 14 ? (
                                          <>
                                            {seeMoreLabel === item?.id ? (
                                              <div>
                                                <p className="break-words">
                                                  {item?.label}
                                                </p>
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreLabel()
                                                  }
                                                >
                                                  See Less
                                                </p>
                                              </div>
                                            ) : (
                                              <div>
                                                <p className="line-clamp-1">
                                                  {item?.label?.slice(0, 14)}...
                                                </p>
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreLabel(item?.id)
                                                  }
                                                >
                                                  See More
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <p className="break-words">
                                            {item?.label}
                                          </p>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="flex items-center gap-1 max-w-[150px] w-full flex-wrap">
                                        {item?.tags?.length > 3 ? (
                                          <>
                                            {seeMoreTags === item?.id ? (
                                              <>
                                                {item?.tags?.map((item) => (
                                                  <div
                                                    key={item}
                                                    className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                                  >
                                                    <p>{item}</p>
                                                  </div>
                                                ))}
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreTags()
                                                  }
                                                >
                                                  See Less
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                {item?.tags
                                                  ?.slice(0, 3)
                                                  ?.map((item) => (
                                                    <div
                                                      key={item}
                                                      className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                                    >
                                                      <p>{item}</p>
                                                    </div>
                                                  ))}
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreTags(item?.id)
                                                  }
                                                >
                                                  See More
                                                </p>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {item?.tags?.map((item) => (
                                              <div
                                                key={item}
                                                className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                                              >
                                                <p>{item}</p>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="flex items-center flex-wrap gap-3 w-fit">
                                        {item?.redirect?.length <= 0 ? (
                                          <div
                                            className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                            title="Please select any redirect"
                                          >
                                            <TriangleAlert className="size-4" />{" "}
                                            Action Required
                                          </div>
                                        ) : (
                                          item?.redirect?.map((e) => (
                                            <React.Fragment key={e}>
                                              {reviewPlatforms(e)}
                                            </React.Fragment>
                                          ))
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell className="w-[50px]">
                                      <p className="text-center">
                                        {item?.reviews_count || 0}
                                      </p>
                                    </TableCell>

                                    <TableCell className="w-[180px]">
                                      <p className="text-center">
                                        {item?.verified_reviews || 0}
                                      </p>
                                    </TableCell>

                                    <TableCell>
                                      <div className="flex items-center">
                                        <IconButton title={item?.employee_code}>
                                          <Link
                                            className={`w-5 h-5 cursor-pointer text-[#888] ${!isActiveStaffManagement && "cursor-not-allowed"}`}
                                            onClick={() => {
                                              setIsOpenLink(item?.id);
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton>
                                          <QrCode
                                            className="w-5 h-5 cursor-pointer text-[#888]"
                                            onClick={() =>
                                              handleGetQr(item?.employee_code)
                                            }
                                          />
                                        </IconButton>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div
                                        className={`flex items-center gap-x-3 ${i === 0 && "activate-magiclink"}`}
                                      >
                                        <Switch
                                          disabled={
                                            isToggleEmployeeLoading ||
                                            !isActiveStaffManagement
                                          }
                                          checked={item?.is_enable}
                                          onCheckedChange={() =>
                                            toggleEmployee(item?.id)
                                          }
                                        ></Switch>
                                        {isToggleEmployeeLoading &&
                                          item?.id === employeeId && (
                                            <div className="flex items-center justify-center">
                                              <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>

                                    {isActiveStaffManagement && (
                                      <TableCell>
                                        <DropdownMenu>
                                          <DropdownMenuTrigger>
                                            <IconButton>
                                              <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                            </IconButton>
                                          </DropdownMenuTrigger>
                                          <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() =>
                                                handleDefaultMagicLink(item?.id)
                                              }
                                            >
                                              <input
                                                type="radio"
                                                name="default"
                                                className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"} w-4 h-4`}
                                                id={item?.id}
                                                checked={
                                                  item?.id ===
                                                  active?.employee_id
                                                }
                                              />
                                              Make a default
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() => {
                                                // handleCopyId(item?.employee_code);
                                                setIsOpenRequestReview(
                                                  item?.id
                                                );
                                              }}
                                            >
                                              <IconButton>
                                                <MailPlus className="w-4 h-4 cursor-pointer" />
                                              </IconButton>
                                              Request Reviews
                                              {/* Copy Id */}
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() => {
                                                setIsOpenStas(true);
                                                setStatsId(item?.employee_code);
                                              }}
                                            >
                                              <IconButton>
                                                <ChartColumn
                                                  className={`w-4 h-4 cursor-pointer`}
                                                />
                                              </IconButton>
                                              Stats
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() => {
                                                setIsOpenEditStaffModal(
                                                  item?.id
                                                );
                                              }}
                                            >
                                              <IconButton>
                                                <Pencil
                                                  className={`w-4 h-4 cursor-pointer ${i === 0 && "edit-magiclink"}`}
                                                />
                                              </IconButton>
                                              Edit
                                            </DropdownMenuItem>
                                            {item?.id !==
                                              active?.employee_id && (
                                              <DropdownMenuItem
                                                className="flex items-center gap-2  pr-4 text-[15px] text-red-500 hover:text-red-500"
                                                onClick={() => {
                                                  setIsOpenDeleteModal(true);
                                                  setDeleteId(item?.id);
                                                }}
                                              >
                                                {item?.id !==
                                                  active?.employee_id && (
                                                  <IconButton>
                                                    <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                                                  </IconButton>
                                                )}
                                                Delete
                                              </DropdownMenuItem>
                                            )}
                                          </DropdownMenuContent>
                                        </DropdownMenu>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  {item?.id === isOpenEditStaffModal && (
                                    <EditStaffModal
                                      openModal={
                                        item?.id === isOpenEditStaffModal
                                      }
                                      setOpenModal={setIsOpenEditStaffModal}
                                      refetch={refetch}
                                      entityId={userInfo?.entity?.id}
                                      key={item?.id}
                                      data={item}
                                      // setData={setToUpdateData}
                                    />
                                  )}
                                  {item?.id === isOpenLink && (
                                    <LinkModal
                                      openModal={item?.id === isOpenLink}
                                      setOpenModal={setIsOpenLink}
                                      link={
                                        baseUrl +
                                        "/" +
                                        userInfo?.entity?.magic_link?.split(
                                          "?"
                                        )?.[0] +
                                        `?employee=${item?.employee_code}`
                                      }
                                    />
                                  )}
                                  {item?.id === isOpenRequestReview && (
                                    <RequestReviewModal
                                      openModal={
                                        item?.id === isOpenRequestReview
                                      }
                                      setOpenModal={setIsOpenRequestReview}
                                      link={
                                        baseUrl +
                                        "/" +
                                        userInfo?.entity?.magic_link?.split(
                                          "?"
                                        )?.[0] +
                                        `?employee=${item?.employee_code}`
                                      }
                                    />
                                  )}
                                </>
                              ))}
                            {data
                              ?.filter((item) => !item?.is_enable)
                              ?.filter(
                                (item) => item?.default_set === selectedSet
                              )
                              ?.map((item) => (
                                <>
                                  <TableRow
                                    className="bg-white text-[#777] dark:bg-darkBg"
                                    key={item?.id}
                                  >
                                    <TableCell>
                                      <div className="flex items-center gap-2">
                                        <p className="text-[#777]">
                                          {item?.name}
                                        </p>
                                        {item?.id === active?.employee_id && (
                                          <div
                                            key={item}
                                            className="bg-[#f0f5ff]  rounded-lg px-3 py-1 w-fit text-xs text-newPrimary"
                                          >
                                            <p>Default</p>
                                          </div>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <div className="max-w-[120px] text-[#777]">
                                        {item?.label?.length > 14 ? (
                                          <>
                                            {seeMoreLabel === item?.id ? (
                                              <div>
                                                <p className="break-words">
                                                  {item?.label}
                                                </p>
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreLabel()
                                                  }
                                                >
                                                  See Less
                                                </p>
                                              </div>
                                            ) : (
                                              <div>
                                                <p className="line-clamp-1">
                                                  {item?.label?.slice(0, 14)}...
                                                </p>
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreLabel(item?.id)
                                                  }
                                                >
                                                  See More
                                                </p>
                                              </div>
                                            )}
                                          </>
                                        ) : (
                                          <p className="break-words">
                                            {item?.label}
                                          </p>
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <div className="flex items-center gap-1 w-[100px] flex-wrap text-[#777]">
                                        {item?.tags?.length > 3 ? (
                                          <>
                                            {seeMoreTags === item?.id ? (
                                              <>
                                                {item?.tags?.map((item) => (
                                                  <div
                                                    key={item}
                                                    className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                                  >
                                                    <p>{item}</p>
                                                  </div>
                                                ))}
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreTags()
                                                  }
                                                >
                                                  See Less
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                {item?.tags
                                                  ?.slice(0, 3)
                                                  ?.map((item) => (
                                                    <div
                                                      key={item}
                                                      className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                                    >
                                                      <p>{item}</p>
                                                    </div>
                                                  ))}
                                                <p
                                                  className="text-secondary cursor-pointer hover:underline text-sm"
                                                  onClick={() =>
                                                    setSeeMoreTags(item?.id)
                                                  }
                                                >
                                                  See More
                                                </p>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {item?.tags?.map((item) => (
                                              <div
                                                key={item}
                                                className="bg-[#f4f4f4] border border-[#f4f4f4] rounded-lg px-3 py-1 w-fit text-sm"
                                              >
                                                <p>{item}</p>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="flex items-center w-fit flex-wrap gap-3">
                                        {item?.redirect?.length <= 0 ? (
                                          <div
                                            className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                            title="Please select any redirect"
                                          >
                                            <TriangleAlert className="size-4" />{" "}
                                            Action Required
                                          </div>
                                        ) : (
                                          item?.redirect?.map((e) => (
                                            <React.Fragment key={e}>
                                              {reviewPlatforms(e)}
                                            </React.Fragment>
                                          ))
                                        )}
                                      </div>
                                    </TableCell>

                                    <TableCell className="w-[50px]">
                                      <p className={"text-[#777] text-center"}>
                                        {item?.reviews_count || 0}
                                      </p>
                                    </TableCell>

                                    <TableCell className="w-[180px]">
                                      <p className="text-center">
                                        {item?.verified_reviews || 0}
                                      </p>
                                    </TableCell>

                                    <TableCell>
                                      <div className="flex items-center">
                                        {/* <p className="w-24">{item?.employee_code}</p> */}
                                        <IconButton title={item?.employee_code}>
                                          <Link
                                            className={`w-5 h-5 cursor-pointer text-[#888]`}
                                            onClick={() => {
                                              // handleCopy(item?.employee_code)

                                              setIsOpenLink(item?.id);
                                            }}
                                          />
                                        </IconButton>
                                        <IconButton>
                                          <QrCode
                                            className="w-5 h-5 cursor-pointer text-[#888]"
                                            onClick={() =>
                                              handleGetQr(item?.employee_code)
                                            }
                                          />
                                        </IconButton>
                                      </div>
                                    </TableCell>
                                    <TableCell>
                                      <div className="flex items-center gap-x-3">
                                        <Switch
                                          disabled={
                                            isToggleEmployeeLoading ||
                                            !isActiveStaffManagement
                                          }
                                          checked={item?.is_enable}
                                          onCheckedChange={() =>
                                            toggleEmployee(item?.id)
                                          }
                                        ></Switch>
                                        {isToggleEmployeeLoading &&
                                          item?.id === employeeId && (
                                            <div className="flex items-center justify-center">
                                              <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                            </div>
                                          )}
                                      </div>
                                    </TableCell>
                                    {isActiveStaffManagement && (
                                      <TableCell>
                                        <DropdownMenu>
                                          <DropdownMenuTrigger>
                                            <IconButton>
                                              <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                            </IconButton>
                                          </DropdownMenuTrigger>
                                          <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() =>
                                                handleDefaultMagicLink(item?.id)
                                              }
                                            >
                                              <input
                                                type="radio"
                                                name="default"
                                                className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === item?.id && "animate-pulse cursor-progress"} w-4 h-4`}
                                                id={item?.id}
                                                checked={
                                                  item?.id ===
                                                  active?.employee_id
                                                }
                                              />
                                              Make a default
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4] cursor-not-allowed"
                                              // onClick={() => {
                                              //   // handleCopyId(item?.employee_code);
                                              //   setIsOpenRequestReview(item?.id);
                                              // }}
                                            >
                                              <IconButton>
                                                <MailPlus className="w-4 h-4 cursor-pointer" />
                                              </IconButton>
                                              Request Reviews
                                              {/* Copy Id */}
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() => {
                                                setIsOpenStas(true);
                                                setStatsId(item?.employee_code);
                                              }}
                                            >
                                              <IconButton>
                                                <ChartColumn
                                                  className={`w-4 h-4 cursor-pointer`}
                                                />
                                              </IconButton>
                                              Stats
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2  pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                              onClick={() => {
                                                setIsOpenEditStaffModal(
                                                  item?.id
                                                );
                                              }}
                                            >
                                              <IconButton>
                                                <Pencil className="w-4 h-4 cursor-pointer" />
                                              </IconButton>
                                              Edit
                                            </DropdownMenuItem>
                                            <DropdownMenuItem
                                              className="flex items-center gap-2 pr-4 text-[15px] text-red-500 hover:text-red-500"
                                              onClick={() => {
                                                setIsOpenDeleteModal(true);
                                                setDeleteId(item?.id);
                                              }}
                                            >
                                              {item?.id !==
                                                active?.employee_id && (
                                                <IconButton>
                                                  <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                                                </IconButton>
                                              )}
                                              Delete
                                            </DropdownMenuItem>
                                          </DropdownMenuContent>
                                        </DropdownMenu>
                                        {/* <div className="flex justify-end items-center text-[#888]">
                                        <IconButton>
                                          <Pencil
                                            className="w-5 h-5 cursor-pointer"
                                            onClick={() => {
                                              setIsOpenEditStaffModal(item?.id);
                                            }}
                                          />
                                        </IconButton>

                                        {item?.id !== active?.employee_id && (
                                          <IconButton>
                                            <Trash2
                                              className="w-5 h-5 cursor-pointer text-red-500"
                                              onClick={() => {
                                                setIsOpenDeleteModal(true);
                                                setDeleteId(item?.id);
                                              }}
                                            />
                                          </IconButton>
                                        )}
                                      </div> */}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  {item?.id === isOpenEditStaffModal && (
                                    <EditStaffModal
                                      openModal={
                                        item?.id === isOpenEditStaffModal
                                      }
                                      setOpenModal={setIsOpenEditStaffModal}
                                      refetch={refetch}
                                      entityId={userInfo?.entity?.id}
                                      key={item?.id}
                                      data={item}
                                      // setData={setToUpdateData}
                                    />
                                  )}
                                  {item?.id === isOpenLink && (
                                    <LinkModal
                                      openModal={item?.id === isOpenLink}
                                      setOpenModal={setIsOpenLink}
                                      link={
                                        baseUrl +
                                        "/" +
                                        userInfo?.entity?.magic_link?.split(
                                          "?"
                                        )?.[0] +
                                        `?employee=${item?.employee_code}`
                                      }
                                    />
                                  )}
                                </>
                              ))}
                          </>
                        ) : (
                          <>
                            {selectedSet === defaultQuestionSetFromApi?.data ? (
                              <TableRow className="bg-[#fafafa] dark:bg-darkBg text-[15px]  relative z-30  ring-1 ring-[#ebebeb] ">
                                <TableCell className="relative">
                                  <p className="text-newPrimary">-</p>
                                </TableCell>
                                <TableCell className="text-newPrimary">
                                  <div className="max-w-[120px] w-full">-</div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center gap-1 max-w-[150px] w-full flex-wrap">
                                    {/* <div className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"> */}
                                    <p className="text-newPrimary">-</p>
                                    {/* </div> */}
                                  </div>
                                </TableCell>
                                <TableCell>
                                  <div className="flex items-center flex-wrap gap-3 w-fit">
                                    {defaultQuestionSetFromApi?.redirect
                                      ?.length <= 0 ? (
                                      <div
                                        className="flex items-center gap-1.5 bg-[#f9c33c20] text-[#ffaf19] p-2 text-sm rounded-lg"
                                        title="Please select any redirect"
                                      >
                                        <TriangleAlert className="size-4" />{" "}
                                        Action Required
                                      </div>
                                    ) : (
                                      defaultQuestionSetFromApi?.redirect?.map(
                                        (e) => (
                                          <React.Fragment key={e}>
                                            {reviewPlatforms(e)}
                                          </React.Fragment>
                                        )
                                      )
                                    )}
                                  </div>
                                </TableCell>

                                <TableCell className="w-[50px]">
                                  <p className="text-center text-newPrimary">
                                    {defaultQuestionSetFromApi?.total_redirected_reviews ||
                                      0}
                                  </p>
                                </TableCell>

                                <TableCell className="w-[180px]">
                                  <p className="text-center text-newPrimary">
                                    {defaultQuestionSetFromApi?.verified_reviews ||
                                      0}
                                  </p>
                                </TableCell>

                                <TableCell>
                                  <div className="flex items-center">
                                    {/* <p className="w-24">{item?.employee_code}</p> */}
                                    <IconButton>
                                      <Link
                                        className={`w-5 h-5 cursor-pointer text-newPrimary `}
                                        onClick={() => setIsOpenLink("default")}
                                      />
                                    </IconButton>
                                    <IconButton>
                                      <QrCode
                                        className="w-5 h-5 cursor-pointer text-newPrimary"
                                        onClick={() => {
                                          handleGetQr();
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </TableCell>
                                <TableCell className="text-newPrimarys">
                                  -
                                </TableCell>

                                {isActiveStaffManagement && (
                                  <TableCell>
                                    <DropdownMenu>
                                      <DropdownMenuTrigger>
                                        <IconButton>
                                          <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                        </IconButton>
                                      </DropdownMenuTrigger>
                                      <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pt-2 pb-3 px-3 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => handleResetMagicLink()}
                                        >
                                          <input
                                            type="radio"
                                            name="default"
                                            className={`cursor-pointer accent-secondary appearance-none checked:accent-secondary border-2  ${defaultMagicLinkLoading && loadingMagicLink === defaultQuestionSetFromApi && "animate-pulse cursor-progress"} w-4 h-4`}
                                            checked={
                                              defaultQuestionSetFromApi?.data ===
                                              active?.employee_id
                                            }
                                          />
                                          Make a default
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => {
                                            setIsOpenStas(true);
                                            setStatsId(null);
                                          }}
                                        >
                                          <IconButton>
                                            <ChartColumn
                                              className={`w-4 h-4 cursor-pointer`}
                                            />
                                          </IconButton>
                                          Stats
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                          className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                          onClick={() => {
                                            setIsOpenEditDefaultStaff(true);
                                          }}
                                        >
                                          <IconButton>
                                            <Pencil
                                              className={`w-4 h-4 cursor-pointer`}
                                            />
                                          </IconButton>
                                          Edit
                                        </DropdownMenuItem>
                                      </DropdownMenuContent>
                                    </DropdownMenu>
                                  </TableCell>
                                )}
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={9}>
                                  <MagiclinkIllustrations />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      )}

      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      <EditDefaultStaffModal
        openModal={isOpenEditDefaultStaff}
        setOpenModal={setIsOpenEditDefaultStaff}
        refetch={refetchDefaultQuestionSet}
        data={defaultQuestionSetFromApi}
        // key={new Date()}
      />
      {isOpenAddStaffModal && (
        <AddStaffModal
          openModal={isOpenAddStaffModal}
          setOpenModal={setIsOpenAddStaffModal}
          refetch={refetch}
          entityId={userInfo?.entity?.id}
          // key={toUpdateData || uuidv4()}
          data={toUpdateData}
          setData={setToUpdateData}
          selectedSet={selectedSet}
        />
      )}
      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={"Are you sure you want to delete this link?"}
        handleDelete={handleDeleteEmployee}
        isLoading={deleteEmployeeLoading}
      />
      <QrContainer
        openEditModal={isOpenQrContainer}
        setOpenEditModal={setIsOpenQrContainer}
        qr={qr}
        isLoading={getMagicQrLoading}
      />
    </Layout>
  );
};

export default Staffs;
