import React, { useRef, useState } from "react";
import Layout from "../component/Layout";
import {
  Check,
  CircleCheckBig,
  CircleDashed,
  CircleX,
  Download,
  Ellipsis,
  EllipsisVertical,
  Eye,
  FilePlus,
  Trash2,
  UploadCloud,
  X,
} from "lucide-react";
import { Progress } from "../../../components/ui/progress";
import {
  useDeleteKnowledgeBase,
  useDeleteKnowledgeUrl,
  useGetStorageUsage,
  useGetUploadKnowledgeBase,
  useGetUploadKnowledgeUrl,
  usePostUploadKnowledgeBase,
  usePostUploadKnowledgeUrl,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { LogoLoading } from "../../../components/loading/loading";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { dayFormatter } from "../../../utils/formatter";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Button } from "../../../components/ui/button";
import axios from "axios";
import { DeleteModal } from "../component/modal";
import { Textarea } from "../../../components/ui/textarea";
import { motion } from "framer-motion";
import { cn } from "../../../utils/cn";

export function GridPattern() {
  const columns = 41;
  const rows = 11;
  return (
    <div className="flex bg-gray-100 dark:bg-neutral-900 flex-shrink-0 flex-wrap justify-center items-center gap-x-px gap-y-px  scale-105">
      {Array.from({ length: rows }).map((_, row) =>
        Array.from({ length: columns }).map((_, col) => {
          const index = row * columns + col;
          return (
            <div
              key={`${col}-${row}`}
              className={`w-10 h-10 flex flex-shrink-0 rounded-[2px] ${
                index % 2 === 0
                  ? "bg-gray-50 dark:bg-neutral-950"
                  : "bg-gray-50 dark:bg-neutral-950 shadow-[0px_0px_1px_3px_rgba(255,255,255,1)_inset] dark:shadow-[0px_0px_1px_3px_rgba(0,0,0,1)_inset]"
              }`}
            />
          );
        })
      )}
    </div>
  );
}

const mainVariant = {
  initial: {
    x: 0,
    y: 0,
  },
  animate: {
    x: 20,
    y: -20,
    opacity: 0.9,
  },
};

const secondaryVariant = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
};

const KnowledgeBase = () => {
  const { darkMode } = useDarkMode();
  const fileRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState("");
  const [isDownloadLoading, setIsDownloadLoading] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenDeleteUrlModal, setIsOpenDeleteUrlModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteUrlId, setDeleteUrlId] = useState();
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState("");

  const { data: storageUsage, refetch: refetchStorageUsage } =
    useGetStorageUsage();
  const {
    data: knowledgeBase,
    refetch: refetchKnowledgeBase,
    isLoading: knowledgeBaseLoading,
  } = useGetUploadKnowledgeBase();

  const {
    data: knowledgeUrl,
    refetch: refetchKnowledgeUrl,
    isLoading: knowledgeUrlLoading,
  } = useGetUploadKnowledgeUrl();

  const { mutate: uploadKnowledgeBase, isLoading: uploadKnowledgeBaseLoading } =
    usePostUploadKnowledgeBase();

  const { mutate: deleteKnowledgeBase, isLoading: deleteKnowledgeBaseLoading } =
    useDeleteKnowledgeBase();

  const { mutate: uploadKnowledgeUrl, isLoading: uploadKnowledgeUrlLoading } =
    usePostUploadKnowledgeUrl();

  const { mutate: deleteKnowledgeUrl, isLoading: deleteKnowledgeUrlLoading } =
    useDeleteKnowledgeUrl();

  const textRef = useRef(null);

  const handleInput = () => {
    if (textRef.current) {
      textRef.current.style.height = "auto"; // Reset height to auto to shrink if necessary
      textRef.current.style.height = `${textRef.current.scrollHeight}px`; // Set to scrollHeight
    }
  };

  const limitFileSize = 50 - storageUsage?.total_storage_used_mb;
  const calculateTotalSize = (fileArray) => {
    return (
      fileArray.reduce((total, file) => total + file.size, 0) / (1024 * 1024)
    ); // Convert to MB
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const acceptedTypes = [
    "text/csv",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
  ];

  const handleFileValidation = (uploadedFiles) => {
    const totalCurrentSize = calculateTotalSize([...files, ...uploadedFiles]);
    if (totalCurrentSize > limitFileSize) {
      toast.error(`Total file size exceeds ${limitFileSize} MB.`);
      return;
    }

    const validFiles = [];
    const rejectedFiles = [];

    uploadedFiles.forEach((file) => {
      if (acceptedTypes.includes(file.type)) {
        validFiles.push(file);
      } else {
        rejectedFiles.push(file.name);
        toast.error(`"${file.name}" is not a supported file type.`);
      }
    });

    if (validFiles.length) {
      setFiles((prevFiles) => [...prevFiles, ...validFiles]);
    }

    if (rejectedFiles.length) {
      console.warn("Rejected Files:", rejectedFiles);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const uploadedFiles = Array.from(e.dataTransfer.files);
    handleFileValidation(uploadedFiles);

    // if (acceptedTypes.includes(uploadFile.type)) {
    //   if (uploadFile.size / (1024 * 1024) < limitFileSize) {
    //     setFiles(uploadFile);

    //     setError(null); // Clear any previous error
    //   } else if (uploadFile) {
    //     setError(`File size must be less than ${limitFileSize} MB.`);
    //   }
    // } else {
    //   toast.error("Only accept .pdf, .csv, .doc, and .docx");
    // }
  };

  const handleFileChange = (e) => {
    const uploadedFiles = Array.from(e.target.files);
    handleFileValidation(uploadedFiles);
    fileRef.current.value = ""; // Reset file input
    // if (uploadFile) {
    //   if (acceptedTypes.includes(uploadFile.type)) {
    //     if (uploadFile?.size / (1024 * 1024) < limitFileSize) {
    //       setFiles(uploadFile);
    //       fileRef.current.value = "";
    //     } else {
    //       toast.error(
    //         `File size must be less than ${100 - storageUsage?.total_storage_used_mb} MB.`
    //       );
    //       fileRef.current.value = "";
    //     }
    //   } else {
    //     toast.error("Only accept .pdf, .csv, .doc, and .docx");
    //     fileRef.current.value = "";
    //   }
    // }
  };

  const handleFileUpload = () => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]); // Match this name to `request.FILES.getlist('files')` in Django.
    }
    // formData.append("files", files);
    uploadKnowledgeBase(formData, {
      onSuccess: (res) => {
        toast.success(res.message);
        setFiles([]);
        refetchStorageUsage();
        refetchKnowledgeBase();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleFileDelete = () => {
    deleteKnowledgeBase(deleteId, {
      onSuccess: (res) => {
        toast.success(res.message);
        setDeleteId();
        setIsOpenDeleteModal(false);
        refetchStorageUsage();
        refetchKnowledgeBase();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleUrlUpload = () => {
    if (!url.startsWith("https")) {
      toast.error("Url must start with https");
      return;
    }
    uploadKnowledgeUrl(
      {
        url: url,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setUrl(null);
          refetchKnowledgeUrl();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUrlDelete = () => {
    deleteKnowledgeUrl(deleteUrlId, {
      onSuccess: (res) => {
        toast.success(res.message);
        setDeleteUrlId();
        setIsOpenDeleteUrlModal(false);

        refetchKnowledgeUrl();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleDownload = async (file) => {
    const fileName = file?.file?.split("/")?.[6]?.split("?")?.[0];
    setIsDownloadLoading(file?.id);
    try {
      const response = await axios.get(file?.file, {
        responseType: "blob",
      });

      const downloadUrl = URL.createObjectURL(response?.data);

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownloadLoading();

      // Clean up the object URL
      URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Download failed:", error);
      setIsDownloadLoading();
    }
    setIsDownloadLoading();
  };

  if (uploadKnowledgeBaseLoading || uploadKnowledgeUrlLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Knowledge Base"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-320px)] px-5 lg:px-[30.2px] overflow-hidden">
        <div className="space-y-5 font-pro">
          <div className="flex flex-col w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm">
            <p className="font-poppins text-[#888] dark:text-white text-sm">
              <span className="text-black dark:text-white">
                {storageUsage?.total_storage_used_mb || 0}/50 MB
              </span>{" "}
              left to upload files
            </p>
            <Progress
              value={storageUsage?.total_storage_used_mb || 0}
              className="h-1.5 mt-3"
              indicatorColor={"#4f75fa"}
            />
          </div>
          <div className=" bg-white dark:bg-darkBg rounded-xl p-5 shadow-sm space-y-5">
            <div className="rounded-[16px] border border-newPrimary p-5 flex flex-col lg:flex-row lg:items-center gap-5 divide-newPrimary">
              <div className="w-full space-y-5 ">
                <div className="mx-auto">
                  <motion.div
                    className={`w-full h-72  relative mx-auto group/file flex flex-col items-center justify-between rounded-lg p-5`}
                    onDragEnter={handleDrag}
                    onDragOver={handleDrag}
                    onDragLeave={handleDrag}
                    onDrop={handleDrop}
                    whileHover={"animate"}
                  >
                    <div className="text-center space-y-1 z-30">
                      <p className="font-medium font-poppins">Upload Files</p>
                      <span className="text-gray-400 text-sm font-poppins">
                        Drag and drop files, or click to select
                      </span>
                    </div>
                    <label
                      htmlFor="upload"
                      className="flex flex-col items-center justify-center text-center cursor-pointer gap-0.5 w-full relative"
                    >
                      {/* <FilePlus className="text-gray-400 mb-1" /> */}
                      <motion.div
                        variants={mainVariant}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                          damping: 20,
                        }}
                        className={cn(
                          "relative group-hover/file:shadow-2xl z-40 bg-white dark:bg-neutral-900 flex items-center justify-center h-32 mt-4 w-full max-w-[8rem] mx-auto rounded-md ",
                          "shadow-[0px_10px_50px_rgba(0,0,0,0.1)]"
                        )}
                      >
                        {dragActive ? (
                          <motion.p
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            className="text-neutral-600 flex flex-col items-center"
                          >
                            Drop it
                            <UploadCloud className="h-4 w-4 text-neutral-600 dark:text-neutral-400" />
                          </motion.p>
                        ) : (
                          <img src="/file-upload.svg" alt="" className="" />
                        )}
                      </motion.div>

                      <motion.div
                        variants={secondaryVariant}
                        className="absolute border border-dashed border-newPrimary inset-0 z-30 bg-transparent flex items-center justify-center h-32 mt-4 w-full max-w-[8rem] mx-auto rounded-md"
                      ></motion.div>

                      <input
                        type="file"
                        id="upload"
                        hidden
                        onChange={handleFileChange}
                        accept=".doc, .docx, .pdf, .csv"
                        multiple
                      />
                    </label>
                    <span className="text-gray-400 text-xs w-10/12 text-center font-poppins z-30">
                      Accepted formats: pdf, doc, docx and csv files
                    </span>
                    <div className="absolute inset-0 [mask-image:radial-gradient(ellipse_at_center,white,transparent)] z-0">
                      <GridPattern />
                    </div>
                    {/* )} */}
                  </motion.div>
                  {/* {error && <p className="text-red-500 mt-2 text-xs">{error}</p>} */}
                </div>
              </div>

              <div className="hidden lg:block w-2 rounded-full h-44 bg-newPrimary"></div>

              <div className="w-full space-y-5 ">
                <p className="font-medium font-poppins text-center">
                  Website Link
                </p>

                <div className="space-y-[17px]">
                  <div>
                    <Textarea
                      placeholder="https://"
                      rows={5}
                      value={url}
                      onChange={(e) => setUrl(e.target.value)}
                      ref={textRef}
                      onInput={handleInput}
                      className="min-h-5 scrollbar-none"
                    />
                  </div>

                  <div className="flex justify-end">
                    <Button
                      className="px-10 text-sm font-poppins"
                      onClick={handleUrlUpload}
                    >
                      Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {files?.length > 0 && (
              <>
                <ul className="gap-3 grid grid-cols-1 lg:grid-cols-2">
                  {files.map((file, index) => (
                    <li
                      key={index}
                      className="border rounded-lg p-3 flex items-center justify-between"
                    >
                      <div className="space-y-2">
                        <p className="font-pro font-medium text-[15px]">
                          {file.name}
                        </p>
                        <p className="flex items-center gap-3 text-xs text-[#656565]">
                          Date:{" "}
                          {file?.lastModified &&
                            dayFormatter(file?.lastModified)}
                        </p>
                      </div>
                      <X
                        onClick={() =>
                          setFiles((prev) =>
                            prev?.filter((item) => item?.name !== file?.name)
                          )
                        }
                        className="size-4 cursor-pointer"
                      />
                    </li>
                  ))}
                </ul>
                <div className="flex items-center justify-end gap-3">
                  <Button onClick={handleFileUpload}>Save</Button>
                  <Button variant="secondary" onClick={() => setFiles([])}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
          </div>
          <div className="grid lg:grid-cols-2 gap-5 mt-5 ">
            <div className="w-full bg-white dark:bg-darkBg rounded-xl px-5 shadow-sm">
              <p className="font-medium font-pro py-5  border-b-2">
                Uploaded Files
              </p>
              <div className="">
                {knowledgeBase?.length <= 0 ? (
                  <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkBg rounded-lg font-pro">
                    <img
                      src="/illustrations/invoice.svg"
                      alt="Questions"
                      className="w-[100px] h-[100px] mx-auto mt-10"
                    />
                    <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                      Uploaded Files
                    </h1>
                    <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                      Your uploaded files will appear here!
                    </p>
                  </div>
                ) : (
                  <div className="rounded-xl overflow-y-auto h-[40vh] scrollbar-thin bg-white dark:bg-darkBg font-pro divide-y-2">
                    {knowledgeBaseLoading ? (
                      <div className="py-5">
                        {new Array(2).fill("").map((_, i) => (
                          <div key={i} className="bg-white dark:bg-darkBg">
                            <Skeleton
                              height={60}
                              baseColor={darkMode && "#262626"}
                            />
                          </div>
                        ))}
                      </div>
                    ) : knowledgeBase?.length > 0 ? (
                      knowledgeBase?.map((charge, i) => (
                        <div
                          key={i}
                          className="flex lg:justify-between lg:items-center py-5 lg:flex-row flex-col gap-5"
                        >
                          <div className="space-y-2">
                            <p className="font-medium text-[15px] text-ellipsis lg:w-[250px]">
                              {charge?.file?.split("/")?.[6]?.split("?")?.[0]}
                            </p>
                            <p className="flex items-center gap-3 text-xs text-[#656565]">
                              Date:{" "}
                              {charge?.uploaded_at &&
                                dayFormatter(charge?.uploaded_at)}
                            </p>
                          </div>
                          <div className="flex lg:items-center gap-2 lg:flex-row  flex-col">
                            <div>
                              {charge?.embedded ? (
                                <p className="text-[#34a853] flex items-center gap-2 font-poppins  text-xs">
                                  <CircleCheckBig className="size-4" /> Embedded
                                </p>
                              ) : (
                                <p className="text-[#ffaf19] flex items-center gap-2 font-poppins  text-xs">
                                  {" "}
                                  <CircleDashed className="size-4 " />{" "}
                                  Processing
                                </p>
                              )}
                            </div>
                            {/* <Button
                        variant="secondary"
                        className="gap-2 text-sm"
                        onClick={() => {
                          handleDownload(charge);
                        }}
                      >
                        {isDownloadLoading === charge?.id ? (
                          <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                        ) : (
                          <Download className="cursor-pointer size-4" />
                        )}
                        Download
                      </Button>
                      <Button
                        variant="secondary"
                        className="gap-2 text-sm"
                        onClick={() => {
                          // window.location.href = charge?.file;
                          window.open(charge?.file, "blank");
                        }}
                      >
                        <Eye className="cursor-pointer size-4" />
                        Preview
                      </Button> */}
                            <div>
                              <DropdownMenu>
                                <DropdownMenuTrigger>
                                  <Ellipsis className="size-4 text-[#595959]" />
                                </DropdownMenuTrigger>
                                <DropdownMenuContent>
                                  <div className="font-pro text-xs flex flex-col gap-1">
                                    <Button
                                      variant="ghost"
                                      className="gap-2 justify-start w-full"
                                      onClick={() => {
                                        handleDownload(charge);
                                      }}
                                    >
                                      {isDownloadLoading === charge?.id ? (
                                        <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                                      ) : (
                                        <Download className="cursor-pointer size-4" />
                                      )}
                                      Download
                                    </Button>
                                    <Button
                                      variant="ghost"
                                      className="gap-2 justify-start w-full"
                                      onClick={() => {
                                        // window.location.href = charge?.file;
                                        window.open(charge?.file, "blank");
                                      }}
                                    >
                                      <Eye className="cursor-pointer size-4" />
                                      Preview
                                    </Button>
                                    <Button
                                      variant="ghost"
                                      className="gap-2 justify-start w-full text-red-500"
                                      onClick={() => {
                                        setIsOpenDeleteModal(true);
                                        setDeleteId(charge?.id);
                                      }}
                                    >
                                      <Trash2 className="size-4 " />
                                      Delete
                                    </Button>
                                  </div>
                                </DropdownMenuContent>
                              </DropdownMenu>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : null}
                  </div>
                )}
              </div>
            </div>
            <div className="w-full bg-white dark:bg-darkBg rounded-xl px-5 shadow-sm">
              <p className="font-medium font-pro py-5 border-b-2">
                Uploaded Links
              </p>
              <div className="">
                {knowledgeUrl?.length <= 0 ? (
                  <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkBg rounded-lg font-pro">
                    <img
                      src="/illustrations/invoice.svg"
                      alt="Questions"
                      className="w-[100px] h-[100px] mx-auto mt-10"
                    />
                    <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                      Uploaded Links
                    </h1>
                    <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                      Your uploaded links will appear here!
                    </p>
                  </div>
                ) : (
                  <div className="rounded-xl overflow-y-auto h-[40vh] scrollbar-thin bg-white dark:bg-darkBg font-pro divide-y-2">
                    {knowledgeUrlLoading ? (
                      <div className="py-5">
                        {new Array(2).fill("").map((_, i) => (
                          <div key={i} className="bg-white dark:bg-darkBg">
                            <Skeleton
                              height={60}
                              baseColor={darkMode && "#262626"}
                            />
                          </div>
                        ))}
                      </div>
                    ) : knowledgeUrl?.length > 0 ? (
                      knowledgeUrl?.map((charge, i) => (
                        <div
                          key={i}
                          className="flex lg:justify-between lg:items-center py-5 lg:flex-row flex-col gap-5"
                        >
                          <div className="space-y-2">
                            <p className="font-medium text-[15px]">
                              {charge?.url}
                            </p>
                            {/* <p className="flex items-center gap-3 text-xs text-[#656565]">
                            Date:{" "}
                            {charge?.uploaded_at &&
                              dayFormatter(charge?.uploaded_at)}
                          </p> */}
                          </div>
                          <div className="flex lg:items-center gap-2 lg:flex-row  flex-col">
                            <div>
                              {charge?.embedded ? (
                                <p className="text-[#34a853] flex items-center gap-2 font-poppins  text-xs">
                                  <CircleCheckBig className="size-4" /> Embedded
                                </p>
                              ) : (
                                <p className="text-[#ffaf19] flex items-center gap-2 font-poppins  text-xs">
                                  {" "}
                                  <CircleDashed className="size-4 " /> Scrapping
                                </p>
                              )}
                            </div>
                            <div
                              className="p-2 bg-[#ff6464]/15 rounded-[8px] cursor-pointer  text-[#ff6464] w-fit"
                              onClick={() => {
                                setIsOpenDeleteUrlModal(true);
                                setDeleteUrlId(charge?.id);
                              }}
                            >
                              <Trash2 className="w-4 h-4 " />
                            </div>
                            {/* <Button
                        variant="secondary"
                        className="gap-2 text-sm"
                        onClick={() => {
                          handleDownload(charge);
                        }}
                      >
                        {isDownloadLoading === charge?.id ? (
                          <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                        ) : (
                          <Download className="cursor-pointer size-4" />
                        )}
                        Download
                      </Button>
                      <Button
                        variant="secondary"
                        className="gap-2 text-sm"
                        onClick={() => {
                          // window.location.href = charge?.file;
                          window.open(charge?.file, "blank");
                        }}
                      >
                        <Eye className="cursor-pointer size-4" />
                        Preview
                      </Button> */}
                            {/* <div>
                            <DropdownMenu>
                              <DropdownMenuTrigger>
                                <Ellipsis className="size-4 text-[#595959]" />
                              </DropdownMenuTrigger>
                              <DropdownMenuContent>
                                <div className="font-pro text-xs flex flex-col gap-1">
                                  <Button
                                    variant="ghost"
                                    className="gap-2 justify-start w-full"
                                    onClick={() => {
                                      handleDownload(charge);
                                    }}
                                  >
                                    {isDownloadLoading === charge?.id ? (
                                      <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                                    ) : (
                                      <Download className="cursor-pointer size-4" />
                                    )}
                                    Download
                                  </Button>
                                  <Button
                                    variant="ghost"
                                    className="gap-2 justify-start w-full"
                                    onClick={() => {
                                      // window.location.href = charge?.file;
                                      window.open(charge?.file, "blank");
                                    }}
                                  >
                                    <Eye className="cursor-pointer size-4" />
                                    Preview
                                  </Button>
                                  <Button
                                    variant="ghost"
                                    className="gap-2 justify-start w-full text-red-500"
                                    onClick={() => {
                                      setIsOpenDeleteModal(true);
                                      setDeleteId(charge?.id);
                                    }}
                                  >
                                    <Trash2 className="size-4 " />
                                    Delete
                                  </Button>
                                </div>
                              </DropdownMenuContent>
                            </DropdownMenu>
                          </div> */}
                          </div>
                        </div>
                      ))
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between lg:flex-nowrap flex-wrap gap-5 mt-5"></div>
        <DeleteModal
          handleDelete={handleFileDelete}
          openModal={isOpenDeleteModal}
          setOpenModal={setIsOpenDeleteModal}
          title={"Are you sure you want to delete this file?"}
          isLoading={deleteKnowledgeBaseLoading}
        />
        <DeleteModal
          handleDelete={handleUrlDelete}
          openModal={isOpenDeleteUrlModal}
          setOpenModal={setIsOpenDeleteUrlModal}
          title={"Are you sure you want to delete this url?"}
          isLoading={deleteKnowledgeUrlLoading}
        />

        {/* <Table
        className="mt-5 w-full"
        theme={{
          root: {
            base: "w-full text-left text-sm lg:text-base ",
            shadow:
              "absolute left-0 top-0 -z-10 h-full w-full rounded-lg bg-white",
          },
          head: {
            base: "group/head font-pro dark:text-darkGray",
            cell: {
              base: " bg-slate-200 dark:bg-darkBg px-6 py-3 group-first/head:first:rounded-tl-xl group-first/head:last:rounded-tr-xl",
            },
          },
          body: {
            base: "group/body",
            cell: {
              base: "px-6 py-4 group-first/body:group-first/row:first:rounded-tl-xl group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-xl group-last/body:group-last/row:last:rounded-br-xl",
            },
          },
        }}
      >
        <Table.Head className="border-b ">
          {tableHead.map((head, index) => (
            <Table.HeadCell key={index}>{head.title}</Table.HeadCell>
          ))}
          <TableHeadCell></TableHeadCell>
        </Table.Head>
        <Table.Body>
          {data.map((file, index) => (
            <Table.Row key={index} className="bg-white dark:bg-darkBg border-b border-t">
              <Table.Cell>
                <div className="flex items-center">
                  <div className="w-5 h-5 bg-gray-200 rounded-md mr-3"></div>
                  <p className="font-medium">{file.name}</p>
                </div>
              </Table.Cell>
              <Table.Cell>{file.date}</Table.Cell>
              <Table.Cell>{file.updated}</Table.Cell>
              <Table.Cell>
                <div className="flex items-center">
                 <img src="/blogpro4.svg" className="w-10 h-10 rounded-full mr-3" />
                  <p className="font-medium">{file.owner}</p>
                </div>
              </Table.Cell>
              <Table.Cell>
                <EllipsisVertical size={20} />
                </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table> */}

        {/* <div className="mt-5">
          <h3 className="text-lg font-semibold">Uploaded Files:</h3>
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        </div> */}
      </div>
    </Layout>
  );
};

export default KnowledgeBase;
