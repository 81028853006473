/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { ChevronDown, FilePlus, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { forwardRef, useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  usePostEventAdd,
  usePostEventGenerate,
  usePostOfferAdd,
  usePostOfferGenerate,
  usePostStandardAdd,
  usePostStandardGenerate,
  usePostUploadMedia,
} from "../../../../query/useReview";
import LoaderIcon from "react-loader-icon";
import { Textarea } from "../../../../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Input } from "../../../../components/ui/input";
import { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { dayFormatter, getShortMonthName } from "../../../../utils/formatter";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";

const DateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className="relative w-full" onClick={onClick} ref={ref}>
    <Input
      type="text"
      value={value}
      readOnly
      className="w-full focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0"
      placeholder={placeholder}
    />
    <ChevronDown className="text-[#ebebeb] absolute right-5 top-1/2 -translate-y-1/2 size-4" />
  </div>
));

const AddEventModal = ({ openModal, setOpenModal }) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [extension, setExtension] = useState(null);
  const [media, setMedia] = useState(null);
  const [offerTitle, setOfferTitle] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [description, setDescription] = useState("");
  const [coupounCode, setCoupounCode] = useState("");
  const [redeemUrl, setRedeemUrl] = useState("");
  const [terms, setTerms] = useState("");
  const [cta, setCta] = useState("NONE");
  const [url, setUrl] = useState("");
  const [generatedPost, setGeneratedPost] = useState(null);
  const [summary, setSummary] = useState("");
  const [isPreview, setIsPreview] = useState(false);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const {
    mutate: uploadMedia,
    isLoading: uploadMediaLoading,
    isError: uploadMediaLoadingError,
  } = usePostUploadMedia();

  const { mutate: standardGenerate, isLoading: standardGenerateLoading } =
    usePostEventGenerate();

  const { mutate: standardAdd, isLoading: standardAddLoading } =
    usePostEventAdd();

  const handleUploadMedia = (file) => {
    const ext = file.type.includes("image") ? "PHOTO" : "VIDEO";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("mediaFormat", ext);
    uploadMedia(formData, {
      onSuccess: (res) => {
        setMedia(res.media);
        setImagePreview(URL.createObjectURL(file));
        setError(null); // Clear any previous error
        setExtension(ext);
      },
      onError: (err) => {
        if (err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      },
    });
  };

  const handleStandardGenerate = () => {
    standardGenerate(
      {
        media: media,
        input: description,
        callToAction:
          cta === "NONE"
            ? {}
            : {
                actionType: cta,
                url: url,
              },
        event: {
          title: offerTitle,
          schedule: {
            startDate: {
              year: startDate.getUTCFullYear(),
              month: startDate.getUTCMonth() + 1,
              day: startDate.getUTCDate(),
            },
            startTime: {
              hours: startDate.getUTCHours(),
              minutes: startDate.getUTCMinutes(),
              seconds: startDate.getUTCSeconds(),
              nanos: 0,
            },
            endDate: {
              year: endDate.getUTCFullYear(),
              month: endDate.getUTCMonth() + 1,
              day: endDate.getUTCDate(),
            },
            endTime: {
              hours: endDate.getUTCHours(),
              minutes: endDate.getUTCMinutes(),
              seconds: endDate.getUTCSeconds(),
              nanos: 0,
            },
          },
        },
      },
      {
        onSuccess: (res) => {
          setGeneratedPost(res);
          setSummary(res.summary);
          if (res.status === "error") {
            toast.error(res.message);
          } else {
            setStep(2);
          }
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleStandartAdd = () => {
    standardAdd(
      { ...generatedPost, summary: summary },
      {
        onSuccess: (res) => {
          toast.success("Post added successfully.");
          setOpenModal(false);
          queryClient.invalidateQueries("getGmbPosts");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (!file.type.includes("image")) {
      setError("Please upload an image file.");
      return;
    }

    if (file && file.size < MAX_FILE_SIZE) {
      handleUploadMedia(file);
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller one.");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size < MAX_FILE_SIZE) {
      handleUploadMedia(file);
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller one.");
    }
  };

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="lg:max-w-xl" isOverlay={false}>
        <DialogHeader>
          <DialogTitle>Add Event</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5 h-[80vh] overflow-y-auto scrollbar-thin ">
          {step === 1 && (
            <>
              <div className="flex flex-col items-center justify-center w-full py-5 ">
                <div
                  className={`max-w-sm w-full mx-auto aspect-video border-4 relative ${
                    dragActive ? "border-blue-500" : "border-gray-300"
                  } border-dashed flex items-center justify-center rounded-lg`}
                  onDragEnter={handleDrag}
                  onDragOver={handleDrag}
                  onDragLeave={handleDrag}
                  onDrop={handleDrop}
                >
                  {uploadMediaLoading && !uploadMediaLoadingError ? (
                    <LoaderIcon type="cylon" color="#7190FF" />
                  ) : (
                    <>
                      {imagePreview ? (
                        <>
                          {extension === "PHOTO" ? (
                            <img
                              src={imagePreview}
                              alt="Uploaded"
                              className="w-fit h-full object-center rounded-lg"
                            />
                          ) : (
                            <video
                              className="w-full h-full object-center rounded-lg"
                              controls
                            >
                              <source src={imagePreview} />
                            </video>
                          )}
                        </>
                      ) : (
                        <label
                          htmlFor="upload"
                          className="flex flex-col items-center justify-center text-center cursor-pointer gap-0.5"
                        >
                          {/* <FilePlus className="text-gray-400 mb-1" /> */}
                          <img src="/file-upload.svg" alt="" className="" />
                          <p className="text-gray-400 text-sm">
                            Drag photo here
                            <br />
                            <span className="text-newPrimary underline inline-block">
                              Select photo
                            </span>
                          </p>

                          <input
                            type="file"
                            id="upload"
                            hidden
                            onChange={handleChange}
                            accept="image/*"
                          />
                        </label>
                      )}
                    </>
                  )}

                  {imagePreview && (
                    <Button
                      className="absolute top-2 right-2 w-6 h-6"
                      shape="pill"
                      size="icon"
                      onClick={() => {
                        setImagePreview(null);
                        setMedia(null);
                      }}
                    >
                      <X className="w-4 h-4" />
                    </Button>
                  )}
                </div>
                {error && <p className="text-red-500 mt-2 text-xs">{error}</p>}
              </div>
              <div className="space-y-3">
                <label htmlFor="title">Event Title</label>
                <Input
                  value={offerTitle}
                  onChange={(e) => setOfferTitle(e.target.value)}
                  id="title"
                />
              </div>
              {/* <div className="flex items-center gap-5 w-full"> */}
              <div className="space-y-3 w-full relative">
                <p className="">Start Date</p>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  showTimeSelect
                  customInput={<DateInput placeholder={"From"} />}
                  wrapperClassName="w-full"
                  placeholderText="From"
                  dateFormat={"MM/dd/yyyy h:mm aa"}
                />
              </div>
              <div className="space-y-3 w-full relative">
                <p className="">End Date</p>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  showTimeSelect
                  customInput={<DateInput placeholder={"To"} />}
                  wrapperClassName="w-full"
                  placeholderText="To"
                  dateFormat={"MM/dd/yyyy h:mm aa"}
                  show
                />
              </div>
              {/* </div> */}
              <div className="space-y-3">
                <label htmlFor="description">Event Details</label>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                />
              </div>

              <div className="space-y-3">
                <label htmlFor="cta">Add Button</label>
                <Select value={cta} onValueChange={(item) => setCta(item)}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select Button" />
                  </SelectTrigger>
                  <SelectContent>
                    {[
                      "NONE",
                      "BOOK",
                      "ORDER",
                      "LEARN_MORE",
                      "SIGN_UP",
                      "CALL",
                      "SHOP",
                      "OFFER",
                    ]?.map((item) => (
                      <SelectItem key={item?.id} value={item}>
                        <p className="capitalize">
                          {item?.split("_")?.join(" ")?.toLowerCase()}
                        </p>
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {!["CALL", "NONE"]?.includes(cta) && (
                <div className="space-y-3">
                  <label htmlFor="cta">Add Url</label>
                  <Input value={url} onChange={(e) => setUrl(e.target.value)} />
                </div>
              )}
              <div className="flex justify-end">
                <Button
                  onClick={handleStandardGenerate}
                  disabled={standardGenerateLoading}
                >
                  Generate
                </Button>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              {generatedPost?.media?.[0]?.mediaFormat === "PHOTO" ? (
                <img
                  className="w-full "
                  src={generatedPost?.media?.[0]?.sourceUrl}
                  alt={"media"}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <video>
                  <source src={generatedPost?.media?.[0]?.sourceUrl} />
                </video>
              )}
              {isPreview && (
                <>
                  <div className="space-y-1">
                    <h2 className="text-xl">{generatedPost?.event?.title}</h2>
                    <p>
                      {generatedPost?.event?.schedule?.startDate &&
                        getShortMonthName(
                          generatedPost?.event?.schedule?.startDate?.month
                        )}{" "}
                      {generatedPost?.event?.schedule?.startDate?.day} -{" "}
                      {generatedPost?.event?.schedule?.endDate &&
                        getShortMonthName(
                          generatedPost?.event?.schedule?.endDate?.month
                        )}{" "}
                      {generatedPost?.event?.schedule?.endDate?.day}
                    </p>
                  </div>
                  <p className="text-sm text-[#525252] dark:text-white">
                    {dayFormatter(new Date())}
                  </p>
                </>
              )}
              {isPreview ? (
                <p>{summary}</p>
              ) : (
                <div className="space-y-3">
                  <label htmlFor="">Edit Summary</label>
                  <Textarea
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                    className="min-h-[200px]"
                  />
                </div>
              )}
              {isPreview && (
                <>
                  {generatedPost?.callToAction?.actionType !== "CALL" ? (
                    <a
                      href={generatedPost?.callToAction?.url}
                      target="_blank"
                      rel="noreferrer"
                      className="text-blue-500 underline capitalize inline-block"
                    >
                      {generatedPost?.callToAction?.actionType
                        ?.split("_")
                        .join(" ")
                        .toLowerCase()}
                    </a>
                  ) : (
                    <a
                      href={`tel:${generatedPost?.callToAction?.phoneNumber}`}
                      className="text-blue-500 underline"
                    >
                      {generatedPost?.callToAction?.phoneNumber}
                    </a>
                  )}
                  {/* <a
                    href={generatedPost?.offer?.redeemOnlineUrl}
                    className="text-blue-500 underline inline-block"
                  >
                    View Offer
                  </a> */}
                </>
              )}
              <div className="flex justify-end gap-5">
                <Button onClick={() => setStep(1)} variant="secondary">
                  Back
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setIsPreview(!isPreview)}
                >
                  {isPreview ? "Edit" : "Preview"}
                </Button>
                <Button
                  onClick={handleStandartAdd}
                  disabled={standardAddLoading}
                >
                  Post
                </Button>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddEventModal;
