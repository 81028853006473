/* eslint-disable react/prop-types */
import { Pencil, Trash2, Users2 } from "lucide-react";
import React, { useState } from "react";
import IconButton from "../../component/IconButton";
import { dayFormatter } from "../../../../utils/formatter";
import EditCampaignModal from "./EditCampaignModal";
import { Button } from "../../../../components/ui/button";
import { DeleteModal } from "../../component/modal";
import { useDeleteCampaign } from "../../../../query/useCampaign";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";

const CampaignBlock = ({ item, selectedCampaign, setSelectedCampaign }) => {
  const [isOpenEditCampaignModal, setIsOpenEditCampaignModal] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useDeleteCampaign();

  const handleClick = () => {
    mutate(item?.id, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        }
        queryClient.invalidateQueries({
          queryKey: ["getCampaignApi"],
        });
        setIsOpenDelete(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  return (
    <div
      key={item?.id}
      className={`border rounded-lg bg-white dark:bg-darkLightBg p-3 h-[100px] flex flex-col justify-between cursor-pointer w-full lg:w-[255px] ${selectedCampaign === item?.id ? "border-newPrimary" : ""}`}
      onClick={() => setSelectedCampaign(item?.id)}
    >
      <div className="flex items-center justify-between">
        <p className="text-xs text-[#4d4d4d]">
          {item?.created_at && dayFormatter(item?.created_at)}
        </p>
        <div className="flex items-center gap-3 bg-[#ffaf19]/20 rounded-md h-6 p-2 text-xs text-[#4d4d4d]">
          <Users2 className="size-4 text-[#ff953a]" />
          <p>{item?.total_users}</p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-[#4d4d4d] line-clamp-1">{item?.name}</p>
        <div className="flex items-center gap-1">
          <Button
            className=""
            variant="ghost"
            size="icon"
            onClick={() => {
              setIsOpenEditCampaignModal(true);
            }}
          >
            <Pencil className="size-4 text-[#656565]" />
          </Button>
          <Button className="" variant="ghost" size="icon">
            <Trash2
              className="size-4 text-red-500"
              onClick={() => {
                setIsOpenDelete(true);
              }}
            />
          </Button>
        </div>
      </div>

      <EditCampaignModal
        openModal={isOpenEditCampaignModal}
        setOpenModal={setIsOpenEditCampaignModal}
        item={item}
      />

      <DeleteModal
        openModal={isOpenDelete}
        setOpenModal={setIsOpenDelete}
        title={`Are you sure you want to delete ${item?.name}?`}
        handleDelete={handleClick}
        isLoading={isLoading}
      />
    </div>
  );
};

export default CampaignBlock;
