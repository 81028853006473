/* eslint-disable no-constant-condition */
import { toast } from "sonner";
import {
  useGetCancleSubsciption,
  useGetCheckCoupon,
  usePostToggleAutoRenew,
} from "../../../../query/usePayment";
import { useEffect, useMemo, useRef, useState } from "react";
import { DeleteModal } from "../../component/modal";
import {
  useGetFreeTrial,
  usePostFreeTrial,
  useUser,
} from "../../../../query/useAuthentication";
import {
  useGetPlans,
  usePostSubscribe,
} from "../../../../query/useSubscription";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import "swiper/css";
// import "swiper/css/bundle";
import { Modal } from "flowbite-react";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  CircleCheck,
  CircleX,
} from "lucide-react";
import Loading, { LoadingButton } from "../../../../components/loading/loading";
import { Switch } from "@headlessui/react";
import { dayFormatter } from "../../../../utils/formatter";
import { toSentenceCase } from "../../../../components/formatter";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";
import { pricingData } from "../../../../data/data";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { useQueryClient } from "@tanstack/react-query";
import { Checkbox } from "../../../../components/ui/checkbox";
const Plan = () => {
  const [openModal, setOpenModal] = useState(false);
  const [planId, setPlanId] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponMessage, setCouponMessage] = useState({});
  const [openCouponModal, setCouponModal] = useState(false);
  const [openChangePlanModal, setOpenChangePlanModal] = useState(false);
  const [selectedChangePlan, setSelectedChangePlan] = useState("");
  const [isNotHaveCoupon, setIsNotHaveCoupon] = useState(false);

  const data = [
    "Instant tap to get AI generated reviews",
    "Real-time tracking of customer reviews",
    "Hassle-free review collection under 30 seconds",
    "Faster reviews without manual typing",
  ];

  const { data: userInfo, refetch, isLoading: userInfoLoading } = useUser();
  const { data: plans } = useGetPlans();
  const { mutate: subscribe, isLoading: subscribeLoading } = usePostSubscribe();
  const { mutate: cancleSubscription, isLoading: cancleSubscriptionLoading } =
    useGetCancleSubsciption();
  const { mutate: toggleAutoRenew, isLoading: toggleLoading } =
    usePostToggleAutoRenew();

  const { isLoading: couponLoading } = useGetCheckCoupon(
    {
      coupon: coupon,
      plan_uuid: planId,
    },
    {
      onSuccess: (res) => {
        setCouponMessage(res);
      },
      onError: (err) => {
        setCouponMessage(err.response.data);
      },
    }
  );

  const handleSubscribe = () => {
    subscribe(
      {
        plan: planId,
        coupon: couponMessage?.status === "success" ? coupon : null,
      },
      {
        onSuccess: (res) => {
          toast.success("Redirecting to checkout url");
          window.location.href = res.url;
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Cannot redirect to checkout url!");
          }
        },
      }
    );
  };

  const handleOpenCouponModal = (id) => {
    setCouponModal(true);
    setPlanId(id);
  };

  const handleCancleSubscription = () => {
    setOpenModal(true);
  };

  const handleCancleRenewal = () => {
    cancleSubscription(
      {},
      {
        onSuccess: (res) => {
          refetch();
          toast.success("Auto-renewel has been cancelled.");
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }

          setOpenModal(false);
        },
      }
    );
  };

  const [isActive, setIsActive] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);

  const toggle = () => {
    // setIsActive((prevState) => !prevState);

    toggleAutoRenew(
      {},
      {
        onSuccess: (res) => {
          refetch();
          toast.success(res.message);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleOpenChangePlanModal = () => {
    setOpenChangePlanModal(true);
  };

  const cardPrice = (planCurrency) => {
    switch (planCurrency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  const { data: freeTrialEligible } = useGetFreeTrial();
  const { mutate: freeTrial, isLoading: freeTrialLoading } = usePostFreeTrial();

  const handleSubscribeFreeTrial = () => {
    freeTrial(
      {},
      {
        onSuccess: (res) => {
          refetch();
          toast.success(res.message);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const isShowAutoRenewal = useMemo(() => {
    if (
      userInfo?.profile?.subscription_type === "ongoing" ||
      userInfo?.profile?.subscription_type === "pause"
    ) {
      return true;
    } else {
      return false;
    }
  }, [userInfo]);

  useEffect(() => {
    setIsActive(userInfo?.profile?.subscription_auto_renew);
  }, [userInfo?.profile?.subscription_auto_renew]);

  useEffect(() => {
    if (userInfo?.profile?.current_plan?.uuid) {
      setSelectedChangePlan(userInfo?.profile?.current_plan?.uuid);
    }
  }, [openChangePlanModal]);

  if (userInfoLoading) {
    return <Loading />;
  }

  return (
    <div className="my-5 w-full min-h-[calc(100vh-160px)] dark:bg-darkLightBg rounded-[16px] py-5 lg:py-8 px-5 lg:px-[30.2px]">
      <div className="flex items-center flex-col lg:flex-row gap-y-5 w-full">
        <p className="font-pro font-medium text-lg lg:w-[60%]">
          Subscription plan:&nbsp;&nbsp;
          <span className="text-[#2A49B8]">
            {toSentenceCase(userInfo?.profile?.current_plan?.short_text) ||
              "No active subscription"}
          </span>
        </p>
        {userInfo?.profile?.current_plan && (
          <div className="lg:w-[38%]">
            <button
              className="py-2.5 px-3 rounded-[7px] font-medium text-[#615e83] dark:text-secondary border border-[#DCDBE5] font-montserrat text-sm hover:underline dark:border-none dark:bg-darkBg"
              onClick={handleOpenChangePlanModal}
            >
              {false ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "View plans"
              )}
            </button>
          </div>
        )}
      </div>
      <hr className="my-6" />
      {userInfo?.profile?.current_plan ? (
        <>
          <div className="flex items-center flex-col lg:flex-row gap-y-5">
            <div className="lg:w-[60%]">
              <p className="font-pro font-semibold lg:text-lg ">
                {userInfo?.profile?.current_plan?.short_text} Plan:{" "}
                {userInfo?.profile?.current_plan?.currency !== "FREE"
                  ? userInfo?.profile?.current_plan?.price?.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: userInfo?.profile?.current_plan?.currency,
                      }
                    )
                  : userInfo?.profile?.current_plan?.secondary_text}
              </p>
              {userInfo?.profile?.current_plan?.currency !== "FREE" ? (
                <p className="font-pro text-[13px] ">
                  Your next payment is{" "}
                  {userInfo?.profile?.current_plan?.price?.toLocaleString(
                    "en-US",
                    {
                      style: "currency",
                      currency: userInfo?.profile?.current_plan?.currency,
                    }
                  )}
                  , to be charged on{" "}
                  {userInfo?.profile?.subscription_expiration_date &&
                    dayFormatter(
                      userInfo?.profile?.subscription_expiration_date * 1000
                    )}
                </p>
              ) : (
                <p className="font-pro text-[13px] ">
                  Your plan will expire in{" "}
                  {Math.round(
                    (userInfo?.profile?.subscription_expiration_date -
                      new Date().getTime() / 1000) /
                      (3600 * 24)
                  )}{" "}
                  days
                </p>
              )}
            </div>

            {userInfo?.profile?.current_plan?.currency !== "FREE" &&
              isShowAutoRenewal && (
                <div className="lg:w-[38%]">
                  <div className="max-w-[120px] flex flex-col justify-center items-center gap-y-2">
                    <Switch
                      checked={isActive}
                      onChange={toggle}
                      className={`${isActive ? "bg-[#3183FF]" : "bg-[#8E8B8B]"}
              relative h-[30px] w-16  shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75 flex items-center`}
                    >
                      <span className="sr-only">Renew Subscription</span>
                      <span
                        aria-hidden="true"
                        className={`${isActive ? "translate-x-9" : "translate-x-0"}
                pointer-events-none inline-block h-6 w-6  transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                      />
                    </Switch>
                    <p className="font-pro text-[13px] text-[#4f4f4f]">
                      Auto Renew
                    </p>
                  </div>
                </div>
              )}
          </div>
          <div className="mt-7">
            {data.map((item, index) => (
              <div
                key={index}
                className={` flex font-pro text-[#4f4f4f] dark:text-white lg:items-center items-start text-start gap-[10px] text-[13px] w-full mb-2.5 pb-2`}
              >
                <Check className="w-[10px] h-[10px] text-[#2A49B8] dark:text-secondary" />
                {item}
              </div>
            ))}
          </div>
          {/* <hr className="my-6" />
          <div>
            <p className="font-pro font-medium text-xl">Payment Method:</p>
            <div className="mt-5">
              <div className="bg-secondary text-white py-3 px-5 lg:py-5 lg:px-8 flex items-center justify-between max-w-[450px] rounded-xl font-pro">
                <div>
                  <p className="text-xl font-semibold">Visa ending 4232</p>
                  <p>Expiry 07/2024</p>
                </div>
                <div>
                  <p className="text-sm">Primary Card</p>
                </div>
              </div>
            </div>
          </div> */}
        </>
      ) : (
        // <section className="w-full relative font-poppins  text-[#333333] space-y-6 z-10 pb-10 lg:mt-10 flex flex-col justify-center items-center">
        //   <div className="bg-[#f6f6ff] dark:bg-darkBg rounded-[19px] max-w-[716px] w-full px-5 space-y-6 py-5 lg:py-[30px] mx-auto">
        //     <div className="lg:w-[80%] mx-auto flex flex-col justify-center items-center">
        //       <div className=" bg-white dark:bg-darkLightBg w-fit px-2 py-2 justify-center rounded-[16px] flex flex-wrap gap-1 md:gap-2 items-center text-xs sm:text-sm md:text-base lg:text-lg text-nowrap">
        //         {plans?.data?.map((item, id) => {
        //           return (
        //             <>
        //               <span
        //                 key={id}
        //                 className={`rounded-[9px] flex justify-center items-center px-3 py-2 sm:py-3 dark:text-white ${selectedPlan == id && "bg-newPrimary text-white"} cursor-pointer transition-all w-fit text-center duration-500`}
        //                 onClick={() => setSelectedPlan(id)}
        //               >
        //                 {item?.short_text}
        //               </span>
        //             </>
        //           );
        //         })}
        //       </div>

        //       <div className="px-3 sm:px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[4px] sm:rounded-[18px] mt-4 sm:mt-[44px] font-poppins text-[12px] sm:text-base mb-[13px] sm:mb-[15px]">
        //         {plans?.data?.[selectedPlan]?.secondary_text}
        //       </div>

        //       <h2 className="text-[#333333] dark:text-white font-poppins text-[32px] sm:text-[40px] lg:text-[67px] font-extrabold text-center sm:mt-3">
        //         {(plans?.data?.[selectedPlan]?.discount !== 0
        //           ? plans?.data?.[selectedPlan]?.price_after_discount
        //           : plans?.data?.[selectedPlan]?.price
        //         )?.toLocaleString("en-US", {
        //           style: "currency",
        //           currency: plans?.data?.[selectedPlan]?.currency,
        //         })}
        //       </h2>
        //       {plans?.data?.[selectedPlan]?.discount !== 0 ? (
        //         plans?.data?.[selectedPlan]?.is_relative_discount_price ? (
        //           <div className="flex gap-2 sm:gap-[31px] items-center font-poppins mb-[27px]">
        //             <h2 className="text-base sm:text-[32px] text-[#333333] dark:text-white line-through">
        //               {plans?.data?.[selectedPlan]?.price?.toLocaleString(
        //                 "en-US",
        //                 {
        //                   style: "currency",
        //                   currency: plans?.data?.[selectedPlan]?.currency,
        //                 }
        //               )}
        //             </h2>
        //             <button className="bg-[#73DF07] rounded-[3px] sm:rounded-[9px] px-2 sm:px-5 py-1 text-white text-[10px] sm:text-[20px] ">
        //               {plans?.data?.[selectedPlan]?.discount}% OFF{" "}
        //             </button>
        //           </div>
        //         ) : (
        //           <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
        //             <h2 className="text-[32px] text-[#333333] line-through">
        //               {plans?.data?.[selectedPlan]?.price?.toLocaleString(
        //                 "en-US",
        //                 {
        //                   style: "currency",
        //                   currency: plans?.data?.[selectedPlan]?.currency,
        //                 }
        //               )}
        //             </h2>
        //             <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-[20px] ">
        //               {/* {plans?.data?.[selectedPlan]?.discount?.toLocaleString(
        //                 "en-US",
        //                 {
        //                   style: "currency",
        //                   currency: plans?.data?.[selectedPlan]?.currency,
        //                 }
        //               )}{" "} */}
        //               {plans?.data?.[selectedPlan]?.discount_percent}% OFF{" "}
        //             </button>
        //           </div>
        //         )
        //       ) : null}
        //     </div>

        //     <div className="flex gap-x-3 justify-center items-center lg:text-lg text-xs">
        //       <p className="text-[#FF0000]">NOTE:</p>
        //       <p className="text-[#1F1E1E] dark:text-white font-medium">
        //         MagicReview Card:{" "}
        //         {cardPrice(plans?.data?.[selectedPlan]?.currency)}/ Card
        //       </p>
        //     </div>

        //     <div className="max-w-[476.79px] mx-auto w-full">
        //       {data.map((item, index) => (
        //         <div
        //           key={index}
        //           className={`${index === data.length - 1 ? "border-none" : "border-b border-[#EAEAEA]"} flex font-poppins text-[#1F1E1E] dark:text-white lg:items-center items-start text-start gap-[13px] lg:text-lg text-xs w-full mb-3 pb-2`}
        //         >
        //           <img
        //             src="/check.svg"
        //             alt="check"
        //             className="w-[24px] h-[24px]"
        //           />
        //           {item}
        //         </div>
        //       ))}

        //       <button
        //         className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
        //         onClick={() =>
        //           handleOpenCouponModal(plans?.data?.[selectedPlan]?.uuid)
        //         }
        //       >
        //         Subscribe Now
        //       </button>
        //       {freeTrialEligible?.is_eligible && (
        //         <button
        //           className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-3 py-3 rounded-[10px] text-white"
        //           onClick={handleSubscribeFreeTrial}
        //         >
        //           Subscribe to 14 days free trial
        //         </button>
        //       )}
        //     </div>
        //   </div>
        // </section>
        <div className="font-poppins flex flex-col lg:flex-row lg:items-center lg:justify-between gap-5 mb-20 w-full">
          <div className="w-fit lg:ml-12">
            <p className="font-medium text-2xl lg:text-[28px] lg:leading-[46px] lg:-tracking-[1%] text-[#333] dark:text-white">
              Features
            </p>

            <div className="divide-y mt-5 mb-3">
              {pricingData.map((item, i) => (
                <div key={i} className="flex items-center gap-5 py-2 lg:py-0">
                  <CircleCheck className="fill-newPrimary text-white" />
                  <p className="text-sm lg:text-base lg:leading-[48px] text-[#1f1e1e] dark:text-white">
                    {item}
                  </p>
                </div>
              ))}
            </div>
            <p className="text-sm lg:text-base text-[#1f1e1e] dark:text-white">
              <span className="text-[#ff0000]">Note:</span>{" "}
              <ul className="list-disc list-inside">
                <li className="font-medium">3 Free Cards</li>
                <li>
                  <span className="font-medium">Additional Card:</span>{" "}
                  {cardPrice()}
                  /card{" "}
                </li>
              </ul>
            </p>
          </div>

          <div className="space-y-3">
            <RadioGroup
              defaultValue={selectedPlan}
              onValueChange={(e) => setSelectedPlan(e)}
            >
              {plans?.data?.map((item, i) => (
                <div
                  key={i}
                  className={`flex items-center w-full gap-3 border-2 rounded-xl px-5 py-3 cursor-pointer lg:h-28 ${selectedPlan === i ? "border-newPrimary bg-[#F6F8FF]" : "border-[#ddd] bg-[#fcfcfc]"}`}
                  htmlFor={i}
                >
                  <RadioGroupItem value={i} id={i} />
                  <label
                    className="flex items-center justify-between gap-3 w-full lg:min-w-[450px] cursor-pointer"
                    htmlFor={i}
                  >
                    <div className="space-y-2">
                      <p className="text-xl lg:text-2xl font-medium text-[#333]">
                        {item?.secondary_text}
                      </p>
                      <div className="flex items-center gap-2">
                        <p className="lg:text-lg text-[#333]">
                          {item?.short_text}
                        </p>
                        {item?.discount !== 0 ? (
                          item?.is_relative_discount_price ? (
                            <button className="bg-[#D9FFE5] rounded-[8px] px-3 py-1 text-[#0BA649] text-xs lg:text-sm font-medium ">
                              Save {item?.discount}% OFF{" "}
                            </button>
                          ) : (
                            <button className="bg-[#D9FFE5] rounded-[8px] px-3 py-1 text-[#0BA649] text-xs lg:text-sm font-medium ">
                              Save {item?.discount_percent}% OFF{" "}
                            </button>
                          )
                        ) : null}
                      </div>
                    </div>
                    <div className="text-black">
                      <p className="font-inter font-bold text-2xl lg:text-[32px] lg:leading-[58px]">
                        {item?.currency &&
                          item?.price_after_discount?.toLocaleString("en-US", {
                            style: "currency",
                            currency: item?.currency,
                          })}
                      </p>
                      {item?.price !== item?.price_after_discount && (
                        <p className="font-inter lg:text-xl font-medium line-through">
                          {item?.currency &&
                            item?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: item?.currency,
                            })}
                        </p>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </RadioGroup>
            <button
              className="flex items-center justify-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary dark:hover:bg-darkLightBg transition-all duration-300 hover:bg-white w-full text-center font-poppins"
              onClick={() =>
                handleOpenCouponModal(plans?.data?.[selectedPlan]?.uuid)
              }
            >
              Continue
            </button>

            {freeTrialEligible?.is_eligible && (
              <button
                className="flex items-center justify-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white dark:hover:bg-darkLightBg w-full text-center font-poppins "
                onClick={handleSubscribeFreeTrial}
              >
                Subscribe to 14 days free trial
              </button>
            )}
          </div>
        </div>
      )}
      <DeleteModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleDelete={toggle}
        title={"Are you sure you want to cancel auto-renewal?"}
        deleteConfirmButtonText={"Yes"}
        deleteCancelButtonText={"No"}
        isLoading={cancleSubscriptionLoading}
      />

      {/* coupon modal */}
      <Dialog
        open={openCouponModal}
        onOpenChange={() => {
          setCouponModal(false);
          setPlanId("");
        }}
      >
        <DialogContent>
          <DialogHeader className={"hidden"}>
            <DialogTitle></DialogTitle>
          </DialogHeader>
          <div className="h-auto px-6 pt-6 font-pro">
            <header className="flex items-center">
              <h1 className="text-[20px] mb-[31px] dark:text-white">
                {"Do you have a coupon code?"}
              </h1>
            </header>
            <div className="space-y-5">
              {coupon && couponMessage?.status === "success" ? (
                <div className="flex flex-col justify-center items-center gap-y-3">
                  <p className="text-[20px] font-semibold text-center">
                    {!couponMessage?.is_relative_discount &&
                      couponMessage?.currency}{" "}
                    {couponMessage?.discount}{" "}
                    {couponMessage?.is_relative_discount && "%"} Discount
                  </p>
                  <div className="flex justify-center items-center gap-x-2">
                    <CircleCheck className="text-[#73DF07] " />
                    <p>{couponMessage?.message}</p>
                  </div>
                </div>
              ) : null}
              <div>
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Enter coupon code"
                    className="w-full rounded-lg pr-5 dark:bg-darkLightBg dark:text-white"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    disabled={isNotHaveCoupon}
                  />
                  {coupon && couponLoading && (
                    <div className="flex items-center justify-center absolute top-1/2 -translate-y-1/2 right-5">
                      <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
                    </div>
                  )}
                </div>
                {coupon && couponMessage?.status === "error" && (
                  <p className="text-red-500 text-[10px] mt-0.5">
                    *{couponMessage?.message}
                  </p>
                )}
              </div>

              <div className="flex gap-2 items-center">
                <Checkbox
                  checked={isNotHaveCoupon}
                  onCheckedChange={() => {
                    setIsNotHaveCoupon((prev) => !prev);
                    setCoupon("");
                  }}
                  id="coupon"
                />{" "}
                <label htmlFor="coupon">I don&apos;t have coupon</label>
              </div>
              <div className=" flex gap-5">
                <Button
                  onClick={handleSubscribe}
                  className="w-full"
                  disabled={!(isNotHaveCoupon || coupon)}
                >
                  {subscribeLoading ? (
                    <LoadingButton />
                  ) : (
                    "Continue subscription"
                  )}
                </Button>
                <Button
                  variant="secondary"
                  className="w-full"
                  onClick={() => {
                    setCouponModal(false);
                    setPlanId("");
                  }}
                >
                  Cancel subscription
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {/* change plan modal */}
      <Dialog
        open={openChangePlanModal}
        onOpenChange={() => {
          setOpenChangePlanModal(false);
        }}
      >
        <DialogContent className="lg:max-w-4xl">
          <DialogHeader className={"hidden"}>
            <DialogTitle></DialogTitle>
          </DialogHeader>
          <div className="px-6 pt-10">
            <div className="flex flex-wrap justify-between">
              <div className="flex flex-col justify-between">
                <div>
                  <p className="font-pro font-medium text-[22px] mb-10 dark:text-white">
                    Features
                  </p>
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={` flex font-pro text-[#4f4f4f] dark:text-white lg:items-center items-start text-start gap-[10px] text-sm w-full mb-2 pb-2`}
                    >
                      <Check className="w-[10px] h-[10px] text-[#2A49B8] dark:text-secondary" />
                      {item}
                    </div>
                  ))}
                </div>
                <div className="w-full hidden lg:flex items-center justify-between gap-x-3">
                  {userInfo?.profile?.current_plan?.secondary_text
                    ?.toLowerCase()
                    ?.includes("free") && (
                    <button
                      className="text-center w-full bg-newPrimary text-white border border-newPrimary px-3 py-2.5 rounded-lg hover:text-newPrimary hover:bg-white transition-all duration-200 font-pro"
                      onClick={() => {
                        handleOpenCouponModal(selectedChangePlan);
                        setOpenChangePlanModal(false);
                      }}
                    >
                      Continue
                    </button>
                  )}
                  {/*<button
                  className="text-center w-full hover:bg-secondary hover:text-white border border-secondary px-3 py-2.5 rounded-lg text-secondary bg-white transition-all duration-200"
                  onClick={() => setOpenChangePlanModal(false)}
                >
                  Cancel
                </button> */}
                </div>
              </div>
              <div className="w-full lg:max-w-[365px]  space-y-5">
                {plans?.data?.map((item) => (
                  <div
                    key={item?.uuid}
                    className={` p-5 flex justify-between items-center w-full h-[110px] rounded-xl  cursor-pointer ${item?.uuid === selectedChangePlan ? "bg-newPrimary text-white" : "bg-[#f6f6ff] dark:bg-darkLightBg dark:text-white"}`}
                    onClick={() => setSelectedChangePlan(item?.uuid)}
                  >
                    <div className="space-y-3">
                      <p className="font-semibold font-pro text-lg">
                        {item?.short_text && item?.short_text.toLowerCase()}
                      </p>
                      <div className="bg-white dark:bg-darkBg px-2.5 py-1 rounded-md font-pro text-[#615e83] dark:text-secondary text-sm text-center">
                        {toSentenceCase(item?.secondary_text)}
                      </div>
                    </div>
                    <div className="space-y-3">
                      <p className="font-pro text-2xl font-bold text-center sm:mt-3">
                        {(item?.discount !== 0
                          ? item?.price_after_discount
                          : item?.price
                        )?.toLocaleString("en-US", {
                          style: "currency",
                          currency: item?.currency,
                        })}
                      </p>
                      {item?.discount !== 0 ? (
                        item?.is_relative_discount_price ? (
                          <div className="flex gap-2 items-center font-poppins">
                            <div className="bg-[#73DF07] px-2 py-1 text-white text-[10px] rounded-[4px]">
                              {item?.discount}% OFF{" "}
                            </div>
                            <p className="text-base line-through">
                              {item?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: item?.currency,
                              })}
                            </p>
                          </div>
                        ) : (
                          <div className="flex gap-2 items-center font-poppins">
                            <button className="bg-[#73DF07] px-2 py-1 text-white text-[10px] rounded-[4px]">
                              {item?.discount_percent}% OFF{" "}
                            </button>
                            <h2 className="text-base line-through">
                              {item?.price?.toLocaleString("en-US", {
                                style: "currency",
                                currency: item?.currency,
                              })}
                            </h2>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full lg:hidden space-y-3 mt-5">
                <button
                  className="text-center w-full bg-newPrimary text-white border border-newPrimary px-3 py-2.5 rounded-lg hover:text-newPrimary hover:bg-white transition-all duration-200"
                  onClick={() => {
                    handleOpenCouponModal(selectedChangePlan);
                    setOpenChangePlanModal(false);
                  }}
                >
                  Continue
                </button>
                {/* <button
                className="text-center w-full hover:bg-secondary hover:text-white border border-secondary px-3 py-2.5 rounded-lg text-secondary bg-white transition-all duration-200"
                onClick={() => setOpenChangePlanModal(false)}
              >
                Cancel
              </button> */}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Plan;
