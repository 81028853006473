/* eslint-disable react/prop-types */
import { ChevronDown, GripVertical, RefreshCcw } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { Switch } from "@headlessui/react";
import {
  usePostSetCategoryOrder,
  usePostToggleCategory,
} from "../../../query/useEntity";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { Tooltip } from "flowbite-react";
import { Reorder, useDragControls } from "framer-motion";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import { Button } from "../../../components/ui/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import { Checkbox } from "../../../components/ui/checkbox";

const Item = ({
  item,
  setIsOpen,
  setValue,
  isToggle,
  isSort,
  isReorder,
  value,
}) => {
  const controls = useDragControls();
  return (
    <Reorder.Item
      key={item?.id}
      value={item}
      dragListener={false}
      dragControls={controls}
      className="flex items-center justify-between gap-x-3 group text-sm"
    >
      {isReorder ? (
        <GripVertical
          onPointerDown={(e) => controls.start(e)}
          className="active:cursor-grabbing"
        />
      ) : (
        <Checkbox
          checked={
            isToggle ? item?.category === value : item?.keyword === value
          }
        />
      )}
      <p
        className="w-full cursor-pointer group-hover:text-newPrimary"
        onClick={() => {
          setIsOpen(false);
          setValue(
            isToggle ? item?.category : isSort ? item?.value : item?.keyword
          );
        }}
      >
        {isToggle ? item?.category : isSort ? item?.name : item?.keyword}
      </p>
    </Reorder.Item>
  );
};

const CustomDropdown = ({
  value,
  setValue,
  values,
  isToggle,
  isSort,
  setId,
  title,
}) => {
  const dropdownRef = useRef(null);
  const queryClient = useQueryClient();
  const [isOpen, setIsOpen] = useState(false);
  const [isReorder, setIsReorder] = useState(false);
  const [orderValues, setOrderValues] = useState([]);
  const [originalValues, setOriginalValues] = useState([]);

  const { mutate: categoryOrder, isLoading: categoryOrderLoading } =
    usePostSetCategoryOrder();

  const handleReorder = () => {
    const orderObj = {};
    orderValues.forEach((item, i) => {
      orderObj[i] = item?.id;
    });
    categoryOrder(
      {
        set: setId,
        order: orderObj,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setIsReorder(false);
          queryClient.invalidateQueries(["getDefaultQuestions"]);
          queryClient.invalidateQueries(["getDefaultQuestionsForSets"]);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    setOrderValues(values);
    setOriginalValues(values);
  }, [values]);
  useOnClickOutside({ ref: dropdownRef, setIsOpen: setIsOpen });
  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value={title} className="border-b-0">
        <AccordionTrigger className="py-0 hover:no-underline font-normal flex-row-reverse justify-end gap-2">
          {title}
        </AccordionTrigger>

        <AccordionContent className="space-y-2 pb-0">
          <Reorder.Group
            className="space-y-2 pt-2"
            values={orderValues}
            onReorder={setOrderValues}
          >
            {orderValues?.map((item) => (
              <Item
                item={item}
                value={value}
                isSort={isSort}
                isToggle={isToggle}
                setIsOpen={setIsOpen}
                setValue={setValue}
                key={item?.id}
                isReorder={isReorder}
              />
            ))}
          </Reorder.Group>
          {isToggle && setId ? (
            <>
              {isReorder ? (
                <div className="flex items-center justify-between gap-1">
                  <Button
                    className="w-full text-xs gap-2"
                    variant="secondary"
                    onClick={handleReorder}
                  >
                    {categoryOrderLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="size-[15px] border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                      </div>
                    ) : (
                      "Confirm"
                    )}
                  </Button>
                  <Button
                    className="w-full text-xs"
                    variant="ghost"
                    onClick={() => {
                      setIsReorder(false);
                      setOrderValues(originalValues);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  variant="ghost"
                  className="w-full gap-2 text-xs"
                  onClick={() => setIsReorder(true)}
                >
                  <RefreshCcw className="size-[15px]" />
                  Reorder {title}
                </Button>
              )}
            </>
          ) : null}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default CustomDropdown;
