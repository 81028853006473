import { Route, Routes, Navigate } from "react-router";
import RequireAuth from "./context/RequireAuth";
import { AuthProvider } from "./context/authContext";
import { OnBoardingProvider } from "./context/onBoardingContext";
import Home from "./routes/Home";
import AboutUs from "./routes/about/aboutus";
import Ads from "./routes/ads/ads";
import Blog from "./routes/blog/blog";
import Custom404 from "./routes/error/error";
import Blog1 from "./routes/blogs/blog1";
import Blog2 from "./routes/blogs/blog2";
import Blog3 from "./routes/blogs/blog3";
import Blog4 from "./routes/blogs/blog4";
import Blog5 from "./routes/blogs/blog5";
import Blog6 from "./routes/blogs/blog6";
import Contact from "./routes/contact/contact";
import Dashboard from "./routes/dashboard/dashboard";
import Account from "./routes/dashboard/routes/account";
import Payment from "./routes/dashboard/routes/payment";
import DashboardQuestions from "./routes/dashboard/routes/questions";
import Reviews from "./routes/dashboard/routes/reviews";
import MobileGenerate from "./routes/generate/mobilegenerate";
import Feedback from "./routes/getfeedback/feedback";
import Howitworks from "./routes/howitworks/howitworks";
import Note from "./routes/notes/note";
import Link from "./routes/onboarding/components/link";
import Onboarding from "./routes/onboarding/onboarding";
import Pricing from "./routes/pricing/pricing";
import Privacy from "./routes/privacy/privacy";
import SignIn from "./routes/signin/signin";
import SignUp from "./routes/signup/signup";
import PaymentSuccess from "./routes/success/paymentsuccess";
import Success from "./routes/success/success";
import TermsAndConditions from "./routes/termsandconditions/termsandcondition";
import Blog8 from "./routes/blogs/blog8";
import Blog9 from "./routes/blogs/blog9";
import Blog10 from "./routes/blogs/blog10";
import Blog11 from "./routes/blogs/blog11";
import Fail from "./routes/fail/fail";
import Blog12 from "./routes/blogs/blog12";
import Blog13 from "./routes/blogs/blog13";
import Blog14 from "./routes/blogs/blog14";
import Blog15 from "./routes/blogs/blog15";
import ContactPerson from "./routes/contact/contactPerson";
import Blog16 from "./routes/blogs/blog16";
import Blog17 from "./routes/blogs/blog17";
import Referral from "./routes/dashboard/routes/referral";
import Blog18 from "./routes/blogs/blog18";
import Blog22 from "./routes/blogs/blog22";
import VerifyOTP from "./routes/forgottenpassword/verifyotp";
import Blog19 from "./routes/blogs/blog19";
import Noti from "./routes/dashboard/routes/noti";
import Vsl from "./routes/vsl/Vsl";
import FreeTrial from "./routes/freeTrial/FreeTrial";
import Blog20 from "./routes/blogs/blog20";
import Blog21 from "./routes/blogs/blog21";
import Staffs from "./routes/dashboard/routes/staffs";
import Integration from "./routes/dashboard/routes/integration";
import Products from "./routes/dashboard/routes/products";
import Keywords from "./routes/dashboard/routes/keywords";
import BuyCard from "./routes/dashboard/routes/buycard";
import { useEffect } from "react";
import VerifyEmail from "./routes/signup/VerifyEmail";
import RegisterEntity from "./routes/signup/RegisterEntity";
import GetStarted from "./routes/dashboard/routes/getstarted";
import KnowledgeBase from "./routes/dashboard/routes/knowledge";
import Master from "./routes/master/Master";
import Users from "./routes/master/Users";
import MagicCard from "./routes/dashboard/routes/components/MagicCard";
import NewUser from "./routes/master/components/NewUser";
import Billing from "./routes/master/Billing";
import SubUserBilling from "./routes/dashboard/routes/subuserbilling";
import Cookies from "./routes/cookies/Cookies";
import CreditsRequestPending from "./routes/master/components/CreditsRequestPending";
import CreditsRequestApproved from "./routes/master/components/CreditsRequestApproved";
import CreditsRequestRejected from "./routes/master/components/CreditsRequestRejected";
import BuyCardHistory from "./routes/dashboard/routes/BuyCardHistory";
import AutoReply from "./routes/dashboard/routes/autoreply";
import GoogleReviews from "./routes/dashboard/routes/googlereviews";
import ReviewsAnalytics from "./routes/dashboard/routes/reviewsanalytics";
import KeyInsights from "./routes/dashboard/routes/keyinsights";
import SeoKeywords from "./routes/dashboard/routes/seokeywords test";
import AiPost from "./routes/dashboard/routes/aipost";
import Schedule from "./routes/contact/schedule";
import ThankYou from "./routes/generate/ThankYou";
import Campaign from "./routes/dashboard/routes/campaign";
import OfferPage from "./routes/generate/OfferPage";
import RedeemPage from "./routes/generate/RedeemPage";
import ResponderAgent from "./routes/products/ResponderAgent";
import KeyInsightsProduct from "./routes/products/KeyInsights";
import AiPostProduct from "./routes/products/AiPost";
import SeoAgentProduct from "./routes/products/SeoAgent";
import Middleware from "./utils/middleware";

function App() {
  return (
    <AuthProvider>
      <OnBoardingProvider>
        <Middleware>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cookies-policy" element={<Cookies />} />
            <Route path="/*" element={<Custom404 />} />
            <Route path="/review" element={<Note />} />
            <Route path="/business/:id" element={<MobileGenerate />} />
            <Route path="/redeem/:id" element={<RedeemPage />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/how-it-works" element={<Howitworks />} />
            <Route path="/contact-us" element={<Contact />} />
            <Route path="/thank-you" element={<ThankYou />} />
            <Route path="/offer" element={<OfferPage />} />
            <Route path="/schedule-a-call" element={<Schedule />} />
            <Route path="/contact/:id" element={<ContactPerson />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route
              path="/products/responder-agent"
              element={<ResponderAgent />}
            />
            <Route
              path="/products/key-insights"
              element={<KeyInsightsProduct />}
            />
            <Route path="/products/ai-post" element={<AiPostProduct />} />
            <Route path="/products/seo-agent" element={<SeoAgentProduct />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog-1" element={<Blog1 />} />
            <Route path="/blog-2" element={<Blog2 />} />
            <Route path="/blog-3" element={<Blog3 />} />
            <Route
              path="/blog/why-ratings-reviews-important-business"
              element={<Blog4 />}
            />
            <Route
              path="/blog/how-to-automate-google-reviews-collection-magicreview"
              element={<Blog5 />}
            />
            <Route
              path="/blog/7-tips-finding-best-review-platforms-business"
              element={<Blog6 />}
            />
            {/* <Route
            path="/blog/build-your-automated-sales-funnel-a-9-step-guide"
            element={<Blog7 />}
          /> */}
            <Route path="/blog/what-is-magicreview" element={<Blog8 />} />
            <Route
              path="/blog/how-to-get-more-google-reviews"
              element={<Blog9 />}
            />
            <Route
              path="/blog/7-powerful-ways-boost-your-google-reviews"
              element={<Blog10 />}
            />
            <Route
              path="/blog/how-to-get-more-google-reviews-for-restaurants"
              element={<Blog11 />}
            />
            <Route
              path="/blog/increase-restaurant-sales-without-ads"
              element={<Blog12 />}
            />
            <Route path="/blog/ai-review-cards-in-uae" element={<Blog13 />} />
            <Route
              path="/blog/how-to-boost-google-reviews"
              element={<Blog14 />}
            />
            <Route
              path="/blog/5-ways-to-increase-your-sales-and-visibility"
              element={<Blog15 />}
            />
            <Route
              path="/blog/traditional-vs-ai-powered-reviews"
              element={<Blog16 />}
            />
            <Route
              path="/blog/top-15-restaurant-review-websites"
              element={<Blog17 />}
            />
            <Route
              path="/blog/how-to-respond-to-google-reviews"
              element={<Blog18 />}
            />
            <Route
              path="/blog/how-to-get-and-share-your-google-review-link"
              element={<Blog19 />}
            />
            <Route
              path="/blog/why-google-maps-ranking-important-local-business"
              element={<Blog20 />}
            />
            <Route
              path="/blog/how-to-create-online-reputation-management-strategy"
              element={<Blog21 />}
            />
            <Route
              path="/blog/benefits-nfc-review-cards"
              element={<Blog22 />}
            />
            <Route
              path="/payment"
              element={<Navigate to="/google-reviews-card" />}
            />
            <Route path="/google-reviews-card" element={<Ads />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
            <Route path="/register-entity" element={<RegisterEntity />} />
            <Route element={<RequireAuth />}>
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/onboarding/link" element={<Link />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/get-started" element={<GetStarted />} />
              <Route path="/dashboard/settings/account" element={<Account />} />
              <Route
                path="/dashboard/settings/integrations"
                element={<Integration />}
              />
              <Route
                path="/dashboard/settings/knowledge"
                element={<KnowledgeBase />}
              />
              <Route
                path="/dashboard/settings/referral"
                element={<Referral />}
              />
              {/* <Route path="/dashboard/keywords" element={<Keywords />} /> */}
              <Route path="/dashboard/magic-card" element={<MagicCard />} />
              <Route
                path="/dashboard/questions"
                element={<DashboardQuestions />}
              />
              <Route path="/dashboard/reviews" element={<Reviews />} />
              <Route
                path="/dashboard/reviews/google"
                element={<GoogleReviews />}
              />
              <Route
                path="/dashboard/reviews/analytics"
                element={<ReviewsAnalytics />}
              />
              <Route
                path="/dashboard/ai/keyinsights"
                element={<KeyInsights />}
              />
              <Route path="/dashboard/ai/auto-reply" element={<AutoReply />} />
              <Route path="/dashboard/ai/seo-agent" element={<SeoKeywords />} />
              <Route path="/dashboard/ai/post" element={<AiPost />} />
              <Route path="/dashboard/payment" element={<Payment />} />
              <Route path="/dashboard/payment/buy-card" element={<BuyCard />} />
              <Route
                path="/dashboard/payment/buy-card-history"
                element={<BuyCardHistory />}
              />

              <Route path="/dashboard/magiclinks" element={<Staffs />} />
              <Route path="/dashboard/campaign" element={<Campaign />} />
              <Route path="/dashboard/knowledge" element={<KnowledgeBase />} />
              <Route path="/dashboard/billing" element={<SubUserBilling />} />
              <Route path="/organisation" element={<Master />} />
              <Route path="/organisation/users" element={<Users />} />
              <Route path="/organisation/users/create" element={<NewUser />} />
              <Route path="/organisation/billing" element={<Billing />} />
              <Route
                path="/organisation/credits/pending"
                element={<CreditsRequestPending />}
              />
              <Route
                path="/organisation/credits/approved"
                element={<CreditsRequestApproved />}
              />
              <Route
                path="/organisation/credits/rejected"
                element={<CreditsRequestRejected />}
              />
            </Route>
            {/* </Route> */}
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route path="/paymentsuccessful" element={<PaymentSuccess />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/payment-success" element={<Success />} />
            <Route path="/payment-fail" element={<Fail />} />
            <Route path="/forgot-password" element={<VerifyOTP />} />
            <Route path="/try-it-now" element={<Vsl />} />
            {/* <Route path="/free-trial" element={<FreeTrial />} /> */}
          </Routes>
        </Middleware>
      </OnBoardingProvider>
    </AuthProvider>
  );
}

export default App;
