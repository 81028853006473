import { motion } from "framer-motion";
import {
  ChevronRight,
  Pencil,
  Search,
  Settings,
  Trash2,
  Sparkles,
  Play,
  SquarePlus,
  Filter,
  ChevronDown,
  Check,
  Loader2,
  Settings2,
  ArrowDownUp,
  Monitor,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import Loading, {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import WordBreak from "../../../components/wordbreak";
import useDebounce from "../../../hooks/useDebounce";
import {
  useDeleteQuestionSet,
  useGetDefaultEntityQuestions,
  useGetDefaultEntityQuestionsForSets,
  useGetQuestionSets,
  useGetSearchQuestions,
  usePostAddQuestionSet,
  usePostToggleQuestion,
} from "../../../query/useEntity";
import AddQuestionModal from "../../dashboard/component/AddQuestionModal";
import { DeleteModal } from "../../dashboard/component/modal";
import AddOrEditQuestionSet from "./AddOrEditQuestionSet";
import CustomDropdown from "./CustomDropdown";
import IconButton from "../../dashboard/component/IconButton";
import QuestionSettingsModal from "./QuestionSettingsModal";
import QuestionCard from "./QuestionCard";
import QuestionCardForQuestionSet from "./QuestionCardForQuestionSet";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "../../dashboard/component/CustomTooltipForJoyride";
import AIQuestions from "./AIQuestions";
import QuestionPreview from "./QuestionPreview";
import { QuestionsIllustrations } from "./illustrations/QuestionsIllustrations";
import { Button } from "../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../../../components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../components/ui/command";
import { cn } from "../../../utils/cn";
import { RadioGroup, RadioGroupItem } from "../../../components/ui/radio-group";
import { Input } from "../../../components/ui/input";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../components/ui/accordion";
import QuestionPreviewMobile from "./QuestionPreviewMobile";
const Questions = () => {
  const questionSetRef = useRef(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const [questions, setQuestions] = useState([]);
  const [questionsForSets, setQuestionsForSets] = useState([]);
  const [extra, setExtra] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [category, setCategory] = useState();
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState("recent");
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [isOpenAddOrEditQuestionSet, setIsOpenAddOrEditQuestionSet] =
    useState(false);
  const [isOpenDeleteQuestionSet, setIsOpenDeleteQuestionSet] = useState(false);
  const [questionSetId, setQuestionSetId] = useState();
  const [toUpdateQuestionSet, setToUpdateQuestionSet] = useState();
  const [questionSetName, setQuestionSetName] = useState("");
  const [isOpenQuestionSettings, setIsOpenQuestionSettings] = useState(false);
  const [isOpenFilterSelect, setIsOpenFilterSelect] = useState(false);
  const [isOpenAIModal, setIsOpenAIModal] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const location = useLocation();
  const dashboard = location.pathname.includes("dashboard");
  const joyRideStepsForQuestions = [
    {
      title: "Add questions",
      content: "Click to add your first question",
      target: ".add-button",
      disableBeacon: true,
      // referrer: "Open Add Question",
      // referrerFunc: function () {
      //   setOpenAddModal(true);
      // },
    },
    {
      title: "Question settings",
      content: "Manage question sets for an easy collection of reviews",
      target: ".question-settings",
      // referrerFunc: function () {
      //   navigate("/dashboard/staffs?referrer=verify-email");
      // },
      // referrer: "Go to magiclink section",
    },
    {
      title: "Keywords",
      content:
        "Keywords are specific words to help your customers find particular topics or items. For instance, as an educational centre, your Keywords may include science courses, beginner level courses, and more.",
      target: ".keywords",
      placement: "bottom",
    },
    {
      title: "Categories",
      content:
        "Categories are broad groups to organise questions with similar topics together. For instance, as an educational centre, your categories may include courses, levels, and more.",
      target: ".categories",
      placement: "bottom",
    },
    {
      title: "Question set(s)",
      content:
        "Question set(s) are a collection of related questions about a topic. For instance, as an educational centre, you may collect reviews via different question set(s) according to intermediate level science courses, advanced level science courses, and more.",
      target: ".question-set",
    },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForQuestions,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  const joyRideStepsForGetStarted = [
    {
      icon: <Sparkles />,
      title: "Add your first question",
      content: "Add / view all questions here. Start to add a question.",
      target: ".questions",
      disableBeacon: true,
      placement: "right",
    },
    {
      icon: <Sparkles />,
      title: "Click to add new question",
      content:
        "Click on “Add Question” button to start adding your first question.",
      target: ".add-button",
    },
    {
      icon: <Sparkles />,
      title: "Click to copy Magiclink",
      content: "Go to your browser and paste the link to view review page.",
      target: ".copy-link",
    },
  ];
  const [stepGetStart, setStepGetStart] = useState({
    run: false,
    steps: joyRideStepsForGetStarted,
    stepIndex: 0,
  });
  const handleJoyrideCallbackGetStart = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStepGetStart((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "get-start") {
      setStepGetStart((prev) => ({ ...prev, run: true }));
    }
  }, [referer]);

  useEffect(() => {
    if (referer === "verify-email" && location.pathname.includes("questions")) {
      setStep((prev) => ({ ...prev, run: true }));
    }
  }, [referer, location.pathname]); // Add dependencies to avoid unnecessary re-renders

  const debouncedSearchQuestion = useDebounce(searchQuestion, 500);

  const {
    data: questionsFromNormalApi,
    isLoading: isQuestionsNormalLoading,
    refetch: refetchNormalQuestions,
    isFetching: isQuestionsFromNormalApiFetching,
  } = useGetDefaultEntityQuestions(
    {
      filter: filter,
      sort: sort,
    },
    {
      onSuccess: (res) => {
        setQuestions(res?.data);
        setExtra(res?.extra?.From);
      },
      enabled: !searchQuestion && selectedQuestionSet === null,
      refetchOnWindowFocus: false, // Prevent refetching when window/tab gains focus
      refetchOnMount: false, // Prevent refetching when the component mounts
      refetchOnReconnect: false, // Prevent refetching when reconnect
    }
  );

  const {
    data: questionsFromSetApi,
    isLoading: isQuestionsSetsLoading,
    refetch: refetchSetsQuestions,
    isFetching: isQuestionsFromSetApiFetching,
  } = useGetDefaultEntityQuestionsForSets(
    {
      filter: filter,
      sort: sort,
      set: selectedQuestionSet,
    },
    {
      onSuccess: (res) => {
        setQuestionsForSets(res?.data);
        setExtra(res?.extra?.From);
      },
      enabled: !searchQuestion && Boolean(selectedQuestionSet),
      refetchOnWindowFocus: false, // Prevent refetching when window/tab gains focus
      refetchOnMount: false, // Prevent refetching when the component mounts
      refetchOnReconnect: false, // Prevent refetching when reconnect
    }
  );

  const { isLoading: isSearchQuestionLoading } = useGetSearchQuestions(
    {
      search: debouncedSearchQuestion,
    },
    {
      onSuccess: (res) => {
        setQuestions(res?.data);
        setFilter(null);
      },
    }
  );

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();

  const { mutate: toggleQuestion } = usePostToggleQuestion();
  const { mutate: deleteQuestionSet, isLoading: deleteQuestionSetLoading } =
    useDeleteQuestionSet();

  const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
    usePostAddQuestionSet();

  const handleAddModal = () => {
    setOpenAddModal(true);
  };

  const handleToggleQuestion = (id) => {
    toggleQuestion(id, {
      onSuccess: (res) => {
        toast.success(res?.message);
        refetchQuestions();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddQuestionSet = () => {
    if (!questionSetName) {
      toast.error("Question set name is required");
      return;
    }
    addQuestionSet(
      {
        name: questionSetName,
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchQuestionSets();
          setQuestionSetName("");
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteQuestionSet = () => {
    deleteQuestionSet(questionSetId, {
      onSuccess: (res) => {
        toast.success(res?.message);
        setIsOpenDeleteQuestionSet(false);
        refetchQuestionSets();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const questionsFromApi = selectedQuestionSet
    ? questionsFromSetApi
    : questionsFromNormalApi;

  const refetchQuestions = selectedQuestionSet
    ? refetchSetsQuestions
    : refetchNormalQuestions;

  const isQuestionsLoading = selectedQuestionSet
    ? isQuestionsSetsLoading
    : isQuestionsNormalLoading;

  const categories =
    questionsFromApi?.tabs &&
    questionsFromApi?.tabs["category"] &&
    questionsFromApi?.tabs["category"]?.map((item) => item?.category);

  const keywords =
    questionsFromApi?.tabs &&
    questionsFromApi?.tabs["keyword"] &&
    questionsFromApi?.tabs["keyword"]?.map((item) => item?.keyword);

  const companyName = localStorage.getItem("companyName");
  const filteredQuestions = useMemo(() => {
    return questions?.filter((q) =>
      filter === "category"
        ? q.category === category
        : filter === "keyword"
          ? q.keyword === category
          : q
    );
  }, [questions, category, filter]);
  const filteredQuestionsForSets = useMemo(() => {
    return questionsForSets?.filter((q) =>
      filter === "category"
        ? q.question.category === category
        : filter === "keyword"
          ? q.question.keyword === category
          : q
    );
  }, [questionsForSets, category, filter]);

  useEffect(() => {
    if (!categories?.includes(category) && filter === "category") {
      setCategory(categories?.[0]);
    }
    if (!keywords?.includes(category) && filter === "keyword") {
      setCategory(keywords?.[0]);
    }
  }, [categories, keywords, filter]);

  useEffect(() => {
    if (!searchQuestion) {
      refetchQuestions();
      setFilter(null);
    }
  }, [searchQuestion]);

  const defaultSet = useMemo(() => {
    return questionSets?.find((item) => item?.is_default);
  }, [questionSets]);

  const selectedQuestionSetName = useMemo(() => {
    if (selectedQuestionSet) {
      return questionSets?.find((item) => item?.id === selectedQuestionSet)
        ?.name;
    }
  }, [questionSets, selectedQuestionSet]);

  useEffect(() => {
    setSelectedQuestionSet(defaultSet?.id);
  }, [defaultSet]);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  // if (addQuestionSetLoading) {
  //   return <LogoLoading />;
  // }

  return (
    <div
      className={`w-full py-5 ${
        !dashboard && "lg:py-[70px]"
      } ${"dark:bg-darkLightBg rounded-[16px] py-5 lg:py-10 min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]"}`}
    >
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      <Joyride
        callback={handleJoyrideCallbackGetStart}
        run={stepGetStart.run}
        steps={stepGetStart.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      {openAddModal && (
        <AddQuestionModal
          openAddModal={openAddModal}
          setOpenAddModal={setOpenAddModal}
          questionSet={selectedQuestionSetName}
        />
      )}

      {isOpenAddOrEditQuestionSet && (
        <AddOrEditQuestionSet
          isOpenModal={isOpenAddOrEditQuestionSet}
          setIsOpenmodal={setIsOpenAddOrEditQuestionSet}
          data={toUpdateQuestionSet}
          setData={setToUpdateQuestionSet}
          refetchQuestionSets={refetchQuestionSets}
          key={uuidv4()}
        />
      )}

      {isOpenDeleteQuestionSet && (
        <DeleteModal
          openModal={isOpenDeleteQuestionSet}
          setOpenModal={setIsOpenDeleteQuestionSet}
          title={`Are you sure want to delete the question set "${questionSets?.find((set) => set?.id === questionSetId)?.name}"?`}
          handleDelete={handleDeleteQuestionSet}
          isLoading={deleteQuestionSetLoading}
        />
      )}

      {isOpenQuestionSettings && (
        <QuestionSettingsModal
          isOpenModal={isOpenQuestionSettings}
          setIsOpenModal={setIsOpenQuestionSettings}
        />
      )}

      {isOpenPreviewModal && (
        <QuestionPreview
          isOpenModal={isOpenPreviewModal}
          setIsOpenModal={setIsOpenPreviewModal}
          questionSetId={selectedQuestionSet}
          key={selectedQuestionSet}
        />
      )}

      <>
        {!dashboard && (
          <motion.h1
            className="md:text-center text-[#333333] text-[36px] lg:text-[68px] font-bold lg:leading-[63.2px] -tracking-[1.3px] mb-[21px] font-opensans"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeIn" }}
          >
            Hello!
            <br className="md:hidden" /> {companyName}
          </motion.h1>
        )}
        {!dashboard && (
          <motion.p
            className="text-[#10182899] font-montserrat md:leading-[28px] text-[15px] mb-[20px] text-center hidden md:block"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4, ease: "easeIn" }}
          >
            Describe your existing or new business in detail, and receive{" "}
            <WordBreak /> feedback oriented questions.
          </motion.p>
        )}
      </>
      <div className="flex flex-col-reverse lg:flex-row flex-wrap gap-5 ">
        <div className="lg:w-[30%] w-full lg:px-1 pt-1">
          <Button
            onClick={() => handleAddModal()}
            className="gap-2 font-normal w-full"
          >
            <SquarePlus className="size-5 " /> Add question
          </Button>
        </div>
        <div className="lg:w-[67.5%] w-full lg:px-1 pt-1 flex items-center gap-5">
          <div className="relative flex w-full ">
            <input
              type="text"
              className="px-6 py-[9px] rounded-[8px] w-full border border-[#CCD7FF] dark:border-[#404040] dark:bg-darkBg placeholder:text-newPrimary dark:placeholder:text-[#a6a6a6] text-newPrimary  bg-lightpurple pl-12 font-pro text-sm font-normal h-9"
              placeholder="Search"
              value={searchQuestion}
              onChange={(e) => setSearchQuestion(e.target.value)}
            />

            <Search className="absolute top-1/2 -translate-y-1/2 left-5 w-5 h-5 text-newPrimary dark:text-[#a6a6a6] dark:border-none" />
          </div>
          <Button
            className="gap-2 border border-[#ebebeb] bg-white text-[#656565] hover:bg-white hover:text-newPrimary dark:hover:bg-white"
            variant="ghost"
            onClick={() => setIsPreview((prev) => !prev)}
          >
            <Play className="size-5" />
            Preview
          </Button>
          {isPreview && (
            <Button
              className="border border-[#ebebeb] bg-white text-[#656565] hover:bg-white hover:text-newPrimary dark:hover:bg-white flex-shrink-0"
              variant="ghost"
              size="icon"
              onClick={() => setIsOpenPreviewModal(true)}
            >
              <Monitor className="size-5" />
            </Button>
          )}
        </div>
        {!isPreview && (
          <div className="lg:w-[30%] w-full rounded-[12px] lg:max-h-[calc(100vh-216px)] lg:overflow-y-auto font-pro scrollbar-none  border border-[#EBEBEB] dark:border-none bg-white dark:bg-darkBg px-5">
            <Accordion type="multiple" collapsible className="w-full">
              <AccordionItem value="question-set">
                <AccordionTrigger>Question Set</AccordionTrigger>
                <AccordionContent>
                  <Command>
                    <CommandList>
                      <CommandEmpty>No question set</CommandEmpty>
                      <CommandGroup>
                        {questionSets
                          ?.sort((a, b) => b?.is_default - a?.is_default)
                          ?.map((item) => (
                            <CommandItem key={item?.id} value={item?.id}>
                              <p
                                className={`w-full cursor-pointer group-hover:text-newPrimary text-[15px] font-normal  ${selectedQuestionSet === item?.id && "text-newPrimary"}`}
                                onClick={() => {
                                  setSelectedQuestionSet(item?.id);
                                }}
                              >
                                {item?.name === "default"
                                  ? "Default"
                                  : item?.name}
                              </p>
                              <div className="flex items-center">
                                <IconButton>
                                  <Pencil
                                    className="w-4 h-4 cursor-pointer text-[#656565]"
                                    onClick={() => {
                                      setIsOpenAddOrEditQuestionSet(true);
                                      setToUpdateQuestionSet(item);
                                    }}
                                  />
                                </IconButton>
                                {item?.name !== "Default" && (
                                  <IconButton>
                                    <Trash2
                                      className="w-4 h-4 cursor-pointer text-red-500"
                                      onClick={() => {
                                        setIsOpenDeleteQuestionSet(true);
                                        setQuestionSetId(item?.id);
                                      }}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            </CommandItem>
                          ))}
                      </CommandGroup>
                      <div className="flex items-center gap-2 px-1 mt-2">
                        <Input
                          value={questionSetName}
                          onChange={(e) => setQuestionSetName(e.target.value)}
                          className="h-8 bg-[#f8f9f8] border-[#ebebeb]"
                          placeholder="Create new set"
                        />
                        <Button onClick={handleAddQuestionSet}>
                          {addQuestionSetLoading ? (
                            <Loader2 className=" size-5 animate-spin" />
                          ) : (
                            "Add"
                          )}
                        </Button>
                      </div>
                    </CommandList>
                  </Command>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="filter">
                <AccordionTrigger>
                  <div className="flex items-center gap-3">
                    <Settings2 className="size-4" />
                    Filters
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <div className="max-h-[300px] overflow-y-auto scrollbar-thin text-sm ">
                    <RadioGroup
                      value={filter}
                      defaultValue={filter}
                      onValueChange={(value) => setFilter(value)}
                      className="gap-0 pb-0"
                    >
                      <div className="flex gap-3  pb-2 w-full justify-between">
                        {filter === "category" ? (
                          <CustomDropdown
                            value={category}
                            setValue={setCategory}
                            values={
                              questionsFromApi?.tabs
                                ? questionsFromApi?.tabs["category"]
                                : []
                            }
                            isToggle={true}
                            setId={selectedQuestionSet}
                            title={"Categories"}
                          />
                        ) : (
                          <label htmlFor="category" className="cursor-pointer">
                            Categories
                          </label>
                        )}
                        <RadioGroupItem
                          value={"category"}
                          id="category"
                          className="mt-0.5"
                        ></RadioGroupItem>
                      </div>
                      <div className="flex gap-3  py-2 w-full justify-between">
                        {filter === "keyword" ? (
                          <CustomDropdown
                            value={category}
                            setValue={setCategory}
                            values={
                              questionsFromApi?.tabs
                                ? questionsFromApi?.tabs["keyword"]
                                : []
                            }
                            isToggle={false}
                            setId={selectedQuestionSet}
                            title={"Keywords"}
                          />
                        ) : (
                          <label htmlFor="keyword" className="cursor-pointer">
                            Keywords
                          </label>
                        )}
                        <RadioGroupItem
                          value={"keyword"}
                          id="keyword"
                          className="mt-0.5"
                        ></RadioGroupItem>
                      </div>
                      <div className="flex items-center gap-3 py-2 justify-between">
                        <label htmlFor="question" className="cursor-pointer">
                          Only Questions
                        </label>
                        <RadioGroupItem
                          value={"question"}
                          id="question"
                        ></RadioGroupItem>
                      </div>
                      <div className="flex items-center gap-3 py-2 justify-between">
                        <label htmlFor="all" className="cursor-pointer">
                          View All
                        </label>
                        <RadioGroupItem value={null} id="all"></RadioGroupItem>
                      </div>
                    </RadioGroup>
                  </div>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="sort">
                <AccordionTrigger>
                  <div className="flex items-center gap-3">
                    <ArrowDownUp className="size-4" />
                    Sort
                  </div>
                </AccordionTrigger>
                <AccordionContent>
                  <RadioGroup
                    value={sort}
                    onValueChange={(e) => setSort(e)}
                    className="gap-0"
                  >
                    <div
                      className={`flex items-center gap-3 justify-between pb-2`}
                    >
                      <label htmlFor="recent" className="cursor-pointer">
                        Recently Added
                      </label>
                      <RadioGroupItem
                        value={"recent"}
                        id="recent"
                      ></RadioGroupItem>
                    </div>
                    <div
                      className={`flex items-center gap-3 justify-between pt-2`}
                    >
                      <label htmlFor="enabled" className="cursor-pointer">
                        Enabled
                      </label>
                      <RadioGroupItem
                        value={"enabled"}
                        id="enabled"
                      ></RadioGroupItem>
                    </div>
                  </RadioGroup>
                </AccordionContent>
              </AccordionItem>
              <AccordionItem value="settings">
                <div
                  className="flex flex-1 items-center justify-between py-4 font-medium transition-all hover:underline cursor-pointer"
                  onClick={() => setIsOpenQuestionSettings(true)}
                >
                  <div className="flex items-center gap-3">
                    <Settings className="size-4" />
                    Settings
                  </div>
                  <ChevronRight className="size-4" />
                </div>
              </AccordionItem>
            </Accordion>
          </div>
        )}
        <div className="lg:w-[67.5%] w-full rounded-[12px]  lg:overflow-y-auto lg:max-h-[calc(100vh-216px)] font-pro scrollbar-none lg:px-1 ">
          {/* <div className="flex items-center justify-between mb-5">
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  role="combobox"
                  aria-expanded={open}
                  className="gap-2 border border-[#ebebeb] bg-white text-[#656565] hover:bg-white hover:text-newPrimary dark:hover:bg-white min-w-[250px]  flex justify-between capitalize"
                  variant="ghost"
                >
                  {selectedQuestionSet
                    ? questionSets?.find(
                        (item) => item?.id === selectedQuestionSet
                      )?.name
                    : "Select question set"}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="min-w-[250px] p-0 text-[#656565]">
                <Command>
                  <CommandList>
                    <CommandEmpty>No question set</CommandEmpty>
                    <CommandGroup>
                      {questionSets
                        ?.sort((a, b) => b?.is_default - a?.is_default)
                        ?.map((item) => (
                          <CommandItem key={item?.id} value={item?.id}>
                            <Check
                              className={cn(
                                "mr-2 h-4 w-4",
                                selectedQuestionSet === item?.id
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            <p
                              className="w-full cursor-pointer group-hover:text-newPrimary text-[15px] font-normal text-[#656565]"
                              onClick={() => {
                                setSelectedQuestionSet(item?.id);
                              }}
                            >
                              {item?.name === "default"
                                ? "Default"
                                : item?.name}
                            </p>
                            <div className="flex items-center">
                              <IconButton>
                                <Pencil
                                  className="w-4 h-4 cursor-pointer"
                                  onClick={() => {
                                    setIsOpenAddOrEditQuestionSet(true);
                                    setToUpdateQuestionSet(item);
                                  }}
                                />
                              </IconButton>
                              {item?.name !== "Default" && (
                                <IconButton>
                                  <Trash2
                                    className="w-4 h-4 cursor-pointer text-red-500"
                                    onClick={() => {
                                      setIsOpenDeleteQuestionSet(true);
                                      setQuestionSetId(item?.id);
                                    }}
                                  />
                                </IconButton>
                              )}
                            </div>
                          </CommandItem>
                        ))}
                    </CommandGroup>
                    <div className="flex items-center gap-2 p-2">
                      <Input
                        value={questionSetName}
                        onChange={(e) => setQuestionSetName(e.target.value)}
                        className="h-8"
                      />
                      {addQuestionSetLoading ? (
                        <Loader2 className="text-newPrimary size-5 animate-spin" />
                      ) : (
                        <SquarePlus
                          className="text-newPrimary size-5 cursor-pointer"
                          onClick={handleAddQuestionSet}
                        />
                      )}
                    </div>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            <div className="flex items-center gap-3">
              <Popover>
                <PopoverTrigger>
                  <Button
                    className="gap-2 border border-[#ebebeb] bg-white text-[#656565] hover:bg-white hover:text-newPrimary dark:hover:bg-white h-9 w-9"
                    variant="ghost"
                    size="icon"
                    // onClick={() => setIsOpenFilterSelect(true)}
                  >
                    <Filter className="size-5" />
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="p-0" align="end">
                  <div className="max-h-[300px] overflow-y-auto scrollbar-thin text-sm text-[#656565]">
                    <RadioGroup
                      value={filter}
                      defaultValue={filter}
                      onValueChange={(value) => setFilter(value)}
                      className="gap-0 p-4 pb-0"
                    >
                      <div className="flex gap-3 border-b pb-2">
                        <RadioGroupItem
                          value={"category"}
                          id="category"
                          className="mt-0.5"
                        ></RadioGroupItem>
                        {filter === "category" ? (
                          <CustomDropdown
                            value={category}
                            setValue={setCategory}
                            values={
                              questionsFromApi?.tabs
                                ? questionsFromApi?.tabs["category"]
                                : []
                            }
                            isToggle={true}
                            setId={selectedQuestionSet}
                            title={"Categories"}
                          />
                        ) : (
                          <label htmlFor="category" className="cursor-pointer">
                            Categories
                          </label>
                        )}
                      </div>
                      <div className="flex gap-3 border-b py-2">
                        <RadioGroupItem
                          value={"keyword"}
                          id="keyword"
                          className="mt-0.5"
                        ></RadioGroupItem>
                        {filter === "keyword" ? (
                          <CustomDropdown
                            value={category}
                            setValue={setCategory}
                            values={
                              questionsFromApi?.tabs
                                ? questionsFromApi?.tabs["keyword"]
                                : []
                            }
                            isToggle={false}
                            setId={selectedQuestionSet}
                            title={"Keywords"}
                          />
                        ) : (
                          <label htmlFor="keyword" className="cursor-pointer">
                            Keywords
                          </label>
                        )}
                      </div>
                      <div className="flex items-center gap-3 py-2 border-b">
                        <RadioGroupItem
                          value={"question"}
                          id="question"
                        ></RadioGroupItem>
                        <label htmlFor="question" className="cursor-pointer">
                          Only Questions
                        </label>
                      </div>
                      <div className="flex items-center gap-3 py-2 ">
                        <RadioGroupItem value={null} id="all"></RadioGroupItem>
                        <label htmlFor="all" className="cursor-pointer">
                          View All
                        </label>
                      </div>
                    </RadioGroup>

                    {selectedQuestionSet && (
                      <>
                        <hr className="mt-3" />
                        <RadioGroup
                          value={sort}
                          onValueChange={(e) => setSort(e)}
                          className="gap-0"
                        >
                          <div
                            className={`flex items-center gap-3 h-9 px-4 ${sort === "recent" && "bg-lightpurple dark:bg-accent"}`}
                          >
                            <RadioGroupItem
                              value={"recent"}
                              id="recent"
                            ></RadioGroupItem>
                            <label htmlFor="recent" className="cursor-pointer">
                              Recently Added
                            </label>
                          </div>
                          <div
                            className={`flex items-center gap-3 h-9 px-4 ${sort === "enabled" && "bg-lightpurple dark:bg-accent"}`}
                          >
                            <RadioGroupItem
                              value={"enabled"}
                              id="enabled"
                            ></RadioGroupItem>
                            <label htmlFor="enabled" className="cursor-pointer">
                              Enabled
                            </label>
                          </div>
                        </RadioGroup>
                      </>
                    )}
                  </div>
                </PopoverContent>
              </Popover>
              <Button
                className="gap-2 border border-[#ebebeb] bg-white text-[#656565] hover:bg-white hover:text-newPrimary dark:hover:bg-white h-9 w-9"
                variant="ghost"
                size="icon"
                onClick={() => setIsOpenQuestionSettings(true)}
              >
                <Settings className="size-5" />
              </Button>
            </div>
          </div> */}

          {isQuestionsLoading ||
          isQuestionsFromSetApiFetching ||
          isQuestionsFromNormalApiFetching ||
          (isSearchQuestionLoading && searchQuestion) ? (
            <Loading height={"50vh"} />
          ) : (
            <div className=" w-full">
              {
                <>
                  {searchQuestion || !selectedQuestionSet ? (
                    <>
                      {filteredQuestions?.length > 0 ? (
                        <>
                          <div className="space-y-5">
                            {filteredQuestions?.map((item, questionIndex) => (
                              <QuestionCard
                                key={item?.id}
                                item={item}
                                questionIndex={questionIndex}
                                handleToggleQuestion={handleToggleQuestion}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-[43vh] bg-white rounded-lg font-pro">
                          <img
                            src="/empty/no-question.svg"
                            alt=""
                            className="w-[153px] h-[118px]"
                          />
                          <p className="text-[13px] font-normal text-[#3d3d3d] dark:text-white mt-5">
                            No questions in the list!
                          </p>
                          <p className="text-[13px] font-normal  text-[#888] dark:text-white text-center mt-3">
                            Click on “Add questions” to add questions to your
                            list
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {filteredQuestionsForSets?.length > 0 ? (
                        <>
                          <div className="space-y-5">
                            {filteredQuestionsForSets?.map(
                              (item, questionIndex) => (
                                <QuestionCardForQuestionSet
                                  key={item?.quesiton?.id}
                                  item={item}
                                  questionIndex={questionIndex}
                                  handleToggleQuestion={handleToggleQuestion}
                                />
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <QuestionsIllustrations />
                      )}
                    </>
                  )}
                </>
              }
            </div>
          )}
        </div>
        {isPreview && (
          <div className="lg:w-[30%] w-full rounded-[12px] lg:max-h-[calc(100vh-216px)] lg:overflow-y-auto font-pro scrollbar-none lg:px-1 pt-1 hidden lg:flex">
            <QuestionPreviewMobile questionSetId={selectedQuestionSet} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Questions;
