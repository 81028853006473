/* eslint-disable react/prop-types */
import React, { forwardRef, useRef, useState } from "react";
import { Switch } from "../../../../components/ui/switch";
import { Input } from "../../../../components/ui/input";
import {
  Asterisk,
  Circle,
  Eye,
  EyeClosed,
  EyeOff,
  Info,
  Phone,
  PlusSquare,
  Trash2,
} from "lucide-react";
import TagsInput from "react-tagsinput";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Button } from "../../../../components/ui/button";

import { Rating } from "react-simple-star-rating";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "../../../../components/ui/tooltip";

const MagiclinkSettings = forwardRef(
  (
    {
      isShowName,
      setIsShowName,
      isVoiceReview,
      setIsVoiceReview,
      isOnlyVoiceReview,
      setIsOnlyVoiceReview,
      starRating,
      setStarRating,
      isNegativeFeedback,
      setIsNegativeFeedback,
      name,
      setName,
      label,
      setLabel,
      tags,
      setTags,
      inputTag,
      setInputTag,
      handleAddTag,
      isOpenRedirectBox,
      setIsOpenRedirectBox,
      isOpenTagBox,
      setIsOpenTagBox,
      tagsFromApi,
      deleteLoading,
      deleteTagId,
      handleDeleteTag,
      extractedTags,
      handleAddClickTag,
      redirects,
      setRedirects,
      inputRedirect,
      setInputRedirect,
      emplyeeIntegrations,
      questionSet,
      questionSets,
      setQuestionSet,
      setOpenModal,
      setData,
      handleClick,
      isLoading,
      customerInfo,
      setCustomerInfo,
      subheading,
      setSubheading,
      isDirectToReview,
      setIsDirectToReview,
      offerPage,
      setOfferPage,
      isReqInfo,
      setIsReqInfo,
    },
    { redirectBoxRef, tagBoxRef }
  ) => {
    const customerInfos = [
      {
        name: "Email",
        field_name: "email",
        must: false,
        type: "email",
      },
      {
        name: "Phone",
        field_name: "phone",
        must: false,
        type: "phone",
      },
      {
        name: "WhatsApp",
        field_name: "whatsapp",
        must: false,
        type: "phone",
      },
    ];

    const handleAddCustomerInfo = (item) => {
      setCustomerInfo((prev) =>
        prev.some((prevItem) => prevItem?.name === item?.name)
          ? prev
          : [...prev, item]
      );
    };

    return (
      <div className="px-6 grid grid-cols-1 lg:grid-cols-2 gap-5 max-h-[70svh] overflow-y-auto scrollbar-thin">
        <div className="flex flex-col gap-5">
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="select-name">
              <div className="flex items-center gap-x-1.5 h-6">
                <p className="font-pro font-medium dark:text-white text-[15px]">
                  Name
                </p>
                <Asterisk className="text-red-500 size-3" />
              </div>

              <Input
                type="text"
                className="mt-3 bg-[#f8f9f8] border-none placeholder:text-[#bfbfbf] h-[38px]"
                placeholder="eg. John"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="select-role">
              <p className="font-pro font-medium dark:text-white text-[15px]">
                Role
              </p>
              <Input
                type="text"
                className="mt-3 bg-[#f8f9f8] border-none placeholder:text-[#bfbfbf] h-[38px]"
                placeholder="Enter role"
                value={label}
                onChange={(e) => setLabel(e.target.value)}
              />
            </div>
            <div className="flex justify-between items-center gap-3">
              <div className="flex items-center gap-3">
                <p className="font-pro dark:text-white text-[15px]">
                  Include &quot;Name&quot; and &quot;Role&quot; in Reviews
                </p>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="size-5" />
                    </TooltipTrigger>
                    <TooltipContent>
                      Show the MagicLink&apos;s assigned Name and Role on the
                      Review page.
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <Switch
                checked={isShowName}
                onCheckedChange={() => setIsShowName((prev) => !prev)}
              ></Switch>
            </div>
          </div>
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="flex justify-between items-start gap-5">
              <div className="space-y-1">
                <p className="font-pro dark:text-white text-[15px]">
                  Voice Review
                </p>
                <p className="font-pro dark:text-white text-[#808080] text-xs">
                  Allow reviewers to record their voice, and review is generated
                  based on their input.
                </p>
              </div>

              <Switch
                checked={isVoiceReview}
                onCheckedChange={() => setIsVoiceReview((prev) => !prev)}
              ></Switch>
            </div>
            {isVoiceReview && (
              <div className="flex justify-between items-start gap-5">
                <div className="space-y-1">
                  <p className="font-pro dark:text-white text-[15px]">
                    Questions / Keywords
                  </p>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Show predefined questions or keywords to help reviewers
                    generate their review.
                  </p>
                </div>
                <Switch
                  checked={!isOnlyVoiceReview}
                  onCheckedChange={() => setIsOnlyVoiceReview((prev) => !prev)}
                ></Switch>
              </div>
            )}
            <div className="flex justify-between items-start gap-5">
              <div className="space-y-1">
                <p className="font-pro dark:text-white text-[15px]">
                  Direct to Review
                </p>
                <p className="font-pro dark:text-white text-[#808080] text-xs">
                  Automatically redirect reviewers to the platform after
                  generating a review, skipping the selection step.
                </p>
              </div>
              <Switch
                checked={isDirectToReview}
                onCheckedChange={() => setIsDirectToReview((prev) => !prev)}
              ></Switch>
            </div>
          </div>
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="flex justify-between">
              <div className="flex gap-3">
                <div className="space-y-1">
                  <p className="font-pro dark:text-white text-[15px]">
                    Request Customer Information
                  </p>
                  <p className="font-pro dark:text-white text-[#808080] text-xs">
                    Collect customer details for redirected reviews.
                  </p>
                </div>

                {isReqInfo && (
                  <DropdownMenu modal={false}>
                    <DropdownMenuTrigger asChild>
                      <PlusSquare className="size-5 text-newPrimary cursor-pointer" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className={"z-[1010]"}>
                      {customerInfos?.map((item, i) => (
                        <DropdownMenuItem
                          key={i}
                          onClick={() => handleAddCustomerInfo(item)}
                          className="capitalize"
                        >
                          {item.name}
                        </DropdownMenuItem>
                      ))}
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
              <Switch
                checked={isReqInfo}
                onCheckedChange={() => setIsReqInfo((prev) => !prev)}
              ></Switch>
            </div>
            <div className="w-full font-pro dark:!border-[#454545] dark:text-white  min-h-10  space-y-3">
              <div className="flex w-full gap-3 items-center">
                <p className="text-xs  font-medium flex-shrink-0">
                  Sub Heading
                </p>

                <Input
                  value={subheading?.text}
                  onChange={(e) => {
                    setSubheading((prev) => {
                      const current = { ...prev };

                      current.text = e.target.value;

                      return current;
                    });
                  }}
                  className={" placeholder:text-[#9d9d9d] dark:bg-darkLightBg"}
                  placeholder="e.g. Enter your details to get rewards"
                  disabled={!subheading.show}
                />
                {subheading?.show ? (
                  <Eye
                    className="size-4 flex-shrink-0"
                    onClick={() => {
                      setSubheading((prev) => {
                        const current = { ...prev };

                        current.show = !current.show;

                        return current;
                      });
                    }}
                  />
                ) : (
                  <EyeOff
                    className="size-4 flex-shrink-0"
                    onClick={() => {
                      setSubheading((prev) => {
                        const current = { ...prev };

                        current.show = !current.show;

                        return current;
                      });
                    }}
                  />
                )}
              </div>
              {customerInfo?.map((item, i) => (
                <div
                  className="grid grid-cols-2 w-full gap-3 items-center"
                  key={i}
                >
                  <div className="relative">
                    <Input
                      value={item?.name}
                      readOnly
                      className={
                        "h-8 bg-newPrimary/10 dark:bg-darkLightBg text-newPrimary focus-visible:ring-0 focus-visible:ring-offset-0 pr-8"
                      }
                    />
                    <div
                      className={`flex items-center justify-center ${item?.must ? "bg-newPrimary" : "bg-[#ddd]"} rounded-full size-4 absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer`}
                      onClick={() => {
                        setCustomerInfo((prev) => {
                          const current = [...prev];
                          current[i].must = !current[i]?.must;
                          return current;
                        });
                      }}
                    >
                      <div className="rounded-full size-1.5 bg-white"></div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <p className="font-medium text-[#9d9d9d] text-xs w-[60px]">
                      {" "}
                      {item?.must ? "Must have" : "Optional"}
                    </p>
                    <Trash2
                      onClick={() =>
                        setCustomerInfo((prev) =>
                          prev.filter((_, index) => index !== i)
                        )
                      }
                      className="size-4 text-red-500 cursor-pointer"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5">
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="space-y-1">
              <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                Redirects <Asterisk className="text-red-500 size-3" />
              </p>
              <p className="font-pro dark:text-white text-[#808080] text-xs">
                Choose platforms where reviewers should be redirected.
              </p>
            </div>

            <div className="relative">
              <TagsInput
                value={redirects}
                onChange={(tags) => setRedirects(tags)}
                inputValue={inputRedirect}
                onChangeInput={(value) => setInputRedirect(value)}
                className="w-full font-pro  dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-md mt-3 px-3 pt-1 min-h-[38px] bg-[#f8f9f8] !border-none placeholder:text-[#bfbfbf]"
                inputProps={{
                  onFocus: () => setIsOpenRedirectBox(true),
                }}
                renderInput={({ onChange, value, ...other }) => (
                  <input
                    type="text"
                    onChange={onChange}
                    value={value}
                    {...other}
                    placeholder=""
                    className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white h-8 font-pro !bg-[#f8f9f8]"
                  />
                )}
                renderTag={({
                  tag,
                  key,
                  disabled,
                  onRemove,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                }) => (
                  <p
                    key={key}
                    {...other}
                    className="react-tagsinput-tag !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !py-0.5 !w-fit !text-black  dark:!text-white font-pro h-8"
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && (
                      <a
                        className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
                        onClick={(e) => onRemove(key)}
                      />
                      // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
                    )}
                  </p>
                )}
              />
              {isOpenRedirectBox && (
                <div
                  className="absolute translate-y-3 left-0 w-full bg-white max-h-[120px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
                  ref={redirectBoxRef}
                >
                  {emplyeeIntegrations
                    ?.filter(
                      (item) =>
                        item
                          ?.toLowerCase()
                          ?.indexOf(inputRedirect?.toLowerCase()) > -1
                    )
                    ?.map((item) => (
                      <div
                        key={item}
                        className="flex justify-between items-center gap-x-1"
                      >
                        <div
                          className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
                          onClick={() => {
                            if (!redirects?.includes(item)) {
                              setRedirects((prev) => [...prev, item]);
                            }
                          }}
                        >
                          <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
                            {item}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="space-y-1">
              <p className="font-pro dark:text-white text-[15px]">
                Question Set
              </p>
              <p className="font-pro dark:text-white text-[#808080] text-xs">
                Select a predefined set of questions to be displayed on the
                review page.
              </p>
            </div>
            <Select
              value={questionSet}
              onValueChange={(e) => setQuestionSet(e)}
            >
              <SelectTrigger className="bg-[#f8f9f8] border-none placeholder:text-[#bfbfbf] h-[38px] dark:bg-darkLightBg">
                <SelectValue placeholder="e.g Set 1" />
              </SelectTrigger>
              <SelectContent className="dark:bg-darkLightBg">
                {questionSets?.map((item) => (
                  <SelectItem value={item?.id} key={item?.id}>
                    {item?.name === "default" ? "Default" : item?.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="border rounded-[10px] p-5 space-y-5 select-tags">
            <div className="space-y-1">
              <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                Tags
              </p>
              <p className="font-pro dark:text-white text-[#808080] text-xs">
                Add tags to this MagicLink to help categorise and differentiate
                it from others.
              </p>
            </div>
            <div className="relative">
              <TagsInput
                value={tags}
                onChange={(tags) => setTags(tags)}
                inputValue={inputTag}
                onChangeInput={(value) => setInputTag(value)}
                className="w-full font-pro  dark:bg-darkLightBg dark:!border-[#454545] dark:text-white rounded-md mt-3 px-3 pt-1 min-h-[38px] bg-[#f8f9f8] !border-none placeholder:text-[#bfbfbf]"
                inputProps={{
                  onKeyDown: handleAddTag,
                  onFocus: () => setIsOpenTagBox(true),
                }}
                renderInput={({ onChange, value, ...other }) => (
                  <input
                    type="text"
                    onChange={onChange}
                    value={value}
                    {...other}
                    placeholder=""
                    className="!border-0 w-24 focus:!border-0 focus:!outline-none focus:!appearance-none focus:!ring-0 dark:!bg-darkLightBg  dark:text-white h-8 font-pro !bg-[#f8f9f8]"
                  />
                )}
                renderTag={({
                  tag,
                  key,
                  disabled,
                  onRemove,
                  classNameRemove,
                  getTagDisplayValue,
                  ...other
                }) => (
                  <p
                    key={key}
                    {...other}
                    className="react-tagsinput-tag font-pro !bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !py-0.5 !w-fit !text-black  dark:!text-white h-8"
                  >
                    {getTagDisplayValue(tag)}
                    {!disabled && (
                      <a
                        className={`${classNameRemove} !text-base before:!content-["x"] font-montserrat before:pl-2 font-medium`}
                        onClick={(e) => onRemove(key)}
                      />
                      // <X className="w-3.5 h-3.5" onClick={(e) => onRemove(key)} />
                    )}
                  </p>
                )}
              />
              {isOpenTagBox && (
                <div
                  className="absolute translate-y-2 left-0 w-full bg-white max-h-[120px] overflow-y-auto scrollbar-thin space-y-1 p-3 rounded-xl shadow-xl z-50 dark:bg-darkLightBg  dark:text-white"
                  ref={tagBoxRef}
                >
                  {tagsFromApi
                    ?.filter(
                      (item) =>
                        item?.tag
                          ?.toLowerCase()
                          ?.indexOf(inputTag?.toLowerCase()) > -1
                    )
                    ?.map((item) => (
                      <div
                        key={item}
                        className="flex justify-between items-center gap-x-1"
                      >
                        <div
                          className="w-full cursor-pointer hover:bg-gray-100 dark:hover:bg-darkBg rounded-md pt-1 px-1"
                          onClick={() => {
                            if (!tags?.includes(item?.tag)) {
                              setTags((prev) => [...prev, item?.tag]);
                            }
                          }}
                        >
                          <div className="!bg-[#7190ff1f] !border-[rgba(107,141,208,0.41)] !rounded-lg !px-2 !w-fit !text-black dark:!text-white react-tagsinput-tag !block ">
                            {item?.tag}
                          </div>
                        </div>

                        {deleteLoading && item?.id === deleteTagId ? (
                          <div className="w-4 h-4 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                        ) : (
                          <Trash2
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => handleDeleteTag(item)}
                          />
                        )}
                      </div>
                    ))}
                  {extractedTags?.length <= 0 && inputTag?.trim() !== "" ? (
                    <div
                      className="flex items-center gap-x-2 w-full cursor-pointer hover:bg-gray-100 rounded-md py-1 px-1"
                      onClick={handleAddClickTag}
                    >
                      <p>Create &quot;{inputTag}&quot;</p>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
          <div className="border rounded-[10px] p-5  space-y-5">
            <div className="flex items-start justify-between">
              <div className="space-y-1">
                <div className="flex items-center gap-3">
                  <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                    Negative Feedback
                  </p>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Info className="size-5" />
                      </TooltipTrigger>
                      <TooltipContent>
                        Users will be redirected to the Thank You page if they
                        select the specified star rating or lower. They
                        won&apos;t be redirected to the review page.
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
                <p className="font-pro dark:text-white text-[#808080] text-xs">
                  Prevents reviews below a selected rating from being posted on
                  the redirected platform.
                </p>
              </div>

              <Switch
                checked={isNegativeFeedback}
                onCheckedChange={() => setIsNegativeFeedback((prev) => !prev)}
              ></Switch>
            </div>

            <div className="w-full font-pro dark:bg-darkLightBg dark:!border-[#454545] dark:text-white  mt-3  h-14 bg-[#f8f9f8]  placeholder:text-[#bfbfbf]  rounded-md flex justify-between items-center px-3">
              <p className="text-sm text-[#9d9d9d] font-medium">
                Select star rating
              </p>
              <Rating
                initialValue={starRating}
                onClick={(e) => setStarRating(e)}
                SVGstyle={{ display: "inline" }}
                SVGclassName="w-6"
                iconsCount={4}
              />
            </div>
          </div>
          <div className="border rounded-[10px] p-5 space-y-5">
            <div className="flex justify-between items-center gap-3">
              <div className="space-y-1">
                <p className="font-pro dark:text-white text-[15px] flex items-center gap-x-1.5">
                  Reward Page
                </p>
                <p className="font-pro dark:text-white text-[#808080] text-xs">
                  Offer a redeemable incentive for future visits.
                </p>
              </div>

              <Switch
                checked={offerPage}
                onCheckedChange={() => setOfferPage((prev) => !prev)}
              ></Switch>
            </div>
          </div>
        </div>

        <div className="flex justify-end items-end gap-x-3 lg:col-span-2">
          <Button
            className="w-[120px]"
            variant="secondary"
            onClick={() => {
              setOpenModal(false);
              setData();
            }}
          >
            Cancel
          </Button>
          <Button
            className="w-[120px]"
            onClick={handleClick}
            disabled={isLoading || !name || redirects?.length <= 0}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }
);

MagiclinkSettings.displayName = "MagiclinkSettings";

export default MagiclinkSettings;
