/* eslint-disable react/prop-types */
import React from "react";
import ProductHero from "../../components/products/ProductHero";
import MainNav from "../../components/mainNavbar/MainNav";
import ProductKeyFeatures from "../../components/products/ProductKeyFeatures";
import ProductBenefits from "../../components/products/ProductBenefits";
import { Bot, Layers, SlidersHorizontal } from "lucide-react";
import PricingComponent from "../pricing/PricingComponent";
import Footer from "../../components/footer/footer";

export const IconWrapper = ({ children, color, isBottom = false }) => (
  <div
    className={`size-[60px] flex justify-center items-center rounded-[7px] ${isBottom && "mt-auto mb-0"}`}
    style={{
      backgroundColor: color + "20",
    }}
  >
    {children}
  </div>
);

const ResponderAgent = () => {
  return (
    <div className="bg-white">
      <MainNav />
      <div className="mt-[1.2rem] lg:mt-[3.5rem] 2xl:mt-[7.5rem] relative text-black">
        <ProductHero
          title={"AI-Powered Replies,\nReal Human Connection"}
          content={
            "MagicReview’s Responder Agent crafts AI-generated responses tailored to your brand. Customize replies with specific instructions, set conditions for different review types, and control tone and length—ensuring every response is professional, personalized, and aligned with your business values."
          }
          badge={"Responder Agent"}
          imgUrl={"/products/responder-hero.png"}
        />
        <div className="bg-gradient-to-b from-[#f1f4ff] via-30% via-white to-white container">
          <ProductKeyFeatures
            contents={[
              {
                title: "Smart, Automated Review Engagement",
                description:
                  "Set AI rules to generate replies based on sentiment, keywords, and response length, saving time while maintaining authenticity.",
                icon: (
                  <IconWrapper color={"#4f75fa"}>
                    <Bot className="text-newPrimary size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Customizable Tone & Conditions",
                description:
                  "Define how your AI should respond—whether friendly, formal, or concise. Add conditions to ensure replies match different customer sentiments.",
                icon: (
                  <IconWrapper color={"#34a853"}>
                    <SlidersHorizontal className="text-[#34a853] size-10" />
                  </IconWrapper>
                ),
              },
              {
                title: "Multi-Platform Response Management",
                description:
                  "Engage with customer feedback effortlessly across multiple platforms while maintaining a consistent brand voice.",
                icon: (
                  <IconWrapper color={"#FFBD07"}>
                    <Layers className="text-[#FFBD07] size-10" />
                  </IconWrapper>
                ),
              },
            ]}
          />
          <ProductBenefits
            title={"How Responder Agent\n Strengthens Your Reputation"}
            description={
              "Automate, customize, and streamline your review\n responses with AI-powered engagement."
            }
            contents={[
              {
                title: "Personalized Replies\nat Scale",
                description:
                  "Deliver thoughtful responses without the manual effort. AI adapts to different customer sentiments for professional, engaging interactions.",
                img: <img src="/products/responder1.png" className="pt-5" />,
              },
              {
                title: "Quick, Consistent, and\n24/7 Responses",
                description:
                  "AI automates responses instantly, ensuring consistency across all reviews, even outside business hours. No review goes unanswered.",
                img: <img src="/products/responder2.png" />,
              },
              {
                title: "Customizable AI for Brand-\nAligned Responses",
                description:
                  "Tailor AI-generated replies by setting tone, length, and conditions to match your brand’s style—professional, friendly, or apologetic.",
                img: <img src="/products/responder3.png" className="pt-5" />,
              },
              {
                title: "Stronger Brand Reputation with Proactive Engagement",
                description:
                  "Businesses that actively engage with reviewers are more likely to build credibility and influence purchasing decisions.",
                img: <img src="/products/responder4.png" className="pt-5" />,
              },
            ]}
          />
        </div>
        <PricingComponent />
      </div>
      <Footer />
    </div>
  );
};

export default ResponderAgent;
