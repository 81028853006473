/* eslint-disable react/prop-types */

import { Circle, CircleCheck } from "lucide-react";
import { useState } from "react";
import Airbnb from "../../../../assets/reviewservices/airbnb.svg";
import Booking from "../../../../assets/reviewservices/booking.svg";
import Google from "../../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../../assets/reviewservices/yelp.svg";
import { Input } from "../../../../components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import {
  useGetDefaultQuestionSets,
  useGetEmployeesList,
  useGetMagicLink,
} from "../../../../query/useEmployees";
import { useGetQuestionSets } from "../../../../query/useEntity";

const SelectMagicLinkComponent = ({
  magicLink,
  setMagicLink,
  openPopover,
  setOpenPopover,
}) => {
  const [isOpenMagicLink, setIsOpenMagicLink] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const { data } = useGetEmployeesList();
  const { data: defaultMagicLink } = useGetMagicLink();
  const { data: defaultQuestionSet } = useGetDefaultQuestionSets();
  const { data: questionSets } = useGetQuestionSets();

  useGetMagicLink({
    onSuccess: (res) => {
      setBaseUrl(res.host);
    },
  });

  const handleSelectMagicLink = (e, link) => {
    e.stopPropagation();
    setMagicLink(link);
    setIsOpenMagicLink(false);
  };

  const reviewPlatforms = (platform) => {
    switch (platform) {
      case "Google":
        return <img src={Google} />;
      case "Yelp":
        return <img src={Yelp} />;
      case "Trustpilot":
        return <img src={TrustPilot} />;
      case "Airbnb":
        return <img src={Airbnb} className="w-5" />;
      case "Booking":
        return <img src={Booking} />;
      case "Tripadvisor":
        return <img src={TripAdvisor} className="w-5" />;
    }
  };

  return (
    <Popover
      open={openPopover}
      onOpenChange={() => {
        setOpenPopover(!openPopover);
      }}
      modal={false}
    >
      <PopoverTrigger className="w-full">
        <Input
          value={magicLink}
          readOnly
          // onClick={() => setIsOpenMagicLink(true)}
          className="w-full"
        />
      </PopoverTrigger>
      <PopoverContent
        className="z-[1010] lg:w-[700px] h-[50vh] w-full p-0 scrollbar-thin overflow-y-auto"
        forceMount={false}
      >
        <div className="overflow-hidden">
          <Table>
            <TableHeader>
              <TableRow className="text-[15px] text-[#808080]">
                {["", "Name", "Role", "Tags", "Redirects", "Question Set"].map(
                  (item) => (
                    <TableHead
                      key={item}
                      className="text-[#808080] dark:text-white"
                    >
                      {item}
                    </TableHead>
                  )
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow
                onClick={(e) =>
                  handleSelectMagicLink(
                    e,
                    baseUrl + "/" + defaultMagicLink?.magic_link
                  )
                }
                className={`cursor-pointer ${
                  magicLink === baseUrl + "/" + defaultMagicLink?.magic_link
                    ? "bg-newPrimary/10"
                    : ""
                }`}
              >
                <TableCell>
                  {magicLink ===
                  baseUrl + "/" + defaultMagicLink?.magic_link ? (
                    <CircleCheck className="size-4 text-newPrimary " />
                  ) : (
                    <Circle className="size-4 text-[#808080] dark:text-white" />
                  )}
                </TableCell>
                <TableCell>Default</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell className="flex items-center gap-1 w-fit">
                  {defaultQuestionSet?.redirect?.map((item) => {
                    return reviewPlatforms(item);
                  })}
                </TableCell>
                <TableCell className="capitalize">
                  {
                    questionSets?.find(
                      (item) => item?.id === defaultQuestionSet?.data
                    )?.name
                  }
                </TableCell>
              </TableRow>
              {data?.map((item) => (
                <TableRow
                  key={item?.id}
                  onClick={(e) => {
                    handleSelectMagicLink(
                      e,
                      baseUrl +
                        "/" +
                        defaultMagicLink?.magic_link?.split("?")?.[0] +
                        "?employee=" +
                        item?.employee_code
                    );
                  }}
                  className={`cursor-pointer ${
                    magicLink ===
                    baseUrl +
                      "/" +
                      defaultMagicLink?.magic_link?.split("?")?.[0] +
                      "?employee=" +
                      item?.employee_code
                      ? "bg-newPrimary/10"
                      : ""
                  }`}
                >
                  <TableCell>
                    {" "}
                    {magicLink ===
                    baseUrl +
                      "/" +
                      defaultMagicLink?.magic_link?.split("?")?.[0] +
                      "?employee=" +
                      item?.employee_code ? (
                      <CircleCheck className="size-4 text-newPrimary " />
                    ) : (
                      <Circle className="size-4 text-[#808080] dark:text-white" />
                    )}
                  </TableCell>
                  <TableCell>{item?.name}</TableCell>
                  <TableCell>
                    <p className="max-w-[180px] line-clamp-1 break-all">
                      {item?.label}
                    </p>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-1 w-[120px] flex-wrap">
                      {item?.tags?.slice(0, 3)?.map((item) => (
                        <div
                          key={item}
                          className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 py-1 w-fit text-sm"
                        >
                          <p>{item}</p>
                        </div>
                      ))}
                    </div>
                  </TableCell>
                  <TableCell className="flex items-center gap-1 w-fit">
                    {item?.redirect?.map((item) => reviewPlatforms(item))}
                  </TableCell>
                  <TableCell className="capitalize">
                    {
                      questionSets?.find((e) => e?.id === item?.default_set)
                        ?.name
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default SelectMagicLinkComponent;
