import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";

/* eslint-disable react/prop-types */
const ProductHero = ({ title, content, imgUrl, badge }) => {
  const navigate = useNavigate();
  const handleNavigate = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };
  return (
    <div className="grid grid-cols-1 2xl:grid-cols-[36%,60%] lg:grid-cols-[41%,59%] font-poppins container lg:pb-[8rem] pb-10 items-center xl:items-end 3xl:items-center lg:justify-between">
      <div className="space-y-8">
        <motion.div
          className="text-newPrimary font-medium text-[17px] lg:leading-[30px] rounded-[16px] px-7 flex justify-center items-center h-10 bg-[#ecf0fd]  w-fit"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          viewport={{
            once: true,
          }}
        >
          {badge}
        </motion.div>
        <motion.h1
          className="font-semibold lg:text-[38px] lg:leading-[48px] lg:tracking-tighter text-[#1d1d1f] text-wrap whitespace-pre"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          {title}
        </motion.h1>
        <motion.p
          className="lg:leading-[26px] text-[#2d2d2d] capitalize lg:tracking-[1%] max-w-[447px]"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.4,
          }}
          viewport={{
            once: true,
          }}
        >
          {content}
        </motion.p>
        <motion.div
          className="flex flex-col md:flex-row md:justify-start  md:items-center gap-4"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.6,
          }}
          viewport={{
            once: true,
          }}
        >
          <button
            className="flex justify-center items-center gap-3 bg-[#ecf0fd] border-l-4 border border-[#ecf0fd] border-l-newPrimary py-3 px-5 h-10 rounded-lg font-medium text-newPrimary hover:underline font-poppins"
            onClick={() => handleNavigate("/schedule-a-call")}
          >
            Schedule a demo
            <ChevronRight className="size-5" />
          </button>
          <button
            className="flex justify-center items-center gap-2 py-3 px-5 text-white bg-newPrimary rounded-lg h-10 border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white font-medium font-poppins 
                  "
            onClick={() => {
              handleNavigate("/sign-up");
            }}
          >
            Try free for 14 days
          </button>
        </motion.div>
      </div>
      <motion.img
        src={imgUrl}
        alt=""
        className={cn(
          "w-full rounded-[20px] -mb-2 max-w-[770px] 3xl:max-w-full ml-auto -mr-3"
        )}
        initial={{
          opacity: 0,
          y: 50,
        }}
        whileInView={{
          opacity: 1,
          y: 0,
        }}
        transition={{
          duration: 0.4,
          ease: "easeInOut",
          delay: 0.8,
        }}
        viewport={{
          once: true,
        }}
      />
    </div>
  );
};

export default ProductHero;
