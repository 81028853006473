import { AnimatePresence, motion } from "framer-motion";

import { Star } from "lucide-react";
import { useEffect, useState } from "react";

const scrollData = ["Responder Agent", "Key Insights", "SEO Agent", "AI Post"];

const NewHeroSectionComponent = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);

  const images = ["/magicreview/group4.png", "/magicreview/group3.png"];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % scrollData.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev === 0 ? 1 : 0));
    }, 6100);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="container pl-0">
      <AnimatePresence mode="wait">
        {currentImage === 0 && (
          <motion.div
            className=" w-full h-full place-content-center py-5 lg:py-0"
            key={0}
          >
            <motion.div
              className="relative w-full aspect-[4/3] mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1,
                ease: "easeInOut",
              }}
              key={0}
            >
              {/* <img src="/magicreview/homebg.png" alt="" /> */}
              <div className="md:flex items-center gap-3 absolute top-7 left-[12%] hidden">
                <div className="bg-newPrimary size-10 rounded-full flex items-center justify-center flex-shrink-0">
                  <Star className="size-[21px] text-white" />
                </div>
                <AnimatePresence mode="wait">
                  <div className="bg-newPrimary rounded-full h-10 px-7 font-poppins text-sm font-medium text-white flex justify-center items-center animate-fadeIn">
                    <motion.p
                      key={currentItem}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      // exit={{ opacity: 0 }}
                      transition={{
                        duration: 0.8,
                        ease: "easeInOut",
                        delay: 0.1,
                      }}
                    >
                      {scrollData[currentItem]}
                    </motion.p>
                  </div>
                </AnimatePresence>
              </div>

              <motion.img
                key={0}
                src={images[0]}
                alt={`UI View`}
                className=" w-full mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
              />
            </motion.div>
          </motion.div>
        )}
        {currentImage === 1 && (
          <motion.div
            className="w-full h-full place-content-center py-5 lg:py-0"
            key={1}
          >
            <motion.div
              className="relative w-full aspect-[4/3] mx-auto"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                duration: 1,
                ease: "easeInOut",
              }}
              key={1}
            >
              {/* <img src="/magicreview/homebg.png" alt="" /> */}
              <div className="hidden md:flex items-center gap-3 absolute top-7 left-[12%]">
                <div className="bg-newPrimary size-10 rounded-full flex items-center justify-center">
                  <Star className="size-[21px] text-white" />
                </div>
                <AnimatePresence mode="wait">
                  <div className="bg-newPrimary rounded-full h-10 px-7 font-poppins text-sm font-medium text-white flex justify-center items-center animate-fadeIn">
                    <motion.p
                      key={currentItem}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      // exit={{
                      //   opacity: 0,
                      // }}
                      transition={{
                        duration: 0.8,
                        ease: "easeInOut",
                        delay: 0.1,
                      }}
                    >
                      {scrollData[currentItem]}
                    </motion.p>
                  </div>
                </AnimatePresence>
              </div>

              {/* <AnimatePresence mode="wait"> */}
              <motion.img
                key={1}
                src={images[1]}
                alt={`UI View`}
                className=" w-full mx-auto"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
              />
              {/* </AnimatePresence> */}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default NewHeroSectionComponent;
