/* eslint-disable react/prop-types */
import { useState } from "react";
import { ChevronDown } from "lucide-react";
import { toSentenceCase } from "../../../components/formatter";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
export default function ReviewsDropdown({
  className,
  selectedOption,
  setSelectedOption,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <Select
        value={selectedOption}
        onValueChange={(e) => setSelectedOption(e)}
      >
        <SelectTrigger className="rounded-lg w-[200px]">
          <SelectValue />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="All Reviews">All Reviews</SelectItem>
          <SelectItem value="Redirected Reviews">Redirected Reviews</SelectItem>
        </SelectContent>
      </Select>
      {/* <button
        className="px-6 py-3 h-10 rounded-[8px] focus:outline-none flex items-center justify-between w-full bg-white dark:bg-darkBg cursor-pointer border border-[#EBEBEB] dark:border-darkBg gap-x-2"
        onClick={toggleDropdown}
      >
        <p className="text-[#777777] dark:text-white text-sm font-medium">
          {toSentenceCase(selectedOption)}
        </p>
        <ChevronDown
          className={`w-5 h-5 transition-all duration-200 ${isOpen ? "rotate-180" : ""}`}
        />
      </button>

      {isOpen && (
        <div className="absolute top-full left-0 mt-1 w-full bg-white dark:bg-darkBg border border-gray-300 dark:border-gray-700 rounded-lg shadow-lg z-20 text-sm font-medium">
          <div
            className="px-4 py-2 hover:text-newPrimary hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
            onClick={() => handleOptionClick("All Reviews")}
          >
            All reviews
          </div>
          <div
            className="px-4 py-2 hover:text-newPrimary hover:bg-gray-100 dark:hover:bg-gray-800 cursor-pointer"
            onClick={() => handleOptionClick("Redirected Reviews")}
          >
            Redirected reviews
          </div>
        </div>
      )} */}
    </div>
  );
}
