/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useShortLink } from "../../../../query/useContact";
import { LogoLoading } from "../../../../components/loading/loading";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Checkbox } from "../../../../components/ui/checkbox";
import { Textarea } from "../../../../components/ui/textarea";

const LinkModal = ({ openModal, setOpenModal, link }) => {
  const [shortLink, setShortLink] = useState("");
  const [originalLink, setOriginalLink] = useState("");

  const { mutate: changeShortLink, isLoading: changeShortLinkLoading } =
    useShortLink();
  const handleCopy = () => {
    if (link) {
      navigator.clipboard.writeText(shortLink);
      setOpenModal(false);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };
  const handleChangeShortLink = () => {
    if (shortLink === originalLink) {
      changeShortLink(
        {
          original_url: originalLink,
        },
        {
          onSuccess: (res) => {
            toast.success("Link is shortened successfully");
            setShortLink(res.short_url);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else if (err.response.data.original_url) {
              toast.error(err.response.data.original_url);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      setShortLink(originalLink);
    }
  };
  useEffect(() => {
    setShortLink(link);
    setOriginalLink(link);
  }, [link]);

  // if (changeShortLinkLoading) {
  //   return <LogoLoading />;
  // }

  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Copy MagicLink</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <Textarea
            type="text"
            value={shortLink}
            className="w-full border-x-0 border-t-0 appearance-none font-pro text-sm text-black focus-visible:ring-0 focus-visible:ring-offset-0 min-h-5 resize-none"
            readOnly
            rows={shortLink !== originalLink ? 1 : 2}
          />
          <div className="flex items-center gap-x-1.5 mt-3">
            <Checkbox
              checked={shortLink !== originalLink}
              onCheckedChange={handleChangeShortLink}
              id="shortenLink"
            />

            <label
              htmlFor="shortenLink"
              className="font-pro text-sm text-[#4a4a4a]"
            >
              Shorten Link
            </label>
            {changeShortLinkLoading && (
              <div className="flex items-center justify-center">
                <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary  rounded-full animate-spin"></div>
              </div>
            )}
          </div>
          <div className="flex justify-end items-center gap-x-3 mt-7">
            <Button
              variant="secondary"
              onClick={() => {
                setOpenModal(null);
              }}
            >
              Cancel
            </Button>
            <Button onClick={handleCopy}>Copy</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LinkModal;
