import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useDeleteCampaign } from "../../../../query/useCampaign";
import { toast } from "sonner";
import { dayFormatter } from "../../../../utils/formatter";
import { TableCell, TableRow } from "../../../../components/ui/table";
import EditCampaignModal from "./EditCampaignModal";
import { DeleteModal } from "../../component/modal";
import { Button } from "../../../../components/ui/button";
import { Pencil, Trash2 } from "lucide-react";

const CampaignRow = ({ item, selectedCampaign, setSelectedCampaign }) => {
  const [isOpenEditCampaignModal, setIsOpenEditCampaignModal] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);

  const queryClient = useQueryClient();
  const { mutate, isLoading } = useDeleteCampaign();

  const handleClick = () => {
    mutate(item?.id, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        }
        queryClient.invalidateQueries({
          queryKey: ["getCampaignApi"],
        });
        setIsOpenDelete(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  return (
    <>
      <TableRow
        key={item?.id}
        className="text-[15px] hover:bg-[#fafafa] cursor-pointer"
      >
        <TableCell onClick={() => setSelectedCampaign(item?.id)}>
          {item?.name}
        </TableCell>
        <TableCell onClick={() => setSelectedCampaign(item?.id)}>
          {item?.created_at && dayFormatter(item?.created_at)}
        </TableCell>
        <TableCell onClick={() => setSelectedCampaign(item?.id)}>
          {item?.total_users}
        </TableCell>
        <TableCell>
          <div className="flex items-center justify-center gap-1">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => {
                setIsOpenEditCampaignModal(true);
              }}
            >
              <Pencil className="size-4 text-[#656565]" />
            </Button>
            <Button variant="ghost" size="icon">
              <Trash2
                className="size-4 text-red-500"
                onClick={() => {
                  setIsOpenDelete(true);
                }}
              />
            </Button>
          </div>
        </TableCell>
      </TableRow>

      <EditCampaignModal
        openModal={isOpenEditCampaignModal}
        setOpenModal={setIsOpenEditCampaignModal}
        item={item}
      />

      <DeleteModal
        openModal={isOpenDelete}
        setOpenModal={setIsOpenDelete}
        title={`Are you sure you want to delete ${item?.name}?`}
        handleDelete={handleClick}
        isLoading={isLoading}
      />
    </>
  );
};

export default CampaignRow;
