import { useState, useEffect } from "react";
import { toast } from "sonner";

export const useMagiclinkForm = ({
  initialData = null,
  onSubmit,
  onSuccess,
  entityId,
  selectedSet = null,
}) => {
  // Form state
  const [name, setName] = useState("");
  const [label, setLabel] = useState("");
  const [tags, setTags] = useState([]);
  const [redirects, setRedirects] = useState([]);
  const [questionSet, setQuestionSet] = useState(null);
  const [isShowName, setIsShowName] = useState(true);
  const [isVoiceReview, setIsVoiceReview] = useState(true);
  const [isOnlyVoiceReview, setIsOnlyVoiceReview] = useState(false);
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(false);
  const [starRating, setStarRating] = useState(1);
  const [isDirectToReview, setIsDirectToReview] = useState(false);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [subheading, setSubheading] = useState({
    name: "subheading",
    text: "",
    show: true,
  });
  const [offerPage, setOfferPage] = useState(false);
  const [isReqInfo, setIsReqInfo] = useState(false);

  // Initialize form with data if provided
  useEffect(() => {
    if (initialData) {
      setName(initialData.name);
      setLabel(initialData.label);
      setTags(initialData.tags);
      setRedirects(initialData.redirect);
      setQuestionSet(initialData.default_set);
      setIsShowName(initialData.show);
      setIsVoiceReview(initialData.voice_review);
      setIsOnlyVoiceReview(initialData.only_voice_review);
      setIsNegativeFeedback(initialData.negative_feedback);
      setStarRating(initialData.star_rating);
      setIsDirectToReview(initialData.direct_to_review);
      setOfferPage(initialData.offer);
      setCustomerInfo(
        initialData.personal_info?.filter((item) => item.name !== "subheading")
      );
      setIsReqInfo(initialData.request_info);
      const existingSubheading = initialData.personal_info?.find(
        (item) => item.name === "subheading"
      );
      if (existingSubheading) {
        setSubheading(existingSubheading);
      }
    }
  }, [initialData]);

  useEffect(() => {
    if (selectedSet) {
      setQuestionSet(selectedSet);
    }
  }, [selectedSet]);

  const handleSubmit = () => {
    if (!name) {
      toast.error("Name field must not be blank");
      return;
    }
    if (label?.length > 50) {
      toast.error("Label field must have maximum 50 characters");
      return;
    }

    const formData = {
      entity: entityId,
      name,
      tags: tags || [],
      label,
      default_set: questionSet,
      show: isShowName,
      voice_review: isVoiceReview,
      redirect: redirects,
      negative_feedback: isNegativeFeedback,
      star_rating: starRating,
      direct_to_review: isDirectToReview,
      personal_info: [...customerInfo, subheading],
      only_voice_review: isOnlyVoiceReview,
      offer: offerPage,
      request_info: isReqInfo,
    };

    if (!isVoiceReview) {
      delete formData.only_voice_review;
    }

    onSubmit(formData, onSuccess);
  };

  return {
    formState: {
      name,
      label,
      tags,
      redirects,
      questionSet,
      isShowName,
      isVoiceReview,
      isOnlyVoiceReview,
      isNegativeFeedback,
      starRating,
      isDirectToReview,
      customerInfo,
      subheading,
      offerPage,
      isReqInfo,
    },
    setters: {
      setName,
      setLabel,
      setTags,
      setRedirects,
      setQuestionSet,
      setIsShowName,
      setIsVoiceReview,
      setIsOnlyVoiceReview,
      setIsNegativeFeedback,
      setStarRating,
      setIsDirectToReview,
      setCustomerInfo,
      setSubheading,
      setOfferPage,
      setIsReqInfo,
    },
    handleSubmit,
  };
};
