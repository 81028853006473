import { useSearchParams } from "react-router-dom";
import {
  useGetEntityReviewPermissions,
  usePostGetReviewDetails,
  usePostGetReviewDetailsQr,
} from "../../query/useReview";
import React, { useEffect, useRef, useState } from "react";
import MainLogo from "../../components/mainlogo";
import { Button } from "../../components/ui/button";
import { Download } from "lucide-react";
import html2canvas from "html2canvas";
import { useDarkMode } from "../../context/DarkModeProvider";

const OfferPage = () => {
  const printRef = useRef(null);
  const [params] = useSearchParams();
  const id = params.get("id");
  const employeeId = params.get("employeeId");
  const reviewId = params.get("reviewId");

  const [reviewDetails, setReviewDetails] = useState(null);
  const [entityName, setEntityName] = useState("");
  const [entityLogo, setEntityLogo] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [qrUrl, setQrUrl] = useState("");
  const [error, setError] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);

  const { isLoading } = useGetEntityReviewPermissions(
    {
      entityId: id,
      employeeId: employeeId,
    },
    {
      onSuccess: (res) => {
        setEntityName(res?.entity_name);
        setEntityLogo(res?.logo);
        setBgColor(res?.bg_color);
      },
      enabled: Boolean(id),
    }
  );

  const { mutate: getReviewDetails } = usePostGetReviewDetails();
  const { mutate: getReviewDetailsQr, isLoading: getReviewDetailsQrLoading } =
    usePostGetReviewDetailsQr();

  const handleSaveAsPng = async () => {
    if (printRef.current === null) {
      return;
    }
    setIsDownloading(true);
    // Remove unwanted TailwindCSS classes before taking screenshot

    // Add useCORS to handle cross-origin images
    html2canvas(printRef.current, { useCORS: true, allowTaint: true }).then(
      (canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "offer.png";
        link.click();

        setIsDownloading(false);
      }
    );
  };

  useEffect(() => {
    if (reviewId) {
      setError("");
      getReviewDetails(
        { review_uuid: reviewId },
        {
          onSuccess: (res) => {
            setReviewDetails(res);
          },
          onError: (err) => {
            setError(err?.response?.data?.message);
          },
        }
      );
    }
  }, [reviewId]);

  useEffect(() => {
    if (reviewDetails?.id) {
      getReviewDetailsQr(
        { id: reviewDetails?.id },
        {
          onSuccess: (res) => {
            const url = URL.createObjectURL(res);
            setQrUrl(url);
          },
        }
      );
    }
  }, [reviewDetails?.id]);

  return (
    <div className="min-h-dvh flex flex-col justify-center gap-10 dark:bg-white overflow-y-auto py-5">
      {entityLogo && (
        <div className="flex items-center gap-3 w-[90%] md:w-[60%] mx-auto">
          <img
            src={entityLogo}
            alt=""
            className="rounded-full size-10 object-cover"
          />
          <p
            className="font-semibold text-[15px] px-5 h-9 rounded-full flex items-center justify-center font-heebo"
            style={{
              backgroundColor: bgColor,
              color: "white",
            }}
          >
            {entityName}
          </p>
        </div>
      )}

      <div
        className={`w-[90%] md:w-[60%] mx-auto rounded-3xl relative font-heebo p-5  border border-[#7190ff]/15 bg-white drop-shadow space-y-5`}
      >
        {error ? (
          <p className="italic text-center">{error}</p>
        ) : (
          <>
            <img
              src="/thank-you.png"
              alt=""
              className="max-w-[272px] mx-auto"
              referrerPolicy="no-referrer"
              crossOrigin={isDownloading && "anonymous"}
            />
            <p className="font-heebo text-[15px] text-[#4d4d4d] text-center w-[70%] mx-auto">
              Thank you for your feedback!
            </p>
            {reviewId && (
              <>
                <hr className="w-full " />
                <h1 className="text-[32.5px] font-semibold mt-8 mb-5  text-left leading-[32.5px] tracking-tight lg:text-center">
                  Get Rewarded <br className="lg:hidden" /> on your next visit!
                </h1>

                <div className="flex lg:flex-col items-center gap-3 print:hidden">
                  <div
                    className="w-fit flex flex-col justify-center items-center gap-5 border"
                    ref={printRef}
                  >
                    <div className="aspect-square w-[120px] lg:w-[250px] overflow-hidden flex items-center justify-center rounded-lg">
                      {isLoading ? (
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        <div className="size-[120px] lg:size-[250px] aspect-square">
                          <img src={qrUrl} alt="" className="w-full" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="space-y-3 lg:space-y-5">
                    <p className="font-heebo text-[15px] text-[#4d4d4d] ">
                      Download the QR code & Redeem the next time you visit.
                    </p>
                    <Button
                      variant="outline"
                      className="w-full gap-3"
                      onClick={() => {
                        handleSaveAsPng();
                      }}
                    >
                      {isDownloading ? (
                        <div className="flex items-center justify-center w-full h-full">
                          <div className="w-5 h-5 border-t-2 border-b-2 border-newPrimary rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        <>
                          <Download /> Download
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>

      <div className="md:flex items-center justify-center gap-5 hidden py-5 lg:py-10 ">
        <div className="h-[1px] w-full bg-[#ebebeb]"></div>
        <div className="flex items-center justify-between flex-shrink-0 gap-2">
          <p className="font-poppins text-[#4d4d4d] text-xl flex-shrink-0">
            Powered by
          </p>
          <MainLogo className={"flex-shrink-0"} />
        </div>
        <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
      </div>
      <div className="md:hidden py-10">
        <div className="flex items-center justify-center gap-5 ">
          {/* <div className="h-[1px] w-full bg-[#ebebeb]"></div> */}
          <p className="font-poppins  text-sm flex-shrink-0">Powered by</p>
          {/* <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div> */}
        </div>

        <div className="flex items-center justify-center gap-5 mt-3">
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
          <div className="flex items-center justify-center gap-x-2 flex-shrink-0">
            <img
              src={"/new-logo.svg"}
              alt="MagicReview Logo"
              className="w-[30px] flex-shrink-0"
            />
            <h1 className="font-avertaBold font-bold text-[18px] text-[#4d4d4d] -tracking-[1%] -mt-1">
              magicreview
            </h1>
          </div>
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
        </div>
      </div>
    </div>
  );
};

export default OfferPage;
