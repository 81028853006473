import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const allowedHosts = [
  "magicreview.ai",
  "www.magicreview.ai",
  "magic-review-dev.vercel.app",
  "localhost",
];

const Middleware = ({ children }) => {
  const location = useLocation();
  const currentHost = window.location.hostname;
  const pathname = location.pathname;

  useEffect(() => {
    if (
      !allowedHosts.includes(currentHost) &&
      !(
        pathname.includes("/business") ||
        pathname.includes("/offer") ||
        pathname.includes("/review") ||
        pathname.includes("/redeem") ||
        pathname.includes("/thank-you")
      )
    ) {
      window.location.href = "https://www.magicreview.ai" + pathname;
    }
  }, [currentHost, pathname]);

  return children;
};

export default Middleware;
