/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";

const CountdownTimer = ({ scheduleTime }) => {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    if (!scheduleTime) {
      setTimeLeft("No schedule time available");
      return;
    }

    const scheduledDate = new Date(scheduleTime);

    const updateCountdown = () => {
      const now = new Date();
      const diffInMs = scheduledDate - now;

      if (diffInMs <= 0) {
        setTimeLeft("Time has passed");
        clearInterval(timer);
        return;
      }

      const diffInSeconds = Math.floor(diffInMs / 1000);
      const days = Math.floor(diffInSeconds / (3600 * 24));
      const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      const seconds = diffInSeconds % 60;

      setTimeLeft(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    updateCountdown(); // Run immediately to avoid delay
    const timer = setInterval(updateCountdown, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(timer);
  }, [scheduleTime]);

  return <div>{timeLeft}</div>;
};

export default CountdownTimer;
