import { useQuery, useMutation, useInfiniteQuery } from "@tanstack/react-query";

import {
  getReviewQuestions,
  postReviewQuestions,
  getReviewUrl,
  reviewCopy,
  getEntityReviews,
  getCanEntityGenerateReviews,
  getReviewPlatforms,
  getEntityReviewPermssions,
  getSeoKeywords,
  postReviewQuestionsByVoice,
  getDownloadReviews,
  getPositivePrompt,
  postPositivePrompt,
  putPositivePrompt,
  postConditionToggle,
  deletePositivePrompt,
  getReviewsAnalyticsPosted,
  getReviewsAnalyticsNonPosted,
  postAiReviewsAnalysis,
  getAiReviewAnalysis,
  getGmbPosts,
  postUploadMedia,
  postStandardGenerate,
  postStandardAdd,
  getPrimaryKeywords,
  postAddPrimaryKeywords,
  deletePrimaryKeywords,
  getSeoSettingCount,
  putUpdateSeoSettingCount,
  getKeywords,
  deleteKeywords,
  toggleKeyword,
  postAddAiKeywords,
  getTargetLocations,
  postAddTargetLocations,
  deleteTargetLocation,
  postSearchTargetLocation,
  postOfferGenerate,
  postOfferAdd,
  postEventGenerate,
  postEventAdd,
  getActiveKeywords,
  getInactiveKeywords,
  putAiReplySettings,
  getSchedulePosts,
  postStandardSchedule,
  postOfferSchedule,
  postEventSchedule,
  deleteSchedulePost,
  putEditSchedulePost,
  getReviewsAnalyticsNegative,
  postSaveNegativeFeedbacks,
  postSaveNegativeFeedbacksByVoice,
  getDownloadReviewsPosted,
  getDownloadReviewsNonPosted,
  getDownloadReviewsNegative,
  postGetReviewDetails,
  postGetReviewDetailsQr,
  postGetRedeemDetails,
  postRedeemReview,
} from "../api/reviewsApi";

export const useGetReviewQuestions = ({ entityId, staffId }, config) => {
  return useQuery({
    queryKey: ["getReviewQuestions", staffId],
    queryFn: () => getReviewQuestions({ entityId: entityId, staffId: staffId }),
    ...config,
  });
};

export const usePostReviewQuestions = (config) => {
  return useMutation({
    mutationKey: ["postReviewQuestions"],
    mutationFn: ({ data, isDetailed }) =>
      postReviewQuestions({ data: data, isDetailed: isDetailed }),
    ...config,
  });
};

export const usePostGetReviewDetails = () => {
  return useMutation({
    mutationKey: ["postGetReviewDetails"],
    mutationFn: (data) => postGetReviewDetails(data),
  });
};

export const usePostGetReviewDetailsQr = () => {
  return useMutation({
    mutationKey: ["postGetReviewDetailsQr"],
    mutationFn: (data) => postGetReviewDetailsQr(data),
  });
};

export const usePostGetRedeemDetails = () => {
  return useMutation({
    mutationKey: ["postGetRedeemDetails"],
    mutationFn: (data) => postGetRedeemDetails(data),
  });
};

export const usePostRedeemReview = () => {
  return useMutation({
    mutationKey: ["postRedeemReview"],
    mutationFn: (data) => postRedeemReview(data),
  });
};

export const usePostSaveNegativeFeedbacks = () => {
  return useMutation({
    mutationKey: ["postSaveNegativeFeedbacks"],
    mutationFn: (data) => postSaveNegativeFeedbacks({ data: data }),
  });
};

export const usePostSaveNegativeFeedbacksByVoice = () => {
  return useMutation({
    mutationKey: ["postSaveNegativeFeedbacksByVoice"],
    mutationFn: (data) => postSaveNegativeFeedbacksByVoice(data),
  });
};

export const usePostReviewQuestionsByVoice = (config) => {
  return useMutation({
    mutationKey: ["postReviewQuestionsByVoice"],
    mutationFn: (data) => postReviewQuestionsByVoice(data),
    ...config,
  });
};

export const useGetReviewUrl = (entityId, config) => {
  return useQuery({
    queryKey: ["getReviewUrl"],
    queryFn: () => getReviewUrl(entityId),
    ...config,
  });
};

export const useReviewCopied = (config) => {
  return useMutation({
    mutationKey: ["reviewCopy"],
    mutationFn: (data) => reviewCopy(data),
    ...config,
  });
};

export const useGetEntityReviews = ({
  entityId,
  limit,
  isAiGenerated,
  platforms,
  is_posted_ai_review,
}) => {
  return useInfiniteQuery({
    queryKey: [
      "getEntityReviews",
      entityId,
      isAiGenerated,
      platforms,
      is_posted_ai_review,
    ],
    queryFn: ({ pageParam = 0 }) => {
      const dataForGetEntityReviews = {
        entity_uuid: entityId,
        limit: limit || 5,
        index: pageParam,
        is_posted_ai_review,
        is_ai_generated: isAiGenerated === "aigenerated" && true,
        platforms: platforms,
      };

      if (isAiGenerated !== "aigenerated")
        delete dataForGetEntityReviews["is_ai_generated"];

      return getEntityReviews(dataForGetEntityReviews);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (
        allPages?.flatMap((page) => page?.results)?.length <
        lastPage?.total_results
      ) {
        return allPages?.flatMap((page) => page?.results)?.length;
      }
    },
    keepPreviousData: true,
    enabled: Boolean(entityId),
  });
};

export const useGetCanEntityGenerateReviews = (entityId, config) => {
  return useQuery({
    queryKey: ["getCanEntityGenerateReviews"],
    queryFn: () => getCanEntityGenerateReviews(entityId),
    ...config,
  });
};

export const useGetEntityReviewPermissions = (
  { entityId, employeeId },
  config
) => {
  return useQuery({
    queryKey: ["getEntityReviewPermissions"],
    queryFn: () => getEntityReviewPermssions({ entityId, employeeId }),
    ...config,
  });
};

export const useGetReviewPlatforms = ({ entityId, employeeId }, config) => {
  return useQuery({
    queryKey: ["getReviewPlatforms"],
    queryFn: () => getReviewPlatforms(entityId, employeeId),
    ...config,
  });
};

export const useGetSeoKeywords = (entityId, config) => {
  return useQuery({
    queryKey: ["getSeoKeywords"],
    queryFn: () => getSeoKeywords(entityId),
    ...config,
  });
};

export const useGetDownloadReviews = () => {
  return useMutation({
    mutationKey: ["getDownloadReviews"],
    mutationFn: (params) => getDownloadReviews(params),
  });
};

export const useGetPositivePrompt = (config) => {
  return useQuery({
    queryKey: ["getPositivePrompt"],
    queryFn: () => getPositivePrompt(),
    ...config,
  });
};

export const usePostPositivePrompt = () => {
  return useMutation({
    mutationKey: ["postPositivePrompt"],
    mutationFn: (data) => postPositivePrompt(data),
  });
};

export const usePutPositivePrompt = () => {
  return useMutation({
    mutationKey: ["putPositivePrompt"],
    mutationFn: ({ id, data }) => putPositivePrompt(id, data),
  });
};

export const usePutAiReplySettings = () => {
  return useMutation({
    mutationKey: ["putAiReplySettings"],
    mutationFn: (data) => putAiReplySettings(data),
  });
};

export const useDeletePositivePrompt = () => {
  return useMutation({
    mutationKey: ["deletePositivePrompt"],
    mutationFn: (id) => deletePositivePrompt(id),
  });
};

export const usePostConditionToggle = () => {
  return useMutation({
    mutationKey: ["postConditioinToggle"],
    mutationFn: (id) => postConditionToggle(id),
  });
};

export const useGetReviewsAnalyticsPosted = (params, config) => {
  return useQuery({
    queryKey: ["getReviewsAnalyticsPosted", params],
    queryFn: () => getReviewsAnalyticsPosted(params),
    ...config,
  });
};

export const useGetReviewsAnalyticsNonPosted = (params, config) => {
  return useQuery({
    queryKey: ["getReviewsAnalyticsNonPosted", params],
    queryFn: () => getReviewsAnalyticsNonPosted(params),
    ...config,
  });
};

export const useGetReviewsAnalyticsNegative = (params, config) => {
  return useQuery({
    queryKey: ["getReviewsAnalyticsNegative", params],
    queryFn: () => getReviewsAnalyticsNegative(params),
    ...config,
  });
};

export const useGetDownloadReviewsPosted = () => {
  return useMutation({
    mutationKey: ["getDownloadReviewsPosted"],
    mutationFn: (params) => getDownloadReviewsPosted(params),
  });
};

export const useGetDownloadReviewsNonPosted = () => {
  return useMutation({
    mutationKey: ["getDownloadReviewsNonPosted"],
    mutationFn: (params) => getDownloadReviewsNonPosted(params),
  });
};

export const useGetDownloadReviewsNegative = () => {
  return useMutation({
    mutationKey: ["getDownloadReviewsNegative"],
    mutationFn: (params) => getDownloadReviewsNegative(params),
  });
};

export const usePostAiReviewAnalysis = () => {
  return useMutation({
    mutationKey: ["postAiReviewsAnalysis"],
    mutationFn: (data) => postAiReviewsAnalysis(data),
  });
};

export const useGetAiReviewAnalysis = (config) => {
  return useQuery({
    queryKey: ["getAiReviewAnalysis"],
    queryFn: () => getAiReviewAnalysis(),
    ...config,
  });
};

export const useGetGmbPosts = (config) => {
  return useInfiniteQuery({
    queryKey: ["getGmbPosts"],
    queryFn: ({ pageParam = null }) =>
      getGmbPosts({ nextPageToken: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.nextPageToken) {
        return lastPage?.nextPageToken;
      }
    },
    keepPreviousData: true,
    ...config,
  });
};

export const useGetSchedulePosts = (config) => {
  return useInfiniteQuery({
    queryKey: ["getSchedulePosts"],
    queryFn: ({ pageParam = null }) =>
      getSchedulePosts({ nextPageToken: pageParam }),
    getNextPageParam: (lastPage) => {
      if (lastPage?.nextPageToken) {
        return lastPage?.nextPageToken;
      }
    },
    keepPreviousData: true,
    ...config,
  });
};

export const usePostUploadMedia = () => {
  return useMutation({
    mutationKey: ["postUploadMedia"],
    mutationFn: (data) => postUploadMedia(data),
  });
};

export const usePostStandardGenerate = () => {
  return useMutation({
    mutationKey: ["postStandardGenerate"],
    mutationFn: (data) => postStandardGenerate(data),
  });
};

export const usePostStandardAdd = () => {
  return useMutation({
    mutationKey: ["postStandardAdd"],
    mutationFn: (data) => postStandardAdd(data),
  });
};

export const usePostStandardSchedule = () => {
  return useMutation({
    mutationKey: ["postStandardSchedule"],
    mutationFn: (data) => postStandardSchedule(data),
  });
};

export const usePostOfferGenerate = () => {
  return useMutation({
    mutationKey: ["postOfferGenerate"],
    mutationFn: (data) => postOfferGenerate(data),
  });
};

export const usePostOfferAdd = () => {
  return useMutation({
    mutationKey: ["postOfferAdd"],
    mutationFn: (data) => postOfferAdd(data),
  });
};

export const usePostOfferSchedule = () => {
  return useMutation({
    mutationKey: ["postOfferSchedule"],
    mutationFn: (data) => postOfferSchedule(data),
  });
};

export const usePostEventGenerate = () => {
  return useMutation({
    mutationKey: ["postEventGenerate"],
    mutationFn: (data) => postEventGenerate(data),
  });
};

export const usePostEventAdd = () => {
  return useMutation({
    mutationKey: ["postEventAdd"],
    mutationFn: (data) => postEventAdd(data),
  });
};

export const usePostEventSchedule = () => {
  return useMutation({
    mutationKey: ["postEventSchedule"],
    mutationFn: (data) => postEventSchedule(data),
  });
};

export const usePutEditSchedulePost = () => {
  return useMutation({
    mutationKey: ["putEditSchedulePost"],
    mutationFn: ({ id, data }) => putEditSchedulePost(id, data),
  });
};

export const useDeleteSchedulePost = () => {
  return useMutation({
    mutationKey: ["deleteSchedulePost"],
    mutationFn: (id) => deleteSchedulePost(id),
  });
};

export const useGetPrimaryKeywords = () => {
  return useQuery({
    queryKey: ["getPrimaryKeywords"],
    queryFn: () => getPrimaryKeywords(),
  });
};

export const usePostAddPrimaryKeywords = () => {
  return useMutation({
    mutationKey: ["postAddPrimaryKeywords"],
    mutationFn: (data) => postAddPrimaryKeywords(data),
  });
};

export const useDeletePrimaryKeywords = () => {
  return useMutation({
    mutationKey: ["deletePrimaryKeywords"],
    mutationFn: (data) => deletePrimaryKeywords(data),
  });
};

export const useGetSeoSettingCount = () => {
  return useQuery({
    queryKey: ["getSeoSettingCount"],
    queryFn: () => getSeoSettingCount(),
  });
};

export const usePutUpdateSeoSettingCount = () => {
  return useMutation({
    mutationKey: ["putUpdateSeoSettingCount"],
    mutationFn: (data) => putUpdateSeoSettingCount(data),
  });
};

export const useGetKeywords = () => {
  return useQuery({
    queryKey: ["getKeywords"],
    queryFn: () => getKeywords(),
  });
};

export const usePostAddAiKeywords = () => {
  return useMutation({
    mutationKey: ["postAddAiKeywords"],
    mutationFn: () => postAddAiKeywords(),
  });
};

export const useDeleteKeywords = () => {
  return useMutation({
    mutationKey: ["deleteKeywords"],
    mutationFn: (id) => deleteKeywords(id),
  });
};

export const useToggleKeywords = () => {
  return useMutation({
    mutationKey: ["toggleKeywords"],
    mutationFn: (id) => toggleKeyword(id),
  });
};

export const useGetTargetLocations = () => {
  return useQuery({
    queryKey: ["getTargetLocations"],
    queryFn: () => getTargetLocations(),
  });
};

export const usePostAddTargetLocations = () => {
  return useMutation({
    mutationKey: ["postAddTargetLocations"],
    mutationFn: (data) => postAddTargetLocations(data),
  });
};

export const usePostSearchTargetLocation = () => {
  return useMutation({
    mutationKey: ["searchTargetLocation"],
    mutationFn: (data) => postSearchTargetLocation(data),
  });
};

export const useDeleteTargetLocation = () => {
  return useMutation({
    mutationKey: ["deleteTargetLocation"],
    mutationFn: (id) => deleteTargetLocation(id),
  });
};

export const useGetActiveKeywords = (params, config) => {
  return useQuery({
    queryKey: ["getActiveKeywords", params],
    queryFn: () => getActiveKeywords(params),
    ...config,
  });
};

export const useGetActiveKeywordsInfinity = (params) => {
  return useInfiniteQuery({
    queryKey: ["useGetActiveKeywordsInfinity", params],
    queryFn: ({ pageParam = 0 }) => {
      const paramsData = {
        ...params,
        index: pageParam,
      };

      return getActiveKeywords(paramsData);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (params?.search) {
        if (
          allPages?.flatMap((page) => page?.active_keywords)?.length <
          lastPage?.search_count
        ) {
          return allPages?.flatMap((page) => page?.active_keywords)?.length;
        }
      } else {
        if (
          allPages?.flatMap((page) => page?.active_keywords)?.length <
          lastPage?.active_counts
        ) {
          return allPages?.flatMap((page) => page?.active_keywords)?.length;
        }
      }
    },
    keepPreviousData: false,
    cacheTime: 0,
  });
};

export const useGetInactiveKeywords = (params, config) => {
  return useQuery({
    queryKey: ["getInactiveKeywords", params],
    queryFn: () => getInactiveKeywords(params),
    ...config,
  });
};

export const useGetInactiveKeywordsInfinity = (params) => {
  return useInfiniteQuery({
    queryKey: ["useGetInactiveKeywordsInfinity", params],
    queryFn: ({ pageParam = 0 }) => {
      const paramsData = {
        ...params,
        index: pageParam,
      };

      return getInactiveKeywords(paramsData);
    },
    getNextPageParam: (lastPage, allPages) => {
      if (params?.search) {
        if (
          allPages?.flatMap((page) => page?.inactive_keywords)?.length <
          lastPage?.search_count
        ) {
          return allPages?.flatMap((page) => page?.inactive_keywords)?.length;
        }
      } else {
        if (
          allPages?.flatMap((page) => page?.inactive_keywords)?.length <
          lastPage?.inactive_counts
        ) {
          return allPages?.flatMap((page) => page?.inactive_keywords)?.length;
        }
      }
    },
    keepPreviousData: false,
    cacheTime: 0,
  });
};
