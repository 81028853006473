import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import {
  useGetCampaign,
  useGetViewCampaign,
  usePostRunCampaign,
} from "../../../query/useCampaign";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { dayFormatter } from "../../../utils/formatter";
import {
  ArrowLeft,
  Pencil,
  SquarePlus,
  Trash2,
  Users,
  Users2,
} from "lucide-react";
import IconButton from "../component/IconButton";
import CampaignBlock from "./components/CampaignBlock";
import Skeleton from "react-loading-skeleton";
import AddCampaignModal from "./components/AddCampaignModal";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { LoadingButton } from "../../../components/loading/loading";
import { toast } from "sonner";
import CampaignRow from "./components/CampaignRow";

const Campaign = () => {
  const { darkMode } = useDarkMode();
  const [isOpenAddCampaignModal, setIsOpenAddCampaignModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  const { data: campaigns, isLoading: campaignsLoading } = useGetCampaign();
  const {
    data: campaignDetails,
    isLoading: campaignDetailsLoading,
    refetch: refetchCampaignDetails,
  } = useGetViewCampaign({ id: selectedCampaign });

  const { mutate: runCampaign, isLoading: runCampaignLoading } =
    usePostRunCampaign();

  const handleRunCampaign = () => {
    runCampaign(selectedCampaign, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetchCampaignDetails();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  // useEffect(() => {
  //   if (campaigns) {
  //     setSelectedCampaign(campaigns?.[0]?.id);
  //   }
  // }, [campaigns]);

  return (
    <Layout title={"Campaign"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-120px)] px-5 lg:px-[30.2px] font-pro">
        <div className="flex flex-wrap gap-5">
          {campaignsLoading ? (
            <>
              {new Array(3)?.fill("").map((_, i) => (
                <div
                  className="border rounded-lg bg-white dark:bg-darkLightBg p-3 h-[100px] flex flex-col justify-between w-[200px]"
                  key={i}
                >
                  <Skeleton
                    containerClassName=""
                    height={70}
                    baseColor={darkMode && "#262626"}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              <div
                className="border border-newPrimary rounded-lg  dark:bg-darkLightBg p-3 h-[100px] flex flex-col justify-between group hover:shadow-sm cursor-pointer w-full lg:w-[144px]"
                onClick={() => setIsOpenAddCampaignModal(true)}
              >
                <SquarePlus className="fill-newPrimary text-white size-8 rounded" />
                <p className="text-newPrimary text-sm group-hover:underline">
                  Create New Campaign
                </p>
              </div>
              {campaigns?.slice(0, 3)?.map((item) => (
                <CampaignBlock
                  key={item?.id}
                  item={item}
                  selectedCampaign={selectedCampaign}
                  setSelectedCampaign={setSelectedCampaign}
                />
              ))}
            </>
          )}
        </div>
        {selectedCampaign ? (
          <>
            {campaignDetails?.length > 0 && (
              <div className="mt-10">
                <div className="flex items-center justify-between">
                  <p className="flex items-center gap-2">
                    <ArrowLeft
                      className="size-4 cursor-pointer"
                      onClick={() => setSelectedCampaign(null)}
                    />
                    {
                      campaigns?.find((item) => item.id === selectedCampaign)
                        ?.name
                    }
                  </p>
                  <Button
                    onClick={handleRunCampaign}
                    isDisabled={runCampaignLoading}
                  >
                    {runCampaignLoading ? <LoadingButton /> : "Run Campaign"}
                  </Button>
                </div>
                <>
                  <div className="rounded-xl overflow-hidden mt-5">
                    <Table>
                      <TableHeader>
                        <TableRow>
                          {[
                            "First Name",
                            "Last Name",
                            "Email Address",
                            "Phone Number",
                            "Status",
                          ]?.map((item) => (
                            <TableHead
                              className="text-[#808080] dark:text-white text-[15px]"
                              key={item}
                            >
                              {item}
                            </TableHead>
                          ))}
                        </TableRow>
                      </TableHeader>
                      <TableBody className="divide-y">
                        {campaignDetailsLoading ? (
                          <TableRow>
                            {new Array(5).fill("").map((_, i) => (
                              <TableCell key={i}>
                                <Skeleton
                                  height={30}
                                  baseColor={darkMode && "#262626"}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        ) : campaignDetails?.length > 0 ? (
                          campaignDetails?.map((item) => (
                            <TableRow key={item?.id} className="text-[15px]">
                              <TableCell>{item?.firstname || "-"}</TableCell>
                              <TableCell>{item?.lastname || "-"}</TableCell>
                              <TableCell>{item?.email || "-"}</TableCell>
                              <TableCell>{item?.phone || "-"}</TableCell>
                              <TableCell>
                                {item?.status === "Success" && (
                                  <div className="w-[90px] h-6 rounded-[7px] bg-[#affebf] text-[#014b40] text-xs text-center flex justify-center items-center">
                                    Success
                                  </div>
                                )}
                                {item?.status === "Pending" && (
                                  <div className="w-[90px] h-6 rounded-[7px] bg-[#ffd6a4] text-[#5e4200] text-xs text-center flex justify-center items-center">
                                    Pending
                                  </div>
                                )}
                                {item?.status === "Failed" && (
                                  <div className="w-[90px] h-6 rounded-[7px] text-[#8e0b21] bg-[#fed1d7] text-xs text-center flex justify-center items-center capitalize">
                                    Failed
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          ))
                        ) : null}
                      </TableBody>
                    </Table>
                  </div>
                </>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="mt-10">
              <div className="flex items-center justify-between">
                <p>List of Campaigns</p>
              </div>
              <>
                <div className="rounded-xl overflow-hidden mt-5">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {["Name", "Date", "No. of people", ""]?.map((item) => (
                          <TableHead
                            className="text-[#808080] dark:text-white text-[15px]"
                            key={item}
                          >
                            {item}
                          </TableHead>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody className="divide-y">
                      {campaignsLoading ? (
                        <TableRow>
                          {new Array(4).fill("").map((_, i) => (
                            <TableCell key={i}>
                              <Skeleton
                                height={30}
                                baseColor={darkMode && "#262626"}
                              />
                            </TableCell>
                          ))}
                        </TableRow>
                      ) : campaigns?.length > 0 ? (
                        campaigns?.map((item) => (
                          <CampaignRow
                            item={item}
                            selectedCampaign={selectedCampaign}
                            setSelectedCampaign={setSelectedCampaign}
                            key={item?.id}
                          />
                        ))
                      ) : null}
                    </TableBody>
                  </Table>
                </div>
              </>
            </div>
          </>
        )}
      </div>
      <AddCampaignModal
        openModal={isOpenAddCampaignModal}
        setOpenModal={setIsOpenAddCampaignModal}
      />
    </Layout>
  );
};

export default Campaign;
