import Layout from "../component/Layout";

import ReviewsAnalyticsComponent from "./ReviewAnalyticsComponent";

const ReviewsAnalytics = () => {
  return (
    <Layout title={"Reviews Analytics"}>
      <ReviewsAnalyticsComponent />
    </Layout>
  );
};

export default ReviewsAnalytics;
