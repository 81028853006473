/* eslint-disable react/prop-types */
import * as React from "react";

import { cn } from "../../utils/cn";

const Textarea = React.forwardRef(({ className, ...props }, ref) => {
  // const handleInput = () => {
  //   if (ref.current) {
  //     ref.current.style.height = "auto"; // Reset height to auto to shrink if necessary
  //     ref.current.style.height = `${ref.current.scrollHeight}px`; // Set to scrollHeight
  //   }
  // };
  return (
    <textarea
      className={cn(
        "flex min-h-[80px] w-full rounded-md border border-input bg-background dark:bg-darkBg px-3 py-2 text-sm ring-offset-background placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-newPrimary focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 scrollbar-thin resize-y",
        className
      )}
      // onInput={handleInput}

      ref={ref}
      {...props}
    />
  );
});
Textarea.displayName = "Textarea";

export { Textarea };
