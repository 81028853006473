import Layout from "../../component/Layout";
import { useMemo, useState } from "react";
import { motion } from "framer-motion";
import {
  SquarePlus,
  QrCode,
  ChevronDown,
  Copy,
  Link,
  Pencil,
  CircleAlert,
  Info,
  Asterisk,
  Ellipsis,
  Trash2,
  Eye,
} from "lucide-react";
import IconButton from "../../component/IconButton";
import WordBreak from "../../../../components/wordbreak";
import { Switch } from "../../../../components/ui/switch";
import Loading from "../../../../components/loading/loading";
import { toast } from "sonner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../components/ui/table";
import RedirectModal from "../../component/RedirectModal";
import { MagicCardIllustrations } from "../../../onboarding/components/illustrations/MagiclinkIllustrations";
import { EditMagicCardModal } from "../../component/RedirectModal";
import {
  useGetAllCards,
  useCreateCard,
  useUpdateCard,
  useDeleteCard,
  useToggleCard,
} from "../../../../query/useCard";
import { useNavigate } from "react-router";
import { useGetPlans } from "../../../../query/useSubscription";
import { usePostBuyCard } from "../../../../query/usePayment";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/ui/popover";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../components/ui/dropdown-menu";
import { DeleteModal } from "../../component/modal";
import CopyMagicLinkModal from "./CopyMagicLinkModal";
const MagicCard = () => {
  const navigate = useNavigate();
  const [isOpenCopyModal, setIsOpenCopyModal] = useState();
  const [qty, setQty] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [updatingCardId, setUpdatingCardId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { mutate: handleAddCard, isLoading: isAddingLoading } = useCreateCard();
  const { data, isLoading, isFetching, refetch } = useGetAllCards();
  const { data: plans } = useGetPlans();
  const { mutate: buyCard, isLoading: buyCardLoading } = usePostBuyCard();
  const [toDeleteId, setToDeleteId] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const currency = useMemo(() => {
    return plans?.data?.[0]?.currency;
  }, [plans]);

  const cardPrice = (quantity) => {
    switch (currency) {
      case "AED":
        return (
          <span>
            {(30 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "AED",
            })}
          </span>
        );

      case "GBP":
        return (
          <span>
            {(7 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </span>
        );
      case "INR":
        return (
          <span>
            {(500 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })}
          </span>
        );
      default:
        return (
          <span>
            {(10 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        );
    }
  };

  const cardPriceForApi = () => {
    switch (currency) {
      case "AED":
        return 30;
      case "GBP":
        return 7;
      case "INR":
        return 500;
      default:
        return 10;
    }
  };

  const [formDetails, setFormDetails] = useState({
    code: "",
    name: "",
  });
  const handleCardClick = () => {
    if (formDetails.name === "") {
      setFormDetails({
        ...formDetails,
        name: "Magicreview card",
      });
    }
    handleAddCard(formDetails, {
      onSuccess: () => {
        setFormDetails({
          code: "",
          name: "",
        });
        refetch();
        toast.success("Card added successfully");
      },
      onError: (err) => {
        toast.error(err?.response?.data?.code || "Something went wrong");
      },
    });
  };
  const handleCopyLink = (code) => {
    navigator.clipboard
      .writeText(`https://go.magicreview.ai/${code}`)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy link to clipboard!");
      });
  };
  const hanndleCopyRedirectLink = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy link to clipboard!");
      });
  };
  const { mutate: handleUpdateCard, isLoading: isUpdateLoading } =
    useUpdateCard();

  const { mutate: deleteCard, isLoading: deleteCardLoading } = useDeleteCard();
  const { mutate: toggleCard, isLoading: toggleCardLoading } = useToggleCard();

  const handleEditModal = (item) => {
    setSelectedItem(item);
    setIsEditModalOpen(true);
  };

  const toggleSwitch = (item, index) => {
    setUpdatingCardId(index); // Set the current row as loading
    // if (item.active) {
    //   handleUpdateCard(
    //     { ...item, active: false },
    //     {
    //       onSuccess: () => {
    //         refetch();
    //         toast.success("Card deactivated successfully");
    //       },
    //       onError: () => {
    //         toast.error("Something went wrong");
    //       },
    //       onSettled: () => {
    //         setUpdatingCardId(null); // Reset loading state after operation
    //       },
    //     }
    //   );
    // }

    // if (!item.active) {
    //   handleUpdateCard(
    //     { ...item, active: true },
    //     {
    //       onSuccess: () => {
    //         refetch();
    //         toast.success("Card activated successfully");
    //       },
    //       onError: () => {
    //         toast.error("Something went wrong");
    //       },
    //       onSettled: () => {
    //         setUpdatingCardId(null); // Reset loading state after operation
    //       },
    //     }
    //   );
    // }
    toggleCard(item?.id, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetch();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
      onSettled: () => {
        setUpdatingCardId(null);
      },
    });
  };

  const handleIncrease = () => {
    setQty((prev) => prev + 1);
  };

  const handleDecrease = () => {
    if (qty > 1) {
      setQty((prev) => prev - 1);
    }
  };

  const handleGoToBuyCard = () => {
    buyCard(
      {
        currency: currency,
        price_per_card: cardPriceForApi(),
        quantity: qty,
      },
      {
        onSuccess: (res) => {
          window.location.href = res?.url;
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteCard = () => {
    deleteCard(toDeleteId, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetch();
        setIsOpenDeleteModal(false);
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  return (
    <Layout title={"Magic Cards"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-120px)] px-5 lg:px-[30.2px] font-pro">
        <div className="max-w-[1003px] w-full flex md:flex-row flex-col gap-[17px]">
          <div className="lg:max-w-[321px] w-full rounded-[16px] p-5 bg-white dark:bg-darkBg shadow-sm">
            <h1 className="text-base font-medium font-pro text-[#252323] dark:text-white">
              Add new card
            </h1>
            <div className="mt-[16px]">
              <label className="text-black dark:text-white text-[13px] font-medium font-pro mb-[5px]">
                Card name
              </label>
              <input
                type="text"
                value={formDetails.name}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, name: e.target.value })
                }
                className="w-full mt-1 dark:border-[#454545] py-[6px] px-3 border dark:bg-darkBg border-[#EBEBEB] rounded-[8px]  text-[12px] mb-[16px]"
                placeholder="MagicReview Card"
              />
              <label className="text-black dark:text-white  text-[13px] font-medium font-pro mb-[5px] flex items-center gap-1">
                Card number <Asterisk className="size-3 text-red-500" />
              </label>
              <input
                type="text"
                value={formDetails.code}
                onChange={(e) =>
                  setFormDetails({ ...formDetails, code: e.target.value })
                }
                className="w-full mt-1 py-[6px] dark:border-[#454545] px-3 border dark:bg-darkBg border-[#EBEBEB] rounded-[8px] text-[12px] mb-[16px]"
                placeholder="Card number"
              />
              <button
                className="w-full mt-1 py-[8px] px-3 bg-[#4F75FA] text-white rounded-[8px] focus:outline-none text-sm font-medium flex justify-center items-center gap-2"
                onClick={handleCardClick}
                disabled={
                  formDetails.code === "" ||
                  formDetails.name === "" ||
                  isAddingLoading
                }
              >
                <SquarePlus size={16} className="" />
                Add
              </button>
            </div>
          </div>
          <div className="lg:max-w-[321px] w-full rounded-[16px] p-5 bg-white dark:bg-darkBg shadow-sm flex flex-col justify-between">
            <h1 className="text-base font-medium font-pro text-[#252323] dark:text-white">
              For Card Customization
            </h1>
            {/* Image Section */}
            <div className="relative cursor-pointer group lg:w-[50%] w-full flex justify-center  mb-20 lg:mb-0 mt-5 lg:ml-6">
              <img
                src="/card2.jpg"
                className="w-[150px]  xl:w-[190px] rounded-xl drop-shadow-2xl relative z-20 -rotate-[14deg] group-hover:rotate-0 transition-transform duration-500 ease-in-out"
              />
              <img
                src="/card1.jpg"
                className="w-[150px] mx-auto xl:w-[190px] rounded-xl drop-shadow-2xl rotate-[14deg] absolute left-[30%] xl:left-[60px] -bottom-[50px] group-hover:rotate-0 transition-transform duration-500 ease-in-out"
              />
            </div>
            <button
              className="bg-newPrimary border border-newPrimary text-white py-2 px-4 text-sm rounded-[8px] transition-all duration-300 font-montserrat font-medium hover:bg-white hover:text-newPrimary active:scale-95 w-full mt-auto mb-0"
              onClick={() => navigate("/contact-us")}
            >
              Schedule a call
            </button>
          </div>
          <div className="lg:max-w-[321px] w-full flex lg:flex-row flex-col justify-between rounded-[16px] p-5  bg-white dark:bg-darkBg shadow-sm items-center">
            {/* Content Section */}
            <div className="w-full text-left lg:mt-0 mt-5">
              <div className="flex justify-between mb-[15px]">
                <h1 className="text-[#252323] dark:text-white text-base font-medium">
                  AI-Powered <br /> Google Review Card
                </h1>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Info className="text-[#888] size-4" />
                    </TooltipTrigger>
                    <TooltipContent>
                      <h1 className="text-sm font-medium">Description</h1>
                      <div className="mt-2">
                        <p className="text-[#595959] dark:text-white text-[13px]">
                          Weight: 23 grams
                          <br />
                          Dimensions: 9cm (horizontal) x 5cm (vertical)
                          <br />
                          Thickness: 1.5 mm
                        </p>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="w-full flex flex-col justify-between mt-5">
                {/* Quantity */}
                <p className="text-[13px] text-[#252323] dark:text-white font-normal mb-[6px]">
                  Quantity
                </p>
                <div className="flex items-center justify-between w-full mb-2">
                  <button
                    className="text-center w-[32px] h-[32px] border text-base text-[#404040] border-[#e5e5e5] rounded-[5px] dark:text-white"
                    onClick={handleDecrease}
                    disabled={qty <= 1}
                  >
                    -
                  </button>
                  <p className="border-none text-center dark:text-white bg-transparent dark:bg-darkBg text-[#010101] font-poppins text-base font-normal">
                    {qty}
                  </p>
                  <button
                    className="text-center w-[32px] h-[32px] border text-base text-[#404040] border-[#e5e5e5] rounded-[5px] dark:text-white"
                    onClick={handleIncrease}
                  >
                    +
                  </button>
                </div>

                {/* Price Section */}
                <div className="flex justify-between items-center font-pro font-medium text-[13px] lg:leading-[46px] w-full mb-5">
                  <p>{qty} Cards</p>
                  <p>{cardPrice(qty)}</p>
                </div>

                {/* Buy Button */}
                <button
                  className="bg-newPrimary border border-newPrimary text-white py-2 px-4 text-sm rounded-[8px] transition-all duration-300 font-montserrat font-medium hover:bg-white hover:text-newPrimary active:scale-95 w-full"
                  onClick={handleGoToBuyCard}
                >
                  Buy Card
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto mt-7 scrollbar-thin shadow-sm">
          <div className="max-w-[1003px] rounded-xl overflow-hidden font-pro">
            {isLoading || isFetching ? (
              <Loading height={"50vh"} />
            ) : !isLoading && data?.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow className="text-sm text-[#808080]">
                    <TableHead className="text-[15px] text-[#808080] w-[70px]"></TableHead>
                    <TableHead className="text-[15px] text-[#808080]">
                      Name
                    </TableHead>
                    <TableHead className="text-[15px] text-[#808080]">
                      Unique code
                    </TableHead>
                    <TableHead className="text-[15px] text-[#808080]">
                      Link
                    </TableHead>
                    {/* <TableHead className="text-[15px] text-[#808080]">
                    QR code
                  </TableHead> */}
                    <TableHead className="text-[15px] text-[#808080]">
                      Redirect Magiclink
                    </TableHead>
                    <TableHead className="text-[15px] text-[#808080]">
                      Activate
                    </TableHead>
                    <TableHead className="text-[15px] text-[#808080]"></TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody className="divide-y">
                  <>
                    {data?.map((item, index) => (
                      <>
                        <TableRow
                          className="text-[15px] text-[#1E1E1E]"
                          key={index}
                        >
                          <TableCell className="text-[15px] text-[#1E1E1E] pl-5 pr-0">
                            <div className="w-[50px]">
                              <img src="/tablecard.svg" className="w-full" />
                            </div>
                          </TableCell>
                          <TableCell className="text-[15px] text-[#1E1E1E] w-[154px] pr-0">
                            <div className="w-[154px]">{item.name}</div>
                          </TableCell>
                          <TableCell className="text-[15px] text-[#1E1E1E]">
                            <div className="w-[100px]">{item.code}</div>
                          </TableCell>
                          <TableCell className="text-[15px] text-[#1E1E1E]">
                            <div className="w-[124px]">
                              <IconButton className="w-fit">
                                <Eye
                                  onClick={() => setIsOpenCopyModal(item?.id)}
                                />
                                {/* <Link
                              onClick={() => handleCopyLink(item.code)}
                            ></Link> */}
                              </IconButton>
                            </div>
                          </TableCell>
                          {/* <TableCell className="text-[15px] text-[#1E1E1E]">
                      <div className="w-[100px]">
                        <IconButton className="w-fit">
                          <QrCode
                            className="w-6 h-6 mx-auto cursor-pointer text-[#888]"
                            //   onClick={() =>
                            //     handleGetQr(item?.employee_code)
                            //   }
                          />
                        </IconButton>
                      </div>
                    </TableCell> */}
                          <TableCell className="text-[15px] text-[#1E1E1E]">
                            <div className="w-[124px]">
                              <IconButton className="w-fit">
                                <Link
                                  onClick={() =>
                                    hanndleCopyRedirectLink(item.redirect_link)
                                  }
                                ></Link>
                              </IconButton>
                            </div>
                            {/* <div className="flex gap-2 w-[150px]">
                          <div
                            className="flex gap-[14px] py-[6px] px-[8px] rounded-[8px] bg-[#FAFAFA] text-[12px] text-[#656565] items-center cursor-pointer dark:bg-darkLightBg dark:text-white"
                            onClick={() => setIsOpenModal(true)}
                          >
                            Link
                            <ChevronDown size={16} />
                          </div>
                          <div className="flex justify-center py-[6px] px-[8px] rounded-[8px] bg-[#F0F5FF] text-[12px] text-[#2A49B8] items-center dark:bg-darkLightBg">
                            <Copy size={16} />
                          </div>
                        </div> */}
                          </TableCell>
                          <TableCell>
                            <div className="flex gap-2">
                              <Switch
                                checked={item.active}
                                onCheckedChange={() =>
                                  toggleSwitch(item, index)
                                }
                                disabled={toggleCardLoading}
                              />
                              {updatingCardId === index && (
                                <div className="flex items-center justify-center">
                                  <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
                                </div>
                              )}
                            </div>
                          </TableCell>
                          <TableCell className="text-[15px] text-[#1E1E1E]">
                            <DropdownMenu>
                              <DropdownMenuTrigger>
                                <IconButton>
                                  <Ellipsis className="w-5 h-5 cursor-pointer dark:text-white text-[#595959]" />
                                </IconButton>
                              </DropdownMenuTrigger>
                              <DropdownMenuContent className="dark:bg-darkLightBg dark:shadow-md">
                                <DropdownMenuItem
                                  className="flex items-center gap-2 pr-4 text-[15px] text-[#232325] dark:text-white border-b border-[#f4f4f4]"
                                  onClick={() => {
                                    handleEditModal(item);
                                  }}
                                >
                                  <IconButton>
                                    <Pencil
                                      className={`w-4 h-4 cursor-pointer`}
                                    />
                                  </IconButton>
                                  Edit
                                </DropdownMenuItem>

                                <DropdownMenuItem
                                  className="flex items-center gap-2  pr-4 text-[15px] text-red-500 hover:text-red-500"
                                  onClick={() => {
                                    setIsOpenDeleteModal(true);
                                    setToDeleteId(item?.id);
                                  }}
                                >
                                  <IconButton>
                                    <Trash2 className="w-4 h-4 cursor-pointer text-red-500" />
                                  </IconButton>
                                  Delete
                                </DropdownMenuItem>
                              </DropdownMenuContent>
                            </DropdownMenu>
                            {/* <IconButton className={"w-fit"}>
                          <Pencil
                            onClick={() => handleEditModal(item)}
                            size={16}
                          />
                        </IconButton> */}
                          </TableCell>
                        </TableRow>
                        {isOpenCopyModal === item?.id && (
                          <CopyMagicLinkModal
                            isOpenModal={isOpenCopyModal === item?.id}
                            setIsOpenModal={setIsOpenCopyModal}
                            code={item?.code}
                          />
                        )}
                      </>
                    ))}
                  </>
                </TableBody>
              </Table>
            ) : (
              <MagicCardIllustrations />
            )}
          </div>
        </div>
        {/* {isOpenModal && (
          <RedirectModal
            isOpenModal={isOpenModal}
            setIsOpenModal={setIsOpenModal}
          />
        )} */}
        {isEditModalOpen && (
          <EditMagicCardModal
            isEditModalOpen={isEditModalOpen}
            selectedItem={selectedItem}
            setIsEditModalOpen={setIsEditModalOpen}
            refetch={refetch}
          />
        )}
        <DeleteModal
          openModal={isOpenDeleteModal}
          setOpenModal={setIsOpenDeleteModal}
          handleDelete={handleDeleteCard}
          isLoading={deleteCardLoading}
          title={"Are you sure you want to delete this MagicCard?"}
        />
      </div>
    </Layout>
  );
};

export default MagicCard;
