/* eslint-disable react/prop-types */
import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { toast } from "sonner";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import WordBreak from "../../components/wordbreak";
import { useNavigate } from "react-router";
import AboutUsImg1 from "/aboutUsImg1.png";
import AboutUsImg2 from "/aboutUsImg2.svg";
import AboutUsIcon1 from "/aboutUsIcon1.svg";
// import { ChartNoAxesCombined } from "lucide-react";
// import { v4 as uuidv4 } from 'uuid';
import { v4 as uuidv4 } from "uuid";
import {
  BadgeCheck,
  ChartNoAxesCombined,
  Command,
  Eye,
  Handshake,
  Lightbulb,
  LineChart,
  QrCode,
  Star,
  WalletCards,
} from "lucide-react";
import { motion } from "framer-motion";
import { SubscribeApi } from "../../api/subscribeApi";
import WorldMap from "../../components/ui/world-map";
import CommonHeading from "../../components/home/CommonHeading";
import CountUp from "../../components/ui/countup";
const AboutUs = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const delays = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2]; // Array of delays
  const valuesData = [
    {
      img: AboutUsIcon1,
      title: "Vision",
      desc: "Our vision extends beyond the present. We aspire to become the go-to platform for one-to-one outreach, setting the industry standard for effectiveness.",
    },
    {
      img: AboutUsIcon1,
      title: "Team work",
      desc: "Our team is dedicated to streamlining your B2B sales Journey through our AI-driven, hyper-personalized email outreach, ensuring your business flourishes.",
    },
    {
      img: AboutUsIcon1,
      title: "Excellence",
      desc: "MagicPitch is committed to being the best in the market when it comes to outreach strategies. ",
    },
    {
      img: AboutUsIcon1,
      title: "Responsibility",
      desc: "Their goal is to help clients promote products or services effectively, build brand awareness, and achieve business objectives.",
    },
  ];

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      if (!emailRegex.test(email)) {
        toast.error("Invalid Email");
        setIsLoading(false);
        return;
      }

      if (email === "") {
        setIsLoading(false);
        toast.error("Email is required");
        return;
      }
      const data = {
        email: email,
      };
      const result = await SubscribeApi(data);

      if (result.status === 201) {
        toast.success("Subscribed Successfully");
        setEmail("");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const handleNavigate = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value === "") {
      setEmailError("Email is required");
    } else {
      setEmailError(null);
    }
  };

  const IconWrapper = ({ children, color, isBottom = false }) => (
    <div
      className={`size-[59px] flex justify-center items-center rounded-[7px] ${isBottom && "mt-auto mb-0"}`}
      style={{
        backgroundColor: color + "29",
      }}
    >
      {children}
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen w-full bg-white">
      <Helmet>
        <title>About Us - Magicreview</title>
        <meta
          name="description"
          content="Discover how magicreview simplifies feedback collection with innovative solutions. Our platform enhances engagement and drives growth for businesses of all sizes."
        />
        <link rel="canonical" href="https://www.magicreview.ai/about-us" />
      </Helmet>

      <Mainnav />
      <div className="font-poppins container text-black pt-10 lg:pt-20 mb-24">
        <CommonHeading
          title={"About Us"}
          description={
            "Welcome to MagicReview, where our AI-powered rapid review\ntool makes collecting reviews quick and easy."
          }
        />

        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center gap-5 mt-10">
          <input
            type="text"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
            className="lg:w-[340px] w-full font-poppins border border-[#C2C2C2] rounded-[8px] p-3  h-11"
          />
          <button
            className=" text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 h-11 px-10 rounded-[8px] font-poppins"
            onClick={handleNavigate}
          >
            Contact Us
          </button>
        </div>
      </div>

      {/* <div className="bg-[#f1f4ff] text-black w-full py-24 container">
        <p className="font-poppins font-semibold text-2xl md:text-[38px] leading-[48px] -tracking-[6%]">
          About our Company on how to <WordBreak /> accelerate the startup
          company and <WordBreak /> built trust over a decade
        </p>

        <div className="flex lg:flex-nowrap flex-wrap justify-evenly md:h-[150px] mt-24 gap-5 ">
          <IconWrapper color="#299F49">
            <ChartNoAxesCombined
              className={`size-[50px]`}
              style={{
                color: "#299F49",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#4869DF" isBottom={true}>
            <QrCode
              className={`size-[50px]`}
              style={{
                color: "#4869DF",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#753EE9">
            <WalletCards
              className={`size-[50px]`}
              style={{
                color: "#753EE9",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#FFAF19" isBottom={true}>
            <Star
              className={`size-[50px]`}
              style={{
                color: "#FFAF19",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#2A49B8">
            <Handshake
              className={`size-[50px]`}
              style={{
                color: "#2A49B8",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#E5484D" isBottom={true}>
            <Command
              className={`size-[50px]`}
              style={{
                color: "#E5484D",
              }}
            />
          </IconWrapper>
        </div>

        <div className="flex lg:no-flexwrap flex-wrap mt-24 gap-24 lg:gap-0">
          <div className="w-full lg:w-1/2 space-y-7 lg:pr-28">
            <p className="font-poppins font-semibold text-[31px] leading-[28.8px]">
              What We Do
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              MagicReview is an AI-powered rapid review tool that utilizes NFC
              and QR technology to initiate the review process. it efficiently
              gathers customer feedback through MCQs.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Our trained AI then transforms these responses into SEO-optimized
              reviews. MagicReview encourages more feedback and attracts
              customers with each review process taking just 30 seconds.
              We&apos;re simplifying the review collection process for
              businesses worldwide.
            </p>
            <button
              className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px] font-poppins font-semibold"
              onClick={handleNavigate}
            >
              Contact Us
            </button>
          </div>
          <div className="w-full lg:w-1/2 space-y-7 lg:pr-28">
            <p className="font-poppins font-semibold text-[31px] leading-[28.8px]">
              Our Journey
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              MagicReview was started in January 2024, with a bright idea born
              from observing the struggles businesses face in collecting
              valuable reviews.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Our team recognized the common challenge of customers often
              neglecting to leave reviews due to the tiring process.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Determined to make a change, MagicReview was created to streamline
              this process using AI and help businesses with genuine,
              SEO-optimized review collections.
            </p>
            <button
              className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px] font-poppins font-semibold"
              onClick={handleNavigate}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div> */}

      <div className="w-full pb-10 lg:pb-20 container grid grid-cols-1 lg:grid-cols-[60%_40%] font-poppins gap-5 lg:gap-7">
        <motion.div
          className="bg-[#f1f4ff] rounded-[20px] p-5 lg:py-8 lg:px-10 space-y-[47px]"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          viewport={{
            once: true,
          }}
        >
          <p className="font-semibold lg:text-[34px] lg:leading-[48px] lg:tracking-tight">
            Empowering Businesses in the <WordBreak /> Digital Age
          </p>
          <p className="lg:text-[17px] text-sm lg:leading-[26px] text-[#333]">
            At MagicReview, we understand that in today&apos;s fast-paced
            digital landscape, maintaining a stellar online reputation is
            paramount. Our mission is to equip businesses with cutting-edge
            AI-driven tools that simplify reputation management, enhance
            customer engagement, and drive growth.
          </p>
        </motion.div>
        <motion.div
          className="bg-[#f1f4ff] rounded-[20px] p-5 lg:py-8 lg:px-10 space-y-[47px]"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          <p className="font-semibold lg:text-[34px] lg:leading-[48px] lg:tracking-tight">
            Our Journey
          </p>
          <p className="lg:text-[17px] text-sm lg:leading-[26px] text-[#333]">
            Founded in [Year], MagicReview emerged from a vision to
            revolutionize how businesses perceive and manage their online
            presence. Recognizing the challenges companies face in monitoring
            and responding to customer feedback across multiple platforms, we
            developed an integrated solution that harnesses the power of
            artificial intelligence.
          </p>
        </motion.div>
        <motion.div
          className="bg-newPrimary rounded-[20px] p-5 lg:py-8 lg:px-10  grid grid-cols-1 lg:grid-cols-2 text-white "
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.4,
          }}
          viewport={{
            once: true,
          }}
        >
          <div className="flex flex-col justify-center items-center border-r border-b border-[#839EFB]">
            <p className="lg:text-[56px] text-lg font-semibold lg:leading-[58px] lg:tracking-tighter text-center ">
              <CountUp
                from={4800}
                to={5000}
                separator=","
                direction="up"
                duration={1}
                className="count-up-text"
              />
              +
            </p>
            <p className="lg:text-xl lg:leading-[32px] text-center">
              Reviews Generated
            </p>
          </div>
          <div className="flex flex-col justify-center items-center border-b border-[#839EFB]">
            <p className="lg:text-[56px] text-lg font-semibold lg:leading-[58px] lg:tracking-tighter text-center">
              <CountUp
                from={0}
                to={100}
                separator=","
                direction="up"
                duration={1}
                className="count-up-text"
              />
              +
            </p>
            <p className="lg:text-xl lg:leading-[32px] text-center">
              Business Served
            </p>
          </div>
          <div className="flex flex-col justify-center items-center border-r border-[#839EFB]">
            <p className="lg:text-[56px] text-lg font-semibold lg:leading-[58px] lg:tracking-tighter text-center">
              <CountUp
                from={2400}
                to={2500}
                separator=","
                direction="up"
                duration={1}
                className="count-up-text"
              />
              +
            </p>
            <p className="lg:text-xl lg:leading-[32px] text-center">
              Users Helped
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <p className="lg:text-[56px] text-lg font-semibold lg:leading-[58px] lg:tracking-tighter text-center">
              <CountUp
                from={0}
                to={7}
                separator=","
                direction="up"
                duration={1}
                className="count-up-text"
              />
              +
            </p>
            <p className="lg:text-xl lg:leading-[32px] text-center">
              Enterprises Served
            </p>
          </div>
        </motion.div>
        <motion.div
          className="bg-[#f1f4ff] rounded-[20px] p-5 lg:py-8 lg:px-10 space-y-[47px]"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.6,
          }}
          viewport={{
            once: true,
          }}
        >
          <p className="font-semibold lg:text-[34px] lg:leading-[48px] lg:tracking-tight">
            Our Mission
          </p>
          <p className="lg:text-[17px] text-sm lg:leading-[26px] text-[#333]">
            Our mission is to empower businesses of all sizes to take control of
            their online reputation through advanced technology and unparalleled
            support. We believe that by providing intuitive and effective tools,
            we can help our clients build trust with their customers and stand
            out in the digital marketplace.
          </p>
        </motion.div>
      </div>

      <div className="bg-[#f1f4ff] text-black w-full py-10 lg:py-20 container font-poppins">
        <CommonHeading
          title={"Our Values"}
          description={"Trusted By top b2C Companies over the world"}
        />

        <div className="grid grid-cols-1 lg:grid-cols-[28%_39%_28%] gap-5 lg:gap-7 mt-16">
          <motion.div
            className="rounded-[20px] bg-white border border-[#d7e0fe] lg:p-7 p-5 space-y-5 "
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
            }}
            viewport={{
              once: true,
            }}
          >
            <IconWrapper color="#ffaf19">
              <Lightbulb
                className={`size-8`}
                style={{
                  color: "#ffaf19",
                }}
              />
            </IconWrapper>
            <p className="font-poppins font-semibold text-[22px]">Innovation</p>
            <p className="font-poppins text-[17px]">
              Showcase a drive to adopt and create cutting-edge AI technology.
            </p>
          </motion.div>
          <motion.div
            className="rounded-[20px] bg-newPrimary flex justify-center items-center row-span-2 lg:p-7 p-5"
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: 0.2,
            }}
            viewport={{
              once: true,
            }}
          >
            <img src="/values.png" alt="" />
          </motion.div>
          <motion.div
            className="rounded-[20px] bg-white border border-[#d7e0fe] lg:p-7 p-5 space-y-5 "
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: 0.4,
            }}
            viewport={{
              once: true,
            }}
          >
            <IconWrapper color="#4869df">
              <Eye
                className={`size-8`}
                style={{
                  color: "#4869df",
                }}
              />
            </IconWrapper>
            <p className="font-poppins font-semibold text-[22px]">
              Transparency
            </p>
            <p className="font-poppins text-[17px]">
              Highlight honesty in processes, data usage, and communication.
            </p>
          </motion.div>
          <motion.div
            className="rounded-[20px] bg-white border border-[#d7e0fe] lg:p-7 p-5 space-y-5 "
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: 0.6,
            }}
            viewport={{
              once: true,
            }}
          >
            <IconWrapper color="#753EE9">
              <BadgeCheck
                className={`size-8`}
                style={{
                  color: "#753EE9",
                }}
              />
            </IconWrapper>
            <p className="font-poppins font-semibold text-[22px]">
              Quality Assurance
            </p>
            <p className="font-poppins text-[17px]">
              Underline a commitment to delivering consistent and exceptional
              results.
            </p>
          </motion.div>
          <motion.div
            className="rounded-[20px] bg-white border border-[#d7e0fe] lg:p-7 p-5 space-y-5 "
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: 0.8,
            }}
            viewport={{
              once: true,
            }}
          >
            <IconWrapper color="#CF3EE9">
              <Handshake
                className={`size-8`}
                style={{
                  color: "#CF3EE9",
                }}
              />
            </IconWrapper>
            <p className="font-poppins font-semibold text-[22px]">
              Customer-Centric Approach
            </p>
            <p className="font-poppins text-[17px]">
              Emphasize a commitment to understanding and exceeding customer
              expectations.
            </p>
          </motion.div>
        </div>
      </div>

      <div className="w-full container py-10 lg:py-20 font-poppins">
        <div className=" w-full bg-[#F7F9FF] rounded-[19px] flex flex-col lg:flex-row lg:justify-between lg:items-center px-10 lg:px-16 lg:py-10 py-8 gap-5">
          <div className="w-fit space-y-1 lg:w-[48%]">
            <p className="w-fit font-medium text-lg text-newPrimary">Try Now</p>
            <p className="w-fit font-semibold text-[28px] text-[#333] leading-[42px]">
              Learn How MagicReview Can <WordBreak /> Grow Your Business{" "}
            </p>
            <p className="w-fit text-lg text-[#888]">
              AI generated reviews with a 14-day free trial
            </p>
          </div>
          <div className="lg:w-[48%] flex lg:flex-row flex-col lg:justify-center gap-5">
            <button
              className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3 hover:text-white hover:bg-newPrimary rounded-lg border border-newPrimary text-newPrimary transition-all duration-300 bg-white h-11"
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
            >
              Schedule a call
            </button>
            <button
              className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3 text-white bg-newPrimary rounded-lg border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white h-11"
              onClick={handleNavigate}
            >
              Start your free trial
            </button>
          </div>
        </div>
      </div>

      {/* <div className="bg-[#f1f4ff] text-black w-full py-8 lg:py-24 container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px]">
            Our Mission
          </h2>
          <div className="space-y-2">
            <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-[#333]">
              Our goal at MagicReview is simple: to give businesses the tools
              they need to succeed in a tough market. We&apos;re changing how
              feedback is collected, making it easy for both businesses and
              customers.{" "}
            </p>
            <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-[#333]">
              With innovation, simplicity, and a focus on real feedback,
              we&apos;re helping businesses everywhere thrive. Come join us in
              our mission to change the way feedback works and utilise the power
              of customer reviews with MagicReview.
            </p>
          </div>
        </div>
        <div className="w-full bg-newPrimary rounded-[20px] p-5 lg:p-16 mt-5 lg:mt-10">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px] text-white">
              Who we are & how <WordBreak /> we got here?
            </h2>
            <button
              className="flex items-center justify-center gap-2 px-10 py-3 rounded-[12px] border border-newPrimary text-newPrimary transition-all duration-300 bg-white font-poppins w-full lg:w-fit"
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </button>
          </div>
          <div className="lg:pl-40 mt-5 lg:mt-10">
            <div className="space-y-5 ">
              <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-white">
                MagicReview is an AI-powered rapid review tool that utilises NFC
                and QR technology to initiate the review process. MagicReview
                encourages more feedback and attracts customers with each review
                process taking just 30 seconds. We&apos;re simplifying the
                review collection process for businesses worldwide.
              </p>
              <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-white">
                MagicReview was started in Feb 2024, with a bright idea born
                from observing the struggles businesses face in collecting
                valuable reviews. Our team recognised the common challenge of
                customers often neglecting to leave reviews due to the tiring
                process. Determined to make a change, MagicReview was created to
                streamline this process using AI and help businesses with
                genuine, SEO-optimized review collections.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="w-full py-24">
        <div className="container">
          <div className="text-[#333333] font-poppins w-full flex flex-col items-center text-center lg:w-[59%] mx-auto ">
            <h1 className="text-[37px] font-semibold mb-[31px]">Our Mission</h1>
            <p className="text-base">
              Our goal at MagicReview is simple: to give businesses the tools
              they need to succeed in a tough market. We&apos;re changing how
              feedback is collected, making it easy for both businesses and
              customers. With innovation, simplicity, and a focus on real
              feedback, we&apos;re helping businesses everywhere thrive. Come
              join us in our mission to change the way feedback works and
              utilize the power of customer reviews with MagicReview.
            </p>
          </div>
        </div>
      </div> */}
      {/* <div className="w-full py-8 lg:py-24 container">
        <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px]">
          Our Values
        </h2>
        <div className="space-y-5 mt-5 lg:mt-24">
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#299F49">
                <ChartNoAxesCombined
                  className={`size-8`}
                  style={{
                    color: "#299F49",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Empowerment
              </p>
              <p className="font-poppins text-[17px]">
                Stress enabling businesses to achieve more through simplified
                and automated review management.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#4869df">
                <Eye
                  className={`size-8`}
                  style={{
                    color: "#4869df",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Transparency
              </p>
              <p className="font-poppins text-[17px]">
                Highlight honesty in processes, data usage, and communication.
              </p>
            </div>
          </div>
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#ffaf19">
                <Lightbulb
                  className={`size-8`}
                  style={{
                    color: "#ffaf19",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Innovation
              </p>
              <p className="font-poppins text-[17px]">
                Showcase a drive to adopt and create cutting-edge AI technology.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#CF3EE9">
                <Handshake
                  className={`size-8`}
                  style={{
                    color: "#CF3EE9",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Customer-Centric Approach
              </p>
              <p className="font-poppins text-[17px]">
                Emphasize a commitment to understanding and exceeding customer
                expectations.
              </p>
            </div>
          </div>
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#753EE9">
                <BadgeCheck
                  className={`size-8`}
                  style={{
                    color: "#753EE9",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Quality Assurance
              </p>
              <p className="font-poppins text-[17px]">
                Underline a commitment to delivering consistent and exceptional
                results.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#E5484D">
                <Command
                  className={`size-8`}
                  style={{
                    color: "#E5484D",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Collaboration
              </p>
              <p className="font-poppins text-[17px]">
                Highlight working with clients and partners to tailor solutions
                for unique needs.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  );
};

export default AboutUs;
