/* eslint-disable react/prop-types */
import { Menu, Transition } from "@headlessui/react";
// import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Drawer } from "flowbite-react";
import {
  AlignJustify,
  Bell,
  Command,
  Copy,
  LogOut,
  MessageSquareText,
  Moon,
  QrCode,
  Rocket,
  Settings,
  Sparkles,
  SquarePen,
  Sun,
  CirclePlay,
  UsersRound,
  ChevronDown,
  ArrowLeftRight,
  MailPlus,
  TriangleAlert,
} from "lucide-react";
import { Fragment, useEffect, useRef, useState } from "react";
import { STATUS } from "react-joyride";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { toast } from "sonner";
import { useDarkMode } from "../../../context/DarkModeProvider";
import useWindowSize from "../../../hooks/useWindowSize";
import {
  useGetIntegrations,
  useGetIsSubUser,
  useGetMasterAccountAccess,
  useGetNotifications,
} from "../../../query/useAuthentication";
import { useGetMagicLink, useGetMagicQr } from "../../../query/useEmployees";
import { timeAgo } from "../../../utils/formatter";
import CustomTooltipForJoyride from "./CustomTooltipForJoyride";
import IconButton from "./IconButton";
import QrContainer from "./QrContainer";
import RemainingSubscription from "./RemainingSubscription";
import SideBar, { LinkComponent } from "./SideBar";
import Joyride from "react-joyride";
import Intercom from "@intercom/messenger-js-sdk";
import { useUser } from "../../../query/useAuthentication";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import ContactDialog from "./ContactDialog";
import RequestReviewModal from "../routes/components/RequestReviewModal";
const Layout = ({ children, title }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const windowSize = useWindowSize();
  const isMobile = windowSize.width <= 768;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [socket, setSocket] = useState(null);
  const [isAlert, setIsAlert] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const dropdownRef = useRef(null);
  const notifactaionRef = useRef(null);
  const webSocketApiUrl = import.meta.env.VITE_WEBSOCKET_API_URL;
  const token = localStorage.getItem("token");
  const [magicLink, setMagicLink] = useState("");
  const [baseUrl, setBaseUrl] = useState("");
  const [isOpenQrContainer, setIsOpenQrContainer] = useState(false);
  const { darkMode, setDarkMode } = useDarkMode();
  const [isOpenContactDialog, setIsOpenContactDialog] = useState(false);
  const [contactMessage, setContactMessage] = useState("");
  const [isOpenRequestReview, setIsOpenRequestReview] = useState(false);
  const [isIntegrated, setIsIntegrated] = useState(true);
  const isMaster = location.pathname.includes("organisation");
  const masterToken =
    sessionStorage.getItem("masterToken") &&
    sessionStorage.getItem("masterToken");
  const masterUser =
    sessionStorage.getItem("masterUser") &&
    sessionStorage.getItem("masterUser");

  const joyRideSteps = [
    {
      icon: <Sparkles />,
      title: "AI reviews",
      content: "View the total number of AI-generated reviews at a glance",
      target: ".generated",
      disableBeacon: true,
    },
    {
      icon: <SquarePen />,
      title: "Redirected reviews",
      content: "Track the reviews posted by users",
      target: ".redirected",
    },
    {
      icon: <MessageSquareText />,
      title: "Reviews",
      content: "View and download reviews for an offline analysis",
      target: ".reviews",
      placement: "right",
    },
    {
      icon: <Sparkles />,
      title: "Questions",
      content: "Create question set(s) to collect reviews from users",
      target: ".questions",
      placement: "right",
    },
    {
      icon: <Command />,
      title: "SEO Keywords",
      content:
        "Add SEO Keywords to ensure better search visibility for your reviews",
      target: ".seo",
      placement: "right",
    },
    {
      icon: <Link />,
      title: "Magiclinks",
      content: "Enable Magiclinks to track reviews collected from each link",
      target: ".magiclinks",
      placement: "right",
    },
    {
      icon: <UsersRound />,
      title: "Referrals",
      content: " Refer to other businesses and earn a month off as a reward",
      target: ".referral",
      placement: "right",
    },
    {
      icon: <Settings />,
      title: "Settings",
      content: "Edit profile details and integrate with other platforms",
      target: ".settings",
      placement: "right",
      // referrerFunc: function () {
      //   navigate("/dashboard/questions?referrer=verify-email");
      // },
      // referrer: "Complete",
    },
  ];

  const joyRideMobileSteps = [
    {
      icon: <Sparkles />,
      title: "AI reviews",
      content: "View the total number of AI-generated reviews at a glance",
      target: ".generated",
      disableBeacon: true,
    },
    {
      icon: <SquarePen />,
      title: "Redirected reviews",
      content: "Track the reviews posted by users",
      target: ".redirected",
      // referrerFunc: function () {
      //   navigate("/dashboard/questions?referrer=verify-email");
      // },
      // referrer: "Go to question section",
    },
  ];

  const [step, setStep] = useState({
    run: false,
    steps: isMobile ? joyRideMobileSteps : joyRideSteps,
    stepIndex: 0,
  });

  const { refetch } = useGetNotifications({
    onSuccess: (res) => {
      setNotifications(res.pages.flatMap((page) => page.results));
    },
  });
  const { refetch: refecthUser } = useUser({
    onSuccess: (res) => {
      setUserInfo(res);
      Intercom({
        app_id: "ncew78xq",
        name: res.first_name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
        email: res.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
        created_at: new Date(res.date_joined).getTime() / 1000, // Convert to Unix timestamp in seconds
        user_hash: res.hmac_code,
      });
      localStorage.setItem("user", JSON.stringify(res));
      localStorage.setItem("entityUuid", res?.entity?.uuid);
    },
  });

  const { mutate: masterAccountAccess } = useGetMasterAccountAccess();
  const { data: isSubuser } = useGetIsSubUser();

  useGetMagicLink({
    onSuccess: (res) => {
      setMagicLink(res?.magic_link);
      setBaseUrl(res?.host);
    },
  });

  useGetIntegrations({
    onSuccess: (res) => {
      setIsIntegrated(res?.value);
    },
  });

  const { data: qr } = useGetMagicQr();

  useEffect(() => {
    const socket = new WebSocket(
      `${webSocketApiUrl}/notification/?token=${token}`
    );

    socket.addEventListener("open", () => {});

    socket.addEventListener("message", (event) => {
      const newNoti = JSON.parse(event.data);
      setNotifications((prev) => [newNoti, ...prev]);
      setIsAlert(true);
      refetch();
    });

    socket.addEventListener("error", () => {});

    socket.addEventListener("close", () => {});

    setSocket(socket);

    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (localStorage.getItem("user")) {
      setUserInfo(JSON.parse(localStorage.getItem("user")));
    }
  }, [localStorage.getItem("user")]);

  const logout = () => {
    if (isSubuser?.is_sub_user && masterToken) {
      localStorage.setItem("token", masterToken);
      localStorage.setItem("user", masterUser);
      navigate("/organisation", { replace: true });
      sessionStorage.removeItem("masterToken");
      sessionStorage.removeItem("masterUser");
    } else {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      sessionStorage.removeItem("tempToken");
      navigate("/sign-in", { replace: true });
    }
  };

  const handleCopy = () => {
    if (magicLink) {
      navigator.clipboard.writeText(baseUrl + "/" + magicLink);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };

  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  const handleMasterAccountAccess = () => {
    if (isMaster) {
      toast.success("Switching to user account");
      navigate("/dashboard");
    } else {
      masterAccountAccess(
        {},
        {
          onSuccess: (res) => {
            if (res.access) {
              toast.success(res.message);
              navigate("/organisation");
            } else {
              setContactMessage(res.message);
              setIsOpenContactDialog(true);
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      // Close the Platform dropdown if clicked outside
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
      // Close the Solution dropdown if clicked outside
      if (
        notifactaionRef.current &&
        !notifactaionRef.current.contains(event.target)
      ) {
        setIsNotificationsOpen(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (referer === "verify-email" && pathname === "/dashboard") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, [referer]);

  return (
    <div className="lg:container w-full min-h-[100vh] relative lg:flex !px-0 bg-[#f0f5ff] dark:bg-darkLightBg dark:text-white">
      <aside
        id="default-sidebar"
        className={`z-[60] w-full lg:max-w-[270px] h-screen transition-transform lg:bg-none hidden lg:block sticky top-0 left-0
        `}
        aria-label="Sidebar"
      >
        <div className="h-full pt-5 pb-5 lg:pt-8 lg:pb-6 pl-5 lg:pl-7 w-4/5 lg:w-full bg-white dark:bg-darkBg relative">
          {/* cancel mark */}
          <div className="overflow-y-auto scrollbar-thin lg:pr-5 h-[calc(100vh-250px)]">
            <div className={`flex items-center max-w-[150px] -mt-2`}>
              <img src="/magiclogo.svg" alt="" className="w-[55px]" />
              <p
                className={`text-[18px] -tracking-[3%] leading-[54px] dark:text-white text-[#434343]
                font-avertaBold text-center -mt-2`}
              >
                magicreview
              </p>
            </div>

            <SideBar
              isSubuser={isSubuser?.is_sub_user}
              isCredit={isSubuser?.credit_based}
            />
          </div>
          <div className="fixed bottom-0 pb-3 bg-white dark:bg-darkBg">
            <LinkComponent
              link={"/dashboard/get-started"}
              icon={<CirclePlay className="w-5 h-5" />}
              title={"Getting Started"}
              className={
                "getstarted font-pro font-medium bg-[#f0f5ff] text-newPrimary dark:bg-darkLightBg dark:text-newPrimary"
              }
            />
            {!isMaster && (
              <RemainingSubscription
                isSubuser={isSubuser?.is_sub_user}
                isCredit={isSubuser?.credit_based}
              />
            )}
          </div>
        </div>
      </aside>

      {/* contents */}
      <div className={`lg:w-[80%] lg:ml-1`}>
        <div className="sticky top-0 w-full z-50 pt-5 pb-5 lg:pt-9 space-y-3 shadow-md lg:shadow-none bg-[#f0f5ff] dark:bg-darkLightBg">
          <header className="flex justify-between w-full gap-4 items-center flex-wrap px-5 lg:px-10">
            <div className="flex items-center gap-x-3">
              <AlignJustify
                className="w-6 h-6 lg:hidden mobile-menu"
                onClick={() => setIsDrawerOpen(true)}
              />

              <h1 className="text-[#333] dark:text-white text-base md:text-[20px] font-semibold font-poppins">
                {title ? title : "Overview"}
              </h1>
            </div>

            <div className="items-center gap-3 ml-auto flex">
              <button
                onClick={() => {
                  setDarkMode((prev) => !prev);
                  localStorage.setItem("darkMode", !darkMode);
                }}
                className="bg-white dark:bg-darkBg lg:flex rounded-[28px] p-2 size-10 items-center justify-center hidden"
              >
                {darkMode ? (
                  <Sun className="size-5" />
                ) : (
                  <Moon className="size-5 text-[#656565]" />
                )}
              </button>
              {!isMaster && (
                <div className="lg:flex hidden gap-3 bg-newPrimary h-10 px-[23px] rounded-[28px] text-white text-sm items-center font-pro font-medium copy-link">
                  Magiclink{" "}
                  <Copy
                    className="cursor-pointer w-5 h-5"
                    onClick={handleCopy}
                  />
                </div>
              )}
              {!isMaster && (
                <button
                  className="lg:flex hidden items-center w-auto px-5 h-10 rounded-full border border-newPrimary text-white bg-newPrimary hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary font-pro text-sm gap-x-2 transition-all duration-200"
                  onClick={() => setIsOpenRequestReview(true)}
                >
                  <p className="text-[14px] font-medium">Request Reviews</p>
                  <MailPlus className="w-5 h-5" />
                </button>
              )}
              {!isMaster && (
                <div
                  className="bg-white dark:bg-darkBg lg:flex hidden rounded-[28px] p-2 size-10 items-center justify-center cursor-pointer"
                  onClick={() => setIsOpenQrContainer(true)}
                >
                  <QrCode className=" w-5 h-5 text-[#656565] dark:text-white" />
                </div>
              )}

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="bg-white dark:bg-darkBg rounded-[28px] p-2 w-9 h-9 md:w-10 md:h-10 flex  items-center justify-center cursor-pointer">
                    <Bell className="w-5 h-5 text-[#656565] dark:text-white" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <div className="flex flex-col  py-5 min-w-[280px] md:w-[373px] font-pro">
                    {/* These are the dropdown links, add or change as needed */}
                    <div className="flex justify-between items-center w-full px-7 pb-3">
                      <p className="text-[20px] font-medium">
                        Your Notifications
                      </p>
                    </div>
                    {notifications?.length > 0 ? (
                      <div className="divide-y overflow-x-hidden overflow-y-auto scrollbar-thin max-h-[230px] px-7">
                        {notifications?.map((noti) => (
                          <div
                            key={noti.message}
                            className="py-3 flex items-center gap-x-3"
                          >
                            <img
                              src="/new-logo.svg"
                              alt=""
                              className="w-10 h-10 rounded-full flex-shrink-0"
                            />

                            <div>
                              <p className="font-opensans text-[15px]">
                                {noti.message}
                              </p>
                              <p className="text-sm text-[#838383]">
                                {noti.timestamp && timeAgo(noti.timestamp)}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <p className="text-center py-5 lg:py-10">
                        No notifications
                      </p>
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
              {/* Profile dropdown */}

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div
                    className="flex gap-2 items-center text-[#888] dark:text-white cursor-pointer font-pro"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <img
                      className="w-9 h-9 md:w-10 md:h-10 rounded-full lg:mr-[9px]"
                      src={
                        userInfo?.profile?.profile_picture
                          ? userInfo?.profile?.profile_picture
                          : "/new-logo.svg"
                      }
                      alt=""
                      referrerPolicy="no-referrer"
                    />
                    <div className=" flex-col md:flex hidden">
                      <p className="w-[30px] md:w-auto line-clamp-1 text-base text-[#252323] dark:text-white">
                        {userInfo?.first_name}
                      </p>
                      <p className="w-[30px] md:w-auto line-clamp-1 text-xs text-[#252323] dark:text-white">
                        {isSubuser?.data?.org_name}
                      </p>
                    </div>

                    <ChevronDown
                      className={`md:ml-[9px] h-5 w-5 transform transition-transform ${
                        isDropdownOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mr-5 lg:mr-10 font-pro">
                  <div className="flex flex-col w-full p-3 gap-2">
                    {/* These are the dropdown links, add or change as needed */}

                    <div className="flex items-center w-full gap-2">
                      <img
                        className="h-10 w-10 rounded-full"
                        src={
                          userInfo?.profile?.profile_picture || "/new-logo.svg"
                        }
                        alt=""
                      />
                      <div className="w-[200px]">
                        <h1 className="text-lg break-words">
                          {userInfo?.first_name} {userInfo?.last_name}
                        </h1>
                        <p className="text-[#888] text-sm break-words">
                          {userInfo?.email}
                        </p>
                      </div>
                    </div>

                    <DropdownMenuSeparator />

                    {!isSubuser?.is_sub_user && (
                      <>
                        <div
                          className="flex items-center w-full gap-2 group cursor-pointer"
                          onClick={handleMasterAccountAccess}
                        >
                          <div className="size-10 rounded-full flex items-center justify-center bg-lightpurple dark:bg-darkLightBg">
                            <ArrowLeftRight className="size-5 text-newPrimary" />
                          </div>
                          <div className="w-[200px]">
                            <h1 className="font-pro text-lg break-words group-hover:underline">
                              {isMaster
                                ? "User Account"
                                : "Organisation Account"}
                            </h1>
                            <p className="text-[#888] text-sm break-words">
                              Switch to {isMaster ? "user" : "organisation"}{" "}
                              account
                            </p>
                          </div>
                        </div>

                        <DropdownMenuSeparator />
                      </>
                    )}

                    <button
                      className="flex items-center gap-2 w-auto mx-auto bg-[#ff4949]/10 py-2 px-10 rounded-lg text-[#ff4949]"
                      onClick={logout}
                    >
                      <LogOut className="size-5" />
                      <p className=" text-base font-pro">
                        {isSubuser?.is_sub_user && masterToken
                          ? "Back to Organization Account"
                          : "Logout"}
                      </p>
                    </button>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </header>
          <div className="relative w-full font-montserrat text-sm hidden">
            <input
              type="text"
              placeholder="MagicReview Link"
              className="w-full rounded-[8px] border border-secondary bg-secondary text-white placeholder:text-sm pl-3 lg:pr-5 py-3"
              disabled
              value={"Your MagicReview Link"}
            />
            <Copy
              className="absolute top-1/2 -translate-y-1/2 right-3 text-white cursor-pointer"
              onClick={handleCopy}
            />
          </div>
        </div>

        <div className="px-2.5">
          {!isIntegrated && (
            <div className="w-full rounded-[8px] bg-[#ffe07c] dark:bg-[#4d2600] text-[#2f1400] dark:text-[#fff2b4] p-[0.8125rem] mt-3 mb-3 flex  gap-2 sm:items-center container ">
              <TriangleAlert className="h-5 w-5  dark:text-[#fff2b4]" />

              <div className="flex sm:flex-row flex-col justify-between sm:items-center gap-5 w-full">
                <p className="font-pro font-normal  text-sm">
                  <span className="font-medium dark:font-semibold">
                    Add review link.
                  </span>{" "}
                  <br className="sm:hidden" />
                  Unfortunately, you still haven&apos;t added your review link
                  for your Business. Add the link to get an analysis of your
                  reviews.
                </p>
                <Link
                  to={"/dashboard/settings/integrations"}
                  className=" text-[#6D3A01] dark:text-[#fcbd00] text-sm font-medium sm:ml-auto text-nowrap"
                >
                  Add link
                </Link>
              </div>
            </div>
          )}
          {children}
        </div>
      </div>

      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        theme={{
          root: {
            base: "fixed z-[60] overflow-y-auto bg-white dark:bg-darkLightBg p-4 transition-transform",
            backdrop: "fixed inset-0 z-50 bg-gray-900/50 dark:bg-gray-900/80",
            position: {
              left: {
                on: "left-0 top-0 h-screen w-80 transform-none",
                off: "left-0 top-0 h-screen w-80 -translate-x-full",
              },
            },
          },
          header: {
            inner: {
              closeButton:
                "absolute end-2.5 top-2.5 flex h-8 w-8 items-center justify-center rounded-lg bg-transparent text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
              closeIcon: "h-4 w-4",
              titleText:
                "mb-4 inline-flex items-center text-base font-medium !font-pro lg:text-lg",
            },
          },
        }}
        // className="lg:w-[350px]"
      >
        <Drawer.Header title="" titleIcon={() => <></>}></Drawer.Header>
        <Drawer.Items>
          <div className="h-full w-[300px] bg-white dark:bg-darkLightBg">
            <div className="overflow-y-auto scrollbar-thin h-full lg:pr-5 lg:scrollbar-track-[#f1f1f1] lg:scrollbar-thumb-[#888]">
              <div className={`flex items-center max-w-[150px] -mt-3 `}>
                <img src="/magiclogo.svg" alt="" className="w-[55px]" />
                <p
                  className={`text-[18px] -tracking-[3%] leading-[54px] dark:text-white text-[#434343]
                font-avertaBold text-center -mt-2`}
                >
                  magicreview
                </p>
              </div>
              <SideBar
                isSubuser={isSubuser?.is_sub_user}
                isCredit={isSubuser?.credit_based}
              />

              <div className=" mr-5 mt-5">
                <button
                  onClick={() => {
                    setDarkMode((prev) => !prev);
                    localStorage.setItem("darkMode", !darkMode);
                  }}
                  className="flex gap-x-2 items-center justify-center py-3 px-3 rounded-[7px] bg-lightpurple dark:bg-darkBg text-[#2a49b8] dark:text-white lg:hidden w-full font-pro text-sm"
                >
                  {darkMode ? (
                    <>
                      <p>Light mode</p>
                      <Sun className="" />
                    </>
                  ) : (
                    <>
                      <p>Dark mode</p>
                      <Moon className="" />
                    </>
                  )}
                </button>
              </div>

              {!isMaster && (
                <div className="mr-5 mt-5">
                  <button
                    className="flex lg:hidden justify-center items-center w-full rounded-[8px]  py-3 border border-newPrimary text-white bg-newPrimary hover:bg-white dark:hover:bg-darkLightBg hover:text-newPrimary font-pro text-sm gap-x-2 transition-all duration-200"
                    onClick={() => setIsOpenRequestReview(true)}
                  >
                    <p className="text-[14px] font-medium">Request Reviews</p>
                    <MailPlus className="w-5 h-5" />
                  </button>
                </div>
              )}

              {!isMaster && (
                <div className="relative text-sm lg:hidden text-white mr-5 mt-5 font-pro">
                  <input
                    type="text"
                    placeholder="MagicReview Link"
                    className="w-full rounded-[8px] bg-newPrimary  text-sm placeholder:text-sm pl-5 lg:pr-5 py-3 font-medium border border-newPrimary"
                    disabled
                    value={"Magic Link"}
                    readOnly
                  />
                  <QrCode
                    className="absolute top-1/2 -translate-y-1/2 right-9 cursor-pointer w-4 h-4"
                    onClick={() => setIsOpenQrContainer(true)}
                  />
                  <Copy
                    className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-4 h-4"
                    onClick={handleCopy}
                  />
                </div>
              )}

              {!isMaster && (
                <RemainingSubscription
                  isSubuser={isSubuser?.is_sub_user}
                  isCredit={isSubuser?.credit_based}
                />
              )}
            </div>

            {/* <div className="flex items-center justify-between mt-5 lg:mt-7 bg-[#F6F6FF] rounded-[10px] p-5 mr-5 lg:mr-0 w-full">
            <div className="flex items-center gap-x-2">
              <img
                className="h-8 w-8 rounded-full mr-[9px]"
                src={
                  userInfo?.profile?.profile_picture
                    ? userInfo?.profile?.profile_picture
                    : "/new-logo.svg"
                }
                alt=""
                referrerPolicy="no-referrer"
              />
              <div className="w-[120px] break-words">
                <p className="font-pro font-medium">{userInfo?.first_name}</p>
                <p className="font-pro text-xs text-[#888] ">
                  {userInfo?.email}
                </p>
              </div>
            </div>
            <LogOut
              onClick={logout}
              className="cursor-pointer text-[#3d3d3d]"
            />
          </div> */}
          </div>
        </Drawer.Items>
      </Drawer>

      <ContactDialog
        isOpenDialog={isOpenContactDialog}
        setIsOpenDialog={setIsOpenContactDialog}
        dialogMessage={contactMessage}
      />

      {isOpenQrContainer && (
        <QrContainer
          openEditModal={isOpenQrContainer}
          setOpenEditModal={setIsOpenQrContainer}
          qr={qr}
        />
      )}

      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
          tooltipTitle: {
            color: "#0000",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />

      <RequestReviewModal
        openModal={isOpenRequestReview}
        setOpenModal={setIsOpenRequestReview}
        link={baseUrl + "/" + magicLink}
      />
    </div>
  );
};

export default Layout;
