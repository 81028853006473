import { Input } from "../../../../components/ui/input";

import SelectMagicLinkComponent from "./SelectMagicLinkComponent";

/* eslint-disable react/prop-types */
const CampaignComponent = ({
  name,
  setName,
  setCsv,
  isEdit,
  magicLink,
  setMagicLink,
  openPopover,
  setOpenPopover,
}) => {
  return (
    <div className="space-y-5">
      <div className="space-y-2">
        <div className="flex items-center gap-x-1.5 h-6">
          <p className="font-pro font-medium dark:text-white">Name</p>
          {/* <Asterisk className="text-red-500 size-4" /> */}
        </div>

        <Input
          type="text"
          placeholder="Enter name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      {!isEdit && (
        <div className="space-y-2">
          <div className="flex items-center gap-x-1.5 h-6">
            <p className="font-pro font-medium dark:text-white">
              Upload .csv file
            </p>
          </div>

          <Input
            type="file"
            placeholder="Upload .csv file"
            accept="text/csv"
            className="py-0"
            onChange={(e) => setCsv(e.target.files[0])}
          />
        </div>
      )}
      <div className="space-y-2 w-full">
        <div className="flex items-center gap-x-1.5 h-6">
          <p className="font-pro font-medium dark:text-white">MagicLink</p>
          {/* <Asterisk className="text-red-500 size-4" /> */}
        </div>
        <SelectMagicLinkComponent
          magicLink={magicLink}
          setMagicLink={setMagicLink}
          openPopover={openPopover}
          setOpenPopover={setOpenPopover}
        />
      </div>
    </div>
  );
};

export default CampaignComponent;
