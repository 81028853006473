import { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { useGetGmbInfo } from "../../../query/useAuthentication";
import GmbIntegrationModal from "./components/GmbIntegrationModal";
import { Button } from "../../../components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Skeleton from "react-loading-skeleton";
import { dayFormatter } from "../../../utils/formatter";
import { Switch } from "../../../components/ui/switch";
import {
  ArrowUpDown,
  Info,
  Map,
  MapPin,
  MapPinned,
  Pencil,
  Search,
  Settings,
  Trash2,
  X,
} from "lucide-react";
import {
  useDeleteKeywords,
  useDeletePrimaryKeywords,
  useGetActiveKeywords,
  useGetActiveKeywordsInfinity,
  useGetInactiveKeywords,
  useGetInactiveKeywordsInfinity,
  useGetKeywords,
  useGetPrimaryKeywords,
  useGetSeoSettingCount,
  usePostAddAiKeywords,
  usePostAddPrimaryKeywords,
  usePutUpdateSeoSettingCount,
  useToggleKeywords,
} from "../../../query/useReview";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import AddPrimaryKeywordModal from "./components/AddPrimaryKeywordModal";
import { toast } from "sonner";
import { DeleteModal } from "../component/modal";
import UpdateSeoSettingCountModal from "./components/UpdateSeoSettingCountModal";
import IconButton from "../component/IconButton";
import TargetLocationModal from "./components/TargetLocationModal";
import Keywords from "./keywords";
import { Input } from "../../../components/ui/input";
import useDebounce from "../../../hooks/useDebounce";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from "../../../components/ui/select";
import { useDarkMode } from "../../../context/DarkModeProvider";
import SettingModal from "./components/SettingModal";
import { useInView } from "react-intersection-observer";
import LoaderIcon from "react-loader-icon";

const SeoKeywords = () => {
  const { darkMode } = useDarkMode();

  const [isGmbIntegrate, setIsGmbItegrate] = useState(true);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const [isAddKeywordModal, setIsAddKeywordModal] = useState(false);
  const [isOpenDeleteKeywordModal, setIsOpenDeleteKeywordModal] =
    useState(false);
  const [toDeleteKeyword, setToDeleteKeyword] = useState("");
  const [toggleKeywordId, setToggleKeywordId] = useState("");
  const [toDeleteAiKeyword, setToDeleteAiKeyword] = useState(0);
  const [isOpenDeleteAiKeywordModal, setIsOpenDeleteAiKeywordModal] =
    useState(false);
  const [isOpenSeoCountModal, setIsOpenSeoCountModal] = useState(false);
  const [isOpenTargetLocationModal, setIsOpenTargetLocationModal] =
    useState(false);
  const [primaryKeyword, setPrimaryKeyword] = useState("");

  const [tab, setTab] = useState("active");
  const [mainTab, setMainTab] = useState("ai");
  const [search, setSearch] = useState("");
  const [index, setIndex] = useState(0);
  const [filter, setFilter] = useState("null");
  const [sortBy, setSortBy] = useState("avg_monthly_search");
  const [asc, setAsc] = useState(false);
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  // const { data: gmbInfo } = useGetGmbInfo({
  //   onSuccess: (res) => {
  //     setIsGmbItegrate(res.GMB_INTEGRATION);
  //   },
  // });

  const { data: primaryKeywords, refetch: refetchPrimaryKeywords } =
    useGetPrimaryKeywords();
  const { mutate: addPrimaryKeywords, isLoading: addPrimaryKeywordsLoading } =
    usePostAddPrimaryKeywords();
  const {
    mutate: deletePrimaryKeywords,
    isLoading: deletePrimaryKeywordsLoading,
  } = useDeletePrimaryKeywords();

  // const {
  //   data: aiKeywords,
  //   refetch: refetchAiKeywords,
  //   isLoading: aiKeywordsLoading,
  // } = useGetKeywords();
  const { mutate: addAiKeywords, isLoading: addAiKeywordsLoading } =
    usePostAddAiKeywords();
  const { mutate: deleteAiKeywords, isLoading: deleteAiKeywordsLoading } =
    useDeleteKeywords();
  const { mutate: toggleKeyword, isLoading: toggleKeywordLoading } =
    useToggleKeywords();

  const debounceSearch = useDebounce(search, 500);

  // const [activeKeywords, setActiveKeywords] = useState([]);
  const [activeCounts, setActiveCounts] = useState(0);

  // const {
  //   data: activeKeywordsFromApi,
  //   isLoading: activeKeywordsLoading,
  //   refetch: refetchActive,
  // } = useGetActiveKeywords(
  //   {
  //     index: index,
  //     limit: 10,
  //     search: debounceSearch,
  //     sort_by: sortBy,
  //     filter_competition: filter === "null" ? "" : filter,
  //     asc: asc,
  //   },
  //   {
  //     onSuccess: (res) => {
  //       setActiveKeywords((prev) => [...prev, ...res.active_keywords]);
  //       setActiveCounts(res.active_counts);
  //     },
  //     enabled: tab === "active",
  //   }
  // );

  const { inView: activeInView, ref: activeRef } = useInView();

  const {
    data: activeKeywordsInfinity,
    isLoading: activeKeywordsLoading,
    refetch: refetchActive,
    hasNextPage: hasActiveNextPage,
    fetchNextPage: fetchActiveNextPage,
  } = useGetActiveKeywordsInfinity({
    // index: index,
    limit: 10,
    search: debounceSearch,
    sort_by: sortBy,
    filter_competition: filter === "null" ? "" : filter,
    asc: asc,
  });

  // console.log(activeKeywordsInfinity);

  const activeKeywords = activeKeywordsInfinity?.pages?.flatMap(
    (page) => page?.active_keywords
  );

  // console.log(activeKeywords);

  useEffect(() => {
    if (hasActiveNextPage && activeInView) {
      // setIndex((prev) => prev + 10);
      fetchActiveNextPage();
    }
  }, [activeInView]);

  // const [inActiveKeywords, setInActiveKeywords] = useState([]);
  const [inActiveCounts, setInActiveCounts] = useState(0);

  // const {
  //   data: inactiveKeywordsFromApi,
  //   isLoading: inactiveKeywordsLoading,
  //   refetch: refetchInactive,
  // } = useGetInactiveKeywords(
  //   {
  //     index: index,
  //     limit: 10,
  //     search: debounceSearch,
  //     sort_by: sortBy,
  //     filter_competition: filter === "null" ? "" : filter,
  //     asc: asc,
  //   },
  //   {
  //     onSuccess: (res) => {
  //       setInActiveKeywords((prev) => [...prev, ...res.inactive_keywords]);
  //       setInActiveCounts(res.inactive_counts);
  //     },
  //     enabled: tab === "used",
  //   }
  // );

  const { inView: inactiveInView, ref: inactiveRef } = useInView();

  const {
    data: inactiveKeywordsInfinity,
    isLoading: inactiveKeywordsLoading,
    refetch: refetchInactive,
    hasNextPage: hasInactiveNextPage,
    fetchNextPage: fetchInctiveNextPage,
  } = useGetInactiveKeywordsInfinity({
    // index: index,
    limit: 10,
    search: debounceSearch,
    sort_by: sortBy,
    filter_competition: filter === "null" ? "" : filter,
    asc: asc,
  });

  const inActiveKeywords = inactiveKeywordsInfinity?.pages?.flatMap(
    (page) => page?.inactive_keywords
  );

  useEffect(() => {
    if (hasInactiveNextPage && inactiveInView) {
      fetchInctiveNextPage();
    }
  }, [inactiveInView]);

  // const aiKeywords = tab === "active" ? activeKeywords : [];

  const { data: seoSettingCount, refetch: refetchSeoSettingCount } =
    useGetSeoSettingCount();
  const {
    mutate: updateSeoSettingCount,
    isLoading: updateSeoSettingCountLoading,
  } = usePutUpdateSeoSettingCount();

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const handleAddPrimaryKeywords = () => {
    addPrimaryKeywords(
      { primary_keyword: primaryKeyword },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          // setIsAddKeywordModal(false);
          setPrimaryKeyword("");
          refetchPrimaryKeywords();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleDeleteKeyword = () => {
    deletePrimaryKeywords(
      {
        primary_keyword: toDeleteKeyword,
      },
      {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("Primary keyword has been deleted");
          }
          refetchPrimaryKeywords();
          setToDeleteKeyword("");
          setIsOpenDeleteKeywordModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleAddAiKeywords = () => {
    addAiKeywords(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchActive();
          // refetchAiKeywords();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleDeleteAiKeywords = () => {
    deleteAiKeywords(toDeleteAiKeyword, {
      onSuccess: (res) => {
        if (res.message) {
          toast.success(res.message);
        }
        // refetchAiKeywords();
        refetchActive();
        refetchInactive();
        setToDeleteAiKeyword(0);
        setIsOpenDeleteAiKeywordModal(false);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleToggleKeyword = (id) => {
    setToggleKeywordId(id);
    toggleKeyword(id, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetchActive();
        refetchInactive();
        // if (tab === "active") {
        //   setActiveKeywords((prev) => prev?.filter((item) => item?.id !== id));
        // } else {
        //   setInActiveKeywords((prev) =>
        //     prev?.filter((item) => item?.id !== id)
        //   );
        // }

        // refetchAiKeywords();
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error("Something went wrong. Please try again later.");
        }
      },
    });
  };

  const handleUpdateSeoSettingCount = (count) => {
    updateSeoSettingCount(
      {
        count: Number(count),
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchSeoSettingCount();
          setIsOpenSettings(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  // useEffect(() => {
  //   if (!isGmbIntegrate) {
  //     setIsOpenLinkModal(true);
  //   } else {
  //     setIsOpenLinkModal(false);
  //   }
  // }, [isGmbIntegrate]);

  if (addPrimaryKeywordsLoading || updateSeoSettingCountLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"SEO Agent"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px] font-pro">
        {/* {gmbInfo?.GMB_INTEGRATION && ( */}
        <>
          <div className="font-pro font-medium text-sm  gap-3 flex items-center mb-5">
            <button
              className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkBg ${mainTab === "ai" && "border-newPrimary text-newPrimary"}`}
              onClick={() => setMainTab("ai")}
            >
              AI-Assist
            </button>
            <button
              className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkBg ${mainTab === "manual" && " border-newPrimary text-newPrimary"}`}
              onClick={() => setMainTab("manual")}
            >
              Manual
            </button>
          </div>
          {mainTab === "ai" && (
            <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
              <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:col-span-2">
                <div className="font-pro text-lg font-semibold bg-[#fafafa] dark:bg-darkBg px-6 py-3 h-16 border-b border-b-[#EBEBEB] flex items-center gap-3">
                  <Info className="text-newPrimary" />
                  <h1>How SEO Agent works</h1>
                </div>
                <div className="px-6 pb-6 mt-5 text-sm text-[#3d3d3d] dark:text-white">
                  <p>SEO Agent enhances your online presence by:</p>

                  <ul className="list-inside list-disc mt-2">
                    <li>Targeting search volume and high-demand keywords.</li>
                    <li>Focuses on easy-to-rank keywords.</li>
                    <li>Aligning content with your business offerings.</li>
                    <li>
                      Generating keyword-rich reviews for better visibility.
                    </li>
                    <li>Boosting local SEO with location-specific terms.</li>
                  </ul>
                </div>
              </div>

              <div className="w-full  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:col-span-3">
                <div className="font-pro text-lg font-semibold bg-[#fafafa] dark:bg-darkBg px-6 py-3 lg:h-16 border-b border-b-[#EBEBEB] flex items-center justify-between gap-5 flex-wrap lg:flex-nowrap">
                  <h1 className="flex-shrink-0">Primary Keywords</h1>
                  {/* <div className="flex items-center gap-5 flex-shrink-0"> */}
                  <Input
                    value={primaryKeyword}
                    onChange={(e) => setPrimaryKeyword(e.target.value)}
                    className="font-normal"
                  />
                  <Button
                    className="text-sm"
                    onClick={handleAddPrimaryKeywords}
                  >
                    Add Keyword
                  </Button>
                  {/* </div> */}
                </div>

                <div className="px-6 pb-6 mt-3 text-sm text-[#3d3d3d] dark:text-white h-[140px] overflow-y-auto scrollbar-thin flex items-start gap-3 flex-wrap">
                  {primaryKeywords?.primary_keywords?.map((item) => (
                    <div
                      className=" border border-[rgba(107,141,208,0.41)] rounded-[5px] py-2 px-2 w-fit text-secondary flex items-center gap-x-2 text-[13px] font-normal"
                      key={item}
                    >
                      <p>{item}</p>
                      <X
                        className="size-4 cursor-pointer"
                        onClick={() => {
                          setIsOpenDeleteKeywordModal(true);
                          setToDeleteKeyword(item);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className="w-full  rounded-[12px] h-full shadow-sm flex flex-col overflow-hidden lg:col-span-5">
                <div className="font-pro text-lg font-semibold py-3 px-5 flex items-center justify-between flex-wrap gap-5 bg-[#fafafa] dark:bg-darkBg">
                  <div className="flex gap-3 items-center">
                    <button
                      className={`h-9 text-sm font-medium text-center py-1.5 px-3 flex items-center gap-2.5 border rounded-md bg-white dark:bg-darkLightBg ${tab === "active" ? " text-newPrimary border-newPrimary" : "text-[#4d4d4d]"}`}
                      onClick={() => {
                        setTab("active");
                        setIndex(0);
                        // setActiveKeywords([]);
                      }}
                    >
                      <h1 className="flex items-center gap-2">
                        {" "}
                        Active Keywords{" "}
                        <span className="block size-[9px] bg-[#34A853] rounded-full flex-shrink-0 "></span>{" "}
                        <span className="font-bold">
                          {activeKeywordsInfinity?.pages?.[0]?.active_counts}
                        </span>
                      </h1>
                    </button>
                    <button
                      className={`h-9 text-sm font-medium text-center py-1.5 px-3 flex items-center gap-2.5 border rounded-md bg-white dark:bg-darkLightBg  ${tab === "used" ? " text-newPrimary border-newPrimary" : "text-[#4d4d4d]"}`}
                      onClick={() => {
                        setTab("used");
                        setIndex(0);
                        // setInActiveKeywords([]);
                      }}
                    >
                      <h1 className="flex items-center gap-2">
                        {" "}
                        Inactive Keywords{" "}
                        <span className="block size-[9px] bg-[#ffaf19] rounded-full flex-shrink-0 "></span>{" "}
                        <span className="font-bold">
                          {
                            inactiveKeywordsInfinity?.pages?.[0]
                              ?.inactive_counts
                          }
                        </span>
                      </h1>
                    </button>
                  </div>

                  <div className="flex items-center gap-3">
                    <div className="relative">
                      <Input
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                          setIndex(0);
                          // setActiveKeywords([]);
                        }}
                        placeholder="Search"
                        className="pl-10 font-pro border-newPrimary bg-transparent text-newPrimary py-1.5 h-9 placeholder:text-newPrimary"
                      />
                      <Search className="text-newPrimary absolute top-1/2 -translate-y-1/2 left-3" />
                    </div>
                    <Button
                      variant="outline"
                      className="text-sm w-9 h-9"
                      size="icon"
                      onClick={() => setIsOpenSettings(true)}
                    >
                      <Settings className="size-5" />
                    </Button>
                    {/* <Button
                        variant="outline"
                        className="text-sm"
                        size="icon"
                        onClick={() => setIsOpenTargetLocationModal(true)}
                      >
                        <MapPin />
                      </Button> */}
                    <Button
                      className="text-sm w-[120px] h-9"
                      onClick={handleAddAiKeywords}
                      disabled={addAiKeywordsLoading}
                    >
                      {addAiKeywordsLoading ? (
                        <LoadingButton />
                      ) : (
                        "Get Keywords"
                      )}
                    </Button>
                    {/* <div className="flex items-center gap-3 text-sm bg-white dark:bg-darkLightBg px-3 py-3 h-10 rounded-md font-medium">
                        <p>
                          Seo Setting Count -{" "}
                          <span className="font-bold">
                            {seoSettingCount?.count}
                          </span>
                        </p>

                        <Pencil
                          className="size-4 text-newPrimary cursor-pointer"
                          onClick={() => setIsOpenSeoCountModal(true)}
                        />
                      </div> */}
                  </div>
                </div>

                <div className="h-[40vh] overflow-y-auto scrollbar-thin rounded-b-[12px] bg-white dark:bg-darkBg">
                  {tab === "active" ? (
                    <Table
                      key={"active"}
                      className="relative"
                      divClassName={"h-full overflow-y-scroll"}
                    >
                      <TableHeader className="sticky top-0 z-10">
                        <TableRow className="">
                          <TableHead className="text-xs text-[#888] font-medium lg:w-[250px]">
                            Keyword
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            <Button
                              className="gap-3 flex items-center text-[#888] dark:text-white w-full  px-1.5 h-10"
                              variant="ghost"
                              onClick={() => {
                                setSortBy("avg_monthly_search");
                                setAsc(!asc);
                              }}
                            >
                              Avg. monthly searches
                              <ArrowUpDown className="size-4" />
                            </Button>
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium lg:w-[140px]">
                            <Select
                              value={filter}
                              onValueChange={(e) => {
                                setFilter(e);
                              }}
                            >
                              <SelectTrigger className="text-xs text-[#888] font-medium border-none hover:bg-accent dark:text-white dark:hover:bg-accent px-1.5">
                                Competition
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value={"null"}>None</SelectItem>
                                {activeKeywordsInfinity?.pages?.[0]?.distinct_competitions?.map(
                                  (item) => (
                                    <SelectItem value={item} key={item}>
                                      {item}
                                    </SelectItem>
                                  )
                                )}
                              </SelectContent>
                            </Select>
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            <Button
                              className="gap-3 flex items-center text-[#888] dark:text-white w-full  px-1.5 h-10"
                              variant="ghost"
                              onClick={() => {
                                setSortBy("competition_index");
                                setAsc(!asc);
                              }}
                            >
                              Competition (indexed value)
                              <ArrowUpDown className="size-4" />
                            </Button>
                          </TableHead>

                          <TableHead className="text-xs text-[#888] font-medium">
                            Active
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            Action
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody className="divide-y">
                        <>
                          {activeKeywordsLoading ? (
                            <>
                              <TableRow>
                                {new Array(6).fill("").map((_, i) => (
                                  <TableCell key={i}>
                                    <Skeleton
                                      height={30}
                                      baseColor={darkMode && "#262626"}
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                {new Array(6).fill("").map((_, i) => (
                                  <TableCell key={i}>
                                    <Skeleton
                                      height={30}
                                      baseColor={darkMode && "#262626"}
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                              <TableRow>
                                {new Array(6).fill("").map((_, i) => (
                                  <TableCell key={i}>
                                    <Skeleton
                                      height={30}
                                      baseColor={darkMode && "#262626"}
                                    />
                                  </TableCell>
                                ))}
                              </TableRow>
                            </>
                          ) : (
                            <>
                              {activeKeywords?.map((item) => (
                                <TableRow
                                  className="text-[15px]"
                                  key={item?.id}
                                >
                                  <TableCell className="">
                                    {item?.keyword}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {item?.avg_monthly_search}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {item?.competition}
                                  </TableCell>
                                  <TableCell className="text-center">
                                    {item?.competition_index}
                                  </TableCell>

                                  <TableCell>
                                    <Switch
                                      className="data-[state=checked]:bg-newPrimary"
                                      checked={item?.is_active}
                                      onCheckedChange={() =>
                                        handleToggleKeyword(item?.id)
                                      }
                                      disabled={
                                        toggleKeywordLoading &&
                                        item?.id === toggleKeywordId
                                      }
                                    />
                                  </TableCell>
                                  <TableCell>
                                    <Trash2
                                      className="text-red-500 cursor-pointer"
                                      onClick={() => {
                                        setIsOpenDeleteAiKeywordModal(true);
                                        setToDeleteAiKeyword(item?.id);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                          {hasActiveNextPage && (
                            <TableRow ref={activeRef}>
                              <TableCell colSpan={8}>
                                <LoaderIcon type="cylon" color="#7190FF" />
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      </TableBody>
                    </Table>
                  ) : (
                    <Table key={"inactive"} className="relative">
                      <TableHeader className="sticky top-0 z-10">
                        <TableRow className="">
                          <TableHead className="text-xs text-[#888] font-medium lg:w-[250px]">
                            Keyword
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            <Button
                              className="gap-3 flex items-center text-[#888] dark:text-white w-full  px-1.5 h-10"
                              variant="ghost"
                              onClick={() => {
                                setSortBy("avg_monthly_search");
                                setAsc(!asc);

                                // setIndex(0);
                              }}
                            >
                              Avg. monthly searches
                              <ArrowUpDown className="size-4" />
                            </Button>
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium lg:w-[140px]">
                            <Select
                              value={filter}
                              onValueChange={(e) => {
                                setFilter(e);

                                // setIndex(0);
                              }}
                            >
                              <SelectTrigger className="text-xs text-[#888] font-medium border-none hover:bg-accent dark:text-white dark:hover:bg-accent px-1.5">
                                Competition
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value={"null"}>None</SelectItem>
                                {inactiveKeywordsInfinity?.pages?.[0]?.distinct_competitions?.map(
                                  (item) => (
                                    <SelectItem value={item} key={item}>
                                      {item}
                                    </SelectItem>
                                  )
                                )}
                              </SelectContent>
                            </Select>
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            <Button
                              className="gap-3 flex items-center text-[#888] dark:text-white w-full  px-1.5 h-10"
                              variant="ghost"
                              onClick={() => {
                                setSortBy("competition_index");
                                setAsc(!asc);

                                // setIndex(0);
                              }}
                            >
                              Competition (indexed value)
                              <ArrowUpDown className="size-4" />
                            </Button>
                          </TableHead>
                          {/* <TableHead className="text-xs text-[#888] font-medium">
                              Top of page (Low Range)
                            </TableHead>
                            <TableHead className="text-xs text-[#888] font-medium">
                              Top of page (High Range)
                            </TableHead> */}
                          <TableHead className="text-xs text-[#888] font-medium">
                            Active
                          </TableHead>
                          <TableHead className="text-xs text-[#888] font-medium">
                            Action
                          </TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody className="divide-y">
                        {inactiveKeywordsLoading ? (
                          <>
                            <TableRow>
                              {new Array(6).fill("").map((_, i) => (
                                <TableCell key={i}>
                                  <Skeleton
                                    height={30}
                                    baseColor={darkMode && "#262626"}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              {new Array(6).fill("").map((_, i) => (
                                <TableCell key={i}>
                                  <Skeleton
                                    height={30}
                                    baseColor={darkMode && "#262626"}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                            <TableRow>
                              {new Array(6).fill("").map((_, i) => (
                                <TableCell key={i}>
                                  <Skeleton
                                    height={30}
                                    baseColor={darkMode && "#262626"}
                                  />
                                </TableCell>
                              ))}
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {inActiveKeywords?.map((item) => (
                              <TableRow className="text-[15px]" key={item?.id}>
                                <TableCell>{item?.keyword}</TableCell>
                                <TableCell className="text-center">
                                  {item?.avg_monthly_search}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item?.competition}
                                </TableCell>
                                <TableCell className="text-center">
                                  {item?.competition_index}
                                </TableCell>
                                {/* <TableCell>
                                    {item?.low_range?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: item?.currency || "USD",
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {item?.high_range?.toLocaleString("en-US", {
                                      style: "currency",
                                      currency: item?.currency || "USD",
                                    })}
                                  </TableCell> */}
                                <TableCell>
                                  <Switch
                                    className="data-[state=checked]:bg-newPrimary"
                                    checked={item?.is_active}
                                    onCheckedChange={() =>
                                      handleToggleKeyword(item?.id)
                                    }
                                    disabled={
                                      toggleKeywordLoading &&
                                      item?.id === toggleKeywordId
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <Trash2
                                    className="text-red-500 cursor-pointer size-5"
                                    onClick={() => {
                                      setIsOpenDeleteAiKeywordModal(true);
                                      setToDeleteAiKeyword(item?.id);
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                            {hasInactiveNextPage && (
                              <TableRow ref={inactiveRef}>
                                <TableCell colSpan={8}>
                                  <LoaderIcon type="cylon" color="#7190FF" />
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  )}
                </div>
              </div>
              {/* 
                {tab === "active" ? (
                  <>
                    {" "}
                    {activeKeywords?.active_counts > 10 && (
                      <div className="flex justify-between items-center mt-5 w-full lg:col-span-5">
                        {index <= 0 ? (
                          <div></div>
                        ) : (
                          <Button
                            className="disabled:hidden"
                            onClick={handlePrev}
                            disabled={index <= 0}
                          >
                            Prev
                          </Button>
                        )}
                        <Button
                          className=" disabled:hidden"
                          onClick={handleNext}
                          disabled={index + 10 > activeKeywords?.active_counts}
                        >
                          Next
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    {inactiveKeywords?.inactive_count > 10 && (
                      <div className="flex justify-between items-center mt-5 lg:col-span-5">
                        {index <= 0 ? (
                          <div></div>
                        ) : (
                          <Button
                            className="disabled:hidden"
                            onClick={handlePrev}
                            disabled={index <= 0}
                          >
                            Prev
                          </Button>
                        )}
                        <Button
                          className=" disabled:hidden"
                          onClick={handleNext}
                          disabled={
                            index + 10 > inactiveKeywords?.inactive_count
                          }
                        >
                          Next
                        </Button>
                      </div>
                    )}
                  </>
                )} */}

              {/* <div className="w-full max-w-4xl  rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm flex flex-col overflow-hidden lg:col-span-3">
                <div className="font-pro text-lg font-semibold bg-[#fafafa] dark:bg-darkBg px-6 py-3 flex items-center justify-between">
                  <h1 className="flex items-center gap-2">
                    {" "}
                    Used Keywords{" "}
                    <span className="block size-3 bg-newPrimary rounded-full flex-shrink-0 "></span>{" "}
                    35
                  </h1>
                  <div className="flex items-center ">
                    
                  </div>
                </div>

                <Table>
                  <TableHeader>
                    <TableRow className="bg-[#fafafa] dark:bg-darkBg">
                      {["Keyword", "Volume", "Active", "Action"].map((item) => (
                        <TableHead
                          key={item}
                          className="text-xs text-[#888] font-medium"
                        >
                          {item}
                        </TableHead>
                      ))}
                    </TableRow>
                  </TableHeader>
                  <TableBody className="divide-y">
                    <TableRow className="text-[15px]">
                      <TableCell>Keyword 1</TableCell>
                      <TableCell>500</TableCell>

                      <TableCell>
                        <Switch className="data-[state=checked]:bg-newPrimary" />
                      </TableCell>
                      <TableCell>
                        <Trash2 className="text-red-500" />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div> */}
            </div>
          )}
          {mainTab === "manual" && <Keywords />}
        </>
        {/* )}
        <GmbIntegrationModal
          openModal={isOpenLinkModal}
          setOpenModal={setIsOpenLinkModal}
        /> */}
        <AddPrimaryKeywordModal
          openModal={isAddKeywordModal}
          setOpenModal={setIsAddKeywordModal}
          handleAction={handleAddPrimaryKeywords}
        />

        <UpdateSeoSettingCountModal
          openModal={isOpenSeoCountModal}
          setOpenModal={setIsOpenSeoCountModal}
          handleAction={handleUpdateSeoSettingCount}
          settingCount={seoSettingCount?.count}
        />

        <SettingModal
          openModal={isOpenSettings}
          setOpenModal={setIsOpenSettings}
          handleAction={handleUpdateSeoSettingCount}
          settingCount={seoSettingCount?.count}
        />

        <TargetLocationModal
          openModal={isOpenTargetLocationModal}
          setOpenModal={setIsOpenTargetLocationModal}
        />

        <DeleteModal
          openModal={isOpenDeleteKeywordModal}
          setOpenModal={setIsOpenDeleteKeywordModal}
          title={`Are you sure you want to delete the primary keyword "${toDeleteKeyword}"?`}
          isLoading={deletePrimaryKeywordsLoading}
          handleDelete={handleDeleteKeyword}
        />

        <DeleteModal
          openModal={isOpenDeleteAiKeywordModal}
          setOpenModal={setIsOpenDeleteAiKeywordModal}
          title={`Are you sure you want to delete the keyword?`}
          isLoading={deleteAiKeywordsLoading}
          handleDelete={handleDeleteAiKeywords}
        />
      </div>
    </Layout>
  );
};

export default SeoKeywords;
