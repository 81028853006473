import { useEffect, useState } from "react";
import { toast } from "sonner";

import {
  useGetEntityReviewPermissions,
  useGetReviewPlatforms,
  usePostReviewQuestions,
  useReviewCopied,
} from "../../query/useReview";
import style from "./note.module.scss";
import {
  ArrowRight,
  Check,
  ChevronRight,
  CircleCheck,
  List,
  Logs,
  RefreshCcw,
  RotateCw,
  X,
} from "lucide-react";
import MainLogo from "../../components/mainlogo";
import Google from "../../assets/reviewservices/google.svg";
import TrustPilot from "../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../assets/reviewservices/yelp.svg";
import Booking from "../../assets/reviewservices/booking.svg";
import Airbnb from "../../assets/reviewservices/airbnb.svg";
import TripAdvisor from "../../assets/reviewservices/tripadvisor.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import CopyModal from "./components/CopyModal";
import CopyAndPostModal from "./components/CopyAndPostModal";
import { Button } from "../../components/ui/button";
import { useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../context/authContext";
import { useDarkMode } from "../../context/DarkModeProvider";

const Note = () => {
  const queryClient = useQueryClient();
  const [notes, setNotes] = useState([]);
  const [response, setResponse] = useState(null);
  const [reviewId, setReviewId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const [bgColor, setBgColor] = useState("#4F75FA");
  const [buttonColor, setButtonColor] = useState("#4F75FA");
  const [entityLogo, setEntityLogo] = useState("");
  const [entityName, setEntityName] = useState("");
  const [directToReview, setDirectToReview] = useState(false);
  const [step, setStep] = useState(1);
  const [isOfferPage, setIsOfferPage] = useState(false);

  const { mutate: regenerateReviews } = usePostReviewQuestions({
    refetchOnMount: false,
  });
  const { mutate: copyReview, isLoading: copyReviewLoading } =
    useReviewCopied();

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const employeeId = params.get("employee");
  const voiceReview = params.get("voicereview");
  const staffName =
    localStorage.getItem("staffName") && localStorage.getItem("staffName");

  const {
    data: reviewPlatforms,
    isLoading: reviewPlatformsLoading,
    isFetching: reviewPlatformsFetching,
  } = useGetReviewPlatforms(
    {
      entityId: reviewId,
      employeeId: employeeId,
    },
    {
      enabled: Boolean(reviewId),
      initialData: queryClient.getQueryData(["getReviewPlatforms"]),
      refetchOnWindowFocus: false,
    }
  );

  const {
    isLoading: reviewPermissionLoading,
    isFetching: reviewPermissionFetching,
  } = useGetEntityReviewPermissions(
    {
      entityId: reviewId,
      employeeId: employeeId,
    },
    {
      onSuccess: (res) => {
        setBgColor(res?.bg_color);
        setButtonColor(res?.button_color);
        setDirectToReview(res?.direct_to_review);
        setEntityName(res?.entity_name);
        setEntityLogo(res?.logo);
        setIsOfferPage(res?.offer);
      },
      enabled: Boolean(reviewId),
      initialData: queryClient.getQueryData(["getEntityReviewPermissions"]),
      refetchOnWindowFocus: false,
    }
  );

  const isClicked =
    sessionStorage.getItem("isClicked") && sessionStorage.getItem("isClicked");

  useEffect(() => {
    setNotes(JSON.parse(localStorage.getItem("reviews")));
    setReviewId(localStorage.getItem("reviewId"));
    setResponse(JSON.parse(localStorage.getItem("response")));
  }, []);

  const handleGoToLink = ({ reviewUrl }) => {
    sessionStorage.setItem("isClicked", "true");
    window.location.href = reviewUrl;
  };

  const handlePostFeedback = ({ id, review, reviewUrl, redirected }) => {
    sessionStorage.setItem("isClicked", "true");
    navigator.clipboard.writeText(review);
    copyReview(
      {
        business_uuid: reviewId,
        review_uuid: id,
        redirected: redirected,
      },
      {
        onSuccess: (res) => {
          toast.custom((t) => (
            <div className="bg-emerald-600 text-white px-6 py-4 rounded-lg shadow-lg flex items-start gap-3 min-w-[300px] font-pro">
              <div className="bg-white size-6 rounded-full flex justify-center items-center">
                <Check className="size-4 flex-shrink-0 text-black" />
              </div>

              <div className="flex-1">
                <h3 className="font-medium mb-1">Review copied</h3>
                <p className="text-sm text-white/90">
                  The review has been copied, please paste and edit on{" "}
                  {Object.keys(reviewPlatforms?.platform)?.[0]}.
                </p>
              </div>
              <button
                onClick={() => toast.dismiss(t)}
                className="text-white/80 hover:text-white mt-0.5"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
          ));
          if (reviewUrl) {
            // if (directToReview) {
            //   window.open(reviewUrl, "_blank");
            //   setStep(2);
            // } else {
            if (isOfferPage) {
              window.open(reviewUrl);
              navigate(
                `/offer?id=${reviewId}&employeeId=${employeeId}&reviewId=${id}`
              );
            } else {
              if (directToReview) {
                window.open(reviewUrl, "_blank");
                navigate(`/offer?id=${reviewId}&employeeId=${employeeId}`);
              } else {
                window.location.href = reviewUrl;
              }
            }

            // }
          }
        },
        onError: (err) => {
          setStep(1);
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        },
      }
    );
  };

  const handleRegenerate = ({ isDetailed }) => {
    setStep(1);
    sessionStorage.setItem("isClicked", "false");
    setLoading(true);
    setResponse(JSON.parse(localStorage.getItem("response")));
    regenerateReviews(
      {
        data: {
          business_uuid: reviewId,
          response: response,
          employee_code:
            employeeId !== "null" && employeeId && staffName !== "undefined"
              ? employeeId
              : null,
          staff_name: staffName !== "undefined" && staffName ? staffName : null,
        },
        isDetailed: isDetailed,
      },
      {
        onSuccess: (data) => {
          setLoading(false);
          setNotes(data);
        },
        onError: (err) => {
          setLoading(false);
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (directToReview && reviewPlatforms?.platform) {
      // setStep(2);
      handlePostFeedback({
        id: notes?.[0]?.uuid,
        review: notes?.[0]?.review,
        reviewUrl:
          reviewPlatforms?.platform?.[
            Object.keys(reviewPlatforms?.platform)?.[0]
          ],
        redirected: Object.keys(reviewPlatforms?.platform)?.[0],
      });
    }
  }, [directToReview, notes, reviewPlatforms]);

  return (
    <div className="w-full text-black min-h-screen font-heebo bg-white">
      <div
        className={`relative max-w-screen-2xl mx-auto h-auto py-10 px-5 lg:p-20 space-y-5`}
      >
        <div className="flex items-center gap-x-2 flex-shrink-0 ">
          <img
            src={"/new-logo.svg"}
            alt="MagicReview Logo"
            className="w-[30px] flex-shrink-0"
          />
          <h1 className="font-avertaBold font-bold text-[18px] -tracking-[1%] text-[#434343]">
            magicreview
          </h1>
        </div>

        {loading ||
        reviewPermissionLoading ||
        reviewPlatformsLoading ||
        copyReviewLoading ||
        reviewPermissionFetching ||
        reviewPlatformsFetching ? (
          <div className="w-full h-[calc(100vh-340px)] flex flex-col justify-center items-center gap-5">
            <img
              loading="lazy"
              src="/mr-logo.gif"
              alt="Loading Logo"
              className="w-20 h-20"
            />
            <p className="animate-pulse">
              Hold on while we get your reviews ...
            </p>
          </div>
        ) : (
          <>
            {isClicked !== "true" && (
              <>
                {!voiceReview && (
                  <div className="grid grid-cols-2 gap-2 mb-5 ">
                    <button
                      className="group gap-3 text-sm rounded-md h-10 flex items-center justify-center"
                      onClick={() => handleRegenerate({ isDetailed: false })}
                      style={{
                        backgroundColor: buttonColor,
                        color: "white",
                      }}
                    >
                      Regenerate reviews
                    </button>
                    <button
                      className="group gap-3 text-sm rounded-md  h-10 flex items-center justify-center"
                      style={{
                        backgroundColor: buttonColor,
                        color: "white",
                      }}
                      onClick={() => handleRegenerate({ isDetailed: true })}
                    >
                      Get detailed reviews
                    </button>
                  </div>
                )}
              </>
            )}
            {step === 2 ? (
              <div className="mt-10 lg:mt-20 flex flex-col justify-center items-center dark:bg-white h-[70svh] ">
                <div
                  className={`w-[90%] md:w-[60%] rounded-3xl relative font-heebo p-5 border border-[#7190ff]/15 bg-white shadow-[0_4px_4px_0_rgba(0,0,0,0.25)]`}
                >
                  {entityLogo && (
                    <div className="flex items-center gap-3 ">
                      <img
                        src={entityLogo}
                        alt=""
                        className="rounded-full size-10 object-cover"
                      />
                      <p
                        className="font-semibold text-[15px] px-5 h-9 rounded-full flex items-center justify-center"
                        style={{
                          backgroundColor: buttonColor,
                          color: "white",
                        }}
                      >
                        {entityName}
                      </p>
                    </div>
                  )}

                  <img
                    src="https://magicreviewstorage.blob.core.windows.net/assets/thank_you.png"
                    alt=""
                    className="w-full lg:w-[250px] mx-auto"
                    referrerPolicy="no-referrer"
                    // crossOrigin={isDownloading && "anonymous"}
                  />
                  <p className="font-heebo text-[10px] text-[#4d4d4d] text-center w-[70%] mx-auto">
                    Your input helps us continually enhance your experience and
                    serve you better
                  </p>
                </div>
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 justify-center mb-10">
                {notes
                  ?.sort((a, b) => a.review.length - b.review.length)
                  ?.map((note, id) => {
                    return (
                      <>
                        <div
                          className={`${style.comment} flex flex-col justify-between bg-white rounded-xl p-5 border w-full`}
                          key={note?.review}
                        >
                          <p className="select-none break-words whitespace-pre-wrap w-full">
                            {note?.review}
                          </p>
                          <div className="flex flex-wrap gap-3 justify-end mt-3">
                            {isClicked !== "true" && (
                              <>
                                {reviewPlatforms?.platform?.google && (
                                  <Button
                                    className="gap-2"
                                    variant={"outline"}
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform?.google,
                                        redirected: "google",
                                      })
                                    }
                                  >
                                    <img src={Google} alt="" />
                                    Copy & Post
                                  </Button>
                                )}
                                {reviewPlatforms?.platform?.trustpilot && (
                                  <Button
                                    className="gap-2"
                                    variant="outline"
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform?.trustpilot,
                                        redirected: "trustpilot",
                                      })
                                    }
                                  >
                                    <img src={TrustPilot} alt="" />
                                    Copy & Post
                                  </Button>
                                )}
                                {reviewPlatforms?.platform?.yelp && (
                                  <Button
                                    className="gap-2"
                                    variant="outline"
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform?.yelp,
                                        redirected: "yelp",
                                      })
                                    }
                                  >
                                    <img src={Yelp} alt="" />
                                    Copy & Post
                                  </Button>
                                )}
                                {reviewPlatforms?.platform?.booking && (
                                  <Button
                                    className="gap-2"
                                    variant="outline"
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform?.booking,
                                        redirected: "booking",
                                      })
                                    }
                                  >
                                    <img src={Booking} alt="" />
                                    Copy & Post
                                  </Button>
                                )}
                                {reviewPlatforms?.platform?.airbnb && (
                                  <Button
                                    className="gap-2"
                                    variant="outline"
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform?.airbnb,
                                        redirected: "airbnb",
                                      })
                                    }
                                  >
                                    <img src={Airbnb} alt="" className="w-6 " />
                                    Copy & Post
                                  </Button>
                                )}
                                {reviewPlatforms?.platform?.tripadvisor && (
                                  <Button
                                    className="gap-2"
                                    variant="outline"
                                    onClick={() =>
                                      handlePostFeedback({
                                        id: note?.uuid,
                                        review: note?.review,
                                        reviewUrl:
                                          reviewPlatforms?.platform
                                            ?.tripadvisor,
                                        redirected: "tripadvisor",
                                      })
                                    }
                                  >
                                    <img
                                      src={TripAdvisor}
                                      alt=""
                                      className="w-6 "
                                    />
                                    Copy & Post
                                  </Button>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}

            {isClicked !== "true" && (
              <>
                {reviewPlatforms?.platform?.google && (
                  <div className="flex justify-center mb-5">
                    <Button
                      variant="link"
                      className="text-newPrimary gap-3"
                      onClick={() => {
                        handleGoToLink({
                          reviewUrl: reviewPlatforms?.platform?.google,
                        });
                      }}
                    >
                      Google Review Page <ArrowRight className="size-4" />
                    </Button>
                  </div>
                )}
              </>
            )}
          </>
        )}

        {isOpenCopyModal && (
          <CopyModal
            isOpenModal={isOpenCopyModal}
            setIsOpenmodal={setIsOpenCopyModal}
          />
        )}
      </div>
    </div>
  );
};

export default Note;
