/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Bot,
  ChartColumn,
  ChevronDown,
  CirclePlay,
  Command,
  CreditCard,
  Gem,
  LayoutGrid,
  LinkIcon,
  Lock,
  Mails,
  MessageSquareText,
  Settings,
  Sparkles,
  UsersRound,
  WalletCards,
} from "lucide-react";
import { useGetGmbInfo } from "../../../query/useAuthentication";
import Google from "../../../assets/reviewservices/google.svg";
import IconLock from "../../../assets/icons/IconLock";

export const LinkComponent = ({ link, icon, title, className }) => {
  const location = window.location.pathname;
  return (
    <Link
      to={link}
      className={`flex gap-[8px] items-center py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === link || location === link + "/create" ? "text-newPrimary dark:text-newPrimary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"} ${className}`}
    >
      <>{icon}</>
      <p className="text-sm">{title}</p>
    </Link>
  );
};

const SideBar = ({ isOpenDrawer, isSubuser, isCredit }) => {
  const [params] = useSearchParams();
  const savedCard = params.get("savedCard");
  const invoice = params.get("invoice");

  const location = window.location.pathname;
  const isMaster = location.includes("organisation");
  const [isOpenSubscription, setIsOpenSubscription] = useState(
    location.includes("payment")
  );
  const [isOpenSetting, setIsOpenSetting] = useState(
    location.includes("settings")
  );

  const [isOpenReview, setIsOpenReview] = useState(
    location.includes("reviews")
  );

  const [isOpenCredits, setIsOpenCredits] = useState(
    location.includes("credits")
  );

  const [isOpenAi, setIsOpenAi] = useState(location.includes("ai"));

  const [isGmbIntegrate, setIsGmbIntegrate] = useState(true);

  const { data: gmbInfo } = useGetGmbInfo({
    onSuccess: (res) => {
      setIsGmbIntegrate(res.GMB_INTEGRATION);
    },
  });

  useEffect(() => {
    if (location.includes("payment")) {
      setIsOpenSubscription(true);
    } else {
      setIsOpenSubscription(false);
    }
    if (location.includes("reviews")) {
      setIsOpenReview(true);
    } else {
      setIsOpenReview(false);
    }
    if (location.includes("credits")) {
      setIsOpenCredits(true);
    } else {
      setIsOpenCredits(false);
    }
    if (location.includes("/ai")) {
      setIsOpenAi(true);
    } else {
      setIsOpenAi(false);
    }
  }, [location]);

  useEffect(() => {
    if (location.includes("settings")) {
      setIsOpenSetting(true);
    } else {
      setIsOpenSetting(false);
    }
  }, [location]);

  return (
    <div className="pr-5 lg:pr-0">
      {isMaster ? (
        <div className="flex flex-col font-medium mt-5 lg:mt-[38px] gap-1 text-base font-pro">
          <LinkComponent
            link={"/organisation"}
            icon={<LayoutGrid className="w-5 h-5" />}
            title={"Overview"}
          />
          <LinkComponent
            link={"/organisation/users"}
            icon={<UsersRound className="w-5 h-5" />}
            title={"Users"}
          />
          <LinkComponent
            link={"/organisation/billing"}
            icon={<WalletCards className="w-5 h-5" />}
            title="Billing"
          />
          <div>
            <div
              className={`reviews flex items-center justify-between py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location.includes("credits") ? "text-[#2a49b8] dark:text-secondary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"}`}
              onClick={() => setIsOpenCredits((prev) => !prev)}
            >
              <div className="flex items-center gap-[8px]">
                <Gem className="w-5 h-5" />
                <p className="text-sm">Credits Request</p>
              </div>
              <ChevronDown
                className={`w-4 transition-all duration-200 ${isOpenCredits && "rotate-180"}`}
              />
            </div>
            {isOpenCredits && (
              <div
                className={`space-y-1 pt-1 transition-all duration-500 text-[#3d3d3d] text-sm ${isOpenCredits ? "h-fit" : "h-0"}`}
              >
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/organisation/credits/pending" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/organisation/credits/pending"}
                >
                  Pending
                </Link>
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/organisation/credits/approved" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/organisation/credits/approved"}
                >
                  Approved
                </Link>
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/organisation/credits/rejected" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/organisation/credits/rejected"}
                >
                  Rejected
                </Link>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="flex flex-col font-medium mt-5 lg:mt-[22px] gap-1 text-base font-pro">
          <LinkComponent
            link={"/dashboard"}
            icon={<LayoutGrid className="w-5 h-5" />}
            title={"Overview"}
          />
          {/* <LinkComponent
            link={"/dashboard/reviews"}
            icon={<MessageSquareText className="w-5 h-5" />}
            title={"Review Manager"}
            className={"reviews"}
          /> */}
          <div>
            <div
              className={`reviews flex items-center justify-between py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location.includes("reviews") ? "text-[#2a49b8] dark:text-secondary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"}`}
              onClick={() => {
                setIsOpenReview((prev) => !prev);
                setIsOpenAi(false);
                setIsOpenCredits(false);
                setIsOpenSetting(false);
                setIsOpenSubscription(false);
              }}
            >
              <div className="flex items-center gap-[8px]">
                <MessageSquareText className="w-5 h-5" />
                <p className="text-sm">Review Manager</p>
              </div>
              <ChevronDown
                className={`w-4 transition-all duration-200 ${isOpenReview && "rotate-180"}`}
              />
            </div>
            {isOpenReview && (
              <div
                className={`space-y-1 pt-1 transition-all duration-500 text-[#3d3d3d] text-sm ${isOpenReview ? "h-fit" : "h-0"}`}
              >
                {/* <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary hidden dark:hover:bg-darkLightBg  ${location === "/dashboard/reviews" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                  to={"/dashboard/settings/account"}
                >
                  Unified Inbox
                </Link> */}
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center gap-1 ${location === "/dashboard/reviews" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/reviews"}
                >
                  <img src={"/new-logo.svg"} alt="" className="size-4" />
                  Reviews
                </Link>
                {gmbInfo?.GMB_INTEGRATION && (
                  <Link
                    className={` hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center gap-1  ${location === "/dashboard/reviews/google" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                    to={"/dashboard/reviews/google"}
                  >
                    <img src={Google} alt="" className="size-4" /> Google
                    Reviews
                  </Link>
                )}
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center gap-1 ${location === "/dashboard/reviews/analytics" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/reviews/analytics"}
                >
                  <ChartColumn alt="" className="size-4" />
                  Reviews Analytics
                </Link>

                {/* {gmbInfo?.GMB_INTEGRATION && (
                  <Link
                    className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/dashboard/reviews/auto-reply" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                    to={"/dashboard/reviews/auto-reply"}
                  >
                    Responder AI Agent
                  </Link>
                )} */}
              </div>
            )}
          </div>

          <div>
            <div
              className={`ai flex items-center justify-between py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location.includes("/ai") ? "text-[#2a49b8] dark:text-secondary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"}`}
              onClick={() => {
                setIsOpenAi((prev) => !prev);
                setIsOpenReview(false);
                setIsOpenCredits(false);
                setIsOpenReview(false);
                setIsOpenSetting(false);
                setIsOpenSubscription(false);
              }}
            >
              <div className="flex items-center gap-[8px]">
                <Bot className="w-5 h-5" />
                <p className="text-sm">AI Assist</p>
              </div>
              <ChevronDown
                className={`w-4 transition-all duration-200 ${isOpenAi && "rotate-180"}`}
              />
            </div>
            {isOpenAi && (
              <div
                className={`space-y-1 pt-1 transition-all duration-500 text-[#3d3d3d] text-sm ${isOpenAi ? "h-fit" : "h-0"}`}
              >
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] pr-5 rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex justify-between items-center   ${location === "/dashboard/ai/auto-reply" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"} ${!isGmbIntegrate && "text-[#888]"}`}
                  to={"/dashboard/ai/auto-reply"}
                >
                  Responder Agent
                  {isGmbIntegrate ? null : (
                    <IconLock className="size-4 flex-shrink-0" />
                  )}
                </Link>
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] pr-5 rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center justify-between ${location === "/dashboard/ai/keyinsights" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"} ${!isGmbIntegrate && "text-[#888]"}`}
                  to={"/dashboard/ai/keyinsights"}
                >
                  Key Insights
                  {isGmbIntegrate ? null : (
                    <IconLock className="size-4 flex-shrink-0" />
                  )}
                </Link>
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center gap-1 ${location === "/dashboard/ai/seo-agent" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/ai/seo-agent"}
                >
                  SEO Agent
                </Link>
                <Link
                  className={` hover:bg-gray-200/70 py-1 pl-[60px] pr-5 rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg flex items-center justify-between ${location === "/dashboard/ai/post" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"} ${!isGmbIntegrate && "text-[#888]"}`}
                  to={"/dashboard/ai/post"}
                >
                  AI Post
                  {isGmbIntegrate ? null : (
                    <IconLock className="size-4 flex-shrink-0" />
                  )}
                </Link>
              </div>
            )}
          </div>

          <LinkComponent
            link={"/dashboard/questions"}
            icon={<Sparkles className="w-5 h-5" />}
            title={"Question Manager"}
            className={"questions"}
          />
          <LinkComponent
            link={"/dashboard/magiclinks"}
            icon={<LinkIcon className="w-5 h-5" />}
            title={"Magic Links"}
            className={"magiclinks"}
          />
          <LinkComponent
            link={"/dashboard/magic-card"}
            icon={<CreditCard className="w-5 h-5" />}
            title={"Magic Cards"}
            className={"magiccards"}
          />
          <LinkComponent
            link={"/dashboard/campaign"}
            icon={<Mails className="w-5 h-5" />}
            title={"Campaign"}
          />
          {/* <LinkComponent
            link={"/dashboard/keywords"}
            icon={<Command className="w-5 h-5" />}
            title={"SEO Keywords"}
            className={"seo"}
          /> */}

          {isSubuser && isCredit ? (
            <LinkComponent
              link={"/dashboard/billing"}
              icon={<WalletCards className="w-5 h-5" />}
              title={"Billing"}
              // className={"seo"}
            />
          ) : (
            <div>
              <div
                className={`flex items-center justify-between py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg ${location.includes("payment") ? "text-[#2a49b8] dark:text-secondary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"}`}
                onClick={() => {
                  setIsOpenSubscription((prev) => !prev);
                  setIsOpenAi(false);
                  setIsOpenCredits(false);
                  setIsOpenReview(false);
                  setIsOpenSetting(false);
                }}
              >
                <div className="flex items-center gap-[8px]">
                  <WalletCards className="w-5 h-5" />
                  <p className="text-sm">Subscription</p>
                </div>
                <ChevronDown
                  className={`w-4 transition-all duration-200 ${isOpenSubscription && "rotate-180"}`}
                />
              </div>
              {isOpenSubscription && (
                <div
                  className={`space-y-1 pt-1 transition-height duration-200 text-[#3d3d3d] text-sm ${isOpenSubscription ? "h-fit" : "h-0"}`}
                >
                  <Link
                    className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${savedCard ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                    to={"/dashboard/payment?savedCard=false"}
                  >
                    Plans
                  </Link>
                  <Link
                    className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${invoice ? "text-[#2a49b8] dark:text-secondary " : "text-[#3D3D3D] dark:text-white"}`}
                    to={"/dashboard/payment?invoice=true"}
                  >
                    Invoice History
                  </Link>
                  {/* <Link
                    className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/payment/buy-card" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-[#979A9B]"}`}
                    to={"/dashboard/payment/buy-card"}
                  >
                    Buy Card
                  </Link> */}
                  <Link
                    className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/payment/buy-card-history" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                    to={"/dashboard/payment/buy-card-history"}
                  >
                    Card Payment History
                  </Link>
                </div>
              )}
            </div>
          )}

          {/* <LinkComponent
            link={"/dashboard/referral"}
            icon={<UsersRound className="w-5 h-5" />}
            title={"Referrals"}
            className={"referral"}
          /> */}
          <div>
            <div
              className={`settings flex items-center justify-between py-[7px] px-[20px] rounded-[7px] hover:bg-gray-200/70 cursor-pointer dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location.includes("settings") ? "text-[#2a49b8] dark:text-secondary bg-[#F0F5FF] dark:bg-darkLightBg" : "text-[#3D3D3D] dark:text-white"}`}
              onClick={() => {
                setIsOpenSetting((prev) => !prev);
                setIsOpenAi(false);
                setIsOpenCredits(false);
                setIsOpenReview(false);
                setIsOpenSubscription(false);
              }}
            >
              <div className="flex items-center gap-[8px]">
                <Settings className="w-5 h-5" />
                <p className="text-sm">Settings</p>
              </div>
              <ChevronDown
                className={`w-4 transition-all duration-200 ${isOpenSetting && "rotate-180"}`}
              />
            </div>
            {isOpenSetting && (
              <div
                className={`space-y-1 pt-1 transition-all duration-500 text-[#3d3d3d] text-sm ${isOpenSetting ? "h-fit" : "h-0"}`}
              >
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg  ${location === "/dashboard/settings/account" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/settings/account"}
                >
                  Account
                </Link>
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/settings/integrations" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/settings/integrations"}
                >
                  Integrations
                </Link>
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/settings/knowledge" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/settings/knowledge"}
                >
                  Knowledge Base
                </Link>
                <Link
                  className={`block hover:bg-gray-200/70 py-1 pl-[60px] rounded-[7px] dark:hover:text-secondary dark:hover:bg-darkLightBg ${location === "/dashboard/settings/referral" ? "text-[#2a49b8] dark:text-secondary" : "text-[#3D3D3D] dark:text-white"}`}
                  to={"/dashboard/settings/referral"}
                >
                  Referrals
                </Link>
              </div>
            )}
          </div>
          {/* <LinkComponent
            link={"/dashboard/get-started"}
            icon={<CirclePlay className="w-5 h-5" />}
            title={"Getting Started"}
            className={"getstarted"}
          /> */}
        </div>
      )}
      {/* <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
            zIndex: 10000,
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      /> */}
    </div>
  );
};

export default SideBar;
