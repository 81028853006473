import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import Footer from "../components/footer/footer";
import MainNav from "../components/mainNavbar/MainNav";
import WordBreak from "../components/wordbreak";
import { useGetPlans } from "../query/useSubscription";

import mouseSvg from "/mouseSvg.svg";
import walletSvg from "/walletSvg.svg";

import features1 from "/features1.png";
import features2 from "/features2.png";
import features3 from "/features3.png";

import { ChevronRight } from "lucide-react";
import "swiper/css";
import "swiper/css/bundle";
import "swiper/css/effect-fade";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Airbnb from "../assets/reviewservices/airbnb.svg";
import Booking from "../assets/reviewservices/booking.svg";
import Google from "../assets/reviewservices/google.svg";
import TripAdvisor from "../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../assets/reviewservices/trustpilot.svg";
import Yelp from "../assets/reviewservices/yelp.svg";
import ClientComponent from "../components/home/ClientComponent";
import NewHeroSectionComponent2 from "../components/home/NewHeroSectionComponent2";
import NewResultComponent from "../components/home/NewResultComponent";
import ReputationComponent from "../components/home/ReputationComponent";
import ClientInfiniteScroll from "../components/slider/ClientInfiniteScroll";
import NewHomeSlider from "../components/slider/NewHomeSlider";
import CookieConsent from "../components/ui/CookieConsent";
import { useAuth } from "../context/authContext";
import Plans from "./pricing/Plans";
import WorldMap from "../components/ui/world-map";
import NewHeroSectionComponent from "../components/home/NewHeroSectionComponent";
import CommonHeading from "../components/home/CommonHeading";

const Home = () => {
  const { token } = useAuth();
  const home = useRef(null);
  const overview = useRef(null);
  const navigate = useNavigate();
  const keyFeatures = useRef(null);
  const containerRef = useRef(null);
  const whyChooseUs = useRef(null);
  const { data: plans } = useGetPlans();
  const faqs = useRef(null);

  const UpdatedPlans = Array.isArray(plans?.data)
    ? plans.data.sort((a, b) => a.price - b.price)
    : [];
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [currentItem, setCurrentItem] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItem((prevItem) => (prevItem + 1) % scrollData.length);
    }, 3000); // Change item every 2 seconds

    return () => clearInterval(interval); // Clean up on component unmount
  }, []);
  const metaKeywords =
    "Google Review NFC Card, Reviews tool, Review Cards, NFC Review Card, Google Reviews With AI, AI Generated Google Reviews, AI Generated Reviews, AI Google Reviews, AI Review Application, AI Reviews, Get More Google Review, Automated Google Reviews, Automated online Reviews, AI Google review tool, Generate AI Google Reviews, Google Reviews, Online Review";
  const pricingData = [
    "Instant tap to get AI generated reviews",
    "Real-Time Tracking of Customer Reviews",
    "Hassle-Free Review Collection under 30 seconds",
    "Faster Reviews Without Manual Typing",
  ];

  const reviewTapData = [
    {
      icon: walletSvg,
      desc: (
        <div>
          Switch from emails and texts to <WordBreak /> MagicReview&apos;s AI
          review cards, featuring <WordBreak /> NFC and QR technology.
        </div>
      ),
    },
    {
      icon: mouseSvg,
      desc: (
        <div>
          Customers can easily leave reviews <WordBreak /> with a tap or scan on
          their phones, <WordBreak /> enabling you to gather reviews under 30{" "}
          <WordBreak /> seconds and lower marketing costs with <WordBreak />{" "}
          word-of-mouth promotion.
        </div>
      ),
    },
  ];
  const reviewData2 = [
    {
      value: "73%",
      desc: "people trust online reviews as much as personal suggestions.",
    },
    {
      value: "92%",
      desc: "of customers read online reviews before a purchase.",
    },
    {
      value: "17%",
      desc: "increase in conversion rate is noticed with positive reviews.",
    },
    {
      value: "46%",
      desc: "increase in revenue is seen with an average rating of 4.7",
    },
  ];
  const featuresData = [
    {
      title: "Google Review Cards",
      img: features3,
      desc: "NFC & QR technology used in the MagicReview Cards allows customers to easily leave reviews by tapping or scanning the AI Review Cards on their phones. The easy process enhances customer experience and boosts participation",
    },
    {
      title: "Human-Like AI Reviews",
      img: features2,
      desc: "MagicReview uses AI technology to create well-structured and engaging reviews based on customer inputs, eliminating the need for manual review writing. Customers can easily share their feedback using MagicReview’s AI Review Cards.",
    },
    {
      title: "Real-Time Tracking",
      img: features1,
      desc: "MagicReview provides you with a personalized dashboard, allowing you to track reviews generated using Google review cards effortlessly. Easily customize review forms and monitor progress with insightful analytics and data.",
    },
  ];
  const scrollData = [
    "Easy Tap and Scan Process",
    "Rapid Feedback in Just 30 Seconds",
    "Rank 1st on Google Search",
    "Human-like AI Reviews",
    "Real-Time Review Tracking",
    // "AI Generated Reviews With A 14-day Free Trial",
  ];
  const handleNavigate = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const cardPrice = () => {
    switch (UpdatedPlans[selectedPlan]?.currency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  return (
    <>
      <CookieConsent />
      <Helmet>
        <title>
          MagicReview | AI powered Google review Card for your business
        </title>
        <meta
          name="title"
          content=" MagicReview | AI powered Google review Card for your business"
        />
        <meta name="keywords" content={metaKeywords} />
        <meta
          name="description"
          content="Boost sales and your business reputation with MagicReview's AI review cards. Increase Google Reviews effortlessly. Start your free plan now."
        />
        <link rel="canonical" href="https://www.magicreview.ai/" />
      </Helmet>

      <div className="w-full bg-white">
        {/* <ul className="bg-newPrimary w-full px-2 h-[50px] rounded-sm hidden md:flex justify-center text-white items-center overflow-hidden font-poppins gap-[43px]">
          <li
            key={currentItem}
            className="font-poppins text-base animate-fadeIn flex items-center gap-2"
          >
            <div className="w-2.5 h-2.5 rounded-full bg-white"></div>
            {scrollData[currentItem]}
            <img src="stargroup.svg" className="" />
      
            <Link
              to="/pricing"
              className="text-[#4F75FA] text-base text-center rounded-[10px] bg-white px-4 py-1 ml-5"
            >
              Checkout
            </Link>
          </li>
        </ul> */}

        <MainNav />

        <div className="w-full gap-12 lg:gap-0 grid lg:grid-cols-[44%_56%] grid-cols-1 items-center min-h-[calc(100svh-64px)]">
          <div className="container lg:pr-0 pt-10 lg:pt-0">
            <motion.p
              className="font-poppins text-newPrimary text-[21.87px] lg:leading-tight font-semibold"
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
              }}
              viewport={{
                once: true,
              }}
            >
              50+ Integrations
            </motion.p>

            <motion.h1
              className="font-poppins font-semibold text-2xl  lg:text-[48px]  md:leading-[60px] text-[#3F3F3F] lg:tracking-[-0.05em]"
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
                delay: 0.2,
              }}
              viewport={{
                once: true,
              }}
            >
              <Swiper
                slidesPerView={1}
                spaceBetween={1}
                className="inline-flex w-full h-[50px] md:h-[68px] max-w-fit lg:mb-7"
                modules={[Autoplay]}
                loop={true}
                autoplay={{
                  delay: 3000,
                }}
                direction={"vertical"}
              >
                <SwiperSlide className="flex items-center gap-2">
                  <img src={Google} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333] lg:tracking-[-6%]">
                    <span className="text-[#4E81EE]">G</span>
                    <span className="text-[#E1183A]">o</span>
                    <span className="text-[#F5C228]">o</span>
                    <span className="text-[#4E81EE]">g</span>
                    <span className="text-[#24BF5A]">l</span>
                    <span className="text-[#E1183A]">e</span>
                  </h1>
                </SwiperSlide>
                <SwiperSlide className="flex items-center gap-2">
                  <img src={TrustPilot} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] lg:tracking-[-6%] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                    <span className="text-[#04da8d]">Trustpilot</span>
                  </h1>
                </SwiperSlide>
                <SwiperSlide className="flex items-center gap-2">
                  <img src={Airbnb} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] lg:tracking-[-6%] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                    <span className="text-[#FF5A5F]">Airbnb</span>
                  </h1>
                </SwiperSlide>
                <SwiperSlide className="flex items-center gap-2">
                  <img src={Booking} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] lg:tracking-[-6%] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                    <span className="text-[#003580]">Booking</span>
                  </h1>
                </SwiperSlide>
                <SwiperSlide className="flex items-center gap-2">
                  <img src={TripAdvisor} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] lg:tracking-[-6%] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                    <span className="text-[#34e0a1]">Tripadvisor</span>{" "}
                  </h1>
                </SwiperSlide>

                <SwiperSlide className="flex items-center gap-2">
                  <img src={Yelp} alt="" className="size-5 lg:size-9" />
                  <h1 className="font-poppins font-semibold text-2xl  md:text-[38px] lg:text-[48.26px] lg:tracking-[-6%] xxs:leading-[2.8rem] md:leading-[3.9rem] text-[#333333]">
                    <span className="text-[#d71616]">Yelp</span>
                  </h1>
                </SwiperSlide>
              </Swiper>
              <br />
              Let{" "}
              <motion.span
                className="inline-block px-5 py-2.5 h-[60px] rounded-[12px] bg-newPrimary text-white  text-xl  lg:text-[38px] md:leading-10"
                initial={{
                  rotate: 0,
                }}
                whileInView={{
                  rotate: -2.82,
                }}
                transition={{
                  duration: 0.4,
                  ease: "easeInOut",
                  delay: 0.4,
                }}
                viewport={{
                  once: true,
                }}
              >
                AI agents
              </motion.span>{" "}
              handle <WordBreak className="lg:hidden xl:block" />
              Business Reputation
            </motion.h1>
            <motion.p
              className="font-poppins text-base md:text-[17px] font-normal h-fit flex gap-5 items-center lg:leading-[26px] text-[#1d1d1f] capitalize mt-5 mb-[33px]"
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
                delay: 0.4,
              }}
              viewport={{
                once: true,
              }}
            >
              AI agents manage your reviews, Boost your SEO, and{" "}
              <WordBreak className="lg:hidden xl:block" /> drive growth.
              boosting your business&apos;s visibility on{" "}
              <WordBreak className="lg:hidden xl:block" /> Google Search.
            </motion.p>
            <motion.div
              className="flex flex-col md:flex-row md:justify-start  md:items-center gap-4"
              initial={{
                opacity: 0,
                y: 50,
              }}
              whileInView={{
                opacity: 1,
                y: 0,
              }}
              transition={{
                duration: 0.4,
                ease: "easeInOut",
                delay: 0.6,
              }}
              viewport={{
                once: true,
              }}
            >
              <motion.button
                className="flex justify-center items-center gap-3 bg-[#ecf0fd] border-l-4 border border-[#ecf0fd] border-l-newPrimary py-3 px-5 h-11 rounded-lg font-medium text-sm text-newPrimary hover:underline font-poppins"
                onClick={() => handleNavigate("/schedule-a-call")}
              >
                Schedule a demo <ChevronRight className="size-5" />
              </motion.button>
              <button
                className="flex justify-center items-center gap-2 py-3 px-5 text-white bg-newPrimary rounded-lg h-11 border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white font-medium font-poppins text-sm
                  "
                onClick={() => {
                  token
                    ? handleNavigate("/dashboard/payment?savedCard=false")
                    : handleNavigate("/sign-up");
                }}
              >
                Try free for 14 days
              </button>
            </motion.div>
          </div>

          {/* <NewHeroSectionComponent2 />
           */}
          <NewHeroSectionComponent />
        </div>

        <div className="client bg-white text-black container ">
          <ClientInfiniteScroll />
        </div>

        {/* <div className="bg-newPrimary flex flex-col lg:flex-row lg:justify-center lg:items-center font-poppins text-white lg:py-10 lg:divide-x-2 divide-[#6a8afb] divide-y lg:divide-y-0 p-5 container">
          <div className="flex flex-col items-center justify-center lg:px-12 pb-5 lg:pb-0">
            <p className="  font-semibold lg:text-[52px] text-xl lg:tracking-[-6%] lg:leading-[58px]">
              3549+
            </p>
            <p>No. of Reviews Generated</p>
          </div>
          <div className="flex flex-col items-center justify-center lg:px-12 py-5 lg:py-0">
            <p className="  font-semibold lg:text-[52px] text-xl lg:tracking-[-6%] lg:leading-[58px]">
              2000+
            </p>
            <p>No. of Users Helped</p>
          </div>
          <div className="flex flex-col items-center justify-center lg:px-12 pt-5 lg:pt-0">
            <p className="  font-semibold lg:text-[52px] text-xl lg:tracking-[-6%] lg:leading-[58px]">
              78+
            </p>
            <p>No. of Business Served</p>
          </div>
        </div> */}

        <div className="w-full font-poppins lg:py-20 py-10 container">
          <CommonHeading
            title={"AI Agents Suite for\nReputation Management"}
            description={
              " Automate reviews, boost SEO, and drive growth with our all-in-one AI solution\ndelivering intelligent, actionable insights, and continuous engagement."
            }
          />

          <ReputationComponent />
        </div>

        <div className="w-full bg-white font-poppins container space-y-10 lg:py-20 py-10 ">
          <CommonHeading title={"Results That Drive Success"} />

          <NewResultComponent />
          {/* <ResultComponent /> */}
        </div>

        <ClientComponent />

        <div className="fixed 2xl:-right-5 lg:right-0 right-[65px] bottom-5 z-30">
          <div className="container">
            <a href="https://wa.me/+447776698333" target="blank">
              <img
                src="/whatsapp.svg"
                className="w-12 hover:w-[52px] transition-all duration-200"
              />
            </a>
          </div>
        </div>

        <div className="w-full font-poppins container py-10 lg:py-20">
          <CommonHeading
            title={"Here is why you'll love\nworking with us"}
            description={
              "Authentic feedback builds trust and brings in more customers."
            }
          />

          <NewHomeSlider />
        </div>

        <div className="w-full container py-10 lg:py-20 font-poppins">
          <CommonHeading
            title={"Global Reach, Local Impact"}
            description={"Enhancing reputation and growth worldwide."}
          />

          <div className="lg:w-[80%] mx-auto mt-16">
            <WorldMap
              lineColor="#4F75FA"
              dots={[
                {
                  start: { lat: 20.5937, lng: 78.9629, label: "IND" }, // India
                  end: { lat: 51.5074, lng: -0.1278, label: "UK" }, // UK (London)
                },
                {
                  start: { lat: 51.5074, lng: -0.1278, label: "UK" }, // UK (London)
                  end: { lat: 37.0902, lng: -95.7129, label: "USA" }, // USA
                },
                {
                  start: { lat: 37.0902, lng: -95.7129, label: "USA" }, // USA
                  end: { lat: 23.4241, lng: 53.8478, label: "UAE" }, // UAE
                },
                {
                  start: { lat: 23.4241, lng: 53.8478, label: "UAE" }, // UAE
                  end: { lat: -25.2744, lng: 133.7751, label: "AUS" }, // Australia
                },
              ]}
            />
          </div>
        </div>

        {/* ninth section */}
        <div className="container">
          <div className=" w-full grid grid-cols-1 lg:grid-cols-2  lg:py-10 py-8 gap-5 container font-poppins bg-newPrimary rounded-[20px]">
            <div className="w-fit space-y-1 text-white">
              <p className="w-fit font-medium text-lg">Try Now</p>
              <p className="w-fit font-semibold text-[26px]  leading-[42px]">
                Learn How MagicReview Can <WordBreak /> Grow Your Business{" "}
              </p>
              <p className="w-fit text-lg ">
                Manage your reputation with a 14-day free trial
              </p>
            </div>
            <div className="flex lg:flex-row flex-col lg:items-center lg:justify-center gap-5">
              <button
                className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3  rounded-lg border border-newPrimary text-newPrimary transition-all duration-300 bg-white font-poppins lg:w-[40%] h-11"
                onClick={() => {
                  navigate("/schedule-a-call");
                  window.scrollTo(0, 0);
                }}
              >
                Schedule a demo
              </button>
              <button
                className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3 text-white bg-[#85a0ff] rounded-lg border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white font-poppins lg:w-[40%] h-11"
                onClick={() =>
                  token
                    ? handleNavigate("/dashboard/payment?savedCard=false")
                    : handleNavigate("/sign-up")
                }
              >
                Start free trial
              </button>
            </div>
          </div>
        </div>
        <div className="w-full bg-white py-10 lg:py-20 flex flex-col justify-center items-center container font-poppins">
          <CommonHeading
            title={"Pricing"}
            description={"Pick the plan that suits your needs the most."}
          />

          <Plans />
        </div>
      </div>

      <Footer
        home={home}
        overview={overview}
        keyFeatures={keyFeatures}
        whyChooseUs={whyChooseUs}
        faqs={faqs}
      />
    </>
  );
};

export default Home;
