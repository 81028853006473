import React from "react";
import WordBreak from "../../components/wordbreak";
import { useNavigate } from "react-router";

const PricingComponent = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full container py-10 lg:py-20 font-poppins">
      <div className=" w-full bg-[#F7F9FF] rounded-[19px] flex flex-col lg:flex-row lg:justify-between lg:items-center px-10 lg:px-16 lg:py-10 py-8 gap-5">
        <div className="w-fit space-y-1 lg:w-[48%]">
          <p className="w-fit font-medium text-lg text-newPrimary">Try Now</p>
          <p className="w-fit font-semibold text-[28px] text-[#333] leading-[42px]">
            Learn How MagicReview Can <WordBreak /> Grow Your Business{" "}
          </p>
          <p className="w-fit text-lg text-[#888]">
            AI generated reviews with a 14-day free trial
          </p>
        </div>
        <div className="lg:w-[48%] flex lg:flex-row flex-col lg:justify-center gap-5">
          <button
            className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3 hover:text-white hover:bg-newPrimary rounded-lg border border-newPrimary text-newPrimary transition-all duration-300 bg-white h-10"
            onClick={() => {
              navigate("/contact-us");
              window.scrollTo(0, 0);
            }}
          >
            Schedule a call
          </button>
          <button
            className="flex items-center justify-center font-medium text-sm gap-2 px-6 py-3 text-white bg-newPrimary rounded-lg border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white h-10"
            onClick={() => {
              navigate("/sign-up");
              window.scrollTo(0, 0);
            }}
          >
            Start your free trial
          </button>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
