/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  Select,
  SelectTrigger,
  SelectItem,
  SelectContent,
  SelectValue,
} from "../../../../components/ui/select";
import { useEffect, useRef, useState } from "react";
import {
  useGetEmployeeIntegrations,
  usePostAddEmployee,
  usePutUpdateEmployee,
} from "../../../../query/useEmployees";
import { toast } from "sonner";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import {
  useDeleteTag,
  useGetQuestionSets,
  useGetTags,
  usePostAddTag,
} from "../../../../query/useEntity";
import { Asterisk, ChevronDown, Trash2 } from "lucide-react";
import { useOnClickOutside } from "../../../../hooks/useOnClickOutside";
import { Switch } from "../../../../components/ui/switch";
import { useSearchParams } from "react-router-dom";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "../../component/CustomTooltipForJoyride";
import { LogoLoading } from "../../../../components/loading/loading";
import { Button } from "../../../../components/ui/button";
import { Input } from "../../../../components/ui/input";
import MagiclinkSettings from "./MagiclinkSettings";
import { useMagiclinkForm } from "../../../../hooks/useMagiclinkForm";

const AddStaffModal = ({
  openModal,
  setOpenModal,
  refetch,
  entityId,
  setData,
  selectedSet,
  // tagsFromApi,
  // refetchTags,
}) => {
  const tagBoxRef = useRef(null);
  const redirectBoxRef = useRef(null);

  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const [tags, setTags] = useState([]);
  const [inputTag, setInputTag] = useState("");
  const [isOpenTagBox, setIsOpenTagBox] = useState(false);
  const [deleteTagId, setDeleteTagId] = useState();
  const [isOpenRedirectBox, setIsOpenRedirectBox] = useState(false);
  const [inputRedirect, setInputRedirect] = useState("");

  const joyRideStepsForMagiclink = [
    {
      title: "Name",
      content: "Enter the staff or team member's name for the MagicLink.",
      target: ".select-name",
      disableBeacon: true,
      spotlightClicks: true,
    },
    {
      title: "Role",
      content: "Specify the person's role or position.",
      target: ".select-role",
      spotlightClicks: true,
    },
    {
      title: "Tags",
      content:
        "Add tags to link the MagicLink to a specific department or team.",
      target: ".select-tags",
      spotlightClicks: true,
    },
    {
      title: "Question Set",
      content: "Select the question set you want to assign to this MagicLink.",
      target: ".select-question-set",
      spotlightClicks: true,
    },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForMagiclink,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "verify-email") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const { mutate: addEmployee, isLoading: addEmployeeLoading } =
    usePostAddEmployee();

  const { data: tagsFromApi, refetch: refetchTags } = useGetTags();
  const { data: emplyeeIntegrations } = useGetEmployeeIntegrations();
  const { mutate: addTag } = usePostAddTag();
  const { mutate: deleteTag, isLoading: deleteLoading } = useDeleteTag();

  const { data: questionSets } = useGetQuestionSets({
    refetchOnWindowFocus: false,
  });

  const isLoading = addEmployeeLoading;

  const extractedTags = tagsFromApi?.filter((item) => item.tag === inputTag);

  const { formState, setters, handleSubmit } = useMagiclinkForm({
    entityId,
    onSubmit: (data, onSuccess) => {
      addEmployee(data, {
        onSuccess: (res) => {
          if (res.message) {
            toast.success(res.message);
          } else {
            toast.success("Link has been added successfully");
          }
          refetch();
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data.tag) {
            toast.error(err.response.data.tag?.[0]);
          } else if (err.response.data.label) {
            toast.error(err.response.data.label?.[0]);
          } else if (err.respopnse.data.redirect?.[0]) {
            toast.error(err.response.data.redirect?.[0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      });
    },
    selectedSet,
  });

  const handleAddTag = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
        addTag(
          {
            tag: inputTag,
            entity: entityId,
          },
          {
            onSuccess: (res) => {
              refetchTags();
            },
          }
        );

        setTags([...tags, inputTag.trim()]);
        setInputTag("");
      }
    }
  };

  const handleAddClickTag = () => {
    if (!tags?.includes(inputTag) && inputTag.trim() !== "") {
      addTag(
        {
          tag: inputTag,
          entity: entityId,
        },
        {
          onSuccess: (res) => {
            refetchTags();
          },
        }
      );

      setTags([...tags, inputTag.trim()]);
      setInputTag("");
    }
  };

  const handleDeleteTag = (item) => {
    setDeleteTagId(item?.id);
    deleteTag(
      {
        name: item?.tag,
        entity: entityId,
        id: item?.id,
      },
      {
        onSuccess: (res) => {
          refetchTags();
          setTags((prev) => prev?.filter((tag) => tag !== item?.tag));
        },
      }
    );
  };

  // const handleClick = () => {
  //   if (!name) {
  //     toast.error("Name field must not be blank");
  //     return;
  //   }
  //   if (label?.length > 50) {
  //     toast.error("Label field must have maximum 50 characters");
  //     return;
  //   }

  //   const data = {
  //     entity: entityId,
  //     name: name,
  //     tags: tags || [],
  //     label: label,
  //     default_set: questionSet,
  //     show: isShowName,
  //     voice_review: isVoiceReview,
  //     redirect: redirects,
  //     negative_feedback: isNegativeFeedback,
  //     star_rating: starRating,
  //     direct_to_review: isDirectToReview,
  //     personal_info: [...customerInfo, subheading],
  //     only_voice_review: isOnlyVoiceReview,
  //   };

  //   if (!isVoiceReview) delete data["only_voice_review"];

  //   addEmployee(data, {
  //     onSuccess: (res) => {
  //       if (res.message) {
  //         toast.success(res.message);
  //       } else {
  //         toast.success("Link has been added successfully");
  //       }
  //       refetch();
  //       setOpenModal(false);
  //     },
  //     onError: (err) => {
  //       if (err.response.data.message) {
  //         toast.error(err.response.data.message);
  //       } else if (err.response.data.tag) {
  //         toast.error(err.response.data.tag?.[0]);
  //       } else if (err.response.data.label) {
  //         toast.error(err.response.data.label?.[0]);
  //       } else if (err.respopnse.data.redirect?.[0]) {
  //         toast.error(err.response.data.redirect?.[0]);
  //       } else {
  //         toast.error(
  //           `Something went wrong, try again later ${err.response.status}`
  //         );
  //       }
  //     },
  //   });
  // };

  // useEffect(() => {
  //   setQuestionSet(selectedSet);
  // }, []);

  useOnClickOutside({ ref: tagBoxRef, setIsOpen: setIsOpenTagBox });
  useOnClickOutside({ ref: redirectBoxRef, setIsOpen: setIsOpenRedirectBox });

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent className="lg:max-w-[888px]">
        <DialogHeader>
          <DialogTitle>Magiclink Settings</DialogTitle>
        </DialogHeader>

        <MagiclinkSettings
          {...formState}
          {...setters}
          // isShowName={isShowName}
          // setIsShowName={setIsShowName}
          // isVoiceReview={isVoiceReview}
          // setIsVoiceReview={setIsVoiceReview}
          // isOnlyVoiceReview={isOnlyVoiceReview}
          // setIsOnlyVoiceReview={setIsOnlyVoiceReview}
          // starRating={starRating}
          // setStarRating={setStarRating}
          // isNegativeFeedback={isNegativeFeedback}
          // setIsNegativeFeedback={setIsNegativeFeedback}
          // name={name}
          // setName={setName}
          // label={label}
          // setLabel={setLabel}
          // tags={tags}
          // setTags={setTags}
          inputTag={inputTag}
          setInputTag={setInputTag}
          handleAddTag={handleAddTag}
          isOpenRedirectBox={isOpenRedirectBox}
          setIsOpenRedirectBox={setIsOpenRedirectBox}
          isOpenTagBox={isOpenTagBox}
          setIsOpenTagBox={setIsOpenTagBox}
          tagsFromApi={tagsFromApi}
          deleteLoading={deleteLoading}
          deleteTagId={deleteTagId}
          handleDeleteTag={handleDeleteTag}
          extractedTags={extractedTags}
          handleAddClickTag={handleAddClickTag}
          // redirects={redirects}
          // setRedirects={setRedirects}
          inputRedirect={inputRedirect}
          setInputRedirect={setInputRedirect}
          emplyeeIntegrations={emplyeeIntegrations}
          // questionSet={questionSet}
          questionSets={questionSets}
          // setQuestionSet={setQuestionSet}
          setOpenModal={setOpenModal}
          setData={setData}
          handleClick={handleSubmit}
          isLoading={isLoading}
          // customerInfo={customerInfo}
          // setCustomerInfo={setCustomerInfo}
          // subheading={subheading}
          // setSubheading={setSubheading}
          // isDirectToReview={isDirectToReview}
          // setIsDirectToReview={setIsDirectToReview}
          // offerPage={offerPage}
          // setOfferPage={setOfferPage}
          ref={{
            tagBoxRef: tagBoxRef,
            redirectBoxRef: redirectBoxRef,
          }}
        />
        <Joyride
          callback={handleJoyrideCallback}
          run={step.run}
          steps={step.steps}
          continuous={true}
          // stepIndex={step.stepIndex}
          scrollToFirstStep
          showProgress
          showSkipButton
          styles={{
            options: {
              primaryColor: "#7190FF",
              zIndex: 10000,
            },
            tooltip: {
              position: "center",
            },
          }}
          tooltipComponent={CustomTooltipForJoyride}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddStaffModal;
