import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  usePostEntityPin,
  usePutResetEntityPin,
} from "../../../../query/useEntity";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { Input } from "../../../../components/ui/input";
import { EyeSlashIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { LogoLoading } from "../../../../components/loading/loading";

const PinInput = ({ pin, setPin }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="relative">
      <Input
        value={pin}
        placeholder="Enter pin"
        type={isVisible ? "number" : "password"}
        onChange={(e) => {
          const value = e.target.value;
          if (value.length <= 4) {
            setPin(value);
          }
        }}
      />
      <div className="absolute inset-y-0 right-4 pl-3 flex items-center z-50">
        <button
          type="button"
          onClick={() => setIsVisible(!isVisible)}
          className="text-gray-500 focus:outline-none"
        >
          {isVisible ? (
            <EyeSlashIcon className="h-5 w-5" />
          ) : (
            <EyeIcon className="h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
};

const PinModal = ({ openModal, setOpenModal, isEdit = false, pinFromApi }) => {
  const queryClient = useQueryClient();
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");

  const { mutate: updatePin, isLoading: updatePinLoading } = usePostEntityPin();
  const { mutate: resetPin, isLoading: resetPinLoading } =
    usePutResetEntityPin();

  const handleUpdatePin = () => {
    if (pin !== confirmPin) {
      toast.error("PIN does not match");
      return;
    }
    updatePin(
      {
        pin: pin,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenModal(false);
          queryClient.invalidateQueries({ queryKey: ["getEntityPin"] });
        },
        onError: (err) => {
          if (err.response.data?.message) {
            toast.error(err.response.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleResetPin = () => {
    if (pin !== confirmPin) {
      toast.error("PIN does not match");
      return;
    }
    resetPin(
      {
        pin: pin,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenModal(false);
          queryClient.invalidateQueries({ queryKey: ["getEntityPin"] });
        },
        onError: (err) => {
          if (err.response.data?.message) {
            toast.error(err.response.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (isEdit) {
      setPin(pinFromApi);
      setConfirmPin(pinFromApi);
    }
  }, [isEdit, pinFromApi]);

  if (updatePinLoading || resetPinLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {isEdit ? "Reset Redeem PIN" : "Setup Redeem PIN"}
          </DialogTitle>
        </DialogHeader>
        <div className="px-6 space-y-5">
          <div>
            <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              {"Enter pin"}
            </label>
            <PinInput pin={pin} setPin={setPin} />
          </div>
          <div>
            <label className=" text-[15px] font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
              {"Re-enter pin"}
            </label>
            <PinInput pin={confirmPin} setPin={setConfirmPin} />
          </div>
          <div className="flex justify-end">
            <Button onClick={isEdit ? handleResetPin : handleUpdatePin}>
              Save
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PinModal;
