/* eslint-disable react/prop-types */

import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  dayAndTimeFormatter,
  dayFormatter,
  getShortMonthName,
} from "../../../../utils/formatter";

const SchedulePostModal = ({ openModal, setOpenModal, data }) => {
  const [isViewOffer, setIsViewOffer] = useState(false);
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(null);
      }}
    >
      <DialogContent className="lg:max-w-xl">
        <DialogHeader>
          <DialogTitle>Post</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro max-h-[80vh] overflow-y-auto scrollbar-thin space-y-3">
          {data?.post_data?.media?.[0]?.sourceUrl ? (
            <img
              className="w-full "
              src={data?.post_data?.media?.[0]?.sourceUrl}
              // alt={data?.post_data?.media?.[0]?.name}
              referrerPolicy="no-referrer"
            />
          ) : (
            <div className="w-full h-64 flex justify-center items-center">
              <img src="/no-picture.svg" alt="" className="w-16 h-16" />
            </div>
          )}
          {data?.type === "Offer" && (
            <div className="space-y-1">
              <h2 className="text-xl">{data?.post_data?.event?.title}</h2>
              <p>
                Valid{" "}
                {data?.post_data?.event?.schedule?.startDate &&
                  getShortMonthName(
                    data?.post_data?.event?.schedule?.startDate?.month
                  )}{" "}
                {data?.post_data?.event?.schedule?.startDate?.day} -{" "}
                {data?.post_data?.event?.schedule?.endDate &&
                  getShortMonthName(
                    data?.post_data?.event?.schedule?.endDate?.month
                  )}{" "}
                {data?.post_data?.event?.schedule?.endDate?.day}
              </p>
            </div>
          )}
          {data?.type === "Event" && (
            <div className="space-y-1">
              <h2 className="text-xl">{data?.post_data?.event?.title}</h2>
              <p>
                Valid{" "}
                {data?.post_data?.event?.schedule?.startDate &&
                  getShortMonthName(
                    data?.post_data?.event?.schedule?.startDate?.month
                  )}{" "}
                {data?.post_data?.event?.schedule?.startDate?.day} -{" "}
                {data?.post_data?.event?.schedule?.endDate &&
                  getShortMonthName(
                    data?.post_data?.event?.schedule?.endDate?.month
                  )}{" "}
                {data?.post_data?.event?.schedule?.endDate?.day}
              </p>
            </div>
          )}
          <div className="flex items-center justify-between text-xs text-[#525252] dark:text-white">
            <p className="">
              {data?.schedule_time &&
                "Schedule At - " + dayAndTimeFormatter(data?.schedule_time)}
            </p>
            <p>{data?.type}</p>
          </div>
          <h3 className=" text-sm  ">
            {" "}
            <pre className="whitespace-pre-line font-pro">
              {data?.post_data?.summary}
            </pre>
          </h3>
          {data?.post_data?.callToAction?.actionType !== "CALL" ? (
            <a
              href={data?.post_data?.callToAction?.url}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500 underline capitalize inline-block"
            >
              {data?.post_data?.callToAction?.actionType
                ?.split("_")
                .join(" ")
                .toLowerCase()}
            </a>
          ) : (
            <a
              href={`tel:${data?.post_data?.callToAction?.phoneNumber}`}
              className="text-blue-500 underline capitalize inline-block"
            >
              {data?.post_data?.callToAction?.phoneNumber}
            </a>
          )}
          {data?.type === "Offer" && (
            <>
              {!isViewOffer && (
                <p
                  onClick={() => setIsViewOffer((prev) => !prev)}
                  className="text-blue-500 underline inline-block"
                >
                  View Offer
                </p>
              )}
              {isViewOffer && (
                <div className="space-y-5">
                  <div className="bg-gray-100 w-full border-2 border-dashed rounded-lg py-5 flex flex-col items-center justify-center gap-1">
                    <p className="text-darkGray">Show this code at the shop</p>
                    <p className="text-3xl font-bold">
                      {data?.post_data?.offer?.couponCode}
                    </p>
                    <p className="font-medium text-sm">
                      Valid{" "}
                      {data?.post_data?.event?.schedule?.startDate &&
                        getShortMonthName(
                          data?.post_data?.event?.schedule?.startDate?.month
                        )}{" "}
                      {data?.post_data?.event?.schedule?.startDate?.day} -{" "}
                      {data?.post_data?.event?.schedule?.endDate &&
                        getShortMonthName(
                          data?.post_data?.event?.schedule?.endDate?.month
                        )}{" "}
                      {data?.post_data?.event?.schedule?.endDate?.day}
                    </p>
                  </div>
                  <a
                    href={data?.post_data?.offer?.redeemOnlineUrl}
                    className="text-blue-500 underline inline-block "
                  >
                    Redeem online
                  </a>
                </div>
              )}
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SchedulePostModal;
