/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { usePostGmbAccConfirm } from "../../../../query/useAuthentication";
import { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { QueryClient } from "@tanstack/react-query";
import { Flag, Link, Locate, MapPin, MapPinned, User } from "lucide-react";

const GmbIntegration = ({
  openModal,
  setOpenModal,
  gmbInfo,
  refreshToken,
  refetchGmbInfo,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [seeMore, setSeeMore] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const locations = gmbInfo?.locations_data?.locations;
  const accounts = gmbInfo?.accounts_data?.accounts;

  const gmbData = gmbInfo?.data;

  // const locations = [
  //   {
  //     name: "location-1",
  //     title: "Downtown Store",
  //     storefrontAddress: {
  //       administrativeArea: "Chiang Mai",
  //       regionCode: "CMI",
  //     },
  //     metadata: {
  //       mapsUri: "https://maps.example.com/location-1",
  //       newReviewUri: "https://reviews.example.com/location-1",
  //     },
  //   },
  //   {
  //     name: "location-2",
  //     title: "Suburb Branch",
  //     storefrontAddress: {
  //       administrativeArea: "Hang Dong",
  //       regionCode: "HD",
  //     },
  //     metadata: {
  //       mapsUri: "https://maps.example.com/location-2",
  //       newReviewUri: "https://reviews.example.com/location-2",
  //     },
  //   },
  //   {
  //     name: "location-3",
  //     title: "Beachside Outlet",
  //     storefrontAddress: {
  //       administrativeArea: "Phuket",
  //       regionCode: "PKT",
  //     },
  //     metadata: {
  //       mapsUri: "https://maps.example.com/location-3",
  //       newReviewUri: "https://reviews.example.com/location-3",
  //     },
  //   },
  // ];

  const queryClient = new QueryClient();

  const { mutate: gmbAccConfirm, isLoading: gmbAccConfirmLoading } =
    usePostGmbAccConfirm();

  const handleGmbAccConfirm = () => {
    const accountId = selectedAcc?.split("/")?.[1];
    const locationId = selectedLocation?.split("/")?.[1];
    gmbAccConfirm(
      {
        refresh_token: refreshToken,
        account_id: accountId,
        location_id: locationId,
      },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          refetchGmbInfo();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (gmbAccConfirmLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={() => {
          setOpenModal(false);
        }}
      >
        <DialogContent
          className={openConfirm ? "lg:max-w-2xl" : "lg:max-w-5xl"}
        >
          <DialogHeader>
            <DialogTitle>
              {!openConfirm ? (
                <p className="font-poppins font-semibold text-[22px]">
                  {/* {accounts?.[0]?.accountName} */}
                  Select Business Location
                </p>
              ) : (
                "Confirm Setup"
              )}
            </DialogTitle>
          </DialogHeader>
          {openConfirm ? (
            <div className="px-6">
              <p className="font-pro text-sm">
                Please confirm to setup the google integration for the account
                that you have selected.
              </p>
              <div className="flex justify-end items-center gap-x-3 mt-7">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleGmbAccConfirm}>Confirm</Button>
              </div>
            </div>
          ) : (
            <div className="px-6 pt-6 font-poppins ">
              {/* <hr className="my-3" /> */}
              {/* <div className="space-y-3 h-[60vh] overflow-y-auto scrollbar-thin">
                <RadioGroup
                  defaultValue={selectedLocation}
                  onValueChange={(e) => setSelectedLocation(e)}
                >
                  {locations?.map((item) => (
                    <div key={item?.name} className="flex gap-3">
                      <RadioGroupItem
                        value={item?.name}
                        id={item?.name}
                        className="rounded"
                        isSquare
                      ></RadioGroupItem>
                      <label
                        htmlFor={item?.name}
                        className={`w-full lg:min-w-[450px] border cursor-pointer grid grid-cols-1 lg:grid-cols-2 gap-3 p-5  dark:bg-darkLightBg rounded-[10px] ${selectedLocation === item?.name ? "bg-[#f0f5ff] border-newPrimary" : ""}`}
                      >
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            <User className="size-4" /> Name
                          </p>
                          <Input
                            value={item?.title}
                            readOnly
                            autoFocus={false}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                            <MapPin className="size-4" /> Location
                          </p>
                          <div className="flex items-center gap-3">
                            <Input
                              value={
                                item?.storefrontAddress?.administrativeArea
                              }
                              readOnly
                              autoFocus={false}
                            />

                            {seeMore === item?.name ? (
                              <Button
                                variant="outline"
                                onClick={() => setSeeMore(null)}
                                className="hidden lg:block"
                              >
                                See Less
                              </Button>
                            ) : (
                              <Button
                                variant="outline"
                                onClick={() => setSeeMore(item?.name)}
                                className="hidden lg:block"
                              >
                                See More
                              </Button>
                            )}
                          </div>
                        </div>
                        {seeMore === item?.name && (
                          <>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                <MapPinned className="size-4" /> Maps URL
                              </p>
                              <Input
                                value={item?.metadata?.mapsUri}
                                readOnly
                                autoFocus={false}
                              />
                            </div>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                <Flag className="size-4" /> Region Code
                              </p>
                              <div className="flex items-center gap-3">
                                <Input
                                  value={item?.storefrontAddress?.regionCode}
                                  readOnly
                                  autoFocus={false}
                                />
                                <div className="w-[128px] hidden lg:block"></div>
                              </div>
                            </div>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                <Link className="size-4" /> Review URL
                              </p>
                              <Input
                                value={item?.metadata?.newReviewUri}
                                readOnly
                                autoFocus={false}
                              />
                            </div>
                          </>
                        )}
                        {seeMore === item?.name ? (
                          <Button
                            variant="outline"
                            onClick={() => setSeeMore(null)}
                            className="lg:hidden"
                          >
                            See Less
                          </Button>
                        ) : (
                          <Button
                            variant="outline"
                            onClick={() => setSeeMore(item?.name)}
                            className="lg:hidden"
                          >
                            See More
                          </Button>
                        )}
                      </label>
                    </div>
                  ))}
                </RadioGroup>
              </div> */}
              <div className="space-y-5 h-[60vh] overflow-y-auto scrollbar-thin">
                {gmbData?.map((item) => (
                  <div
                    key={item?.["Account_Data"]?.name}
                    className="space-y-3 border p-5 rounded-xl"
                  >
                    <p className="font-medium">
                      {item?.["Account_Data"]?.accountName}
                    </p>
                    <div className="space-y-3">
                      {item?.["Location_Data"]?.locations?.length > 0 ? (
                        <RadioGroup
                          defaultValue={selectedLocation}
                          onValueChange={(e) => {
                            setSelectedLocation(e);
                            setSelectedAcc(item?.["Account_Data"]?.name);
                          }}
                        >
                          {item?.["Location_Data"]?.locations?.map(
                            (location) => (
                              <div key={location?.name} className="flex gap-3">
                                <RadioGroupItem
                                  value={location?.name}
                                  id={location?.name}
                                  className="flex-shrink-0"
                                ></RadioGroupItem>
                                <label
                                  htmlFor={location?.name}
                                  className={`w-full lg:min-w-[450px] border cursor-pointer grid grid-cols-1 lg:grid-cols-2 gap-3 p-5  flex-grow-0 dark:bg-darkLightBg rounded-[10px] ${selectedLocation === location?.name ? "bg-[#f0f5ff] border-newPrimary" : ""}`}
                                >
                                  <div className="flex flex-col gap-2">
                                    <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                      <User className="size-4" /> Name
                                    </p>
                                    <Input
                                      value={location?.title}
                                      readOnly
                                      autoFocus={false}
                                    />
                                  </div>
                                  <div className="flex flex-col gap-2">
                                    <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                      <MapPin className="size-4" /> Location
                                    </p>
                                    <div className="flex items-center gap-3">
                                      <Input
                                        value={
                                          location?.storefrontAddress
                                            ?.administrativeArea
                                        }
                                        readOnly
                                        autoFocus={false}
                                      />

                                      {seeMore === location?.name ? (
                                        <Button
                                          variant="outline"
                                          onClick={() => setSeeMore(null)}
                                          className="hidden lg:block"
                                        >
                                          See Less
                                        </Button>
                                      ) : (
                                        <Button
                                          variant="outline"
                                          onClick={() =>
                                            setSeeMore(location?.name)
                                          }
                                          className="hidden lg:block"
                                        >
                                          See More
                                        </Button>
                                      )}
                                    </div>
                                  </div>
                                  {seeMore === location?.name && (
                                    <>
                                      <div className="flex flex-col gap-2">
                                        <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                          <MapPinned className="size-4" /> Maps
                                          URL
                                        </p>
                                        <Input
                                          value={location?.metadata?.mapsUri}
                                          readOnly
                                          autoFocus={false}
                                        />
                                      </div>
                                      <div className="flex flex-col gap-2">
                                        <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                          <Flag className="size-4" /> Region
                                          Code
                                        </p>
                                        <div className="flex items-center gap-3">
                                          <Input
                                            value={
                                              location?.storefrontAddress
                                                ?.regionCode
                                            }
                                            readOnly
                                            autoFocus={false}
                                          />
                                          <div className="w-[128px] hidden lg:block"></div>
                                        </div>
                                      </div>
                                      <div className="flex flex-col gap-2">
                                        <p className="font-medium text-[15px] font-poppins text-[#777] flex items-center gap-1">
                                          <Link className="size-4" /> Review URL
                                        </p>
                                        <Input
                                          value={
                                            location?.metadata?.newReviewUri
                                          }
                                          readOnly
                                          autoFocus={false}
                                        />
                                      </div>
                                    </>
                                  )}
                                  {seeMore === location?.name ? (
                                    <Button
                                      variant="outline"
                                      onClick={() => setSeeMore(null)}
                                      className="lg:hidden"
                                    >
                                      See Less
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="outline"
                                      onClick={() => setSeeMore(location?.name)}
                                      className="lg:hidden"
                                    >
                                      See More
                                    </Button>
                                  )}
                                </label>
                              </div>
                            )
                          )}
                        </RadioGroup>
                      ) : (
                        <p className="text-red-500 text-center">
                          No Business found
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-end items-center gap-x-3 mt-7">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => setOpenConfirm(true)}
                  disabled={!selectedLocation}
                >
                  Continue
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GmbIntegration;
