/* eslint-disable react/prop-types */
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";

const CopyMagicLinkModal = ({ isOpenModal, setIsOpenModal, code }) => {
  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(`https://go.magicreview.ai/${code}`)
      .then(() => {
        toast.success("Link copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy link to clipboard!");
      });
  };
  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Copy MagicLink</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <Input value={`https://go.magicreview.ai/${code}`} readOnly />
          <div className="w-full flex justify-end gap-3 mt-5">
            <Button variant="secondary" onClick={() => setIsOpenModal(false)}>
              Cancel
            </Button>
            <Button onClick={handleCopyLink}>Copy</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CopyMagicLinkModal;
