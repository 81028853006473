import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { useState, useRef } from "react";
import { toast } from "sonner";
import LoaderIcon from "react-loader-icon";
import { usePostContact } from "../../query/useContact";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import CommonHeading from "../../components/home/CommonHeading";
export const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const phoneRegex = /^[0-9+]*$/;
const Contact = () => {
  const form = useRef();
  const [isLoading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    message: "",
    phone_number: "",
    company: "",
  });
  const { mutate } = usePostContact();

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // Phone number validation regex

    // Email validation regex

    // Check if the input field is the phone number and if it matches the regex
    if (name === "phone_number" && !phoneRegex.test(value)) {
      // Invalid phone number, don't update the state
      return;
    }
    setFormFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formFields.name.trim() === "" ||
      formFields.company.trim() === "" ||
      formFields.phone_number.trim() === "" ||
      formFields.email.trim() === "" ||
      formFields.message.trim() === "" ||
      isLoading
    ) {
      toast.error("Kindly provide all the inputs!");
      return;
    }

    setLoading(true);
    if (!emailRegex.test(formFields.email)) {
      toast.error("Invalid email");
      setLoading(false);
      return;
    }

    let data = {
      name: formFields.name,
      email: formFields.email,
      message: formFields.message,
      phone_number: formFields.phone_number,
      company: formFields.company,
    };

    mutate(data, {
      onSuccess: (res) => {
        setLoading(false);
        toast.success("Message sent successfully");
        form.current.reset();
        setFormFields({
          name: "",
          email: "",
          message: "",
          phone_number: "",
          company: "",
        });
      },
      onError: () => {
        setLoading(false);
        toast.error("An error occured");
      },
    });
  };

  return (
    <div className="flex flex-col min-h-screen bg-white text-black">
      <Helmet>
        <title>Contact Us - Magicreview</title>
        <meta
          name="description"
          content="Improve feedback collection: Use magicreview to simplify, engage, and grow. Empower your business for digital excellence. Contact us today!"
        />
        <link rel="canonical" href="https://www.magicreview.ai/contact-us" />
      </Helmet>

      <div className="relative bg-white h-auto">
        <Mainnav />
        <div className="w-full container flex flex-row flex-wrap items-center justify-between mt-10 mb-10 md:mt-16 xl:mt-24 md:mb-32 gap-9 md:gap-0">
          <motion.div className="flex flex-col gap-3 md:gap-4 items-center  md:mb-7">
            <CommonHeading title={" Have any questions?\nContact us today!"} />
          </motion.div>
          <form
            className="flex flex-wrap justify-between md:w-[55%]"
            onSubmit={handleSubmit}
            ref={form}
          >
            <div className="w-full font-poppins sm:w-[48%] mb-3">
              <label htmlFor="">Name</label>
              <input
                name="name"
                value={formFields.name}
                onChange={handleFormChange}
                type="text"
                className="w-full font-poppins border border-[#C2C2C2] rounded-[10px] p-3 mt-3 h-[45px]"
              />
            </div>
            <div className="w-full font-poppins sm:w-[48%] mb-3">
              <label htmlFor="">Company</label>
              <input
                name="company"
                value={formFields.company}
                onChange={handleFormChange}
                type="text"
                className="w-full font-poppins border border-[#C2C2C2] rounded-[10px] p-3 mt-3 h-[45px]"
              />
            </div>
            <div className="w-full font-poppins sm:w-[48%] mb-3">
              <label htmlFor="">Phone Number</label>
              <input
                type="tel"
                name="phone_number"
                value={formFields.phone_number}
                onChange={handleFormChange}
                className="w-full font-poppins border border-[#C2C2C2] rounded-[10px] p-3 mt-3 h-[45px]"
              />
            </div>
            <div className="w-full font-poppins sm:w-[48%] mb-3">
              <label htmlFor="">Email Address</label>
              <input
                type="text"
                name="email"
                value={formFields.email}
                onChange={handleFormChange}
                className="w-full font-poppins border border-[#C2C2C2] rounded-[10px] p-3 mt-3 h-[45px]"
              />
            </div>
            <div className="w-full">
              <label htmlFor="" className="font-poppins">
                Company Description
              </label>
              <textarea
                cols="30"
                rows="5"
                name="message"
                value={formFields.message}
                onChange={handleFormChange}
                className="w-full font-poppins border border-[#C2C2C2] rounded-[10px] p-3 resize-none mt-3"
              ></textarea>
              {/* <input
              type="text"
              className="w-full border border-[#C2C2C2] rounded-[10px] p-3"
            /> */}
            </div>
            <button
              className="rounded-lg font-medium text-sm bg-newPrimary text-white font-poppins mt-6 px-8 py-3.5  hover:bg-white hover:text-primary active:scale-95 w-full border border-newPrimary transition-all duration-200 h-11"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <LoaderIcon type="cylon" color="#4F75FA" size={25} />
              ) : (
                "Send"
              )}
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
