import { useParams, useSearchParams } from "react-router-dom";
import {
  useGetEntityReviewPermissions,
  usePostGetRedeemDetails,
  usePostGetReviewDetails,
  usePostGetReviewDetailsQr,
  usePostRedeemReview,
} from "../../query/useReview";
import { useEffect, useState } from "react";
import MainLogo from "../../components/mainlogo";
import { Button } from "../../components/ui/button";
import { dayFormatter } from "../../utils/formatter";
import Google from "../../assets/reviewservices/google.svg";
import { Badge, BadgeCheck } from "lucide-react";
import { toast } from "sonner";
import PinModal from "./component/PinModal";
import { LogoLoading } from "../../components/loading/loading";

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const RedeemPage = () => {
  const { id } = useParams();

  const [reviewDetails, setReviewDetails] = useState(null);
  const [error, setError] = useState(null);
  const [pin, setPin] = useState("");
  const [isInternal, setIsInternal] = useState(false);
  const [openPinModal, setOpenPinModal] = useState(false);

  const { mutate: getRedeemDetails } = usePostGetRedeemDetails();
  const { mutate: redeemReview, isLoading: redeemReviewLoading } =
    usePostRedeemReview();

  const handleRedeemReview = () => {
    redeemReview(
      { id: id, pin: pin, internal: isInternal },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenPinModal(false);
          getRedeemDetails(
            { id: id },
            {
              onSuccess: (res) => {
                setReviewDetails(res);
                setError("");
              },
              onError: (err) => {
                setError(err?.response?.data?.message);
              },
            }
          );
        },
        onError: (err) => {
          toast.error(err?.response?.data?.message);
        },
      }
    );
  };

  useEffect(() => {
    if (id) {
      getRedeemDetails(
        { id: id },
        {
          onSuccess: (res) => {
            setReviewDetails(res);
            setError("");
          },
          onError: (err) => {
            setError(err?.response?.data?.message);
          },
        }
      );
    }
  }, [id]);

  if (redeemReviewLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="min-h-dvh flex flex-col justify-center gap-10 dark:bg-white overflow-y-auto py-10">
      <div
        className={`w-[90%] md:w-[60%] mx-auto rounded-3xl rounded-tl-none relative font-poppins p-5 border border-[#7190ff]/15 bg-white shadow-[0_4px_4px_0_rgba(0,0,0,0.25)] flex flex-col justify-center items-center gap-5`}
      >
        <div className="absolute -top-7 left-0 font-medium text-[13px] text-newPrimary border border-b-0 border-newPrimary rounded-t px-5 py-1">
          {id}
        </div>
        <div className="flex items-center justify-between w-full font-pro">
          <div className="flex gap-2 items-center">
            <img
              src={reviewDetails?.profile_photo_url || "/new-logo.svg"}
              alt=""
              className="size-7"
              referrerPolicy="no-referrer"
            />
            <div>
              <h1 className="font-pro text-sm font-medium ">
                {reviewDetails?.display_name || "MagicReview"}
              </h1>
              <p className="text-[#777] text-[11px] font-pro ">
                {reviewDetails?.created_at &&
                  dayFormatter(reviewDetails?.created_at)}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            {reviewDetails?.redirected === "google" && (
              <img src={Google} alt="Google" className="size-6" />
            )}

            {reviewDetails?.star_rating && (
              <>
                <svg width="20" height="20" fill="none">
                  <path
                    fill={"#F9C33C"}
                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                  />
                </svg>
                <p> {wordsToNumber[reviewDetails?.star_rating]}/5</p>
              </>
            )}
          </div>
        </div>

        <hr className="w-full" />

        {/* <p className="font-medium text-left w-full">{id}</p> */}

        {error ? (
          <p className="font-heebo text-sm text-[#353535] italic">{error}!</p>
        ) : (
          <p className="font-heebo text-sm text-[#353535]">
            {
              reviewDetails?.all_reviews?.find(
                (item) => item?.is_posted_ai_review
              )?.text
            }
          </p>
        )}

        {reviewDetails?.redeemed ? (
          <div className="h-[30px] w-fit flex items-center gap-3 py-3 px-3 rounded-md bg-[#34A853]/10 border border-[#34A853]/20 text-[#34A853] font-poppins font-medium mx-auto text-[11px]">
            <BadgeCheck className="size-5 fill-[#34A853] text-white" />
            <p>Redeemed</p>
          </div>
        ) : (
          <Button
            className="w-full md:w-1/2 h-10"
            disabled={error}
            onClick={() => setOpenPinModal(true)}
          >
            Redeem
          </Button>
        )}
      </div>

      {reviewDetails?.verified_posted ? (
        <div className="h-[30px] w-fit flex items-center gap-3 py-3 px-3 rounded-md bg-[#34A853]/10 border border-[#34A853]/20 text-[#34A853] font-poppins font-medium mx-auto text-[11px]">
          <BadgeCheck className="size-5 fill-[#34A853] text-white" />{" "}
          <p>
            Verified on{" "}
            <span className="capitalize">{reviewDetails?.redirected}</span>
          </p>
        </div>
      ) : (
        <div className="mx-auto w-[70%] space-y-5">
          <div className="h-[30px] w-fit flex items-center gap-3 py-3 px-3 rounded-md bg-[#f9c33c]/10 border border-[#f9c33c]/20 text-[#f9c33c] font-poppins font-medium mx-auto text-[11px]">
            <Badge className="size-5 fill-[#f9c33c] text-white" />{" "}
            <p>Not Verified</p>
          </div>
          <p className="font-poppins font-medium text-xs text-[#656565] text-center">
            Please wait 30 minutes until we verify the review posted.
          </p>
        </div>
      )}

      <div className="md:flex items-center justify-center gap-5 hidden py-5 lg:py-10 ">
        <div className="h-[1px] w-full bg-[#ebebeb]"></div>
        <div className="flex items-center justify-between flex-shrink-0 gap-2">
          <p className="font-poppins text-[#4d4d4d] text-xl flex-shrink-0">
            Powered by
          </p>
          <MainLogo className={"flex-shrink-0"} />
        </div>
        <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div>
      </div>
      <div className="md:hidden py-10">
        <div className="flex items-center justify-center gap-5 ">
          {/* <div className="h-[1px] w-full bg-[#ebebeb]"></div> */}
          <p className="font-poppins  text-sm flex-shrink-0">Powered by</p>
          {/* <div className="h-[1px] w-full bg-[#ebebeb] flex-shrink"></div> */}
        </div>

        <div className="flex items-center justify-center gap-5 mt-3">
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
          <div className="flex items-center justify-center gap-x-2 flex-shrink-0">
            <img
              src={"/new-logo.svg"}
              alt="MagicReview Logo"
              className="w-[30px] flex-shrink-0"
            />
            <h1 className="font-avertaBold font-bold text-[18px] text-[#4d4d4d] -tracking-[1%] -mt-1">
              magicreview
            </h1>
          </div>
          {/* <div className="h-[1px] w-full bg-[#4d4d4d]"></div> */}
        </div>
      </div>

      <PinModal
        openModal={openPinModal}
        setOpenModal={setOpenPinModal}
        pin={pin}
        setPin={setPin}
        handleAction={handleRedeemReview}
        internal={isInternal}
        setInternal={setIsInternal}
      />
    </div>
  );
};

export default RedeemPage;
