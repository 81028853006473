import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteCampaignApi,
  getCampaignApi,
  getViewCampaignApi,
  postAddCampaignApi,
  postRunCampaignApi,
  putEditCampaignApi,
} from "../api/campaignApi";

export const useGetCampaign = () => {
  return useQuery({
    queryKey: ["getCampaignApi"],
    queryFn: () => getCampaignApi(),
  });
};

export const useGetViewCampaign = ({ id }) => {
  return useQuery({
    queryKey: ["getViewCampaignApi", id],
    queryFn: () => getViewCampaignApi({ id: id }),
    enabled: Boolean(id),
  });
};

export const usePostAddCampaign = () => {
  return useMutation({
    mutationKey: ["postAddCampaignApi"],
    mutationFn: (data) => postAddCampaignApi(data),
  });
};

export const usePostRunCampaign = () => {
  return useMutation({
    mutationKey: ["postRunCampaignApi"],
    mutationFn: (id) => postRunCampaignApi(id),
  });
};

export const usePutEditCampaign = () => {
  return useMutation({
    mutationKey: ["putEditCampaignApi"],
    mutationFn: ({ id, data }) => putEditCampaignApi({ id: id, data: data }),
  });
};

export const useDeleteCampaign = () => {
  return useMutation({
    mutationKey: ["deleteCampaignApi"],
    mutationFn: (id) => deleteCampaignApi(id),
  });
};
