/* eslint-disable react/prop-types */

import { ChevronRight } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { useState } from "react";
import AddUpdateModal from "./AddUpdateModal";
import AddOfferModal from "./AddOfferModal";
import AddEventModal from "./AddEventModal";
import AddScheduleUpdateModal from "./AddScheduleUpdateModal";
import AddScheduleOfferModal from "./AddScheduleOfferModal";
import AddScheduleEventModal from "./AddScheduleEventModal";

const AddSchedulePostModal = ({ openModal, setOpenModal }) => {
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [openEventModal, setOpenEventModal] = useState(false);
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Schedule Post</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro divide-y">
          <div
            className="flex items-center justify-between group hover:cursor-pointer pb-3"
            onClick={() => setOpenUpdateModal(true)}
          >
            <div className="space-y-1 group-hover:underline">
              <p className="text-semibold">Schedule update</p>
              <p className="text-sm text-[#525252] dark:text-white">
                Post updates to your customers on Google
              </p>
            </div>
            <ChevronRight className="text-[#525252] dark:text-white" />
          </div>
          <div
            className="flex items-center justify-between group hover:cursor-pointer py-3"
            onClick={() => setOpenOfferModal(true)}
          >
            <div className="space-y-1 group-hover:underline">
              <p className="text-semibold">Schedule offer</p>
              <p className="text-sm text-[#525252] dark:text-white">
                Create an offer and attract customers to your business
              </p>
            </div>
            <ChevronRight className="text-[#525252] dark:text-white" />
          </div>
          <div
            className="flex items-center justify-between group hover:cursor-pointer pt-3"
            onClick={() => {
              setOpenEventModal(true);
            }}
          >
            <div className="space-y-1 group-hover:underline">
              <p className="text-semibold">Schedule event</p>
              <p className="text-sm text-[#525252] dark:text-white">
                Let customers know about events that you’re hosting
              </p>
            </div>
            <ChevronRight className="text-[#525252] dark:text-white" />
          </div>
        </div>
        <AddScheduleUpdateModal
          openModal={openUpdateModal}
          setOpenModal={setOpenUpdateModal}
        />
        <AddScheduleOfferModal
          openModal={openOfferModal}
          setOpenModal={setOpenOfferModal}
        />
        <AddScheduleEventModal
          openModal={openEventModal}
          setOpenModal={setOpenEventModal}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddSchedulePostModal;
