/* eslint-disable react/prop-types */
import {
  ArrowLeft,
  CircleCheckBig,
  CircleDashed,
  Download,
} from "lucide-react";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import Airbnb from "../../../assets/reviewservices/airbnb.svg";
import Booking from "../../../assets/reviewservices/booking.svg";
import Google from "../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../assets/reviewservices/yelp.svg";
import { Button } from "../../../components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { useDarkMode } from "../../../context/DarkModeProvider";
import {
  useGetReviewsAnalyticsNegative,
  useGetReviewsAnalyticsNonPosted,
  useGetReviewsAnalyticsPosted,
} from "../../../query/useReview";
import { dayFormatter, timeFormatter } from "../../../utils/formatter";
import Layout from "../component/Layout";
import RaReviewsModal from "./components/RaReviewsModal";
import RaViewFormModal from "./components/RaViewFormModal";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../components/ui/tooltip";
import DownloadStatsRedirectedModal from "./components/DownloadStatsRedirectedModal";

const redirects = [
  {
    name: "Google",
    value: "google",
    img: Google,
  },
  {
    name: "Yelp",
    value: "yelp",
    img: Yelp,
  },
  {
    name: "Trustpilot",
    value: "trustpilot",
    img: TrustPilot,
  },
  {
    name: "Booking",
    value: "booking",
    img: Booking,
  },
  {
    name: "Airbnb",
    value: "airbnb",
    img: Airbnb,
  },
  {
    name: "Trip Advisor",
    value: "tripadvisor",
    img: TripAdvisor,
  },
];

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const ReviewsAnalyticsComponent = ({
  isOpenStats,
  setIsOpenStats,
  statsId,
}) => {
  const { darkMode } = useDarkMode();
  const [tab, setTab] = useState("redirected");
  const [redirectsFromApi, setRedirectsFromApi] = useState([]);
  const [redirect, setRedirect] = useState("all");
  const [index, setIndex] = useState(0);
  const [isOpenReviews, setIsOpenReviews] = useState(false);
  const [openReviewsId, setOpenReviewsId] = useState();
  const [verifiedPosted, setVerifiedPosted] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const {
    data: reviewsAnalyticsPosted,
    isLoading: reviewsAnalyticsPostedLoading,
  } = useGetReviewsAnalyticsPosted(
    {
      limit: 10,
      index: index,
      redirect: redirect === "all" ? "" : redirect,
      verified_posted: verifiedPosted,
      stats: isOpenStats,
      employee_code: statsId,
    },
    {
      onSuccess: (res) => {
        setRedirectsFromApi(res.redirect);
      },
      enabled: tab === "redirected",
    }
  );

  const {
    data: reviewsAnalyticsNonPosted,
    isLoading: reviewsAnalyticsNonPostedLoading,
  } = useGetReviewsAnalyticsNonPosted(
    {
      limit: 10,
      index: index,
      stats: isOpenStats,
      employee_code: statsId,
      // redirect: redirect,
    },
    {
      enabled: tab === "non-redirected",
    }
  );

  const {
    data: reviewsAnalyticsNegative,
    isLoading: reviewsAnalyticsNegativeLoading,
  } = useGetReviewsAnalyticsNegative(
    {
      limit: 10,
      index: index,
      stats: isOpenStats,
      employee_code: statsId,
      // redirect: redirect,
    },
    {
      enabled: tab === "negative",
    }
  );

  const reviewsAnalytics = () => {
    switch (tab) {
      case "redirected":
        return reviewsAnalyticsPosted;
      case "non-redirected":
        return reviewsAnalyticsNonPosted;
      case "negative":
        return reviewsAnalyticsNegative;
    }
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const reviewPlatforms = (platform) => {
    switch (platform) {
      case "google":
        return <img src={Google} title="Google" />;
      case "yelp":
        return <img src={Yelp} title="Yelp" />;
      case "trustpilot":
        return <img src={TrustPilot} title="TrustPilot" />;
      case "airbnb":
        return <img src={Airbnb} className="w-5" title="Airbnb" />;
      case "booking":
        return <img src={Booking} title="Booking" />;
      case "tripadvisor":
        return <img src={TripAdvisor} className="w-5" title="TripAdvisor" />;
    }
  };

  return (
    <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px]">
      {isOpenStats && (
        <button
          onClick={() => setIsOpenStats(false)}
          className="flex items-center gap-2 text-sm font-poppins mb-5"
        >
          <ArrowLeft /> Back
        </button>
      )}
      <div className="flex items-center justify-between flex-wrap gap-5">
        {tab === "redirected" ? (
          <div className="flex items-center gap-5">
            <Select
              value={redirect}
              onValueChange={(value) => setRedirect(value)}
            >
              <SelectTrigger className="w-[200px] font-poppins h-10 text-sm">
                <SelectValue placeholder="Select redirect" />
              </SelectTrigger>
              <SelectContent className="font-poppins">
                <SelectItem value={"all"}>
                  <div className="flex items-center gap-2 justify-between">
                    All redirects
                  </div>
                </SelectItem>
                {redirects
                  ?.filter((item) =>
                    redirectsFromApi
                      ?.map((item) => item?.redirected)
                      ?.includes(item?.value)
                  )
                  ?.map((item, i) => (
                    <SelectItem value={item?.value} key={item?.value}>
                      <div className="flex items-center gap-2 justify-between">
                        <img src={item?.img} alt="" className="w-5 h-5" />
                        {item?.name}

                        <div className="font-semibold size-[22px] bg-[#f0f5ff] flex items-center justify-center rounded-full flex-shrink-0 dark:bg-newPrimary/20 dark:text-newPrimary">
                          {
                            redirectsFromApi?.find(
                              (red) => red?.redirected === item?.value
                            )?.count
                          }
                        </div>
                      </div>
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>

            <button
              className={`font-poppins flex-shrink-0 text-sm text-newPrimary font-medium bg-white dark:bg-darkBg px-5 h-10 flex justify-center items-center gap-2 rounded-[8px] ${verifiedPosted && "border border-newPrimary "}`}
              onClick={() => {
                if (verifiedPosted) {
                  setVerifiedPosted(false);
                } else {
                  setVerifiedPosted(true);
                }
              }}
            >
              Show verified reviews{" "}
              <span className="text-xs font-semibold size-6 rounded-full bg-newPrimary/20 text-center inline-flex justify-center items-center">
                {reviewsAnalyticsPosted?.total_verified_posted}
              </span>
            </button>
          </div>
        ) : (
          <div className="hidden lg:block"></div>
        )}
        <div className="flex items-center gap-5">
          <Select
            value={tab}
            onValueChange={(value) => {
              setTab(value);
              setIndex(0);
            }}
          >
            <SelectTrigger className="w-[250px] font-poppins h-10 text-sm gap-3">
              <SelectValue placeholder="Select redirect" />{" "}
              {
                <div className="font-semibold size-[22px] bg-[#f0f5ff] flex items-center justify-center rounded-full flex-shrink-0 dark:bg-newPrimary/20 dark:text-newPrimary">
                  {reviewsAnalytics()?.totol_results}
                </div>
              }
            </SelectTrigger>
            <SelectContent className="font-poppins ">
              <SelectItem value={"redirected"}>Redirected</SelectItem>
              <SelectItem value={"non-redirected"}>Non-Redirected</SelectItem>
              <SelectItem value={"negative"}>Negative Feedback</SelectItem>
            </SelectContent>
          </Select>
          {isOpenStats && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <Button
                    variant="outline"
                    className="size-9 bg-white"
                    size="icon"
                    onClick={() => setIsOpenModal(true)}
                  >
                    <Download />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  Download stats for Review Analytics
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>

      {reviewsAnalytics()?.data?.length <= 0 ? (
        <div className="flex flex-col items-center justify-center w-full h-[60vh] dark:bg-darkLightBg rounded-lg font-pro">
          <img
            src="/illustrations/reviews.svg"
            alt="Questions"
            className="w-[100px] h-[100px] mx-auto mt-10"
          />
          <h1 className="mt-[18px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
            Generate Review
          </h1>
          <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
            No Reviews Generated Yet!
          </p>
        </div>
      ) : (
        <div className="rounded-xl overflow-hidden mt-10">
          <Table>
            <TableHeader>
              <TableRow>
                {tab === "non-redirected" ? (
                  <>
                    {" "}
                    {[
                      "Date",
                      "Reviews",
                      "Redeem Reviews",
                      "Device Type & Location",
                      "Details",
                    ].map((item) => (
                      <TableHead
                        key={item}
                        className="text-[#808080] dark:text-white text-[15px]"
                      >
                        {item}
                      </TableHead>
                    ))}
                  </>
                ) : tab === "redirected" ? (
                  <>
                    {" "}
                    {[
                      "Platform",
                      "Ratings",
                      "Date",
                      "Verified Post",
                      "Reviews",
                      "Name",
                      "Redeem Reviews",
                      "Device Type & Location",
                      "Details",
                    ].map((item) => (
                      <TableHead
                        key={item}
                        className="text-[#808080] dark:text-white text-[15px]"
                      >
                        {item}
                      </TableHead>
                    ))}
                  </>
                ) : (
                  <>
                    {["Date", "Device Type & Location", "Details"].map(
                      (item) => (
                        <TableHead
                          key={item}
                          className="text-[#808080] dark:text-white text-[15px]"
                        >
                          {item}
                        </TableHead>
                      )
                    )}
                  </>
                )}
              </TableRow>
            </TableHeader>
            <TableBody className="divide-y">
              {(
                tab === "redirected"
                  ? reviewsAnalyticsPostedLoading
                  : tab === "non-redirected"
                    ? reviewsAnalyticsNonPostedLoading
                    : reviewsAnalyticsNegativeLoading
              ) ? (
                <TableRow>
                  {tab === "non-redirected" || tab === "negative" ? (
                    <>
                      {new Array(5).fill("").map((_, i) => (
                        <TableCell key={i} className="bg-white dark:bg-darkBg">
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </>
                  ) : (
                    <>
                      {new Array(9).fill("").map((_, i) => (
                        <TableCell key={i} className="bg-white dark:bg-darkBg">
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </>
                  )}
                </TableRow>
              ) : reviewsAnalytics()?.data?.length > 0 ? (
                reviewsAnalytics()?.data?.map((charge, i) => (
                  <>
                    <TableRow key={i} className="text-[15px]">
                      <TableCell
                        className={`capitalize ${(tab === "non-redirected" || tab === "negative") && "hidden"}`}
                      >
                        {charge?.redirected
                          ? reviewPlatforms(charge?.redirected)
                          : "-"}
                      </TableCell>
                      <TableCell
                        className={` ${(tab === "non-redirected" || tab === "negative") && "hidden"}`}
                      >
                        {charge?.star_rating
                          ? wordsToNumber[charge?.star_rating] + "/5"
                          : "-"}
                      </TableCell>
                      <TableCell className="">
                        <div className="cursor-default">
                          {charge?.created_at && (
                            <div className="space-y-0.5">
                              <p>{dayFormatter(charge?.created_at)}</p>
                              <p className="text-[10px] text-[#888]">
                                {timeFormatter(charge?.created_at)}
                              </p>
                            </div>
                          )}
                        </div>
                      </TableCell>
                      <TableCell
                        className={` ${(tab === "non-redirected" || tab === "negative") && "hidden"}`}
                      >
                        {charge?.verified_posted ? (
                          <p className="text-[#34a853] flex items-center gap-2 font-poppins  text-xs">
                            <CircleCheckBig className="size-4" />{" "}
                          </p>
                        ) : (
                          <p className="text-[#ffaf19] flex items-center gap-2 font-poppins  text-xs">
                            {" "}
                            <CircleDashed className="size-4 " />{" "}
                          </p>
                        )}
                      </TableCell>
                      <TableCell
                        className={` ${tab === "negative" && "hidden"}`}
                      >
                        <Button
                          variant="secondary"
                          size="sm"
                          className="text-xs"
                          onClick={() => {
                            setIsOpenReviews(true);
                            setOpenReviewsId(charge?.id);
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                      <TableCell
                        className={` ${(tab === "non-redirected" || tab === "negative") && "hidden"}`}
                      >
                        {charge?.display_name || "-"}
                      </TableCell>
                      <TableCell
                        className={` ${tab === "negative" && "hidden"}`}
                      >
                        {charge?.redeemed ? (
                          <p className="text-[#34a853] flex items-center gap-2 font-poppins  text-xs">
                            <CircleCheckBig className="size-4" />{" "}
                          </p>
                        ) : (
                          <p className="text-[#ffaf19] flex items-center gap-2 font-poppins  text-xs">
                            {" "}
                            <CircleDashed className="size-4 " />{" "}
                          </p>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="space-y-0.5">
                          <p>{charge?.device_type}</p>
                          <p className="text-[10px] text-[#888]">
                            {charge?.location} - {charge?.ip_address}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell>
                        {/* {charge?.voice_review ? (
                          <ReviewAnalyticsAudio
                            url={charge?.original_voice?.voice}
                          />
                        ) : ( */}
                        <Button
                          variant="secondary"
                          size="sm"
                          className="text-xs"
                          onClick={() => {
                            setIsOpenReviews(false);
                            setOpenReviewsId(charge?.id);
                          }}
                        >
                          View
                        </Button>
                        {/* )} */}
                      </TableCell>
                    </TableRow>
                    {isOpenReviews && charge?.id === openReviewsId && (
                      <RaReviewsModal
                        isOpen={charge?.id === openReviewsId}
                        setIsOpen={setOpenReviewsId}
                        data={charge}
                      />
                    )}
                    {!isOpenReviews && charge?.id === openReviewsId && (
                      <RaViewFormModal
                        isOpen={charge?.id === openReviewsId}
                        setIsOpen={setOpenReviewsId}
                        data={charge}
                      />
                    )}
                  </>
                ))
              ) : null}
            </TableBody>
          </Table>
        </div>
      )}
      {reviewsAnalytics()?.totol_results > 10 && (
        <div className="flex justify-between items-center mt-5">
          {index <= 0 ? (
            <div></div>
          ) : (
            <Button
              className="disabled:hidden"
              onClick={handlePrev}
              disabled={index <= 0}
            >
              Prev
            </Button>
          )}
          <Button
            className=" disabled:hidden"
            onClick={handleNext}
            disabled={index + 10 > reviewsAnalytics()?.totol_results}
          >
            Next
          </Button>
        </div>
      )}
      <DownloadStatsRedirectedModal
        openModal={isOpenModal}
        setOpenModal={setIsOpenModal}
        employeeCode={statsId}
        tab={tab}
      />
    </div>
  );
};

export default ReviewsAnalyticsComponent;
