import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getAiReviewPosted,
  getAiReviewPostedAggregation,
  getDefaultEntityQuestions,
  getEntityExists,
  getLatestReviews,
  getReviewCount,
  getTotalReviewsGeneratedCount,
  getUniqueEntityUrl,
  postCreateNewEntity,
  // postUpdateEntityQuestions,
  getEntityInformation,
  postUpdateEntityQuestionsV2,
  deleteEntityQuestionsV2,
  getEntitySuggestions,
  getStats,
  getStatsGraph,
  postAddEntityQuestion,
  putUpdateEntityQuestion,
  deleteEntityQuestion,
  getQuestionCategories,
  postAddQuestionCategories,
  putEditQuestionCategories,
  deleteQuestionCategories,
  getQuestionKeyword,
  postAddQuestionKeyword,
  putEditQuestionKeyword,
  deleteQuestionKeyword,
  updateEntityInfo,
  getTags,
  getTagsExist,
  postAddTag,
  deleteTag,
  getEntityInfo,
  postToggleQuestion,
  getQuestionSetsApi,
  postAddQuestionSetApi,
  putUpdateQuestionSetApi,
  deleteQuestionSetApi,
  getSearchQuestions,
  getEntityTheme,
  putUpdateEntityTheme,
  deleteEntityTheme,
  postToggleCategory,
  getToggleDirectReview,
  postToggleDirectReview,
  getDefaultEntityQuestionsForSets,
  getSetIdsForQuesitons,
  getToggleVoiceReview,
  postToggleVoiceReview,
  postSetCategoryOrder,
  getAudioUrl,
  postToggleCategoryMandatory,
  postGenerateAiQuestions,
  postAddAiQuestions,
  getSupportedLanguageLocales,
  putSupportedLanguageLocales,
  getPreviewQuestionsForSets,
  postRequestReview,
  postGenerateAiQuestionsByVoice,
  getStorageUsage,
  getUploadKnowledgeBase,
  postUploadKnowledgeBase,
  deleteKnowledgeBase,
  getUploadKnowledgeUrl,
  postUploadKnowledgeUrl,
  deleteKnowledgeUrl,
  getEntityPin,
  postEntityPin,
  putResetEntityPin,
  getCustomDomain,
  postAddCustomDomain,
  putEditCustomDomain,
  deleteCustomDomain,
} from "../api/onboardingApi";
export const useGetEntityExists = (config) => {
  return useMutation({
    mutationKey: ["getEntityExists"],
    mutationFn: () => getEntityExists(),
    ...config,
  });
};

export const usePostCreateNewEntity = () => {
  return useMutation({
    mutationKey: ["postCreateNewEntity"],
    mutationFn: (data) => postCreateNewEntity(data),
  });
};

export const useGetDefaultEntityQuestions = (params, config) => {
  return useQuery({
    queryKey: ["getDefaultQuestions", params],
    queryFn: () => getDefaultEntityQuestions(params),
    ...config,
  });
};

export const useGetDefaultEntityQuestionsForSets = (params, config) => {
  return useQuery({
    queryKey: ["getDefaultQuestionsForSets", params],
    queryFn: () => getDefaultEntityQuestionsForSets(params),
    ...config,
  });
};

export const useGetPreviewQuestionsForSets = (params, config) => {
  return useQuery({
    queryKey: ["getPreviewQuestionsForSets", params],
    queryFn: () => getPreviewQuestionsForSets(params),
    ...config,
  });
};

// export const usePostUpdateEntityQuestions = () => {
//   return useMutation({
//     mutationKey: ["postUpdateEntityQuestions"],
//     mutationFn: (data) => postUpdateEntityQuestions(data),
//   });
// };

export const usePostUpdateEntityQuestionsV2 = () => {
  return useMutation({
    mutationKey: ["postUpdateEntityQuestionsV2"],
    mutationFn: (data) => postUpdateEntityQuestionsV2(data),
  });
};

export const usePostAddEntityQuestion = () => {
  return useMutation({
    mutationKey: ["postAddEntityQuestion"],
    mutationFn: (data) => postAddEntityQuestion(data),
  });
};

export const usePutUpdateEntityQuestion = () => {
  return useMutation({
    mutationKey: ["putUpdateEntityQuestion"],
    mutationFn: ({ id, data }) =>
      putUpdateEntityQuestion({ id: id, data: data }),
  });
};

export const useDeleteEntityQuestion = () => {
  return useMutation({
    mutationKey: ["deleteEntityQuestion"],
    mutationFn: (id) => deleteEntityQuestion(id),
  });
};

export const usePostToggleQuestion = () => {
  return useMutation({
    mutationKey: ["postToggleQuestion"],
    mutationFn: (id) => postToggleQuestion(id),
  });
};

export const useDeleteEntityQuestionsV2 = () => {
  return useMutation({
    mutationKey: ["deleteEntityQuestionsV2"],
    mutationFn: (data) => deleteEntityQuestionsV2(data),
  });
};

export const useGetUniqueEntityUrl = (config) => {
  return useQuery({
    queryKey: ["getUniqueEntityUrl"],
    queryFn: () => getUniqueEntityUrl(),
    ...config,
  });
};

export const useGetTotalReviewsGeneratedCount = (config) => {
  return useQuery({
    queryKey: ["getTotalReviewsGeneratedCount"],
    queryFn: () => getTotalReviewsGeneratedCount(),
    ...config,
  });
};

export const useGetReviewCount = ({ startDate, endDate }, config) => {
  return useQuery({
    queryKey: ["getReviewCount"],
    queryFn: () => getReviewCount({ startDate, endDate }),
    ...config,
  });
};

export const useGetAiReviewPosted = (config) => {
  return useQuery({
    queryKey: ["getAiReviewPosted"],
    queryFn: () => getAiReviewPosted(),
    ...config,
  });
};

export const useGetLatestReviews = (config) => {
  return useQuery({
    queryKey: ["getLatestReviews"],
    queryFn: () => getLatestReviews(),
    ...config,
  });
};

export const useGetAiReviewPostedAggregation = ({ aggregation }, config) => {
  return useQuery({
    queryKey: ["getAiReviewPostedAggregation", aggregation],
    queryFn: () => getAiReviewPostedAggregation({ aggregation }),
    ...config,
  });
};

export const useGetEntityInformation = ({ id }, config) => {
  return useQuery({
    queryKey: ["getEntityInformation"],
    queryFn: () => getEntityInformation(id),
    ...config,
  });
};

export const useGetEntitySuggestions = ({ placename }, config) => {
  return useQuery({
    queryKey: ["getEntitySuggestions", placename],
    queryFn: () => getEntitySuggestions(placename),
    ...config,
  });
};

export const useGetStats = () => {
  return useQuery({
    queryKey: ["getStats"],
    queryFn: () => getStats(),
  });
};

export const useGetStatsGraph = (sort) => {
  return useQuery({
    queryKey: ["getStatsGraph", sort],
    queryFn: () => getStatsGraph(sort),
    retry: 0,
  });
};

export const useGetQuestionCategories = (config) => {
  return useQuery({
    queryKey: ["getQuestionCategories"],
    queryFn: () => getQuestionCategories(),
    ...config,
  });
};

export const usePostAddQuestionCategories = () => {
  return useMutation({
    mutationKey: ["postAddQuestionCategories"],
    mutationFn: (data) => postAddQuestionCategories(data),
  });
};

export const usePutEditQuestionCategories = () => {
  return useMutation({
    mutationKey: ["putEditQuestionCategories"],
    mutationFn: (data) => putEditQuestionCategories(data),
  });
};

export const useDeleteQuestionCategories = () => {
  return useMutation({
    mutationKey: ["postDeleteQuestionCategories"],
    mutationFn: (data) => deleteQuestionCategories(data),
  });
};

export const useGetQuestionKeywords = (config) => {
  return useQuery({
    queryKey: ["getQuestionKeyword"],
    queryFn: () => getQuestionKeyword(),
    ...config,
  });
};

export const usePostAddQuestionKeyword = () => {
  return useMutation({
    mutationKey: ["postAddQuestionKeyword"],
    mutationFn: (data) => postAddQuestionKeyword(data),
  });
};

export const usePutEditQuestionKeyword = () => {
  return useMutation({
    mutationKey: ["putEditQuestionKeyword"],
    mutationFn: (data) => putEditQuestionKeyword(data),
  });
};

export const useDeleteQuestionKeyword = () => {
  return useMutation({
    mutationKey: ["deleteQuestionKeyword"],
    mutationFn: (data) => deleteQuestionKeyword(data),
  });
};

export const useGetEntityInfo = (config) => {
  return useQuery({
    queryKey: ["getEntityInfo"],
    queryFn: () => getEntityInfo(),
    ...config,
  });
};

export const useUpdateEntityInfo = () => {
  return useMutation({
    mutationKey: ["updateEntityInfo"],
    mutationFn: (data) => updateEntityInfo(data),
  });
};

export const useGetTags = () => {
  return useQuery({
    queryKey: ["getTags"],
    queryFn: () => getTags(),
    refetchOnWindowFocus: false,
  });
};

export const useGetTagExist = () => {
  return useMutation({
    mutationKey: ["getTagExist"],
    mutationFn: (data) => getTagsExist(data),
  });
};

export const usePostAddTag = () => {
  return useMutation({
    mutationKey: ["postAddTag"],
    mutationFn: (data) => postAddTag(data),
  });
};

export const useDeleteTag = () => {
  return useMutation({
    mutationKey: ["deleteTag"],
    mutationFn: (data) => deleteTag(data),
  });
};

export const useGetQuestionSets = (config) => {
  return useQuery({
    queryKey: ["getQuestionSets"],
    queryFn: () => getQuestionSetsApi(),
    ...config,
  });
};

export const useGetSetIdsForQuestions = (id, config) => {
  return useQuery({
    queryKey: ["getSetIdsForQuestions", id],
    queryFn: () => getSetIdsForQuesitons(id),
    ...config,
  });
};

export const usePostAddQuestionSet = () => {
  return useMutation({
    mutationKey: ["postAddQuestionSet"],
    mutationFn: (data) => postAddQuestionSetApi(data),
  });
};

export const usePutUpdateQuestionSet = () => {
  return useMutation({
    mutationKey: ["putUpdateQuestionSet"],
    mutationFn: (data) => putUpdateQuestionSetApi(data),
  });
};

export const useDeleteQuestionSet = () => {
  return useMutation({
    mutationKey: ["deleteQuestionSet"],
    mutationFn: (id) => deleteQuestionSetApi(id),
  });
};

export const useGetSearchQuestions = ({ search }, config) => {
  return useQuery({
    queryKey: ["getSearchQuestions", search],
    queryFn: () => getSearchQuestions({ search: search }),
    enabled: Boolean(search),
    ...config,
  });
};

export const useGetEntityTheme = (config) => {
  return useQuery({
    queryKey: ["getEntityTheme"],
    queryFn: () => getEntityTheme(),
    ...config,
  });
};

export const usePutUpdateEntityTheme = () => {
  return useMutation({
    mutationKey: ["putUpdateEntityTheme"],
    mutationFn: (data) => putUpdateEntityTheme(data),
  });
};

export const useDeleteEntityTheme = () => {
  return useMutation({
    mutationKey: ["deleteEntityTheme"],
    mutationFn: () => deleteEntityTheme(),
  });
};

export const useGetEntityPin = () => {
  return useQuery({
    queryKey: ["getEntityPin"],
    queryFn: () => getEntityPin(),
  });
};

export const usePostEntityPin = () => {
  return useMutation({
    mutationKey: ["postEntityPin"],
    mutationFn: (data) => postEntityPin(data),
  });
};

export const usePutResetEntityPin = () => {
  return useMutation({
    mutationKey: ["putResetEntityPin"],
    mutationFn: (data) => putResetEntityPin(data),
  });
};

export const useGetCustomDomain = () => {
  return useQuery({
    queryKey: ["getCustomDomain"],
    queryFn: () => getCustomDomain(),
  });
};

export const usePostAddCustomDomain = () => {
  return useMutation({
    mutationKey: ["postAddCustomDomain"],
    mutationFn: (data) => postAddCustomDomain(data),
  });
};

export const usePutEditCustomDomain = () => {
  return useMutation({
    mutationKey: ["putEditCustomDomain"],
    mutationFn: (data) => putEditCustomDomain(data),
  });
};

export const useDeleteCustomDomain = () => {
  return useMutation({
    mutationKey: ["deleteCustomDomain"],
    mutationFn: () => deleteCustomDomain(),
  });
};

export const usePostToggleCategory = () => {
  return useMutation({
    mutationKey: ["postToggleCategory"],
    mutationFn: (id) => postToggleCategory(id),
  });
};

export const usePostToggleCategoryMandatory = () => {
  return useMutation({
    mutationKey: ["postToggleCategoryMandatory"],
    mutationFn: (id) => postToggleCategoryMandatory(id),
  });
};

export const useGetToggleDirectReview = () => {
  return useQuery({
    queryKey: ["getToggleDirectReview"],
    queryFn: () => getToggleDirectReview(),
  });
};

export const usePostToggleDirectReview = () => {
  return useMutation({
    mutationKey: ["postToggleDirectReview"],
    mutationFn: () => postToggleDirectReview(),
  });
};

export const useGetToggleVoiceReview = ({ entityId }, config) => {
  return useQuery({
    queryKey: ["getToggleVoiceReview", entityId],
    queryFn: () => getToggleVoiceReview(entityId),
    ...config,
  });
};

export const usePostToggleVoiceReview = () => {
  return useMutation({
    mutationKey: ["postToggleVoiceReview"],
    mutationFn: ({ entityId }) => postToggleVoiceReview(entityId),
  });
};

export const usePostSetCategoryOrder = () => {
  return useMutation({
    mutationKey: ["postSetCategoryOrder"],
    mutationFn: (data) => postSetCategoryOrder(data),
  });
};

export const useGetAudioUrl = () => {
  return useMutation({
    mutationKey: ["getAudioUrl"],
    mutationFn: (data) => getAudioUrl(data),
  });
};

export const usePostGenerateAiQuestions = () => {
  return useMutation({
    mutationKey: ["postGenerateAiQuestions"],
    mutationFn: (data) => postGenerateAiQuestions(data),
  });
};

export const usePostGenerateAiQuestionsByVoice = () => {
  return useMutation({
    mutationKey: ["postGenerateAiQuestionsByVoice"],
    mutationFn: (data) => postGenerateAiQuestionsByVoice(data),
  });
};

export const usePostAddAiQuestions = () => {
  return useMutation({
    mutationKey: ["postAddAiQuestions"],
    mutationFn: ({ set, data }) => postAddAiQuestions({ set: set, data: data }),
  });
};

export const useGetSupportedLanguageLocales = (config) => {
  return useQuery({
    queryKey: ["getSupportedLanguageLocales"],
    queryFn: () => getSupportedLanguageLocales(),
    ...config,
  });
};

export const usePutEditSupportedLanguageLocales = () => {
  return useMutation({
    mutationKey: ["putSupportedLanguageLocales"],
    mutationFn: (data) => putSupportedLanguageLocales(data),
  });
};

export const usePostRequestReview = () => {
  return useMutation({
    mutationKey: ["postRequestReview"],
    mutationFn: (data) => postRequestReview(data),
  });
};

export const useGetStorageUsage = () => {
  return useQuery({
    queryKey: ["getStorageUsage"],
    queryFn: () => getStorageUsage(),
  });
};

export const useGetUploadKnowledgeBase = () => {
  return useQuery({
    queryKey: ["getUploadKnowledgeBase"],
    queryFn: () => getUploadKnowledgeBase(),
    refetchInterval: 1000 * 60 * 5,
  });
};

export const usePostUploadKnowledgeBase = () => {
  return useMutation({
    mutationKey: ["postUploadKnowledgeBase"],
    mutationFn: (data) => postUploadKnowledgeBase(data),
  });
};

export const useDeleteKnowledgeBase = () => {
  return useMutation({
    mutationKey: ["deleteKnowledgeBase"],
    mutationFn: (id) => deleteKnowledgeBase(id),
  });
};

export const useGetUploadKnowledgeUrl = () => {
  return useQuery({
    queryKey: ["getUploadKnowledgeUrl"],
    queryFn: () => getUploadKnowledgeUrl(),
    refetchInterval: 1000 * 60 * 5,
  });
};

export const usePostUploadKnowledgeUrl = () => {
  return useMutation({
    mutationKey: ["postUploadKnowledgeUrl"],
    mutationFn: (data) => postUploadKnowledgeUrl(data),
  });
};

export const useDeleteKnowledgeUrl = () => {
  return useMutation({
    mutationKey: ["deleteKnowledgeUrl"],
    mutationFn: (id) => deleteKnowledgeUrl(id),
  });
};
