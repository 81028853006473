const clientsImage = [
  "/client11.png",
  "/client12.png",
  "/client10.png",
  "/client9.png",
  "/client13.png",
  "/client15.png",
  "/client14.png",
  "/client16.png",
  "/client17.png",
  "/client1.png",
  "/client2.png",
  "/client3.png",
  "/client4.png",
  "/client5.png",
  "/client6.png",
  "/client7.png",
  "/client8.png",
  // "/client18.png",
  "/client19.png",
  "/client20.png",
  "/client21.png",
  "/client22.png",
  "/client23.png",
  //   "/primestay.svg",
  "/mila-blue.png",
  //   "/lallummas.png",
];

const ClientInfiniteScroll = () => {
  return (
    <div className="space-y-3 my-12">
      <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
        <ul className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_li]:mx-5 [&_img]:max-w-none h-[70px]">
          {clientsImage.map((logo, index) => (
            <li
              key={index}
              className={
                "rounded-[12px] overflow-hidden h-[60px] flex justify-center items-center"
              }
            >
              <img
                src={logo}
                alt={"Client Logos"}
                className="w-[120px] rounded-[12px]"
              />
            </li>
          ))}
        </ul>
        <ul
          className="flex animate-infinite-scroll items-center justify-center md:justify-start [&_li]:mx-5 [&_img]:max-w-none h-[70px] "
          aria-hidden="true"
        >
          {clientsImage.map((logo, index) => (
            <li
              key={index}
              className={
                "rounded-[12px] overflow-hidden h-[60px] flex justify-center items-center"
              }
            >
              <img
                src={logo}
                alt={"Client logos"}
                className="w-[120px] rounded-[12px]"
              />
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="inline-flex w-full flex-nowrap overflow-hidden [mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-128px),transparent_100%)]">
        <ul className="flex animate-infinite-scroll-reverse items-center justify-center md:justify-start [&_li]:mx-5 [&_img]:max-w-none h-[70px] ">
          {clientsImage.slice(11).map((logo, index) => (
            <li
              key={index}
              className={
                "rounded-[12px] overflow-hidden h-[60px] flex justify-center items-center"
              }
            >
              <img
                src={logo}
                alt={"Client Logos"}
                className="w-[120px] rounded-[12px] object-contain"
              />
            </li>
          ))}
        </ul>
        <ul
          className="flex animate-infinite-scroll-reverse items-center justify-center md:justify-start [&_li]:mx-5 [&_img]:max-w-none h-[70px] "
          aria-hidden="true"
        >
          {clientsImage.slice(11).map((logo, index) => (
            <li
              key={index}
              className={
                "rounded-[12px] overflow-hidden h-[60px] flex justify-center items-center"
              }
            >
              <img
                src={logo}
                alt={"Client logos"}
                className="w-[120px] rounded-[12px]"
              />
            </li>
          ))}
        </ul>
      </div> */}
    </div>
  );
};

export default ClientInfiniteScroll;
