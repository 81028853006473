/* eslint-disable react/prop-types */
import React from "react";
import { motion } from "framer-motion";

const ProductKeyFeatures = ({ contents }) => {
  return (
    <div className="lg:py-20 py-10 font-poppins">
      <div className="">
        <motion.p
          className="font-semibold lg:text-[38px] lg:leading-[66px] lg:tracking-tighter text-center"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
          }}
          viewport={{
            once: true,
          }}
        >
          Key Features
        </motion.p>
        <motion.p
          className="text-sm lg:text-[17px] text-[#2d2d2d] text-center leading-[35px]"
          initial={{
            opacity: 0,
            y: 50,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.4,
            ease: "easeInOut",
            delay: 0.2,
          }}
          viewport={{
            once: true,
          }}
        >
          Trusted By top b2C Companies over the globe
        </motion.p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-5 lg:mt-20 mt-10 lg:px-20">
        {contents.map((content, i) => (
          <motion.div
            key={content.title}
            className={`w-full h-fit bg-white border border-[#ebebeb] rounded-[16px]  py-10 px-5 shadow-[0_1px_2px_0_#00000040] space-y-5 ${i === 1 && "mt-10"}`}
            initial={{
              opacity: 0,
              y: 50,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.4,
              ease: "easeInOut",
              delay: i * 0.2,
            }}
            viewport={{
              once: true,
            }}
          >
            {content.icon}
            <p className="font-semibold lg:text-[22px] lg:leading-[30px] text-[#1d1d1f] text-wrap whitespace-pre">
              {content.title}
            </p>
            <p>{content.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default ProductKeyFeatures;
