import Apimanager from "./axios";

export const getAllCardsApi = async () => {
  const res = await Apimanager.get("/entity/nfccard");
  return res.data;
};

export const createCardApi = async (data) => {
  const res = await Apimanager.post("/entity/nfccard", data);
  return res.data;
};

export const updateCardApi = async (data) => {
  const res = await Apimanager.put("/entity/nfccard", data);
  return res.data;
};

export const deleteCardApi = async (id) => {
  const res = await Apimanager.delete(`/entity/${id}/delete_nfccard`);
  return res.data;
};

export const toggleCardApi = async (id) => {
  const res = await Apimanager.post(`/entity/${id}/toggle_nfccard`);
  return res.data;
};
