/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/prop-types */
import { ChevronDown, FilePlus, X } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { forwardRef, useEffect, useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  usePostStandardAdd,
  usePostStandardGenerate,
  usePostStandardSchedule,
  usePostUploadMedia,
  usePutEditSchedulePost,
} from "../../../../query/useReview";
import LoaderIcon from "react-loader-icon";
import { Textarea } from "../../../../components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../components/ui/select";
import { Input } from "../../../../components/ui/input";
import { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { dayFormatter } from "../../../../utils/formatter";
import { useQueryClient } from "@tanstack/react-query";
import DatePicker from "react-datepicker";

const DateInput = forwardRef(({ value, onClick, placeholder }, ref) => (
  <div className="relative w-full" onClick={onClick} ref={ref}>
    <Input
      type="text"
      value={value}
      readOnly
      className="w-full focus-visible:ring-0 focus-visible:outline-none focus-visible:ring-offset-0"
      placeholder={placeholder}
    />
    <ChevronDown className="text-[#ebebeb] absolute right-5 top-1/2 -translate-y-1/2 size-4" />
  </div>
));

const UpdateScheduleUpdateModal = ({ openModal, setOpenModal, data }) => {
  const queryClient = useQueryClient();
  const [step, setStep] = useState(1);
  const [imagePreview, setImagePreview] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [error, setError] = useState(null);
  const [extension, setExtension] = useState(null);
  const [media, setMedia] = useState(null);
  const [description, setDescription] = useState("");
  const [cta, setCta] = useState("NONE");
  const [url, setUrl] = useState("");
  const [generatedPost, setGeneratedPost] = useState(null);
  const [summary, setSummary] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [scheduleDate, setScheduleDate] = useState();

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes

  const {
    mutate: uploadMedia,
    isLoading: uploadMediaLoading,
    isError: uploadMediaLoadingError,
  } = usePostUploadMedia();
  const { mutate: standardGenerate, isLoading: standardGenerateLoading } =
    usePostStandardGenerate();

  const { mutate: standardAdd, isLoading: standardAddLoading } =
    usePutEditSchedulePost();

  const handleUploadMedia = (file) => {
    const ext = file.type.includes("image") ? "PHOTO" : "VIDEO";
    const formData = new FormData();
    formData.append("file", file);
    formData.append("mediaFormat", ext);
    uploadMedia(formData, {
      onSuccess: (res) => {
        setMedia(res.media);
        setImagePreview(URL.createObjectURL(file));
        setError(null); // Clear any previous error
        setExtension(ext);
      },
      onError: (err) => {
        if (err.response.data.message) {
          setError(err.response.data.message);
        } else {
          setError("Something went wrong. Please try again later.");
        }
      },
    });
  };

  const handleStandardGenerate = () => {
    standardGenerate(
      {
        media: media,
        input: description,
        callToAction:
          cta === "NONE"
            ? {}
            : {
                actionType: cta,
                url: url,
              },
      },
      {
        onSuccess: (res) => {
          setGeneratedPost(res);
          setSummary(res.summary);
          if (res.status === "error") {
            toast.error(res.message);
          } else {
            setStep(2);
          }
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleStandartAdd = () => {
    standardAdd(
      {
        id: data?.id,
        data: {
          schedule_time: scheduleDate,
          post_data: {
            summary: summary,
            media: media,
            callToAction:
              cta === "NONE"
                ? {}
                : {
                    actionType: cta,
                    url: url,
                  },
            languageCode: data?.post_data?.languageCode,
            topicType: data?.post_data?.topicType,
          },
        },
      },
      {
        onSuccess: (res) => {
          toast.success("Post added successfully.");
          setOpenModal();
          queryClient.invalidateQueries("getSchedulePosts");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Something went wrong. Please try again later.");
          }
        },
      }
    );
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const file = e.dataTransfer.files[0];
    if (!file.type.includes("image")) {
      setError("Please upload an image file.");
      return;
    }

    if (file && file.size < MAX_FILE_SIZE) {
      handleUploadMedia(file);
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller one.");
    }
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size < MAX_FILE_SIZE) {
      handleUploadMedia(file);
    } else if (file) {
      setError("File size exceeds 5MB. Please upload a smaller one.");
    }
  };

  // Function to filter out previous times
  const filterTime = (time) => {
    const selectedDateTime = new Date(time);
    const now = new Date();

    return selectedDateTime.getTime() > now.getTime();
  };

  useEffect(() => {
    setMedia(data?.post_data?.media);
    setImagePreview(data?.post_data?.media?.[0]?.sourceUrl);
    setScheduleDate(new Date(data?.schedule_time));
    setSummary(data?.post_data?.summary);
    setCta(data?.post_data?.callToAction?.actionType);
    setUrl(data?.post_data?.callToAction?.url);
  }, [data]);

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal();
      }}
    >
      <DialogContent className="lg:max-w-xl">
        <DialogHeader>
          <DialogTitle>Edit Update</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5 max-h-[80vh] overflow-y-auto scrollbar-thin">
          <div className="flex flex-col items-center justify-center w-full py-5 ">
            <div
              className={`max-w-sm w-full mx-auto aspect-video border-4 relative ${
                dragActive ? "border-blue-500" : "border-gray-300"
              } border-dashed flex items-center justify-center rounded-lg`}
              onDragEnter={handleDrag}
              onDragOver={handleDrag}
              onDragLeave={handleDrag}
              onDrop={handleDrop}
            >
              {uploadMediaLoading && !uploadMediaLoadingError ? (
                <LoaderIcon type="cylon" color="#7190FF" />
              ) : (
                <>
                  {imagePreview ? (
                    <>
                      <img
                        src={imagePreview}
                        alt="Uploaded"
                        className="w-fit h-full object-center rounded-lg"
                      />
                    </>
                  ) : (
                    <label
                      htmlFor="upload"
                      className="flex flex-col items-center justify-center text-center cursor-pointer gap-0.5"
                    >
                      {/* <FilePlus className="text-gray-400 mb-1" /> */}
                      <img src="/file-upload.svg" alt="" className="" />
                      <p className="text-gray-400 text-sm">
                        Drag photo here
                        <br />
                        <span className="text-newPrimary underline inline-block">
                          Select photo
                        </span>
                      </p>

                      <input
                        type="file"
                        id="upload"
                        hidden
                        onChange={handleChange}
                        accept="image/*"
                      />
                    </label>
                  )}
                </>
              )}

              {imagePreview && (
                <Button
                  className="absolute top-2 right-2 w-6 h-6"
                  shape="pill"
                  size="icon"
                  onClick={() => {
                    setImagePreview(null);
                  }}
                >
                  <X className="w-4 h-4" />
                </Button>
              )}
            </div>
            {error && <p className="text-red-500 mt-2 text-xs">{error}</p>}
          </div>
          {/* <div className="space-y-3">
                <label htmlFor="description">
                  Describe the post you have in mind
                </label>
                <Textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  id="description"
                />
              </div> */}
          <div className="space-y-5">
            <div className="space-y-3 relative w-full">
              <label htmlFor="">Schedule At</label>
              <DatePicker
                selected={scheduleDate}
                onChange={(date) => setScheduleDate(date)}
                showTimeSelect
                customInput={<DateInput placeholder={"Schedule At"} />}
                wrapperClassName="w-full"
                placeholderText="Schedule At"
                dateFormat={"MM/dd/yyyy h:mm aa"}
                minDate={new Date()}
                filterTime={filterTime}
                timeIntervals={15}
                timeFormat="HH:mm"
              />
            </div>
            <div className="space-y-3">
              <label htmlFor="">Edit Summary</label>
              <Textarea
                value={summary}
                onChange={(e) => setSummary(e.target.value)}
                className="min-h-[200px]"
              />
            </div>
          </div>
          <div className="space-y-3">
            <label htmlFor="cta">Add Button</label>
            <Select value={cta} onValueChange={(item) => setCta(item)}>
              <SelectTrigger>
                <SelectValue placeholder="Select Button" />
              </SelectTrigger>
              <SelectContent>
                {[
                  "NONE",
                  "BOOK",
                  "ORDER",
                  "LEARN_MORE",
                  "SIGN_UP",
                  "CALL",
                  "SHOP",
                  "OFFER",
                ]?.map((item) => (
                  <SelectItem key={item?.id} value={item}>
                    <p className="capitalize">
                      {item?.split("_")?.join(" ")?.toLowerCase()}
                    </p>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          {!["CALL", "NONE"]?.includes(cta) && (
            <div className="space-y-3">
              <label htmlFor="cta">Add Url</label>
              <Input value={url} onChange={(e) => setUrl(e.target.value)} />
            </div>
          )}

          {/* {generatedPost?.media?.[0]?.mediaFormat === "PHOTO" ? (
            <>
              {generatedPost?.media?.[0]?.sourceUrl ? (
                <img
                  className="w-full "
                  src={generatedPost?.media?.[0]?.sourceUrl}
                  alt={"media"}
                  referrerPolicy="no-referrer"
                />
              ) : (
                <div className="w-full h-64 flex justify-center items-center">
                  <img src="/no-picture.svg" alt="" className="w-16 h-16" />
                </div>
              )}
            </>
          ) : (
            <video>
              <source src={generatedPost?.media?.[0]?.sourceUrl} />
            </video>
          )}
          {isPreview && (
            <p className="text-sm text-[#525252] dark:text-white">
              {dayFormatter(new Date())}
            </p>
          )}
          {isPreview ? (
            <p>{summary}</p>
          ) : (
            <div className="space-y-5">
              <div className="space-y-3 relative w-full">
                <label htmlFor="">Schedule At</label>
                <DatePicker
                  selected={scheduleDate}
                  onChange={(date) => setScheduleDate(date)}
                  showTimeSelect
                  customInput={<DateInput placeholder={"Schedule At"} />}
                  wrapperClassName="w-full"
                  placeholderText="Schedule At"
                  dateFormat={"MM/dd/yyyy h:mm aa"}
                  minDate={new Date()}
                  filterTime={filterTime}
                  timeIntervals={15}
                  timeFormat="HH:mm"
                />
              </div>
              <div className="space-y-3">
                <label htmlFor="">Edit Summary</label>
                <Textarea
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                  className="min-h-[200px]"
                />
              </div>
            </div>
          )}
          {isPreview && (
            <>
              {generatedPost?.callToAction?.actionType !== "CALL" ? (
                <a
                  href={generatedPost?.callToAction?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500 underline capitalize inline-block"
                >
                  {generatedPost?.callToAction?.actionType
                    ?.split("_")
                    .join(" ")
                    .toLowerCase()}
                </a>
              ) : (
                <a
                  href={`tel:${generatedPost?.callToAction?.phoneNumber}`}
                  className="text-blue-500 underline"
                >
                  {generatedPost?.callToAction?.phoneNumber}
                </a>
              )}
            </>
          )} */}
          <div className="flex justify-end gap-5">
            <Button onClick={() => setOpenModal()} variant="secondary">
              Cancel
            </Button>
            {/* <Button
              variant="secondary"
              onClick={() => setIsPreview(!isPreview)}
            >
              {isPreview ? "Edit" : "Preview"}
            </Button> */}
            <Button onClick={handleStandartAdd} disabled={standardAddLoading}>
              Confirm
            </Button>
          </div>
          {/* </>
          )} */}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateScheduleUpdateModal;
