/* eslint-disable react/prop-types */
import { Label, Pie, PieChart } from "recharts";
import {
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "../../../components/ui/chart";
import { useMemo } from "react";

const KeyInsightAnalysisPieChart = ({ data }) => {
  const chartData = useMemo(() => {
    if (data) {
      return [
        {
          reviews: "positive",
          score: data.positive_percentage,
          fill: "#4f75fa",
        },
        {
          reviews: "negative",
          score: data.negative_percentage,
          fill: "#f9c33c",
        },
        {
          reviews: "neutral",
          score: data.neutral_percentage,
          fill: "#e5d1fa",
        },
      ];
    } else {
      return [
        {
          reviews: "positive",
          score: 100,
          fill: "#4f75fa",
        },
        {
          reviews: "negative",
          score: 0,
          fill: "#f9c33c",
        },
        {
          reviews: "neutral",
          score: 0,
          fill: "#e5d1fa",
        },
      ];
    }
  }, [data]);

  const chartConfig = {
    reviews: {
      label: "Reviews",
    },
    positive: {
      label: "Positive Percentage",
      color: "#4f75fa",
    },
    negative: {
      label: "Negative Percentage",
      color: "#f9c33c",
    },
    neutral: {
      label: "Neutral Percentage",
      color: "#e5d1fa",
    },
  };

  return (
    <div className="flex flex-col xl:flex-row lg:items-center lg:justify-center gap-1 font-pro">
      <ChartContainer
        config={chartConfig}
        className="mx-auto aspect-square min-h-[240px] 2xl:min-h-[250px]"
      >
        <PieChart>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent hideLabel />}
          />
          <Pie
            data={chartData}
            dataKey="score"
            nameKey="reviews"
            innerRadius={60}
            strokeWidth={5}
          >
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      >
                        {data?.total_reviews?.toLocaleString()}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      >
                        Reviews
                      </tspan>
                    </text>
                  );
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>

      <div className="grid grid-cols-1 gap-3">
        <div className="bg-[#f0f5ff] dark:bg-darkLightBg rounded-lg  p-2 space-y-3 w-full">
          <p className="font-medium text-[#4a4a4a] text-xs">Overall Score</p>
          <p className="font-bold text-[#34a853] text-[27px] text-center">
            {data?.sentiment_score}
          </p>
          {/* <p className="font-bold 2xl:text-base text-sm">Positive Reviews</p> */}
        </div>
        <div className="bg-[#f0f5ff] dark:bg-darkLightBg rounded-lg p-2 space-y-3 w-full">
          <p className="flex items-center gap-1 font-medium 2xl:text-base text-sm">
            <span className="size-4 rounded-full bg-[#4f75fa] block"></span>{" "}
            Positive -{" "}
            <span className="font-bold 2xl:text-xl">
              {data?.positive_percentage}%
            </span>
          </p>
          <p className="flex items-center gap-1 font-medium 2xl:text-base text-sm">
            <span className="size-4 rounded-full bg-[#f9c33c] block"></span>{" "}
            Negative -{" "}
            <span className="font-bold 2xl:text-xl">
              {data?.negative_percentage}%
            </span>
          </p>
          <p className="flex items-center gap-1 font-medium 2xl:text-base text-sm">
            <span className="size-4 rounded-full  bg-[#e5d1fa] block"></span>{" "}
            Neutral -{" "}
            <span className="font-bold 2xl:text-xl">
              {data?.neutral_percentage}%
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default KeyInsightAnalysisPieChart;
