/* eslint-disable react/prop-types */
import { Switch } from "../../../../components/ui/switch";
import { Modal } from "flowbite-react";
import { Info, Link, Settings } from "lucide-react";
import IconButton from "../../component/IconButton";
import { LogoLoading } from "../../../../components/loading/loading";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";

const LinkedAccount = ({
  isActiveLink,
  setIsActiveLink,
  isOpenModal,
  setIsOpenmodal,
  toggle,
  link,
  setLink,
  handleClick,
  isLoading,
  isEnableLoading,
  isToggleLoading,
  image,
  icon,
  name,
  isComingSoon,
  isSupport,
  onEnableSupport,
  onUpdateSettings,
}) => {
  if (isLoading) {
    return <LogoLoading />;
  }
  return (
    <div className="bg-white border border-[#e7e4e4] dark:border-[#454545] dark:bg-darkLightBg rounded-xl h-16 flex justify-between items-center px-5">
      <div className="flex items-center gap-x-2">
        {image ? <img src={image} className="w-6 h-6" /> : <>{icon}</>}
        <p className="font-pro font-medium text-[15px]">{name}</p>
      </div>
      {isComingSoon ? (
        <p className="text-sm font-semibold">Coming soon</p>
      ) : isSupport ? (
        <div className="flex items-center gap-x-3">
          <p
            className={`font-pro text-sm ${isActiveLink ? "text-[#0DCF0D]" : "text-red-500"}`}
          >
            {isActiveLink ? "Active" : "Inactive"}
          </p>
          <div className="w-[1px] h-[30px] bg-[#e6e6e6]"></div>
          <Switch
            disabled={isToggleLoading}
            checked={isActiveLink}
            onCheckedChange={toggle}
          ></Switch>
          {isToggleLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6] rounded-full animate-spin"></div>
            </div>
          ) : (
            <Button
              className=" rounded-[7px]  px-3 text-sm"
              onClick={() => setIsOpenmodal(true)}
              variant={link ? "secondary" : "default"}
              size="sm"
            >
              {link ? "Update Link" : " Add Link"}
            </Button>
          )}
          {!link && (
            <div>
              <Info className="text-[#888] size-5 fill-[#f9c33c] stroke-white" />
            </div>
          )}
        </div>
      ) : (
        <button
          className="bg-newPrimary rounded-lg text-white px-2 py-1.5 flex justify-center items-center w-[80px] font-poppins text-sm"
          onClick={onEnableSupport}
        >
          {isEnableLoading ? (
            <div className="flex items-center justify-center">
              <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
            </div>
          ) : (
            "Enable"
          )}
        </button>
      )}
      <Dialog
        open={isOpenModal}
        onOpenChange={() => {
          setIsOpenmodal(!isOpenModal);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Settings</DialogTitle>
          </DialogHeader>
          <div className="px-6">
            <p className="font-pro font-medium dark:text-white">Review URL</p>
            <input
              type="text"
              className="w-full border font-pro border-[#e7e4e4] rounded-lg mt-3 dark:bg-darkLightBg dark:border-[#454545] dark:text-white"
              placeholder="Review URL"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />
            <div className="flex justify-end items-center gap-x-3 mt-7">
              <Button disabled={!link} onClick={handleClick}>
                Save
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsOpenmodal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LinkedAccount;
